import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import FormInput from "../../../../../components/FormInput";
import { withTestId } from "../../../../../lib/utils";

export type FacebookPixelFields = {
  pixelId: string | null;
};

type FacebookPixelProps = {
  initialValue: FacebookPixelFields;
  /** Called when the user fills in the form */
  next: SubmitHandler<FacebookPixelFields>;
} & TestingProps;

const FacebookPixelForms = React.memo(function FacebookPixelForms(props: FacebookPixelProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FacebookPixelFields>({
    defaultValues: props.initialValue ?? undefined,
  });

  return (
    <form onSubmit={handleSubmit(props.next)} className="w-full">
      <FormInput
        {...withTestId("facebook-input")}
        id="facebook"
        placeholder="Facebook Link"
        control={{
          type: "text",
          ...register("pixelId", {
            minLength: 15,
          }),
        }}
        errMsg={"Please enter a valid facebook link"}
        isError={errors.pixelId != null}
      />
      <button
        type="submit"
        disabled={Object.keys(errors).length > 0}
        className="uppercase bg-primary text-stone-200 font-bold p-2 w-full rounded disabled:bg-gray-400"
      >
        Add Facebook Pixel
      </button>
    </form>
  );
});

export default FacebookPixelForms;
