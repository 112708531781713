import React from "react";

import { ArrowRightIcon } from "@heroicons/react/solid";
import { FiShare, FiEdit } from "react-icons/fi";

const StoreFrontDetails = React.memo(function StoreFrontDetails({
  slug,
  open,
  setOpen,
}: {
  slug: string;
  open: boolean;
  setOpen: (val: boolean) => void;
}) {
  return (
    <div className="w-full my-1 py-1 flex-col justify-start items-center">
      <div className="w-full h-full">
        <h6 className="text-center break-words font-bold">E-commerce Website</h6>
      </div>
      <div className="w-full my-2 bg-white h-full flex-col justify-center">
        <div>
          <span className="text-base font-bold flex-wrap">Visit your store at:</span>
        </div>
        <div className="flex flex-row justify-between items-center">
          <a href={`https://instasell.io/${slug}`} target="_blank">
            <span className="text-base font-body break-words">https://instasell.io/{slug}</span>
          </a>
          <FiEdit className="h-6 w-6 m-2" onClick={() => setOpen(!open)} />
          <FiShare
            onClick={() => {
              window.navigator.share({ url: `https://instasell.io/${slug}` });
            }}
            className="h-6 w-6 m-2"
            aria-hidden="true"
          />
        </div>
      </div>
      <div className="w-full my-2 bg-white h-full flex flex-row justify-start items-center">
        {/* <ImWhatsapp className="h-6 w-6 m-1" aria-hidden="true" />
        <RiMessengerLine className="h-6 w-6 m-1" aria-hidden="true" /> */}
      </div>
      <button
        type="button"
        className="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-primary hover:bg-opacity-95 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Get custom domain
        <ArrowRightIcon className="ml-3 -mr-1 h-5 w-5" aria-hidden="true" />
      </button>
    </div>
  );
});

export default StoreFrontDetails;
