import React, { useState, useContext, useEffect } from "react";
import { FiLoader } from "react-icons/fi";
import { useParams, useNavigate } from "react-router-dom";
import { Switch } from "@headlessui/react";
// import FormInput from "../../../components/FormInput";
import PagingControls from "../../../../components/PagingControls";
import { ApiContext } from "../../../../lib/services/api";
import { AuthContext } from "../../../../lib/services/auth";
import { CategoryDetailsResponse, ProductListResponse, SellerBanner } from "../../../../lib/types";
import ProductGrid from "./components/ProductGrid";
import { classNames, slugify } from "../../../../lib/utils";
import ProductList from "./components/ProductList";
import { NotificationContext } from "../../../../lib/services/notification";
import EditCategoryModal from "./components/EditCategoryModal";
import CategoryBanners from "./components/CategoryBanners";

const RESULT_PER_PAGE = 24;

const CategoryDetails = () => {
  const { categorySlug } = useParams();
  const navigate = useNavigate();

  const { sellerDetails, refreshAndReturnFirebaseToken, localeDetails } = useContext(AuthContext);
  const { getCategory, editCategory, fetchProducts, toggleProductsOfCategory } =
    useContext(ApiContext);
  const { addNotification, removeNotificationByTag } = useContext(NotificationContext);

  const [isOpen, setIsOpen] = useState(false);
  const [category, setCategory] = useState<CategoryDetailsResponse | null>(null);
  const [categoryUpdatedDetails, setCategoryUpdatedDetails] =
    useState<CategoryDetailsResponse | null>(null);
  const [isUpdatingCategory, setIsUpdatingCategory] = useState<boolean>(false);
  const [savingProducts, setSavingProducts] = useState(false);
  const [gridViewEnabled, setGridViewEnabled] = useState(true);

  const [products, setProducts] = useState<ProductListResponse[]>([]);
  const [productSearchValue, setProductSearchValue] = useState<string>("");
  const [goBack, setGoBack] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [cursorId, setCursorId] = useState<string | null>(null);
  const [prevCursorId, setprevCursorId] = useState<string | null>(null);
  const [originalProductIds, setOriginalProductIds] = useState<string[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<string[]>([]);

  useEffect(() => {
    const getProducts = async () => {
      const refreshedToken = await refreshAndReturnFirebaseToken();
      if (refreshedToken !== null) {
        const fetchedProducts = await fetchProducts(
          refreshedToken,
          goBack ? 1 : 0,
          pageNumber === 1 ? null : goBack ? null : cursorId,
          pageNumber === 1 ? null : goBack ? prevCursorId : null,
          RESULT_PER_PAGE
        );
        if (fetchedProducts.Err) {
          setProducts([]);
        }
        if (fetchedProducts.Data) {
          setProducts(fetchedProducts.Data.products);
          setCursorId(fetchedProducts.Data.next);
          setprevCursorId(fetchedProducts.Data.prev);
        }
      }
    };
    getProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sellerDetails, pageNumber]);

  const handlePageChange = (backPress: boolean) => {
    if (backPress) {
      setPageNumber(Math.max(pageNumber - 1, 1));
    } else {
      setPageNumber(pageNumber + 1);
    }
    setGoBack(backPress);
  };

  function renderSwitch() {
    return (
      <Switch.Group as="div" className="flex items-center my-2">
        <Switch.Label as="span" className="mr-3">
          <span className="text-sm font-medium text-gray-900">Grid View</span>
        </Switch.Label>
        <Switch
          checked={gridViewEnabled}
          onChange={setGridViewEnabled}
          className={classNames(
            !gridViewEnabled ? "bg-primary" : "bg-gray-200",
            "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          )}
        >
          <span className="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            className={classNames(
              !gridViewEnabled ? "translate-x-5" : "translate-x-0",
              "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
            )}
          />
        </Switch>
        <Switch.Label as="span" className="ml-3">
          <span className="text-sm font-medium text-gray-900">List View</span>
        </Switch.Label>
      </Switch.Group>
    );
  }
  useEffect(() => {
    const fetchCategory = async () => {
      if (categorySlug) {
        const refreshedToken = await refreshAndReturnFirebaseToken();
        if (refreshedToken !== null) {
          const fetchedCategory = await getCategory(
            refreshedToken,
            categorySlug,
            (pageNumber - 1) * 20
          );
          if (fetchedCategory.Err) {
            setCategory({} as CategoryDetailsResponse);
          }
          if (fetchedCategory.Data) {
            setCategory(fetchedCategory.Data);
            setSelectedProducts(fetchedCategory.Data.ProductIDs);
            setOriginalProductIds(fetchedCategory.Data.ProductIDs);
          }
        }
      }
    };
    fetchCategory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sellerDetails, categorySlug]);

  const saveProducts = async () => {
    // setLoading(true);
    setSavingProducts(true);
    removeNotificationByTag("Category");
    const removedProductIds = originalProductIds.filter(
      (prodId) => !selectedProducts.includes(prodId)
    );
    const addedProductIds = selectedProducts.filter(
      (prodId) => !originalProductIds.includes(prodId)
    );
    const refreshedToken = await refreshAndReturnFirebaseToken();
    if (refreshedToken !== null && categorySlug != null) {
      await toggleProductsOfCategory(
        refreshedToken,
        addedProductIds ?? [],
        removedProductIds ?? [],
        categorySlug
      );
      removeNotificationByTag("Category");
      addNotification("success", "Successfully updated the products list!", "Category");
    }
    setSavingProducts(false);
    // setLoading(false);
  };
  const handleSave = async () => {
    const newCategory = Object.assign({}, categoryUpdatedDetails);

    if (newCategory.Name.length < 3) {
      alert("Please enter a category name that is greater than 3 characters");
      return;
    }
    if (categorySlug == null || category == null) {
      return;
    }
    const slug = slugify(newCategory.Name.trim());
    newCategory.Slug = slug;

    newCategory.HistoricalSlugs = newCategory.HistoricalSlugs.length
      ? [...newCategory.HistoricalSlugs, slug].filter((value, index, self) => {
          return self.indexOf(value) === index;
        })
      : [slug];

    const refreshedToken = await refreshAndReturnFirebaseToken();
    setIsUpdatingCategory(true);
    if (refreshedToken !== null) {
      const res = await editCategory(refreshedToken, categorySlug, newCategory);
      if (res.Data) {
        setCategory({ ...category, Name: res.Data.Name, Slug: res.Data.Slug });
        navigate(`/categories/${res.Data.Slug}`, { replace: true });
        addNotification("success", "Successfully updated the Category!", "Category");
      }
      setIsOpen(false);
    }
    setIsUpdatingCategory(false);
  };

  const saveBanners = async (banners: SellerBanner[]) => {
    const newCategory = Object.assign({}, categoryUpdatedDetails);
    newCategory.Banners = banners;
    const refreshedToken = await refreshAndReturnFirebaseToken();
    if (refreshedToken !== null && categorySlug != null) {
      const res = await editCategory(refreshedToken, categorySlug, newCategory);
      if (res.Data) {
        setCategory(newCategory);
      }
    }
  };

  if (sellerDetails === null || category === null) {
    return <FiLoader className="animate-spin"></FiLoader>;
  }

  return (
    <div className="my-4 sm:px-6 lg:px-8">
      <div>
        <h2 className="flex items-center text-3xl py-2 font-light leading-8 text-gray-900 sm:text-4xl sm:truncate">
          {category.Name}
          <button
            className="ml-2 text-blue-600 text-base"
            onClick={() => {
              setCategoryUpdatedDetails(category);
              setIsOpen(true);
            }}
          >
            Edit
          </button>
        </h2>
      </div>
      <EditCategoryModal
        category={categoryUpdatedDetails}
        setCategory={setCategoryUpdatedDetails}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        handleSave={handleSave}
        isUpdatingCategory={isUpdatingCategory}
        setIsUpdatingCategory={setIsUpdatingCategory}
      />
      <CategoryBanners banners={category.Banners} saveBanners={saveBanners} />

      <h3 className="text-2xl font-light mb-4">Products</h3>

      <div className="flex items-between justify-between mb-4">
        {renderSwitch()}
        <input
          type="text"
          value={productSearchValue}
          placeholder="Search"
          onChange={(e) => setProductSearchValue(e.target.value)}
          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full max-w-sm shadow-sm sm:text-sm border-gray-300 rounded-md"
        />
      </div>
      {gridViewEnabled ? (
        <ProductGrid
          products={(products || []).filter((product) =>
            productSearchValue.trim() !== ""
              ? product.Name.toLowerCase().includes(productSearchValue.toLowerCase())
              : true
          )}
          currencySymbol={localeDetails.currencySymbol}
          selectedProducts={selectedProducts}
          setSelectedProducts={setSelectedProducts}
        />
      ) : (
        <ProductList
          products={products || []}
          selectedProducts={selectedProducts}
          setSelectedProducts={setSelectedProducts}
        />
      )}
      <div className="py-4 flex items-center justify-end">
        <button
          className="inline-flex items-center px-4 py-2 text-base font-medium text-white bg-green-500 border border-transparent rounded-md shadow-sm hover:bg-green-00 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
          onClick={saveProducts}
        >
          {savingProducts ? "Saving..." : "Save"}
        </button>
      </div>
      <PagingControls
        nextPage={products.length == RESULT_PER_PAGE ? () => handlePageChange(false) : null}
        previousPage={cursorId == null || pageNumber == 1 ? null : () => handlePageChange(true)}
      />
    </div>
  );
};

export default CategoryDetails;
