import React, { useContext, useState, useEffect } from "react";

import { AuthContext } from "../../../lib/services/auth";
import { FiLoader } from "react-icons/fi";
import { NotificationContext } from "../../../lib/services/notification";
import FormInput from "../../../components/FormInput";
import { processText, withTestId } from "../../../lib/utils";

const ReturnPolicies = [
  "No return or exchange",
  "Exchange only on faulty products",
  "Return/Exchange on faulty products",
  "Return but shipping on buyer's expense",
  "No questions asked Exchange",
  "No questions asked Returns",
];

const ReturnsPolicy = () => {
  const { sellerDetails, updateSellerDetails, refreshAndReturnFirebaseToken } =
    useContext(AuthContext);
  const { addNotification } = useContext(NotificationContext);

  const [isupdatingReturnsPolicyDetails, setIsupdatingReturnsPolicyDetails] = useState(false);

  const [returnPolicyType, setReturnPolicyType] = useState(
    sellerDetails?.Shipping?.ReturnPolicy ? sellerDetails.Shipping.ReturnPolicy : ReturnPolicies[0]
  );
  const [returnDays, setReturnDays] = useState(
    sellerDetails?.Shipping.ReturnDays ? sellerDetails?.Shipping.ReturnDays.toString() : "0"
  );
  useEffect(() => {}, [sellerDetails]);

  if (sellerDetails === null) {
    return <FiLoader className="animate-spin"></FiLoader>;
  }

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const shippingDetails = Object.assign({}, sellerDetails.Shipping);
    shippingDetails.ReturnPolicy = returnPolicyType;
    shippingDetails.ReturnDays = returnPolicyType === ReturnPolicies[0] ? 0 : Number(returnDays);
    const updatedSellerDetails = { Shipping: shippingDetails };

    setIsupdatingReturnsPolicyDetails(true);

    const refreshedToken = await refreshAndReturnFirebaseToken();
    if (refreshedToken !== null) {
      await updateSellerDetails(refreshedToken, updatedSellerDetails);
      addNotification(
        "success",
        "Successfully updated the return policy details!",
        "StoreSettings"
      );
    }
    setIsupdatingReturnsPolicyDetails(false);
  };

  return (
    <div className="my-4 sm:px-6 lg:px-8">
      <div>
        <h1 className="text-3xl font-light leading-7 text-gray-900 my-2">Returns Policy</h1>
      </div>
      <form onSubmit={onSubmit} className="w-1/2 mx-auto">
        <section className={`mb-4 `}>
          <label
            htmlFor={"announcement-bar"}
            className={`block text-xs mb-1 font-medium text-left text-neutral-600`}
          >
            Return Policy
          </label>

          <select
            className="block mt-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            value={returnPolicyType}
            onChange={(e) => setReturnPolicyType(e.target.value)}
          >
            {ReturnPolicies.map((choice) => (
              <option key={choice} value={choice}>
                {choice}
              </option>
            ))}
          </select>
        </section>
        {returnPolicyType !== ReturnPolicies[0] ? (
          <FormInput
            {...withTestId("returnDays-input")}
            id="returnDays"
            placeholder="Return within days"
            label="Return within days"
            control={{
              value: returnDays ?? "0",
              type: "text",
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                const processedValue = processText(e.target.value);
                let value = +processedValue;
                if (isNaN(value)) {
                  value = 0;
                  return;
                }
                setReturnDays(String(value));
              },
            }}
            errMsg="Please enter a valid number of days"
            isError={Number(returnDays) <= 0}
          />
        ) : null}
        <button
          type="submit"
          disabled={isupdatingReturnsPolicyDetails}
          className="uppercase bg-primary text-stone-200 font-bold p-2 w-full rounded disabled:bg-gray-400"
        >
          {isupdatingReturnsPolicyDetails ? "Saving" : "Save"}
        </button>
      </form>
    </div>
  );
};

export default ReturnsPolicy;
