import React from "react";
import { ProductListResponse } from "../../../../../lib/types";
import ProductGridCard from "../ProductGridCard";

type Props = {
  products: ProductListResponse[];
  toggleProductState: (productId: string, isActive: boolean) => void;
  currencySymbol: string;
};

function ProductGridView({ products, toggleProductState, currencySymbol = "₹" }: Props) {
  return (
    <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
      {products.map((product: ProductListResponse, index: number) => (
        <ProductGridCard
          product={product}
          key={index}
          toggleProductState={toggleProductState}
          currencySymbol={currencySymbol}
        />
      ))}
    </ul>
  );
}

export default ProductGridView;
