import React from "react";

import { CategoryListResponse } from "../../../../../../lib/types";
import CategoryTableRow from "../CategoryTableRow";

type Props = {
  deleteCategory: (slug: string) => void;
  toggleCategoryState: (categorySlug: string, isVisibleOnHome: boolean) => void;
  categories: CategoryListResponse[];
};

const CategoriesTable = (props: Props) => {
  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto">
        <div className="py-2 align-middle inline-block min-w-full px-1">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-100">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Category
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Products
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {props.categories.map((category) => (
                  <CategoryTableRow
                    category={category}
                    deleteCategory={props.deleteCategory}
                    toggleCategoryState={props.toggleCategoryState}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoriesTable;
