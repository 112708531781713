import React, { useEffect, useContext, useState } from "react";
import { SubmitHandler } from "react-hook-form";
import { FiLoader } from "react-icons/fi";
import { AuthContext } from "../../../lib/services/auth";
import { NotificationContext } from "../../../lib/services/notification";
import FacebookPixelForms, { FacebookPixelFields } from "./components/FacebookPixelForm";
import SocialMediaLinksForm, { SocialMediaFields } from "./components/SocialMediaForm";

const SocialMedia = () => {
  const { sellerDetails, updateSellerDetails, refreshAndReturnFirebaseToken } =
    useContext(AuthContext);

  const { addNotification, removeNotificationByTag } = useContext(NotificationContext);

  const [socialMediaLinks, setSocialMediaLinks] = useState<SocialMediaFields>({
    facebook: sellerDetails?.Social?.Facebook ?? null,
    instagram: sellerDetails?.Social?.Instagram ?? null,
    pinterest: sellerDetails?.Social?.Pinterest ?? null,
    twitter: sellerDetails?.Social?.Twitter ?? null,
    youtube: sellerDetails?.Social?.Youtube ?? null,
  });

  const [isUpdatingSocialMediaDetails, setIsUpdatingSocialMediaDetails] = useState<boolean>(false);
  const [facebookPixel, setFacebookPixel] = useState<FacebookPixelFields>({
    pixelId: null,
  });

  useEffect(() => {}, [sellerDetails]);

  if (sellerDetails === null) {
    return <FiLoader className="animate-spin"></FiLoader>;
  }

  const submitSocialMediaLinks: SubmitHandler<SocialMediaFields> = async (_data) => {
    setSocialMediaLinks(_data);
    setIsUpdatingSocialMediaDetails(false);
    removeNotificationByTag("SocialMedia");

    const updatedSellerDetails = { Social: sellerDetails.Social };
    updatedSellerDetails.Social = {
      Facebook: _data.facebook,
      Instagram: _data.instagram,
      Pinterest: _data.pinterest,
      Twitter: _data.twitter,
      Youtube: _data.youtube,
    };

    setIsUpdatingSocialMediaDetails(true);
    const refreshedToken = await refreshAndReturnFirebaseToken();
    if (refreshedToken !== null) {
      await updateSellerDetails(refreshedToken, updatedSellerDetails);
      addNotification("success", "Successfully updated the social media details!", "SocialMedia");
    }
    setIsUpdatingSocialMediaDetails(false);
  };

  const submitFacebookPixel: SubmitHandler<FacebookPixelFields> = (_data) => {
    setFacebookPixel(_data);
  };

  return (
    <div className="my-4 sm:px-6 lg:px-8">
      <div className="w-full flex flex-col items-center">
        <div>
          <h6 className="text-xl font-medium leading-7 text-gray-900 my-4">Social Media Links</h6>
        </div>
        <div className="w-1/2 flex flex-col justify-center items-center">
          <SocialMediaLinksForm
            initialValue={socialMediaLinks}
            next={submitSocialMediaLinks}
            isUpdatingSocialMediaDetails={isUpdatingSocialMediaDetails}
            key="social-media-forms"
          />
        </div>
        <div>
          <h6 className="text-xl font-medium leading-7 text-gray-900 my-4">Facebook Pixel</h6>
        </div>
        <div className="w-1/2 flex flex-col justify-start items-center">
          <FacebookPixelForms
            initialValue={facebookPixel}
            next={submitFacebookPixel}
            key="facebook-pixel-forms"
          />
        </div>
      </div>
    </div>
  );
};

export default SocialMedia;
