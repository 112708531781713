import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { FiEdit, FiTrash2 } from "react-icons/fi";

import { CategoryCollectionListResponse } from "../../../../../../lib/types";

type Props = {
  deleteCategoryCollection: (slug: string) => void;
  categoryCollection: CategoryCollectionListResponse;
};

const CategoryCollectionTableRow = ({ categoryCollection, deleteCategoryCollection }: Props) => {
  const navigate = useNavigate();
  return (
    <tr key={categoryCollection.Id} className="hover:bg-gray-50">
      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
        <Link to={`/category-collections/${categoryCollection.Slug}`}>
          {categoryCollection.Name}
        </Link>
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        <div className="flex flex-row justify-end items-center">
          <FiEdit
            className="text-gray-500 mr-1 text-lg aspect-square hover:scale-125 hover:text-indigo-600"
            onClick={() => navigate(`${categoryCollection.Slug}`)}
          />
          <FiTrash2
            className="text-gray-500 ml-1 text-lg aspect-square hover:scale-125 hover:text-red-500"
            onClick={() => deleteCategoryCollection(categoryCollection.Slug)}
          />
        </div>
      </td>
    </tr>
  );
};
export default CategoryCollectionTableRow;
