// Contains functions related to a user's auth

import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { User, SellerDetailsResponse } from "../types";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import { auth } from "../firebase";
import { ApiContext, ApiResult } from "./api";
import { baseUrl, cloudFunctionsUrl } from "../utils";

export interface IAuthService {
  // /** This is the firebase user object which we get after authenticating */
  user: Readonly<User | null>;
  isLoggedIn: Readonly<boolean>;
  isLoading: Readonly<boolean>;
  /** Store the data of the seller fetched from the server */
  sellerDetails: Readonly<SellerDetailsResponse | null>;
  /** Locale details of the seller */
  localeDetails: Readonly<{
    countryCode: string;
    currencyCode: string;
    callingCode: string;
    currencySymbol: string;
    phoneLength: number[];
  }>;
  /** This method is used to get the verification code on sing in using phone number */
  getOtp(phoneNumber: string, countryCode: string): Promise<ApiResult<void>>;

  /** This method is used to  verify otp for the user */
  verifyOtp(
    phoneNumber: string,
    countryCode: string,
    otp: string,
    currencyDetails: {
      countryCode: string;
      currencyCode: string;
      callingCode: string;
      currencySymbol: string;
      phoneLength: number[];
    }
  ): Promise<ApiResult<void>>;

  loginUser(
    phoneNumber: string,
    countryCode: string,
    value: string,
    currencyDetails: {
      countryCode: string;
      currencyCode: string;
      callingCode: string;
      currencySymbol: string;
      phoneLength: number[];
    }
  ): Promise<void>;

  /**
   * Refresh and return firebase token.
   * It will return null if the user is not authenticated.
   *
   * Use `forceRefresh = true` to force fetching a new token.
   * For most purposes, you will pass `false` here. (default true).
   */
  refreshAndReturnFirebaseToken(forceRefresh?: boolean): Promise<string | null>;

  /** Updates the seller details. Returns `SellerDetailsResponse` or null */
  updateSellerDetails(
    token: string,
    sellerDetails: Partial<SellerDetailsResponse>
  ): Promise<SellerDetailsResponse | null>;

  /**Fetches seller details. Returns `SellerDetailsResponse` or null */
  fetchSellerDetails(token: string): Promise<SellerDetailsResponse | null>;

  /** Update the locale details of the seller based on the country. */
  // updateLocaleDetails(newLocaleDetails:
  //   {
  //     countryCode: string;
  //     currencyCode: string;
  //     callingCode: string;
  //     currencySymbol: string;
  //     phoneLength: number[];
  //   }
  // ): void;
  /**
   * Logs out the user
   */
  logout(): Promise<void>;
}

export const AuthContext = createContext<IAuthService>(null as never);

/**
 * Higher-order component that provides access to Auth. It also fetches
 * the user's cart automatically. Note that it has a dependency on ApiContext
 */
export const AuthContextProvider = React.memo(function AuthContextProvider({ children }) {
  const [user, setUser] = useState<User | null>(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [sellerDetails, setSellerDetails] = useState<SellerDetailsResponse | null>(null);
  const [localeDetails, setLocaleDetails] = useState<{
    countryCode: string;
    currencyCode: string;
    callingCode: string;
    currencySymbol: string;
    phoneLength: number[];
  }>({
    callingCode: "91",
    countryCode: "IN",
    currencyCode: "INR",
    currencySymbol: "₹",
    phoneLength: [10],
  });

  const apiCtx = useContext(ApiContext);
  const authService = useMemo<IAuthService>(() => {
    return {
      user: user,
      isLoggedIn: isLoggedIn,
      isLoading: isLoading,
      sellerDetails: sellerDetails,
      localeDetails: localeDetails,

      getOtp: async (phoneNumber: string, countryCode: string) => {
        const phoneNumberWithCountryCode = `${countryCode}${phoneNumber}`.trim();
        try {
          const getSms = await fetch(
            `${baseUrl}/v1/send_seller_otp?phone=${phoneNumberWithCountryCode}`,
            {
              method: "GET",
            }
          );
          const json = await getSms.json();
          if (json) {
            if (json.Err) {
              return json;
            }
            // TODO(rranjan14): start some timer or anyting.
          } else {
            return {
              Err: {
                message: "Something went wrong!",
              },
            };
          }
        } catch (error) {
          return {
            Err: {
              message: "Something went wrong!",
            },
          };
        }
      },

      verifyOtp: async (
        phoneNumber: string,
        countryCode: string,
        otp: string,
        currencyDetails: {
          countryCode: string;
          currencyCode: string;
          callingCode: string;
          currencySymbol: string;
          phoneLength: number[];
        }
      ) => {
        const newPhoneNumber = `${countryCode}${phoneNumber}`.trim();
        const verifyOtp = await fetch(
          `${baseUrl}/v1/verify_seller_otp?phone=${newPhoneNumber}&otp=${otp.toString().trim()}`
        );
        const json = await verifyOtp.json();
        if (json) {
          if (json.Err) {
            return json;
          }
          //TODO(rranjan14): create user or login user.
          await authService.loginUser(phoneNumber, countryCode, otp, currencyDetails);
        } else {
        }
      },
      loginUser: async (
        phoneNumber: string,
        countryCode: string,
        value: string,
        currencyDetails: {
          countryCode: string;
          currencyCode: string;
          callingCode: string;
          currencySymbol: string;
          phoneLength: number[];
        }
      ) => {
        if (value) {
          setIsLoading(true);
          try {
            if (value.length < 4) {
              setIsLoading(false);
              return;
            }
            const newPhoneNumber = `+${countryCode}${phoneNumber}`.trim();
            const tokenFetch = await fetch(
              `${cloudFunctionsUrl}/webApi/api/v2/util/generateToken`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  phoneNumber: newPhoneNumber,
                  currencyDetails: currencyDetails,
                }),
              }
            );
            const token = await tokenFetch.json();
            if (token.status === "ok") {
              await signInWithCustomToken(getAuth(), token.token).then(async (results) => {
                const idToken = await results.user.getIdToken(true);
                if (idToken) {
                  setIsLoggedIn(true);
                  setUser(results.user);
                  setIsLoading(false);
                  setLocaleDetails(currencyDetails);
                }
              });
              setIsLoading(false);
            }
          } catch (error) {
            setIsLoading(false);
          }
        } else {
        }
      },

      refreshAndReturnFirebaseToken: async (forceRefresh = true) => {
        if (auth.currentUser !== null) {
          const refreshedToken = await auth.currentUser.getIdToken(forceRefresh);
          return refreshedToken;
        }
        return null;
      },

      fetchSellerDetails: async (token: string) => {
        const apiResult = await apiCtx.fetchStoreDetails(token);
        if (apiResult.Err) {
          setSellerDetails(null);
          return null;
        }
        if (apiResult.Data) {
          setSellerDetails(apiResult.Data);
          return apiResult.Data;
        }
        return null;
      },

      updateSellerDetails: async (token: string, detailsToUpdate: SellerDetailsResponse) => {
        const apiResult = await apiCtx.updateSellerDetails(token, detailsToUpdate);
        if (apiResult.Err) {
          return null;
        }
        if (apiResult.Data) {
          setSellerDetails(apiResult.Data);
          return apiResult.Data;
        }
        return null;
      },

      // updateLocaleDetails: (newLocaleDetails: {
      //   countryCode: string;
      //   currencyCode: string;
      //   callingCode: string;
      //   currencySymbol: string;
      //   phoneLength: number[];
      // }) => {
      //   setLocaleDetails(newLocaleDetails)
      // },

      logout: async () => {
        try {
          await auth.signOut();
        } catch (error) {
          alert(error);
        }
      },
    };
  }, [sellerDetails, user, isLoading, isLoggedIn, apiCtx, localeDetails]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (u) => {
      if (u !== null) {
        setIsLoggedIn(true);
        setUser(u);
        const token = await u.getIdToken(true);
        const res = await apiCtx.fetchStoreDetails(token);
        if (res.Data) {
          setSellerDetails(res.Data);
        }
      } else {
        setIsLoggedIn(false);
        setUser(null);
      }
      setIsLoading(false);
    });
    return unsubscribe;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <AuthContext.Provider value={authService}>{children}</AuthContext.Provider>;
});
