import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { FiInstagram, FiTwitter, FiFacebook, FiYoutube } from "react-icons/fi";
import { ImPinterest } from "react-icons/im";
import FormInput from "../../../../../components/FormInput";
import { withTestId } from "../../../../../lib/utils";

export type SocialMediaFields = {
  facebook: string | null;
  instagram: string | null;
  pinterest: string | null;
  twitter: string | null;
  youtube: string | null;
};

type SocialMediaLinksProps = {
  initialValue: SocialMediaFields;
  isUpdatingSocialMediaDetails: boolean;
  /** Called when the user fills in the form */
  next: SubmitHandler<SocialMediaFields>;
} & TestingProps;

const SocialMediaLinksForm = React.memo(function SocialMediaLinksForm(
  props: SocialMediaLinksProps
) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SocialMediaFields>({
    defaultValues: props.initialValue ?? undefined,
  });

  return (
    <form onSubmit={handleSubmit(props.next)} className="w-full">
      <FormInput
        {...withTestId("instagram-input")}
        id="instagram"
        placeholder="e.g. instasell.in"
        label="Instagram Link"
        className="placeholder:text-gray-300"
        icon={<FiInstagram className="absolute h-5 w-5 bottom-2 left-2" />}
        control={{
          type: "text",
          ...register("instagram", {
            validate: (value: string | null) => (value ? value.length >= 2 : false),
          }),
        }}
        errMsg="Please enter a valid instagram link"
        isError={errors.instagram != null}
      />
      <FormInput
        {...withTestId("facebook-input")}
        id="facebook"
        placeholder="e.g. instasell.in"
        label="Facebook Link"
        className="placeholder:text-gray-300"
        icon={<FiFacebook className="absolute h-5 w-5 bottom-2 left-2" />}
        control={{
          type: "text",
          ...register("facebook", {
            validate: (value: string | null) => (value ? value.length >= 2 : false),
          }),
        }}
        errMsg={"Please enter a valid facebook link"}
        isError={errors.facebook != null}
      />
      <FormInput
        {...withTestId("twitter-input")}
        id="twitter"
        className="placeholder:text-gray-300"
        label="Twitter Link"
        placeholder="e.g. InstaSellHQ"
        icon={<FiTwitter className="absolute h-5 w-5 bottom-2 left-2" />}
        control={{
          type: "text",
          ...register("twitter", {
            validate: (value: string | null) => (value ? value.length >= 2 : false),
          }),
        }}
        errMsg="Please enter a valid twitter link"
        isError={errors.twitter != null}
      />
      <FormInput
        {...withTestId("youtube-input")}
        id="youtube"
        className="placeholder:text-gray-300"
        label="YouTube Link"
        placeholder="e.g. https://www.youtube.com/channel/UCUnrhV97LAuj0uny0V5WgtQ"
        icon={<FiYoutube className="absolute h-5 w-5 bottom-2 left-2" />}
        control={{
          type: "text",
          ...register("youtube", {
            validate: (value: string | null) => (value ? value.length >= 2 : false),
          }),
        }}
        errMsg="Please enter a valid youtube link"
        isError={errors.youtube != null}
      />
      <FormInput
        {...withTestId("pinterest-input")}
        id="pinterest"
        className="placeholder:text-gray-300"
        label="Pinterest Link"
        placeholder="e.g. instasell.in"
        icon={<ImPinterest className="absolute h-5 w-5 bottom-2 left-2" />}
        control={{
          type: "text",
          ...register("pinterest", {
            validate: (value: string | null) => (value ? value.length >= 2 : false),
          }),
        }}
        errMsg="Please enter a valid pinterest link"
        isError={errors.pinterest != null}
      />
      <button
        type="submit"
        className="uppercase bg-primary text-stone-200 font-bold p-2 w-full rounded disabled:bg-gray-400"
      >
        {props.isUpdatingSocialMediaDetails ? "Saving Links..." : "Save Links"}
      </button>
    </form>
  );
});

export default SocialMediaLinksForm;
