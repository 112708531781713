import React, { useEffect, useState } from "react";
import FormInput from "../../../../components/FormInput";
import { processText, withTestId } from "../../../../lib/utils";

export type ShippingSettingsFields = {
  averageShippingDays: number;
};
type ShippingSettingsProps = {
  initialValue: ShippingSettingsFields;
  /** Called when the user fills in the form */
  next: (data: ShippingSettingsFields) => void;
  isUpdatingShippingSettings: boolean;
  isShippingSettingsUpdated: boolean;
  internationalShipping: boolean;
  setInternationalShipping: (isInternationalShipping: boolean) => void;
} & TestingProps;

type OptionsFlags<Type> = {
  [Property in keyof Type]: boolean;
};

type ShippingSettingsFieldsError = OptionsFlags<ShippingSettingsFields>;

const ShippingSettingsForm = React.memo(function ShippingSettingsForm(
  props: ShippingSettingsProps
) {
  const [formData, setFormData] = useState<ShippingSettingsFields>(
    props.initialValue || {
      averageShippingDays: 0,
    }
  );
  const [errors, setErrors] = useState<Partial<ShippingSettingsFieldsError>>({});
  const validateForm = () => {
    const currentErrors: Partial<ShippingSettingsFieldsError> = {};
    if (formData.averageShippingDays <= 0) {
      currentErrors.averageShippingDays = true;
    }
    setErrors({ ...currentErrors });
  };

  useEffect(() => {
    validateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  const updateFormData = (updatedFormFields: Partial<ShippingSettingsFields>) => {
    setFormData({ ...formData, ...updatedFormFields });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (Object.keys(errors).length === 0) {
      props.next(formData);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="w-1/2">
      <FormInput
        {...withTestId("averageShippingDays-input")}
        id="averageShippingDays"
        placeholder="Average Shipping Time"
        control={{
          value: formData.averageShippingDays ?? "0",
          type: "text",
          onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
            const processedValue = processText(e.target.value);
            let value = +processedValue;
            if (isNaN(value)) {
              value = 0;
              return;
            }
            updateFormData({ averageShippingDays: value });
          },
        }}
        errMsg="Please enter a valid amount"
        isError={errors.averageShippingDays != null}
      />

      <label
        htmlFor="allow-intl-shipping"
        className="mb-6 flex flex-row items-center justify-between w-full"
      >
        <p className="flex flex-col">
          <span className="font-medium text-sm">Allow International Shipping</span>
          <span className="text-xs text-gray-600">
            Enable International Shipping and Ship All Over The World
          </span>
        </p>
        <input
          type="checkbox"
          id="allow-intl-shipping"
          checked={props.internationalShipping}
          onChange={(e) => {
            props.setInternationalShipping(e.target.checked);
          }}
          className="text-indigo-600 border-gray-300 rounded shadow-sm focus:border-indigo-300 focus:ring focus:ring-offset-0 focus:ring-indigo-200 focus:ring-opacity-50 "
        />
      </label>
      {props.isShippingSettingsUpdated ? (
        <p className="px-4 py-2 my-4 text-green-500 bg-green-500 border border-green-500 border-solid rounded bg-opacity-5">
          Successfully updated shipping charges!
        </p>
      ) : null}
      <button
        type="submit"
        disabled={Object.keys(errors).length > 0 || props.isUpdatingShippingSettings}
        className="w-full p-2 font-bold uppercase bg-primary rounded text-stone-200 disabled:bg-gray-400"
      >
        {props.isUpdatingShippingSettings ? "Saving..." : "Save Average Shipping Time"}
      </button>
    </form>
  );
});

export default ShippingSettingsForm;
