import React, { useContext, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { FiLoader } from "react-icons/fi";
import { AuthContext } from "../../lib/services/auth";
import { SellerDetailsResponse } from "../../lib/types";

const AboutUs = () => {
  const { sellerDetails, updateSellerDetails, refreshAndReturnFirebaseToken } =
    useContext(AuthContext);

  const [aboutUs, setAboutUs] = useState(sellerDetails?.Description ?? "");
  const [tagLine, setTagline] = useState(sellerDetails?.Tagline ?? "");

  if (sellerDetails === null) {
    return <FiLoader className="animate-spin"></FiLoader>;
  }

  const submit = async () => {
    if (tagLine.length > 160) {
      alert("Tagline should be less than 160 characters");
      return;
    }
    const updatedBankDetails: Partial<SellerDetailsResponse> = {};

    updatedBankDetails.Description = aboutUs;
    updatedBankDetails.Tagline = tagLine;

    const refreshedToken = await refreshAndReturnFirebaseToken();
    if (refreshedToken !== null) {
      await updateSellerDetails(refreshedToken, updatedBankDetails);
    }
  };

  return (
    <div className="w-full flex flex-col items-center sm:px-6 lg:px-8">
      <div className="w-2/3 flex flex-col items-center">
        <div className="w-full my-10 px-4 sm:px-0">
          <h2 className="text-3xl py-2 font-light leading-8 text-gray-900 sm:text-4xl sm:truncate">
            About Us
          </h2>
        </div>
        <div className="w-full">
          <label htmlFor="aboutus" className="block text-sm font-medium text-gray-700">
            About Us
          </label>
          <div className="mt-1">
            <ReactQuill
              className="border border-gray-300 shadow-sm rounded-md"
              value={aboutUs}
              onChange={(value) => setAboutUs(value)}
            />
          </div>
        </div>
        <div className="w-full my-10 px-4 sm:px-0">
          <h2 className="text-3xl py-2 font-light leading-8 text-gray-900 sm:text-4xl">Tagline</h2>
        </div>
        <div className="w-full">
          <label htmlFor="tagline" className="block text-sm font-medium text-gray-700">
            Tagline
          </label>
          <div className="mt-1">
            <textarea
              rows={2}
              name="tagline"
              id="tagline"
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              defaultValue={tagLine}
              onChange={(e) => setTagline(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="my-4 w-2/3 self-center">
        <button
          className="uppercase bg-primary text-stone-200 font-bold p-2 w-full rounded disabled:bg-gray-400"
          onClick={submit}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default AboutUs;
