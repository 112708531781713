import { PencilIcon } from "@heroicons/react/outline";
import { TrashIcon } from "@heroicons/react/solid";
import React, { ChangeEvent, createRef } from "react";
import { FiPlus } from "react-icons/fi";

export type ImageTileProps = {
  /**
   * Initial image to show. If not present, a "click to add image" will be
   * shown instead.
   *
   * A data URI can be generated from the image's blob
   */
  initialImageDataUri: string | null;
  /**
   * Called when the user uploads an image. The caller is responsible for
   * re-rendering this component with the data URI.
   */
  onUpload: (imageBlob: FileList) => void;
  onDelete?: () => void;
  isLogo?: boolean;
  uniqueId: string;
  allowMultiple: boolean;
} & TestingProps;

/**
 * A single image tile that can be clicked to select an image
 */
const ImageTile: React.FC<ImageTileProps> = (props) => {
  const uploadRef = createRef<HTMLInputElement>();

  function handleUpload(ev: ChangeEvent<HTMLInputElement>) {
    const files = ev.target.files;
    if (!files || files.length == 0) {
      return;
    }
    props.onUpload(files);
  }

  return (
    <label
      htmlFor={`file-upload-store-banner-${props.uniqueId}`}
      data-testid={props["data-testid"]}
      style={{
        aspectRatio: "15/9",
        backgroundImage: props.initialImageDataUri
          ? `url(${props.initialImageDataUri})`
          : undefined,
      }}
      className="block overflow-hidden relative rounded-lg border bg-no-repeat bg-center bg-cover cursor-pointer"
    >
      {props.initialImageDataUri ? (
        <button className="py-2 px-2 bg-primary text-white" onClick={() => props.onDelete?.()}>
          <TrashIcon className="h-6 w-6" />
        </button>
      ) : null}
      {props.initialImageDataUri ? (
        <button
          onClick={() => uploadRef.current?.click()}
          className="absolute flex items-center justify-center w-full left-0 py-4 px-4 bottom-0 text-center bg-primary text-white"
        >
          <PencilIcon className="h-4 w-4 mr-1" />
          <span>Change Category Banner</span>
        </button>
      ) : null}
      {props.initialImageDataUri == null && (
        <div className="absolute top-0 right-0 bottom-0 left-0 flex flex-col items-center justify-center">
          <FiPlus />
          <p>Click to upload</p>
        </div>
      )}

      <input
        type="file"
        id={`file-upload-store-banner-${props.uniqueId}`}
        multiple={props.allowMultiple}
        ref={uploadRef}
        onChange={handleUpload}
        className="w-0 h-0"
      />
    </label>
  );
};

export default ImageTile;
