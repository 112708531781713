import React from "react";
import type { HTMLAttributes } from "react";

/**
 * A single, full width image input
 */
const ImageInput = function ImageInput(
  props: {
    /** ID for input */
    id: string;
    placeholder: string;
    /** React-Hook-Form control props */
    control: HTMLAttributes<HTMLInputElement> | object;
    /** Error message */
    errMsg?: string;
    /** Whether to show error message */
    isError: boolean;
    /**Ref of the input */
    ref: HTMLAttributes<HTMLInputElement> | object;
  } & TestingProps
) {
  return (
    <section data-testid={props["data-testid"]} className="mb-4">
      <label
        htmlFor={props.id}
        className={`block text-xs mb-1 font-medium ${
          props.isError ? "text-red-500" : "text-neutral-600"
        }`}
      >
        {props.isError ? props.errMsg : props.placeholder}
      </label>
      <div className="flex justify-center items-center">
        <div className="w-full flex justify-center pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
          <div className="space-y-1 text-center">
            <svg
              className="mx-auto h-12 w-12 text-gray-400"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 48 48"
              aria-hidden="true"
            >
              <path
                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <div className="flex text-sm text-gray-600">
              <label
                htmlFor={props.id}
                className="relative cursor-pointer bg-white rounded-md font-medium text-primary hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
              >
                <span>Upload an image</span>
                <input
                  id={props.id}
                  type="file"
                  accept="image"
                  className="sr-only"
                  {...props.control}
                  {...props.ref}
                />
              </label>
              <p className="pl-1">or drag and drop</p>
            </div>
            <p className="text-xs text-gray-500">PNG, JPG up to 10MB</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ImageInput;
