import { useState } from "react";
import { Pagination } from "../lib/types";

function usePagination(initialPagination?: Pagination) {
  const [goBack, setGoBack] = useState<boolean>(initialPagination?.goBack ?? false);
  const [nextCursor, setNextCursor] = useState<string | null>(initialPagination?.next ?? null);
  const [prevCursor, setPrevCursor] = useState<string | null>(initialPagination?.next ?? null);
  const [page, setPage] = useState(1);

  return {
    goBack,
    setGoBack,
    nextCursor,
    setNextCursor,
    prevCursor,
    setPrevCursor,
    page,
    setPage,
    nextPage() {
      setPage((old) => old + 1);
    },
    prevPage() {
      setPage((old) => Math.max(old - 1, 1));
    },
    canGoBack: page > 1,
  };
}

export default usePagination;
