import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import FormInput from "../../../../../components/FormInput";
import { withTestId } from "../../../../../lib/utils";

export type StorefrontTrackingDetailsFields = {
  FbPixelId: string | null;
  GtmContainerId: string | null;
};

type StorefrontTrackingDetailsFormProps = {
  initialValue: StorefrontTrackingDetailsFields;
  isUpdatingStorefrontTrackingDetails: boolean;
  next: SubmitHandler<StorefrontTrackingDetailsFields>;
} & TestingProps;

const StorefrontTrackingDetailsForm = React.memo(function StorefrontTrackingDetailsForm(
  props: StorefrontTrackingDetailsFormProps
) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<StorefrontTrackingDetailsFields>({
    defaultValues: props.initialValue ?? undefined,
  });

  return (
    <form onSubmit={handleSubmit(props.next)} className="w-full">
      <FormInput
        {...withTestId("fb-pixel-id-input")}
        id="fb-pixel-id"
        placeholder="e.g. 98765432"
        label="Facebook Pixel Id"
        className="placeholder:text-gray-300"
        control={{
          type: "text",
          ...register("FbPixelId", {
            required: false,
            validate: (value: string | null) => (value ? value.length >= 2 : true),
          }),
        }}
        errMsg="Please enter a valid facebook pixel id"
        isError={errors.FbPixelId != null}
      />
      <FormInput
        {...withTestId("gtm-container-id-input")}
        id="gtm-container-id"
        placeholder="e.g. GTM-XXXXXXX"
        label="Google Tag Manager Container Id"
        className="placeholder:text-gray-300"
        control={{
          type: "text",
          ...register("GtmContainerId", {
            required: false,
            validate: (value: string | null) => (value ? value.length >= 2 : true),
          }),
        }}
        errMsg="Please enter a valid google tag manager container id"
        isError={errors.GtmContainerId != null}
      />
      <button
        type="submit"
        className="uppercase bg-primary text-white tracking-normal font-bold p-2 w-full rounded disabled:bg-gray-400"
      >
        {props.isUpdatingStorefrontTrackingDetails ? "Saving..." : "Save"}
      </button>
    </form>
  );
});

export default StorefrontTrackingDetailsForm;
