import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import FormInput from "../../../../../components/FormInput";
import { withTestId } from "../../../../../lib/utils";

export type AccountDetailsField = {
  name: string;
  email: string;
  accountNumber: string;
  confirmAccountNumber: string;
  ifsc: string;
  address: string;
  pan: string | null;
};

type AccountDetailsProps = {
  initialValue: AccountDetailsField;
  /** Called when the user fills in the form */
  next: SubmitHandler<AccountDetailsField>;
} & TestingProps;

const AccountDetailsForm = React.memo(function PersonalDetailsForm(props: AccountDetailsProps) {
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<AccountDetailsField>({
    defaultValues: props.initialValue ?? undefined,
  });

  return (
    <form onSubmit={handleSubmit(props.next)} className="w-full">
      <FormInput
        {...withTestId("name-input")}
        id="name"
        placeholder="Name"
        control={{ type: "text", ...register("name", { required: true, minLength: 2 }) }}
        errMsg={"Please enter your name"}
        isError={errors.name != null}
      />
      <FormInput
        {...withTestId("email-input")}
        id="email"
        placeholder="Email"
        control={{
          type: "email",
          ...register("email", {
            required: true,
            pattern:
              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          }),
        }}
        errMsg="Please enter a valid email"
        isError={errors.email != null}
      />
      <FormInput
        {...withTestId("account-number-input")}
        id="accountNumber"
        placeholder="Account Number"
        control={{ type: "text", ...register("accountNumber", { required: true, maxLength: 18 }) }}
        errMsg="Please enter a valid account number"
        isError={errors.accountNumber != null}
      />
      <FormInput
        {...withTestId("confirm-account-input")}
        id="confirmAccountNumber"
        placeholder="Re-enter Account Number"
        control={{
          type: "password",
          ...register("confirmAccountNumber", {
            required: true,
            maxLength: 18,
            validate: () => getValues().accountNumber === getValues().confirmAccountNumber,
          }),
        }}
        errMsg="Doesn't match your account number"
        isError={errors.confirmAccountNumber != null}
      />
      <FormInput
        {...withTestId("ifsc-input")}
        id="ifsc"
        placeholder="IFSC Code"
        control={{ type: "text", ...register("ifsc", { minLength: 8 }) }}
        errMsg="Please enter correct IFSC Code"
        isError={errors.ifsc != null}
      />
      <FormInput
        {...withTestId("address-input")}
        id="address"
        placeholder="Address"
        control={{ type: "text", ...register("address", { minLength: 5 }) }}
        errMsg="Please enter your address"
        isError={errors.address != null}
      />
      <FormInput
        {...withTestId("pan-input")}
        id="pan"
        placeholder="PAN Card Number"
        control={{ type: "text", ...register("pan", { minLength: 10, maxLength: 10 }) }}
        errMsg="Please enter your pinocde"
        isError={errors.pan != null}
      />
      <button
        type="submit"
        disabled={Object.keys(errors).length > 0}
        className="uppercase bg-primary text-stone-200 font-bold p-2 w-full rounded disabled:bg-gray-400"
      >
        Save
      </button>
    </form>
  );
});

export default AccountDetailsForm;
