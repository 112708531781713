import React, { useContext, useState, useEffect } from "react";
import { ApiContext } from "../../../lib/services/api";
import { AuthContext } from "../../../lib/services/auth";
import { useParams } from "react-router-dom";

import type { AbandonedCartDetails } from "../../../lib/types";
import { getDate } from "../../../lib/utils";
import { FiLoader } from "react-icons/fi";
import ProductList from "./components/ProductList";
import CustomerDetails from "./components/CustomerDetails";
import { ImMail, ImWhatsapp } from "react-icons/im";

function AbandonedCartDetailsPage() {
  const { cartId } = useParams();

  const { sellerDetails, refreshAndReturnFirebaseToken, localeDetails } = useContext(AuthContext);
  const { fetchCart } = useContext(ApiContext);
  const [abandonedCartDetails, setAbandonedCartDetails] = useState<AbandonedCartDetails | null>(
    null
  );

  useEffect(() => {
    const fetchAbandonedCarts = async () => {
      const refreshedToken = await refreshAndReturnFirebaseToken();
      if (refreshedToken !== null) {
        const fetchedAbandonedCarts = await fetchCart(refreshedToken, String(cartId));
        if (fetchedAbandonedCarts.Err) {
          setAbandonedCartDetails(null);
        }
        if (fetchedAbandonedCarts.Data) {
          setAbandonedCartDetails(fetchedAbandonedCarts.Data);
        }
      }
    };
    fetchAbandonedCarts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sellerDetails]);

  const sendWhatsAppMsg = () => {
    if (!abandonedCartDetails || !sellerDetails) return;
    const webSite = sellerDetails.Fqdn
      ? sellerDetails.Fqdn
      : `www.instasell.io/${sellerDetails.Slug}`;
    const number = abandonedCartDetails.BuyerDetails.PhoneNumber;

    const message = `Hello from ${sellerDetails.BusinessName}, ${abandonedCartDetails.BuyerDetails.FullName}! We just saw that you left some items in your cart.\n\n In case you’ve changed your mind, here’s the link to shop: ${webSite}.\n\n If not, we would like to know what we can do better so that you actually click that “Buy” button the next time. 💗\n\n`;

    const url = "whatsapp://send?text=" + message + "&phone=" + number;
    window.open(url);
  };

  const sendMail = () => {
    if (!abandonedCartDetails || !sellerDetails) return;
    const webSite = sellerDetails.Fqdn
      ? sellerDetails.Fqdn
      : `www.instasell.io/${sellerDetails.Slug}`;

    const body = `Hello from ${sellerDetails.BusinessName}, ${abandonedCartDetails.BuyerDetails.FullName}! We just saw that you left some items in your cart.\n\nIn case you’ve changed your mind, here’s the link to shop: ${webSite}.\n\nIf not, we would like to know what we can do better so that you actually click that “Buy” button the next time. 💗\n\n`;

    const url =
      "mailto://" +
      abandonedCartDetails.BuyerDetails.Email +
      "?body=" +
      body +
      "&subject=" +
      sellerDetails?.BusinessName;
    window.open(url);
  };

  if (!abandonedCartDetails) {
    return <FiLoader className="animate-spin"></FiLoader>;
  }
  return (
    <div className="my-4 sm:px-6 lg:px-44">
      <div className="my-2 mb-8 md:flex md:items-center md:justify-between">
        <div className="flex-1 min-w-0">
          <h2 className="py-2 text-3xl font-light leading-7 text-gray-900 sm:text-3xl sm:truncate">
            Abandoned Cart Details
          </h2>
          <p>
            Attempted on: {getDate(abandonedCartDetails.Created / 1000)} by{" "}
            {abandonedCartDetails.BuyerDetails.FullName}
          </p>
        </div>
        <div className="flex flex-col items-center justify-center">
          <button
            className="flex items-center px-2 py-1 mb-2 text-red-400 border border-red-400 border-solid rounded-md"
            onClick={sendMail}
          >
            <ImMail className="mr-1 text-lg aspect-square " />
            Send email reminder
          </button>
          <button
            className="flex items-center px-2 py-1 text-green-500 border border-green-500 border-solid rounded-md"
            onClick={sendWhatsAppMsg}
          >
            <ImWhatsapp className="mr-1 text-lg aspect-square" />
            Send WhatsApp reminder{" "}
          </button>
        </div>
      </div>
      <div className="flex flex-col space-y-4">
        <dl>
          <dt className="font-bold">Full Name</dt>
          <dd>{abandonedCartDetails.BuyerDetails.FullName}</dd>
        </dl>
      </div>
      <ProductList
        orderItems={abandonedCartDetails.Items}
        currencySymbol={localeDetails.currencySymbol}
      />
      <CustomerDetails customerDetails={abandonedCartDetails.BuyerDetails} />
    </div>
  );
}

export default AbandonedCartDetailsPage;
