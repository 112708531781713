import React from "react";

import PrimaryMenu from "../components/PrimaryMenu";
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}

const Layout: React.FC<Props> = ({ children }) => {
  return (
    <>
      <PrimaryMenu>
        <div className="p-4">{children}</div>
      </PrimaryMenu>
    </>
  );
};

export default Layout;
