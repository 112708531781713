/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useRef, useContext, useEffect, MouseEvent } from "react";
import { urlRegex } from "../../../lib/utils";
import CategoryList from "./components/CategoryList";
import VariantsContent from "./components/VariantsContent";
import { mediaUrl2ImageRes, processText, resizedUrl, uploadImage } from "../../../lib/utils";
import { AuthContext } from "../../../lib/services/auth";
import { ApiContext } from "../../../lib/services/api";
import {
  Category,
  ProductDetailsResponse,
  ProductVariation,
  DetailedProductVariant,
  CategoryListResponse,
} from "../../../lib/types";
import { useNavigate, useParams } from "react-router-dom";
import EditProductImages from "./components/EditProductImages";
import VariantsTable from "./components/VariantsTable";
import { FiLoader } from "react-icons/fi";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { TrashIcon } from "@heroicons/react/outline";
import { NotificationContext } from "../../../lib/services/notification";
import { v4 as uuidv4 } from "uuid";
import DigitalProduct from "../AddProduct/components/DigitalProduct";

function EditProduct() {
  const { productSlug } = useParams();
  const { sellerDetails, refreshAndReturnFirebaseToken, user, localeDetails } =
    useContext(AuthContext);
  const { fetchProductDetails, editProduct, getAllCategories, deleteProduct } =
    useContext(ApiContext);
  const { addNotification, removeNotificationByTag } = useContext(NotificationContext);
  const navigate = useNavigate();

  const [product, setProduct] = useState<ProductDetailsResponse | null>(null);
  const [, setVariantsData] = useState([]) as any;
  const [categories, setCategories] = useState<CategoryListResponse[]>([]);
  const [digitalProductURL, setDigitalProductURL] = useState(product?.DigitalProductUrl || "");

  useEffect(() => {
    setDigitalProductURL(product?.DigitalProductUrl || "");
  }, [product]);

  const [{ colorVariants, sizeVariants }, setVariants] = useState<{
    colorVariants: string[];
    sizeVariants: string[];
  }>({
    colorVariants: [],
    sizeVariants: [],
  });
  const setColorVariants = (newColorVariants: string[]) =>
    setVariants((variants) => ({ ...variants, colorVariants: newColorVariants }));

  const setSizeVariants = (newSizeVariants: string[]) =>
    setVariants((variants) => ({ ...variants, sizeVariants: newSizeVariants }));

  const variantsTableContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const fetchCategories = async () => {
      const refreshedToken = await refreshAndReturnFirebaseToken();
      if (refreshedToken !== null) {
        const fetchedProducts = await getAllCategories(refreshedToken);
        if (fetchedProducts.Err) {
          setCategories([]);
        }
        if (fetchedProducts.Data) {
          setCategories(fetchedProducts.Data);
        }
      }
    };
    fetchCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sellerDetails]);

  useEffect(() => {
    const createvariantsData = (prod: ProductDetailsResponse) => {
      const colors: string[] = [];
      const size: string[] = [];
      prod.Variants.map((variant: DetailedProductVariant) => {
        variant.Variations.map((variation: ProductVariation) => {
          if (variation.Name === "Color") {
            if (!colors.includes(variation.Value)) {
              colors.push(variation.Value);
            }
          } else {
            if (!size.includes(variation.Value)) {
              size.push(variation.Value);
            }
          }
        });
      });
      setVariants({
        colorVariants: colors,
        sizeVariants: size,
      });
    };
    const getProduct = async () => {
      if (productSlug) {
        const refreshedToken = await refreshAndReturnFirebaseToken();
        if (refreshedToken !== null) {
          const fetchedProduct = await fetchProductDetails(refreshedToken, productSlug);
          if (fetchedProduct.Err) {
            setProduct(null);
          }
          if (fetchedProduct.Data) {
            setProduct(fetchedProduct.Data);
            createvariantsData(fetchedProduct.Data);
          }
        }
      }
    };
    getProduct();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sellerDetails]);

  const hiddenImageFileInput = useRef<HTMLInputElement>(null);

  const showErrorNotification = (message: string) => addNotification("error", message, "Product");
  const showSuccessNotification = (message: string) =>
    addNotification("success", message, "Product");

  const handleImageUploadClick = (_event: MouseEvent<HTMLButtonElement>) => {
    if (hiddenImageFileInput.current) {
      hiddenImageFileInput.current.click();
    }
  };

  const handleImageChange = async (_event: React.ChangeEvent<HTMLInputElement>) => {
    if (product && product.Images.length >= (sellerDetails?.InventoryConfig.ImageCount || 5)) {
      addNotification(
        "error",
        `You can only add a maximum of ${sellerDetails?.InventoryConfig.ImageCount || 5} images`,
        "edit-prodct",
        null,
        3
      );
      return;
    }
    if (hiddenImageFileInput.current) {
      const files = _event.target.files;
      if (files?.length && user && product != null) {
        const imageUploadResponses = await Promise.all(
          Array.from(files)
            .filter((file: Blob) => file != null)
            .map((img) => uploadImage(img, user.uid, true))
        );
        const newProductImages: ProductDetailsResponse["Images"] = [];
        imageUploadResponses.forEach((imageRes) => {
          if (!imageRes) return null;
          const productImage: ImageRes = mediaUrl2ImageRes({
            MediaUrl1: imageRes.MediaUrl1,
            MediaUrl2: imageRes.MediaUrl2,
          });
          newProductImages.push(productImage);
        });

        const newProduct = {
          ...product,
          Images: [...product.Images, ...newProductImages],
        };
        setProduct(newProduct);
      }
    }
  };

  function generateVariantsData() {
    if (colorVariants.length <= 1 && sizeVariants.length <= 1) {
      setVariantsData([]);
    } else {
      let combos = [{}];
      let newcombos = [];
      if (colorVariants.length > 1) {
        newcombos = [];
        for (let i = 0; i < colorVariants.length; i++) {
          for (let j = 0; j < combos.length; j++) {
            newcombos.push({ ...combos[j], color: colorVariants[i] });
          }
        }
        combos = newcombos;
      }
      if (sizeVariants.length > 1) {
        newcombos = [];
        for (let i = 0; i < sizeVariants.length; i++) {
          for (let j = 0; j < combos.length; j++) {
            newcombos.push({ ...combos[j], size: sizeVariants[i] });
          }
        }
        combos = newcombos;
      }

      const temp = [] as any;
      for (let i = 0; i < combos.length; i++) {
        const newCombo: any = combos[i];
        const data: DetailedProductVariant = {
          Variations: [] as ProductVariation[],
          Price: product?.Variants[0]?.Price || 0,
          CmpPrice: product?.Variants[0]?.CmpPrice || 0,
          Stock: product?.Variants[0]?.Stock || 0,
          Images: [],
          ID: uuidv4(),
          Sku: product?.Sku || "",
        };
        if (newCombo.color) {
          data.Variations.push({
            Name: "Color",
            Value: newCombo.color as string,
          });
        }
        if (newCombo.size) {
          data.Variations.push({
            Name: "Size",
            Value: newCombo.size as string,
          });
          const existingProductVariantWithSameSize = product?.Variants?.find((variant) =>
            variant.Variations.some(
              (variation) => variation.Name === "Size" && variation.Value === newCombo.size
            )
          );
          data.Price = existingProductVariantWithSameSize?.Price || data?.Price;
          data.CmpPrice = existingProductVariantWithSameSize?.CmpPrice || data?.CmpPrice;
        }
        temp.push(data);
        if (newCombo.size && newCombo.color) {
          const existingProductVariantWithSameSizeAndColor = product?.Variants?.find(
            (variant) =>
              variant.Variations.some(
                (variation) => variation.Name === "Size" && variation.Value === newCombo.size
              ) &&
              variant.Variations.some(
                (variation) => variation.Name === "Color" && variation.Value === newCombo.color
              )
          );
          data.Stock = existingProductVariantWithSameSizeAndColor?.Stock || data.Stock;
          data.Sku = existingProductVariantWithSameSizeAndColor?.Sku || data.Sku;
          data.ID = existingProductVariantWithSameSizeAndColor?.ID || data.ID;
        }
      }
      const newProduct = product;
      if (newProduct) {
        newProduct.Variants = temp;
      }
      setProduct(newProduct);
      setVariantsData(temp);
      variantsTableContainerRef.current?.scrollIntoView();
    }
  }

  useEffect(() => {
    if (product) {
      generateVariantsData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sizeVariants, colorVariants]);

  const updateAllVariantsPriceBySize = (
    size: string,
    detailsToUpdate: { Price?: number; CmpPrice?: number; Stock?: number }
  ) => {
    if (!product) return;
    const newProduct = { ...product };
    newProduct.Variants = product.Variants.map((variant) =>
      variant.Variations.some((variation) => variation.Name === "Size" && variation.Value === size)
        ? { ...variant, ...detailsToUpdate }
        : variant
    );
    setProduct(newProduct);
  };

  function deleteImage(_index: number) {
    const newProduct = Object.assign({}, product);
    newProduct.Images = newProduct.Images.filter(
      (_image: ImageRes, index: number) => index !== _index
    );
    setProduct(newProduct);
  }

  const reorderImages = (x: number, y: number) => {
    if (!product) return;
    if (x < 0 || x >= product.Images.length) {
      return;
    }
    if (y < 0 || y >= product.Images.length) {
      return;
    }
    setProduct({
      ...product,
      Images: product.Images.map((val, i, arr) => {
        if (x === i) {
          return arr[y];
        }
        if (y === i) {
          return arr[x];
        }
        return val;
      }),
    });
  };

  function addSizeOption(_val: string) {
    if (!sizeVariants.includes(_val)) setSizeVariants([...sizeVariants, _val]);
  }

  function addColorOption(_val: string) {
    if (!colorVariants.includes(_val)) setColorVariants([...colorVariants, _val]);
  }

  function removeSizeVariantOption(_option: string) {
    const tempList = sizeVariants.filter((_c: string) => _c !== _option);
    setSizeVariants(tempList);
  }

  function addCategory(_category: Category): void {
    if (product !== null) {
      const newProduct = { ...product };
      if (newProduct.Categories.findIndex((category) => category.ID === _category.ID) !== -1) {
        const newCategories = newProduct.Categories.filter((c: Category) => c.ID !== _category.ID);
        newProduct.Categories = newCategories;
        setProduct(newProduct);
      } else {
        newProduct.Categories.push(_category);
        setProduct(newProduct);
      }
    }
  }

  function removeColorVariantOption(_option: string) {
    const tempList = colorVariants.filter((_c: string) => _c !== _option);
    setColorVariants(tempList);
  }

  if (sellerDetails === null || product === null) {
    return <FiLoader className="animate-spin"></FiLoader>;
  }

  const handleChanges =
    (fieldName: string) =>
    (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
      const newProduct = { ...product };
      if (fieldName === "Name") {
        newProduct.Name = e.target.value;
        setProduct(newProduct);
      }
      if (fieldName === "Description") {
        newProduct.Description = e.target.value;
        setProduct(newProduct);
      }
      if (fieldName === "Price") {
        const processedValue = processText(
          e.target.value.replace(localeDetails.currencySymbol, "")
        );
        let value = +processedValue;
        if (isNaN(value)) {
          value = 0;
          return;
        }
        newProduct.Variants = newProduct.Variants.map((variant) => ({ ...variant, Price: value }));
        setProduct(newProduct);
      }
      if (fieldName === "CmpPrice") {
        const processedValue = processText(
          e.target.value.replace(localeDetails.currencySymbol, "")
        );
        let value = +processedValue;
        if (isNaN(value)) {
          value = 0;
          return;
        }
        newProduct.Variants = newProduct.Variants.map((variant) => ({
          ...variant,
          CmpPrice: value === 0 ? null : value,
        }));
        setProduct(newProduct);
      }
      if (fieldName === "Stock") {
        let val: number = +e.target.value;
        if (isNaN(+e.target.value)) {
          val = 0;
        }
        newProduct.Variants = newProduct.Variants.map((variant) => ({ ...variant, Stock: val }));
        setProduct(newProduct);
      }
    };

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    removeNotificationByTag("Product");
    const newProduct = Object.assign({}, product);

    if (product?.DigitalProductUrl) {
      newProduct.DigitalProductUrl = digitalProductURL;
    }

    if (newProduct.Name.length < 3) {
      showErrorNotification("Please enter a valid product name");
      return;
    }
    if (newProduct.Images.length === 0) {
      showErrorNotification("Please upload atleast 1 image");
      return;
    }
    if (newProduct.Images.length >= sellerDetails.InventoryConfig.ImageCount) {
      showErrorNotification(
        `You can only upload a maximum of ${sellerDetails.InventoryConfig.ImageCount || 5} images`
      );
    }
    if (newProduct.Variants[0].Price === 0) {
      showErrorNotification("Please enter a valid price");
      return;
    }
    if (
      newProduct.Variants[0] &&
      newProduct.Variants[0].Price != null &&
      newProduct.Variants[0].CmpPrice != null &&
      newProduct.Variants[0].CmpPrice != 0 &&
      newProduct.Variants[0].Price > newProduct.Variants[0].CmpPrice
    ) {
      showErrorNotification("Price can't be higher than strike-off price");
      return;
    }
    if (
      newProduct.Variants[0] &&
      newProduct.Variants[0].Price != null &&
      newProduct.Variants[0].CmpPrice != null &&
      newProduct.Variants[0].CmpPrice != 0 &&
      newProduct.Variants[0].Price === newProduct.Variants[0].CmpPrice
    ) {
      showErrorNotification("Price can't be equal to strike-off price");
      return;
    }

    if (newProduct.Variants[0].Stock < 0 && !product?.DigitalProductUrl) {
      showErrorNotification("Please enter a valid stock");
      return;
    }

    if (
      product?.DigitalProductUrl &&
      digitalProductURL.length < 3 &&
      !urlRegex.test(digitalProductURL)
    ) {
      showErrorNotification("Please enter a valid digital product URL");
      return;
    }

    const refreshedToken = await refreshAndReturnFirebaseToken();
    if (refreshedToken !== null) {
      const newImages = newProduct.Images.map((image: ImageRes) => {
        const img = Object.assign({}, image);
        if (img.webpUrl) {
          if (!img.webpUrl?.includes("_800x800.webp")) {
            img.webpUrl = resizedUrl(img.webpUrl, ".webp");
          }
        }
        if (img.jpgUrl) {
          if (!img.jpgUrl?.includes("_800x800.jpg")) {
            img.jpgUrl = resizedUrl(img.jpgUrl, ".jpg");
          }
        }
        return img;
      });

      const editedProduct = await editProduct(refreshedToken, product.Sku, {
        ...newProduct,
        Images: newImages,
      });
      if (editedProduct.Err) {
        setProduct(null);
      }
      if (editedProduct.Data) {
        showSuccessNotification("Successfully updated the product!");
        navigate(-1);
      }
    }
  };
  const handleDelete = async () => {
    if (confirm("Are you sure you want to delete this product?")) {
      if (productSlug) {
        const refreshedToken = await refreshAndReturnFirebaseToken();
        if (refreshedToken !== null) {
          const deletedProduct = await deleteProduct(refreshedToken, productSlug);
          if (deletedProduct.Err) {
          }
          if (deletedProduct.Data) {
            navigate(-1);
          }
        }
      }
    }
  };
  const hasSizeVariants: boolean =
    Array.from(
      new Set(
        (product?.Variants || [])
          .map(
            (variant) =>
              variant.Variations.find((variation) => variation.Name === "Size")?.Value || ""
          )
          .filter((val) => val)
      )
    ).length > 0;

  const lowestPricedVariant = product?.Variants.reduce(
    (acc, variant) => (variant.Price < acc.Price ? variant : acc),
    product?.Variants[0]
  );

  return (
    <div className="my-4 sm:px-6 lg:px-8">
      <div className="mb-4 flex items-center justify-between">
        <h2 className="text-3xl py-2 font-light leading-8 text-gray-900 sm:text-4xl sm:truncate">
          Edit Product
        </h2>
        <button
          type="button"
          onClick={handleDelete}
          className="inline-flex justify-center items-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-red-500 border-red-500 hover:bg-opacity-95 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
        >
          <TrashIcon className="h-4 w-4 mr-2" /> Delete Product
        </button>
      </div>
      <form>
        <div className="grid grid-cols-3 mt-10 sm:mt-0">
          <div className="col-span-1">
            <EditProductImages
              images={product.Images}
              reorderImages={reorderImages}
              deleteImage={deleteImage}
              handleImageChange={handleImageChange}
              handleImageUploadClick={handleImageUploadClick}
              hiddenImageFileInput={hiddenImageFileInput}
            />
          </div>
          <div className="col-span-2">
            <div className="shadow overflow-hidden sm:rounded-md">
              <div className="px-4 py-5 bg-white sm:p-6">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6">
                    <label htmlFor="product-name" className="block text-sm font-bold text-gray-700">
                      Product Name
                    </label>
                    <input
                      type="text"
                      name="Name"
                      id="Name"
                      autoComplete="Name"
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      value={product.Name}
                      onChange={handleChanges("Name")}
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label htmlFor="Price" className="block text-sm font-bold text-gray-700">
                      Price
                    </label>
                    <input
                      type="text"
                      name="Price"
                      id="Price"
                      autoComplete="Price"
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      value={localeDetails.currencySymbol + product.Variants[0].Price!}
                      onChange={handleChanges("Price")}
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label htmlFor="CmpPrice" className="block text-sm font-bold text-gray-700">
                      Strike-off Price
                    </label>
                    <input
                      type="text"
                      name="CmpPrice"
                      id="CmpPrice"
                      autoComplete="CmpPrice"
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      value={
                        localeDetails.currencySymbol +
                        (hasSizeVariants
                          ? lowestPricedVariant.CmpPrice!
                          : product.Variants[0].CmpPrice
                          ? product.Variants[0].CmpPrice
                          : "0")
                      }
                      onChange={handleChanges("CmpPrice")}
                    />
                  </div>

                  {product.DigitalProductUrl && (
                    <div className="col-span-6">
                      <DigitalProduct
                        digitalProductURL={digitalProductURL}
                        setDigitalProductURL={setDigitalProductURL}
                      />
                    </div>
                  )}

                  {!product.DigitalProductUrl && (
                    <div className="col-span-3">
                      <label htmlFor="Stock" className="block text-sm font-bold text-gray-700">
                        Stock
                      </label>
                      <input
                        type="text"
                        name="Stock"
                        id="Stock"
                        autoComplete="Stock"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        value={product.Variants[0].Stock!}
                        onChange={handleChanges("Stock")}
                      />
                    </div>
                  )}
                  <div className="col-span-6">
                    <label htmlFor="Description" className="block text-sm font-bold text-gray-700">
                      Product Description
                    </label>
                    <div className="mt-1">
                      <ReactQuill
                        className="border border-gray-300 shadow-sm rounded-md"
                        value={product.Description}
                        onChange={(value) => {
                          setProduct((currentProduct) => {
                            const newProduct = Object.assign({}, currentProduct);
                            newProduct.Description = value;
                            return newProduct;
                          });
                        }}
                      />
                    </div>
                  </div>

                  {!product.DigitalProductUrl && (
                    <div className="col-span-6">
                      <VariantsContent
                        colorVariants={colorVariants}
                        removeColorVariantOption={removeColorVariantOption}
                        addColorOption={addColorOption}
                        sizeVariants={sizeVariants}
                        removeSizeVariantOption={removeSizeVariantOption}
                        addSizeOption={addSizeOption}
                        generateVariantsData={generateVariantsData}
                      />
                    </div>
                  )}
                  {hasSizeVariants && !product.DigitalProductUrl ? (
                    <div className="col-span-6">
                      <VariantsTable
                        key={"variants-table"}
                        variantsTableContainerRef={variantsTableContainerRef}
                        sizeVariants={sizeVariants}
                        variantsData={product.Variants}
                        updateAllVariantsPriceBySize={updateAllVariantsPriceBySize}
                        currencySymbol={localeDetails.currencySymbol}
                      />
                    </div>
                  ) : null}

                  <div className="col-span-6">
                    <CategoryList
                      categoryList={categories}
                      categoriesSelected={product.Categories}
                      addCategory={addCategory}
                    />
                  </div>
                </div>
              </div>
              <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                <button
                  onClick={handleSubmit}
                  className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-opacity-95 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
          {/* </div> */}
        </div>
      </form>
    </div>
  );
}

export default EditProduct;
