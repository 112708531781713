import React from "react";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/outline";
const DigitalProduct = ({
  digitalProductURL,
  setDigitalProductURL,
}: {
  digitalProductURL: string;
  setDigitalProductURL: (newValue: string) => void;
}) => {
  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
  }
  return (
    <Disclosure as="div" className="pt-2" defaultOpen={true}>
      {({ open }) => (
        <>
          <dt className="text-lg">
            <Disclosure.Button className="text-left w-full flex justify-between items-start bg-gray-200 p-3">
              <span className="font-semibold text-gray-900">Digital Product Details</span>
              <span className="ml-6 h-7 flex items-center">
                <ChevronDownIcon
                  className={classNames(open ? "-rotate-180" : "rotate-0", "h-6 w-6 transform")}
                  aria-hidden="true"
                />
              </span>
            </Disclosure.Button>
          </dt>
          <Disclosure.Panel as="dd">
            <div className="w-3/4 mt-4">
              <label htmlFor="product-name" className="block text-sm font-bold text-gray-700">
                Product URL
              </label>
              <input
                type="text"
                name="Name"
                id="Name"
                autoComplete="Name"
                placeholder="https://your-product-url"
                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                value={digitalProductURL}
                onChange={(e) => {
                  setDigitalProductURL(e.target.value);
                }}
              />
              <p className="mt-4 text-sm">
                Upon successful purchase, we will email this URL to your customer.
              </p>
              <p className="mt-2 text-sm">Here’s a sample email:</p>
              <img
                className="mt-2"
                src="/imgbin/digital_product_email.png"
                style={{ width: "65%" }}
              />
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default DigitalProduct;
