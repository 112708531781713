import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import FormInput from "../../../../../components/FormInput";
import { withTestId } from "../../../../../lib/utils";

export type PersonalDetailsField = {
  name: string;
  email: string;
  businessName: string;
  address: string | null;
  city: string | null;
  state: string | null;
  pincode: string | null;
};

type PersonalDetailsProps = {
  initialValue: PersonalDetailsField;
  /** Called when the user fills in the form */
  next: SubmitHandler<PersonalDetailsField>;
} & TestingProps;

const PersonalDetailsForm = React.memo(function PersonalDetailsForm(props: PersonalDetailsProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<PersonalDetailsField>({
    defaultValues: props.initialValue ?? undefined,
  });

  return (
    <form onSubmit={handleSubmit(props.next)} className="w-1/2">
      <FormInput
        {...withTestId("name-input")}
        id="name"
        placeholder="Name"
        control={{ type: "text", ...register("name", { required: true }) }}
        errMsg={"Please enter your name"}
        isError={errors.name != null}
      />
      <FormInput
        {...withTestId("email-input")}
        id="email"
        placeholder="Email"
        control={{
          type: "text",
          ...register("email", {
            required: true,
            pattern:
              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          }),
        }}
        errMsg="Please enter a valid email"
        isError={errors.email != null}
      />
      <FormInput
        {...withTestId("business-name-input")}
        id="businessName"
        placeholder="Business name"
        control={{ type: "text", ...register("businessName", { required: true }) }}
        errMsg="Please enter a valid business name"
        isError={errors.businessName != null}
      />
      <FormInput
        {...withTestId("address-input")}
        id="address"
        placeholder="Address"
        control={{ type: "text", ...register("address", { minLength: 5 }) }}
        errMsg="Please enter your address"
        isError={errors.address != null}
      />
      <FormInput
        {...withTestId("city-input")}
        id="city"
        placeholder="City"
        control={{ type: "text", ...register("city", { minLength: 3 }) }}
        errMsg="Please enter your city"
        isError={errors.city != null}
      />
      <FormInput
        {...withTestId("state-input")}
        id="state"
        placeholder="State"
        control={{ type: "text", ...register("state", { minLength: 3 }) }}
        errMsg="Please enter your state"
        isError={errors.state != null}
      />
      <FormInput
        {...withTestId("pincode-input")}
        id="pincode"
        placeholder="Pincode"
        control={{
          type: "text",
          ...register("pincode", { minLength: 6, maxLength: 6, valueAsNumber: true }),
        }}
        errMsg="Please enter your pinocde"
        isError={errors.pincode != null}
      />
      <button
        type="submit"
        disabled={Object.keys(errors).length > 0}
        className="uppercase bg-primary text-stone-200 font-bold p-2 w-full rounded disabled:bg-gray-400"
      >
        Save
      </button>
    </form>
  );
});

export default PersonalDetailsForm;
