import React, { useContext, useEffect, useState } from "react";
// import { SearchIcon } from "@heroicons/react/solid";
import { useNavigate } from "react-router-dom";

import CouponGrid from "./components/CouponGrid";
import { CouponDetails } from "../../../../lib/types";
import { AuthContext } from "../../../../lib/services/auth";
import { ApiContext } from "../../../../lib/services/api";
import { FiLoader } from "react-icons/fi";

const Coupons = () => {
  const navigate = useNavigate();
  const [coupons, setCoupons] = useState<CouponDetails[]>([] as CouponDetails[]);
  const { sellerDetails, refreshAndReturnFirebaseToken, localeDetails } = useContext(AuthContext);
  const { fetchAllCoupons, editCoupon, deleteCoupon } = useContext(ApiContext);

  const updateCoupon = async (updatedCouponDetails: CouponDetails, index: number) => {
    const refreshedToken = await refreshAndReturnFirebaseToken();
    if (refreshedToken) {
      const data = await editCoupon(refreshedToken, coupons[index].Id, updatedCouponDetails);
      if (data.Data) {
        const newCoupons = JSON.parse(JSON.stringify(coupons));
        newCoupons[index] = data.Data;
        setCoupons(newCoupons);
      }
    }
  };

  const handleDeleteCoupon = async (couponId: string) => {
    const refreshedToken = await refreshAndReturnFirebaseToken();
    if (refreshedToken) {
      const newCoupons = coupons.filter((coupon) => coupon.Id !== couponId);
      setCoupons(newCoupons);
      const data = await deleteCoupon(refreshedToken, couponId);
      if (data.Data) {
      }
    }
  };

  useEffect(() => {
    const fetchCoupons = async () => {
      const refreshedToken = await refreshAndReturnFirebaseToken();
      if (refreshedToken) {
        const data = await fetchAllCoupons(refreshedToken);
        if (data.Data) {
          setCoupons(data.Data);
        }
      }
    };
    fetchCoupons();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (sellerDetails === null) {
    return <FiLoader className="animate-spin"></FiLoader>;
  }

  return (
    <div className="my-4 sm:px-6 lg:px-8">
      <div className="flex justify-between align-center content-center">
        <h2 className="py-2 text-3xl font-light leading-8 text-gray-900 sm:text-4xl sm:truncate">
          Coupons
        </h2>
        <div className="w-2/5 flex flex-row justify-end items-center">
          <button
            type="button"
            className="inline-flex items-center px-4 py-2 mr-4 text-base font-medium text-white bg-primary border border-transparent rounded-md shadow-sm hover:bg-opacity-95 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => navigate("/store/coupons/new")}
          >
            Create a Coupon
          </button>
        </div>
      </div>
      {/* <div className="w-full flex flex-row justify-between items-center my-4">
        <div className="w-2/5 flex flex-row justify-start">
          <div className="flex-1 flex items-center">
            <div className="max-w-lg w-full lg:max-w-xs">
              <label htmlFor="search" className="sr-only">
                Search
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </div>
                <input
                  id="search"
                  name="search"
                  className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  placeholder="Search"
                  type="search"
                />
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* <div className="w-full flex flex-row justify-between items-center my-4">
        Filtering chips here
      </div> */}
      <CouponGrid
        coupons={coupons}
        editCoupon={updateCoupon}
        deleteCoupon={handleDeleteCoupon}
        currencySymbol={localeDetails.currencySymbol}
      />
    </div>
  );
};

export default Coupons;
