import React, { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/outline";
import { Category, CategoryListResponse } from "../../../../../lib/types";
import { classNames } from "../../../../../lib/utils";

const CategoryList = React.memo(function CategoryList({
  categoryList,
  categoriesSelected,
  addCategory,
}: {
  categoryList: CategoryListResponse[];
  categoriesSelected: Category[];
  addCategory: (_category: Category) => void;
}) {
  return (
    <Popover as="div" className={classNames("relative inline-block text-left")}>
      {({ open }) => (
        <div className={open ? "pb-48" : ""}>
          <Popover.Button className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none ">
            Select Categories
            <ChevronDownIcon className="w-5 h-5 ml-2 -mr-1" aria-hidden="true" />
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute z-10 w-56 px-4 mt-2 overflow-auto inset-0 bg-white rounded-md shadow-lg top-10 max-h-48 sm:px-0 lg:max-w-3xl ring-1 ring-black ring-opacity-5 focus:outline-none">
              {/* <Menu.Items className="absolute w-56 mt-2 overflow-auto  max-h-48 "> */}
              <div className="py-1">
                {categoryList
                  .slice()
                  .sort((_category) =>
                    categoriesSelected.findIndex((category) => category.ID === _category.Id) !== -1
                      ? -1
                      : 1
                  )
                  .map((_category: CategoryListResponse, i: number) => (
                    <div key={i}>
                      <div className="relative flex items-start px-4 py-2 text-sm">
                        <div className="flex items-center h-5">
                          <input
                            id={`category-${_category.Id}`}
                            aria-describedby="comments-description"
                            name="comments"
                            type="checkbox"
                            checked={
                              categoriesSelected.findIndex(
                                (category) => category.ID === _category.Id
                              ) !== -1
                            }
                            onChange={() => addCategory({ Name: _category.Name, ID: _category.Id })}
                            className="w-4 h-4 text-primary border-gray-300 rounded focus:ring-indigo-500"
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label
                            htmlFor={`category-${_category.Id}`}
                            className="font-medium text-gray-700"
                          >
                            {_category.Name}
                          </label>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </Popover.Panel>
          </Transition>
        </div>
      )}
    </Popover>
  );
});

export default CategoryList;
