/* eslint-disable react-hooks/exhaustive-deps */
import { AuthContext } from "../../../lib/services/auth";
import React, { useContext, useEffect, useState } from "react";

import AnnouncementBar from "./components/AnnouncementBar";
import RevenueSummary from "./components/RevenueSummary";
import TransactionsTable from "./components/TransactionsTable";
import { ApiContext } from "../../../lib/services/api";
import { Payment } from "../../../lib/types";
import { FiLoader } from "react-icons/fi";

export default function Payments() {
  const [payments, setPayments] = useState<Payment[] | null>([]);

  const { sellerDetails, refreshAndReturnFirebaseToken } = useContext(AuthContext);
  const { getAllPayments } = useContext(ApiContext);

  useEffect(() => {
    const getPayments = async () => {
      const refreshedToken = await refreshAndReturnFirebaseToken();
      if (refreshedToken !== null) {
        const fetchedPayments = await getAllPayments(refreshedToken);
        if (fetchedPayments.Err) {
          setPayments(null);
        }
        if (fetchedPayments.Data) {
          setPayments(fetchedPayments.Data.payments);
        }
      }
    };
    getPayments();
  }, []);

  if (sellerDetails === null) {
    return <FiLoader className="animate-spin"></FiLoader>;
  }

  return (
    <div className="my-4 sm:px-6 lg:px-8">
      {!sellerDetails.BankDetails && <AnnouncementBar />}
      <RevenueSummary />
      {payments && payments.length > 0 && <TransactionsTable payments={payments} />}
    </div>
  );
}
