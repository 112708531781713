/* API response types */
import { User as FirebaseUserObject, IdTokenResult, ConfirmationResult } from "firebase/auth";

export type DeliveryModeKind = "cod" | "card";
/** Type of payment method accepted by seller */
export type DeliveryMode = {
  kind: DeliveryModeKind; // Used to refer to this mode
  surcharge: number; // Extra charge for this payment method
};

/**
 * Used solely to document that the enclosing type can be acquired by querying
 * the collection. It has no meaning outside this documentation
 */
export type FirestoreCollection<Type> = Type;

export type Uuid = string;

/**
 * A single image resource
 */

/**
 * A single WebPush or other notification endpoint to send push notifications
 */
export type PushToken = {
  /**
   * Endpoint URL to send WebPush push notifications.
   */
  Url: string | null;
  /**
   * Token to sened push notifications via FCM.
   */
  Token: string | null;
  /**
   * The provider being used to send notifications.
   *
   * apn: Apple push notification
   * fcm: Firebase cloud messaging
   */
  Provider: "fcm" | "apn";
};

/**
 * Id token result of firebase
 */
export type TokenVerificationResponse = IdTokenResult;

/**
 * Firebase User Object
 */
export type User = FirebaseUserObject;

export type VerificationCodeResponse = ConfirmationResult;

export type Category = {
  /** ID of category */
  ID: string;
  /** Friendly name of category */
  Name: string;
};

export type Timeseries = {
  today: number;
  yesterday: number;
  week: number;
  month: number;
};

export type Money = number;

export type Address = {
  /**
   * Free-form user provided address (line 1). If the address isn't provided,
   * defaults to an empty string.
   *
   * Example: `"12/404 trilok Puri delhi"`
   */
  Address1: string | null;
  /**
   * Free-form user provided address (line 2). If it isn't provided,
   * defaults to an empty string.
   *
   * Example: See `Address1`
   */
  Address2: string | null;
  /**
   * Free-form user provided city.
   *
   * Example: `"New Delhi"`
   */
  City: string | null;
  /**
   * Free-form user provided state.
   *
   * Example: `"Uttar Pradesh"`
   */
  State: string | null;
  /**
   * Free-form user provided pin code.
   *
   * Example: `"857894"`
   */
  Pincode: string | null;
};

export type MediaUrl = {
  /**
   * WebP image URL
   *
   * Example: `"https://firebasestorage.googleapis.com/v0/b/instasell-7f53f.appspot.com/o/000040cc-37cc-4beb-9edd-e01413076b90%2Fpic_28c61f11-a74a-496b-9a08-7b0e14ce1304_800x800.webp?alt=media&token=339b5bc5-e496-415c-bf21-2f20344decb0"`
   */
  MediaUrl1: string;
  /**
   * JPG image URL
   *
   * FIXME(muscache, rranjan14): This should never be null but it is when
   * uploading from CSV.
   *
   * Example: `"https://firebasestorage.googleapis.com/v0/b/instasell-7f53f.appspot.com/o/000040cc-37cc-4beb-9edd-e01413076b90%2Fpic_28c61f11-a74a-496b-9a08-7b0e14ce1304_800x800.jpg?alt=media&token=339b5bc5-e496-415c-bf21-2f20344decb0"`
   */
  MediaUrl2: string;
  /**
   * Allows the seller to decide whether the image should be contain or cover.
   *
   * If not present, callers should assume "cover".
   */
  ObjectFit?: "contain" | "cover";
};

/**
 * Announcement bar shown on seller pages
 */
export type SellerAnnouncement = {
  /**
   * Text to show in the announcement bar
   *
   * Example: `"Summer offer! Use code LOVESUMMER10 to get 10% off."`
   */
  Text: string;
  /**
   * Background color of announcement bar. Hex code without leading '#'
   *
   * Example: `"000000"`
   */
  BgColor: string;
  /**
   * Text color of announcement bar. Hex code without leading '#'
   *
   * Example: `"ffffff"`
   */
  TextColor: string;
  /**
   * Clicking on the bar will redirect to this link.
   *
   * Example: `"https://example.com/can-be-anything"`
   */
  Href: string | null;
};

export type SellerBanner = {
  /**
   * Image to show in the banner
   *
   * Example: See `MediaUrl`.
   */
  Media: MediaUrl;
  /**
   * Optional seller-provided title for the banner.
   *
   * Example: `"Introducing our winter collection"`
   */
  Caption: string | null;
  /**
   * Optional seller-provided call-to-action button shown on the banner.
   * If it is absent, only the image is shown.
   */
  Cta: {
    /**
     * Color of button background, hex code not including '#'
     *
     * Example: `"dedede"`
     */
    BgColor: string;
    /**
     * Color of button text, hex code not including '#'
     *
     * Example: `"000000"`
     */
    TextColor: string;
    /**
     * Text on the button.
     *
     * Example: `"Learn more"`
     */
    Text: string;
    /**
     * Clicking on the button will redirect to the given url. Seller-provided.
     *
     * Example: `"https://instasell.io/berrysorbet/fashion"`
     */
    Href: string;
  } | null;
};

/** Basic information about a product. Used in product cards */
export type ProductRef = {
  /** Product ID */
  id: string;
  /** Product slug */
  slug: string;
  /** Product name */
  name: string;
  /** How many are in stock? */
  stock: number;
  /** Product price (currently rupees) */
  price: number;
  /**
   * Comparison product price (currently rupees)
   * It is always greater than `Price`
   * If it is present, a discount badge will be shown on ProductCard and
   * CartGridItem
   */
  cmpPrice: number | null;
  /** Images of product */
  images: ImageRes[];
};

/**
 * Fields for a single "variation" of a variant.
 *
 * For example. A `Product` for jeans may have multiple `ProductVariant`.
 * Each `ProductVariant` may have the following `ProductVariation`s:
 * - name: "Color", value: "Red"
 * - name: "Size", value: "Large"
 *
 * So, the seller may provide red jeans of large size, or blue jeans of small size
 */
export type ProductVariation = {
  /**
   * Seller provided arbitrary name of this variation.
   *
   * Example: `"Size"`
   */
  Name: string;
  /**
   * Seller provided arbitrary value for this variation.
   *
   * Example: `"Small"`
   */
  Value: string;
  /**
   * Seller provided optional description for what this variation means.
   *
   * Example: `"Fits children upto 6 years old"`
   */
  Description?: string;
};

/** Detailed information about a single product variant */
export type DetailedProductVariant = {
  /** Variant ID */
  ID: string;
  /** Product price (currently rupees) */
  Price: number;
  /**
   * Comparison product price (currently rupees)
   * It is always greater than `Price`
   * If it is present, a discount badge will be shown on ProductCard and
   * CartGridItem
   */
  CmpPrice: number | null;
  /** Images specific to this variant */
  Images: ImageRes[];
  /** How many are in stock? */
  Stock: number;
  /** Variations of this product */
  Variations: ProductVariation[];

  Sku: string;
};

/**
 * Detailed information about a product. Used on Productfront pages
 */
export type ProductDetailsResponse = {
  /** Product ID */
  Sku: string;
  /** Product slug */
  Slug: string;
  /** Product name */
  Name: string;
  /**
   * Variants of this product.
   * Note that all products have at least one "variant"
   */
  Variants: DetailedProductVariant[];
  /**
   * What categories this product is part of. Length is at least one
   * Used in the `you may also like` section
   */
  Categories: Category[];

  /** Images general to this product */
  Images: ImageRes[];
  /** Description of product (HTML) */
  Description: string;
  /** Product description in plain text for SEO purposes */
  SeoDescription: string;
  /** Rating from 1 to 5 */
  Rating: number;
  /** URL of the product if it is digital */
  DigitalProductUrl: string | undefined;
};

/** Response when fetching details about token validity */

/** Response when fetching information about a single storefront */
export type SellerDetailsResponse = {
  /**
   * Slug for the store and used to refer to the store in URLs.
   *
   * Example: `"berrysorbet"`
   */
  Slug: string;
  /**
   * Free-form seller provided business name. Should not be confused with
   * `BusinessName`.
   *
   * Example: `rajeSh TripaTHi`
   */
  SellerName: string;
  /**
   * Free-form seller provided business name. Buyers see this on the nav bar.
   * Should not be confused with `SellerName`.
   *
   * Example: `"Berry Sorbet"`
   */
  BusinessName: string;
  /**
   * Address information of seller.
   *
   * Example: See `Address`
   */
  Address: Address;
  /**
   * Order in which the categories will be displayed on the storefront.
   * It is a list of IDs of the categories.
   */
  CategoryOrder: string[];
  /**
   * HTML seller description.
   *
   * Callers must sanitize the HTML to only allow (exhaustive):
   * - Tags: `div`, `ul`, `ol`, `li`, `p`, `strong`, `em`, `a`, `h(1|2|3|4|5|6)`
   * - Attributes: `href`
   *
   * Example: `"<div><p>Example <a href="a">URL</a></p></div>"`
   */
  Description: string | null;
  /**
   * Seller-provided tagline.
   *
   * Example: `"We are the best"`
   */
  Tagline: string | null;
  /**
   * Email address of seller. Case sensitive
   *
   * Example: `my.402@yahoo.co.in`
   */
  Email: string | null;
  /**
   * Phone number of seller. E.164 format.
   *
   * Example: `"+918142513301"`
   */
  PhoneNumber: string;
  /**
   * Gst details of the seller, it includes gst number,pan,etc..
   */
  GstDetails: {
    /**
     * Name on gst
     */
    Name: string | null;
    /**
     * 15 digit Gst Id
     */
    Id: string | null;
    /**
     * Url of gst certificate
     */
    CertificateUrl: string | null;
    /**
     * Pancard of seller.
     *
     * Example: `"DDQPB7781f"`
     */
    Pan: string | null;
    /**
     * PanCard frontside picture url
     */
    PanUrl: string | null;
    /**
     * PanCard backside picture url
     */
    PanBackUrl: string | null;
  };
  /**
   * Plan type of the seller
   */
  PlanType: PlanTypes;
  /**
   * Inventory config and limits based on plan types
   */
  InventoryConfig: InventoryConfigLimits;
  /**
   * WhatsApp contact number of seller. E.164 format.
   *
   * Example: `"+918142513301"`
   */
  WhatsappNumber: string | null;
  /**
   * Seller social media presence
   */
  Social: {
    /**
     * Facebook page name.
     *
     * Example: `"ceylon"`
     */
    Facebook: string | null;
    /**
     * Instagram account name.
     *
     * Example: `"ceylon"`
     */
    Instagram: string | null;
    /**
     * Pinterest account name.
     *
     * Example: `"ceylon"`
     */
    Pinterest: string | null;
    /**
     * Twitter account name.
     *
     * Example: `"ceylon"`
     */
    Twitter: string | null;
    /**
     * YouTube channel ID.
     *
     * Example: `"ceylon"` or `"UClCnFg6o49f3qGIjmy1jGcg"`
     */
    Youtube: string | null;
  };
  /**
   * Shipping information about the seller.
   */
  Shipping: {
    /**
     * Averahe time(in days) taken by the seller to
     * ship the product
     */
    AverageShippingDays: number;
    /**
     * Return policy for customers
     */
    ReturnPolicy: string;
    /**
     * Exchange the product within this many days. If zero, the seller does not
     * accept exchanges.
     *
     * Example: `0`
     */
    ExchangeDays: number;
    /**
     * Return the product within this many days. If zero, the seller does not
     * accept returns.
     *
     * Example: `0`
     */
    ReturnDays: number;
    /**
     * Extra surcharge for cash-on-delivery orders in paise.
     * Zero represents no charge. `-1` means that the seller does not accept
     * cash-on-delivery.
     *
     * Example: `4590` to represent a surcharge of 45.9 rupees
     */
    CodCharge: Money;
    /**
     * COD Shipping is free if the total order goes above this value (paise).
     * If it is null, there is no free shipping.
     *
     * Example: `45900` represents that, to qualify for free shipping, the order
     * must be at least 459.00 rupees.
     */
    CodFreeShippingAbove: Money | null;
    /**
     * Limit above which cod orders will be disabled
     */
    CodUpperLimit: Money | null;
    /**
     * Limit below which cod orders will be disabled
     */
    CodLowerLimit: Money | null;
    /**
     * Extra surcharge for debit/credit card orders in paise.
     * Zero represents no charge. Unlike `CodCharge`, all sellers are guaranteed
     * to accept credit card.
     *
     * Example: `4590` to represent a surcharge of 45.9 rupees
     */
    CardCharge: Money;
    /**
     * Prepaid Shipping is free if the total order goes above this value (paise).
     * If it is null, there is no free shipping.
     */
    PrepaidFreeShippingAbove: Money | null;
    /**
     * Limit above which prepaid orders will be disabled
     */
    PrepaidUpperLimit: Money | null;
    /**
     * Limit below which prepaid orders will be disabled
     */
    PrepaidLowerLimit: Money | null;
    /**
     * Shipping is free if the total order goes above this value (paise).
     * If it is null, there is no free shipping.
     *
     * Example: `45900` represents that, to qualify for free shipping, the order
     * must be at least 459.00 rupees.
     */
    FreeShippingAbove: Money | null;
    /**
     * International shipping is enabled or not.
     *
     * If this is true, we will show a list of countries in contact details during checkout
     * Else we will show only the home country of the seller.
     */
    InternationalShipping: boolean;
  };
  /**
   * Banners to show on storefront.
   *
   * Example: See `SellerBanner`
   */
  Banners: SellerBanner[];
  /**
   * Announcement bar on the seller's homepage
   *
   * Example: See `SellerAnnouncement`
   */
  Announcement: SellerAnnouncement | null;
  /**
   * Logo of the store.
   *
   * Example: See `MediaUrl`
   */
  Logo: MediaUrl | null;
  /**
   * Determines whether the bank details of the seller can be updated or not.
   * Max limit for updating bank details is 3.
   */
  CanUpdatebankDetails: boolean;
  /**
   * Seller's bank information.
   */
  BankDetails: {
    /**
     * Name of account holder.
     *
     * Example: `"Neel Talvar"`
     */
    AccountHolder: string | null;
    /**
     * Email related to this bank. May differ from `Seller.Email`.
     *
     * Example: `"neel.talvar42@gmail.com"`
     */
    Email: string | null;
    /**
     * IFSC code of seller
     *
     * Example: `"BCEY0CHEN01"`
     */
    IfscCode: string | null;
    /**
     * Bank account number of seller
     *
     * Example: `"57597659495"`
     */
    AccountNumber: string | null;
    /**
     * PAN of seller
     *
     * Example: `"ABCDE1234F"`
     */
    Pan: string | null;
    /**
     * Image of seller PAN card
     *
     * Example: `"https://firebasestorage.googleapis.com/v0/b/instasell-7f53f.appspot.com/o/000040cc-37cc-4beb-9edd-e01413076b90%2Fpic_28c61f11-a74a-496b-9a08-7b0e14ce1304_800x800.jpg?alt=media&token=339b5bc5-e496-415c-bf21-2f20344decb0"`
     */
    PanImgUrl: string | null;
    /**
     * Seller's UPI ID.
     *
     * Example: `"1822myers@icicibank"`
     */
    UpiId: string | null;
    /**
     * Randomly generated ID used for [CashFree payments](https://www.cashfree.com/).
     *
     * Example: `"Ceylon6976901138"`
     */
    BeneficiaryId: string | null;
    /**
     * Method to get paid.
     */
    Method: "upi" | "bankDetails";
  };
  /**
   * Tokens to send push notifications to the seller.
   *
   * Example: See `PushToken`
   */
  Push?: FirestoreCollection<PushToken[]>;
  /**
   * Whether this seller is premium
   */
  IsPremium: boolean;
  /**
   * Whether the seller has completed onboarding
   */
  IsOnboarded: boolean;
  /**
   * Instagram token for the user.
   */
  InstagramToken: string | null;
  /**
   * Custom configuration for the website store page.
   */
  WebsiteConfig: {
    /**
     * Custom field to show to customer at checkout form
     */
    CustomField: string;
    /**
     * Whether to show the whatsapp badge on the seller's page.
     *
     * If `Seller.WhatsappNumber` is null, this value has no effect.
     */
    ShowWhatsAppBadge: boolean;
    /**
     * A flag to see if category collections feature is active or not.
     */
    CategoryCollections: boolean;
    /**
     * Survey pop-up form related config. If it is null, the form will not be
     * shown.
     */
    SurveyForm: {
      /**
       * What fields to collect.
       */
      Fields: SurveyFields[];
      /**
       * Show the form after this many seconds (integer).
       *
       * If the value is -1, the survey form is disabled.
       */
      AfterDelaySecs: number;
      /**
       * The buyer will be presented this coupon as a reward for filling the
       * form.
       *
       * Example: `"THANKS20"`
       */
      CouponReward: string | null;
    } | null;

    /**
     * Primary color chosen by seller without leading `#`
     *
     * Example: `"ff0000"`
     */
    PrimaryColor: string | null;
    /**
     * Object-Fit of the pictures used in the product card.
     * If there is a different value on the product card, it will overwrite this
     * value.
     */
    ProductCardObjectFit: "cover" | "contain" | null;
    /**
     * Seller provided facebook pixel ID. If null, use InstaSell's facebook
     * pixel ID instead.
     *
     * Example: `"294514837558533"`
     */
    FbPixelId: string | null;
    /**
     * Seller provided GTM container ID. If null, do not render a GTM tag because
     * we don't have our own
     *
     * Example: `"GTM-QPDN44"`
     */
    GtmContainerId: string | null;
  };
  /**
   * FQDN of seller's custom domain. The store will only serve on custom domain
   * if the `Host` header matches this string.
   *
   * It is not used when serving storefronts on `instasell.io`.
   *
   * Example: `"www.berrysorbet.io"`
   */
  Fqdn: string | null;
  /**
   * Contains credentials of seller's shiprocket's accounts.
   * The given credentials are only able to work with Shiprocket APIs
   * and cannot be used to authenticate through dashboard
   *
   * Example: Email:`"test@test.com"`, Password:`"test"`
   */
  ShiprocketCredentials: {
    /**
     * Email associated to the seller's shiprocket account to access through APIs.
     * Note: This is not the same as the email used to access the shiprocket dashboard.
     */
    Email: string;
    /**
     * Paasowrd to access the seller's shiprocket account through the APIs.
     * Note: This won't work with the shiprocket dashboard.
     */
    Password: string;
    /**
     * Shiprocket token
     *
     * Example: `"eyJhbGciOiJIUzI1iIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMj3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKx4fwpMeJf36POk6yJVadQssw5c"`
     */
    Token: string;
    /**
     * When the token was created as a UNIX timestamp (seconds)
     *
     * Example: `11896749656`
     */
    TokenCreatedAt: number;
  } | null;
  /**
   * This contains data of all the new people coming to the store.
   */
  Visitors: Timeseries;

  /**
   * This contains data of all the sessions created for the store.
   */
  Sessions: Timeseries;

  /**
   * This contains data of the number of orders for the seller.
   */
  OrdersData: Timeseries;

  /**
   * This contains data of the revenue for the seller
   */
  Revenue: Timeseries;
  /**
   * Details about `locale` of the seller.
   */
  LocaleDetails: {
    /** Phone number length of the country from where the seller signs in, e.g, [10] */
    PhoneLength: number[] | null;
    /** Country code of the country from where the seller signs in, e.g. `IN` */
    CountryCode: string | null;
    /** Currency code of the country from where the seller signs in, e.g. `INR` */
    CurrencyCode: string | null;
    /** Currency symbol of the country from where the seller signs in, e.g. `₹` */
    CurrencySymbol: string | null;
    /** Phone code of the country from where the seller signs in, e.g. '91`.
     * Note: We will not be including the `+` sign in the phone code.
     */
    PhoneCode: string | null;
  };
  /**
   * Stripe account details if the seller has connected their stripe account.
   */
  StripeAccountDetails: {
    /**Account id of the connected account.*/
    AccountId: string | null;
    /**Country of the seller */
    Country: string | null;
    /** Currency of the country from where the account is created */
    Currency: string | null;
    /** Account creating date in seconds*/
    Created: number | null;
    /** Determines whether the seller account is ready for payout or not.
     * Incase, it is not ready, the seller will not be able to collect payment using their gateway.
     */
    Onboarded: boolean;
    /** Email associated with stripe account */
    Email: string | null;
  };
};

/** A single item in cart. Used when updating cart */
export type UpdateCartItem = {
  id: ProductRef["id"];
  /** Quantity in cart */
  quantity: number;
};

/** A single item in cart */
export type CartItem = {
  /**
   * Information about the product. For updates use `UpdateCartItem`
   */
  details: ProductRef;
  /** Quantity in cart */
  quantity: number;
};

/** Response when getting items in cart */
export type CartResponse = {
  /** Cart ID. Used as reference when updating cart */
  ID: string;
  /** Items in the cart */
  Items: CartItem[];
  /** Total value of all items, not including shipping charge */
  Subtotal: number;
  /** Shipping charges */
  Shipping?: number;
};

/**
 * Order List data
 */
export type OrderList = {
  /**
   * Friendly ID of this order.
   *
   * Example: `"1001"`
   */
  OrderId: string;
  /**
   * UNIX timestamp (seconds) when this order was created. Has nothing to do
   * with `Cart.Created`.
   *
   * Example: `1639735533`
   */
  Created: number;
  /**
   * Name of the buyer as saved in OrderDetails.
   */
  BuyerName: string;
  /**
   * Email of the buyer.
   */
  Email: string;
  /**
   * Phonenumber of the buyer.
   */
  Phonenumber: string;
  /**
   * Total order amount, including shipping and other charges (in paise)
   *
   * Example: `40000` to represent an order of 400.00 rupees
   */
  Total: number;
  /**
   * What the status of the order is.
   */
  Status:
    | "Pending" // The order has not yet been seen by the seller
    | "Accepted" // The order has been seen by the seller and is pending shipment
    | "Shipped" // The order has been shipped but not yet delivered
    | "Delivered" // The order has been delivered
    | "Pickup Ready" // This means that the order has been packed by the seller and the buyer is ready and waiting for pickup
    | "Rejected" // The order was cancelled by the seller
    | "Cancelled" // The order was cancelled by the buyer
    | "Returned" // The order was returned to the seller
    | "NDR" // If the buyer doesn't accept the order, this is marked as "ndr". (Non-Delivery Report)
    | "RTO" //The order was returned to origin.
    | undefined;
  /**
   * Total number of items bought by the buyer
   */
  TotalItems: number;
  /**
   * Which payment method was used for the order.
   *
   * - "cod" - The order is Cash-On-Delivery.
   * - "prepaid" - The order was paid by Credit/Debit card. `Order.Prepaid` will
   * be non-null.
   */
  DeliveryMode: "cod" | "prepaid";
  /**
   * Name of the products in this order.
   *
   * Used solely for the purpose of assisting in the search of orders through product names.
   */
  ProductNames: string[];
};

/**
 * Address of a seller or buyer
 */

/**
 * A single item in an order. Note that only a reference to the item are stored in
 * the document. The caller is responsible for actually fetching the product
 * they reference.
 */
export type OrderItem = CartItem & {
  /**
   * Price of the product in paise at the time the order was placed.
   *
   * Example: `4000` to represent a product was priced at 40.00 rupees when the
   * order was placed.
   */
  price: number;
};

/**
 * Cart for a single shop. It represents items that haven't yet been ordered.
 * Should not be confused with `Order`, which represents
 * a `Cart` that has been placed for order.
 *
 * Note that this document is meant to be embedded into a subcollection on the
 * `sellers` collection.
 *
 * When the user checks out their cart, the document is converted into an `Order`.
 * Once an order is placed, the corresponding cart is deleted.
 */
export type Cart = {
  /**
   * ID of owner of this cart. References document by ID in the `buyers`
   * collection.
   *
   * Example: See `Buyer.Id`
   */
  buyerId: string;
  /**
   * UNIX timestamp (seconds) when this cart was created.
   *
   * Example: `1639735533`
   */
  created: number;
  /**
   * Items in this cart.
   *
   * Example: See `CartItem`
   */
  items: CartItem[];
  /**
   * Buyer details at the time of checkout
   */
  buyerDetails: {
    /** `Buyer["FullName"]` */
    fullName: string;
    /** `Buyer["Email"]` */
    email: string;
    /** `Buyer["PhoneNumber"]` */
    phoneNumber: string;
    /** `Buyer["Address"]` */
    address: Address;
  };
};

/** How to order products on Categoryfront */
export type FilterOrdering = "price_asc" | "price_desc" | "popularity";

/**
 * The response for `Product Listing` screen on the Web App.
 */
export type ProductListResponse = {
  /**
   * ID of the product.
   * E.g. See `Uuid`
   */
  Id: Uuid;
  /**
   * SKU of the product. Can change.
   * E.g. `"12345"`
   */
  Sku: string;
  /**
   * Slug of the product.
   * E.g. `"berry-sorbet"`
   */
  Slug: string;
  /**
   * Name of the product.
   * E.g. `"Berry Sorbet"`
   */
  Name: string;
  /**
   * Price of the product. It will be minimum of all the variants.
   */
  Price: Money;
  /**
   * Total number of variants of the product.
   */
  VariantsCount?: number;
  /**
   * Total number of the stocks of all the variants. This will be used for the webapp.
   */
  TotalStocks?: number;
  /**
   * Minimum number of stocks of all the variants. This will be used for mobile app.
   */
  MinStocks?: number;
  /**
   * First image in the list of images in product's gallery.
   */
  Image: ImageRes;
  /**
   * A boolean which shows the state of the product
   *
   * Is it is true, we send it to the storefrom, else we don't send that product to the storefront.
   */
  IsActive: boolean;
};

/**
 * A single product stored in the database.
 *
 * It can be thought of as a "container" for common fields like product images.
 */
export type Product = {
  /**
   * Freeform seller-provided locally (ie., within the store) unique identifier.
   * If it is not provided, defaults to `Id`.
   *
   * Example: `"895698474"`, `"google jumper"`, `"JRKHRKJ79738935JID"`
   */
  Sku: string;
  /**
   * Slug for the product. Derived from `Name` on creation and
   * guaranteed to never change, regardless of updates to `Name`.
   *
   * Example: `"google-jumper"`
   */
  Slug: string;
  /**
   * List of category *ids* this product belongs to.
   *
   * Example: See `UUID`
   */
  Categories: Uuid[];
  /**
   * Dynamic seller-provided key-value information. Not shown to the buyer.
   * Reserved for future use when supporting custom websites.
   *
   * Example: `{"Can_Be_Anything": 2}`
   */
  DynamicProps: { [key: string]: string | number | boolean };
  /**
   * A list of images that are common across this product's variants.
   *
   * If it is empty, the caller
   * should send a default "image not available" sprite instead.
   * TODO(muscache): Provide a link here to the "image not available" sprite.
   *
   * Example: See `MediaUrl`
   */
  Media: MediaUrl[];
  /**
   * HTML product description.
   *
   * Callers must sanitize the HTML to only allow (exhaustive):
   * - Tags: `div`, `ul`, `ol`, `li`, `p`, `strong`, `em`, `a`, `h(1|2|3|4|5|6)`
   * - Attributes: `href`
   *
   * Example: `"<div><p>Example <a href="a">URL</a></p></div>"`
   */
  Description: string;
  /**
   * Product name.
   *
   * Example: `"Google Jumper"`
   */
  Name: string;
  /**
   * UNIX timestamp (seconds) when this product was created.
   *
   * Example: `1639735533`
   */
  Created: number;
  /**
   * Variants of this product. All products have at least one "variant".
   *
   * Example: See `ProductVariant`
   */
  Variants: ProductVariant[];
  /**
   * The average price of this product's variants. It is not guaranteed to be
   * accurate and, for critical contexts, callers must compute this value
   * manually.
   *
   * It is used only to order products by price and should not be used outside
   * this use-case.
   *
   * Example: See `Money`
   */
  AveragePrice: Money;
};

export type ProductInfoCard = {
  /**
   * Title of the card.
   *
   * Example: `"Size"`
   */
  Title: string;
  /**
   * Value of the card (shown under title).
   *
   * Example: `"400ml"`
   */
  Value: string;
};

export type ProductVariant = {
  /**
   * Seller-provided variant Sku.
   * If it is not provided, defaults to `Sku` of parent `Product`.
   *
   * Example: See `Product.Sku`
   */
  Sku: string;
  /**
   * Locally unique 6-letter ID. Used to reference a particular product's
   * variant.
   *
   * Note that it is not globally unique, ie., many products may share the same
   * variant ID.
   *
   * Example: `"fDj3A4"`
   */
  Id: string;
  /**
   * Seller-provided key-value information. Unlike `DynamicProps`, it is shown
   * to the buyer in the form of information cards.
   *
   * Example: See `ProductInfoCard`
   */
  InfoCards: ProductInfoCard[];
  /**
   * Number of units currently in stock. Integer.
   *
   * Example: `34`
   */
  Stock: number;
  /**
   * Original, (always higher) price of the product in paise. Used to compare
   * old price with new, lower price.
   *
   * Example: `4400` to represent a product worth 44.00 rupees.
   */
  CmpPrice: Money | null;
  /**
   * Price of the product in paise.
   *
   * Example: `4000` to represent a product worth 40.00 rupees
   */
  Price: Money;
  /**
   * A list of images that are specific to this variant.
   *
   * If it is empty, the view should display `Media` of the parent
   * `Product`.
   *
   * If it is not empty, the view should display this list of images in priority
   * to `media_url_list` of the parent `Product`
   *
   * Example: See `MediaUrl`
   */
  Media: MediaUrl[];
  /**
   * Field values in this variant. Can be empty if the product has no variants.
   *
   * Example: See `ProductVariation`
   */
  Variations: ProductVariation[];
};

/**
 * Payment made to a seller. Stored in the `Payments` subcollection
 */
export type Payment = {
  /**
   * Transfer Id of the payment.
   * It will not be auto generated.
   * Generally will be in the format - `BeneficiaryIdOfSeller_OrderId`.
   * Example: `Ranjan9887309554_1009`
   */
  TransferId: string;
  /**
   * Time at which this transfer is created.
   * It will be a number in seconds since epoch.
   * Example: `1589735600`
   */
  Created: number;
  /**
   * Time at which this transfer is updated.
   * It will be a number in seconds since epoch.
   * Example: `1589735600`
   */
  Updated: number;
  /**
   * ID of buyer
   */
  BuyerId: Uuid;
  /**
   * Order document ID against which this payout is created. Note that it is
   * **NOT** `Order.HumanId`
   */
  OrderId: number;
  /**
   * Reference Id provided by cashfree against the transfer requested.
   * Example: `"10023"`
   */
  ReferenceId: string;
  /**
   * UTR number of the transfer.
   * Example: `"P16111765023806"`
   */
  UTR: string;
  /**
   * Indicates the status of this payment
   */
  Status: "pending" | "settled";
  /**
   * Transfer Amount for the seller in paise.
   * E.g. `500` for Rs. 5.00
   */
  TransferAmount: number;
  /**
   * Order Amount for the given order id in paise. OrderId here is the `Order.HumanId`
   * E.g. `500` for Rs. 5.00
   */
  OrderAmount: number;
};

export type CouponDetails = {
  /**
   * ID of the coupon
   * E.g. `"b9615764-68f6-4647-a960-de2a3d9f8f56"`
   */
  Id: Uuid;
  /**
   * Code of the coupon.
   * E.g. `"ABC123"`
   */
  Code: string;
  /**
   * Discount type of the coupon.
   *
   * `percentage` - Reduce the total payable amount by a certain percentage.
   * `amount` - Reduce the total payable amount by a certain fixed amount.
   * `free_shipping` - Qualifies the order for free shipping
   */
  DiscountType: "percentage" | "amount" | "free_shipping";
  /**
   * Applicable category of the coupon
   *
   * Example: If it is "all-products", we need not do anything
   * Else we have the ID
   */
  Category: string[];
  /**
   * Minimum Order Value to have this coupon applicable.
   * It has to be a number in paise.
   */
  MinimumOrderValue: Money;
  /**
   * Discount value for the coupon. Note that the semantics of this field
   * depend on `Coupon.DiscountType`
   *
   * Example: `0.1` for 10% discount or `1000` for 10 rupee discount.
   */
  DiscountValue: number | null;
  /**
   * Only applicable when `DiscountType` is `percentage`.
   *
   * It is the maximum discount (in paise) that will be given. Any value
   * exceeding this amount will not be discounted.
   *
   * Example: If DiscountValue is `0.1` (10%), cart amount is `20000` (200 rupees)
   * and `MaxDiscount` is `1000` (10 rupees), instead of discounting by 20 rupees,
   * it will discount only upto 10 rupees (`MaxDiscount` value)
   */
  MaxDiscount: Money | null;
  /**
   * Whether coupon is active
   */
  Active: boolean;
  /**
   * Starting date of the coupon.
   * Example: `1589735600` (time in seconds since epoch)
   */
  StartDate: number;
  /**
   * Expiry date of the coupon.
   * Example: `1589735600` (time in seconds since epoch).
   * Always greater than `StartDate`
   */
  EndDate: number;
  /**
   * A boolean flag which tells if the coupon is applicable to
   * a user only once or not.
   */
  OneCouponPerBuyer: boolean;
};

export type OrderStage =
  | "pending" // The order has not yet been seen by the seller
  | "accepted" // The order has been seen by the seller and is pending shipment
  | "shipped" // The order has been shipped but not yet delivered
  | "delivered" // The order has been delivered
  | "pickup_ready" // TODO(rranjan14): What does this mean?
  | "rejected" // The order was cancelled by the seller
  | "cancelled" // The order was cancelled by the buyer
  | "returned" // The order was returned to the seller
  | "ndr" // TODO(rranjan14): What does this mean?
  | "rto"; // TODO(rranjan14): What does this mean?

export type Order = {
  /**
   * Friendly ID of this order. Note that it is **NOT** the same as its document
   * ID. It is shown to the seller and to the buyer.
   *
   * It does not exist on temporary orders
   *
   * Example: `"1001"`
   */
  HumanId: string;
  /**
   * Current status of this order. Only present on temporary orders
   */
  CfStatus?: "ACTIVE" | "EXPIRED";
  /**
   * ID of owner of this order. References document by ID in the `buyers`
   * collection. This value is exactly the same as `Cart.BuyerId`
   *
   * Example: See `Cart.BuyerId`
   */
  BuyerId: Uuid;
  /**
   * UNIX timestamp (seconds) when this order was created. Has nothing to do
   * with `Cart.Created`.
   *
   * Example: `1639735533`
   */
  Created: number;
  /**
   * Items in this cart.
   */
  Items: {
    /**
     * ID of product in the store (ie., `Product.Id`).
     * Should not be confused with `VariantId`.
     *
     * Example: See `Product.Id`
     */
    ProductId: Uuid;
    /**
     * ID of product variant (ie., `ProductVariant.Id`).
     * Should not be confused with `ProductId`.
     *
     * Example: See `ProductVariant.Id`
     */
    VariantId: string;
    /**
     * Variant Details of the product
     */
    Variant: ProductVariant | null;
    /**
     * How many of this item are in cart. Integer
     *
     * Example: `3`
     */
    Quantity: number;
    /**
     * Price of this item (in paise)
     */
    Price: number;
    /**
     * Name of the product
     */
    Name: string;
    /**
     * Image of the Product
     */
    Image: MediaUrl;
    /**
     * Slug of the product. If the product doesn't exist in the store, this will be null then.
     */
    Slug: string | null;
  }[];
  /**
   * Total order amount, including shipping and other charges (in paise)
   *
   * Example: `40000` to represent an order of 400.00 rupees
   */
  Total: Money;
  /**
   * IP of client that performed transaction.
   *
   * Example: `"1.1.1.1"`
   */
  ClientIp: string;
  /**
   * Which payment method was used for the order.
   *
   * - "cod" - The order is Cash-On-Delivery.
   * - "prepaid" - The order was paid by Credit/Debit card. `Order.Prepaid` will
   * be non-null.
   */
  DeliveryMode: "cod" | "prepaid";
  /**
   * Optional coupon code that was applied for this order.
   */
  Coupon: {
    /**
     * Code of coupon. Equivalent to coupon document ID
     *
     * Example: `"20PERCENTOFF"`
     */
    Code: string;
    /**
     * The type of discount applied
     */
    DiscountType: CouponDetails["DiscountType"];
    /**
     * The amount that was discounted, in paise.
     * Note that, regardless of `DiscountType`,
     * this value will always be a monetary value.
     *
     * Example: If coupon discount type is "free_shipping",
     * This value will be the value of seller's shipping at the
     * time of order
     */
    DiscountedAmount: Money;
  } | null;
  /**
   * Cashfree details when creating an order. See https://docs.cashfree.com/docs/create-order
   * It is null when `DeliveryMode` is not "prepaid"
   */
  Prepaid: {
    /**
     * Cashfree order ID
     */
    CfOrderId: number;
    /**
     * Cashfree order token
     */
    CfOrderToken: string;
  } | null;
  /**
   * Buyer details at the time of order
   */
  BuyerDetails: {
    /** `Buyer["FullName"]` */
    FullName: string;
    /** `Buyer["Email"]` */
    Email: string;
    /** `Buyer["PhoneNumber"]` */
    PhoneNumber: string;
    /** `Buyer["Address"]` */
    Address: Address;
  };
  /**
   * Shipping charge levied on the order, in paise.
   *
   * Example: See `Money`
   */
  ShippingCharge: Money;
  /**
   * Payment charge. This is `CardCharge` or `CodCharge` in `Seller.Shipping`
   * depending on `DeliveryMode`.
   *
   * Example: See `Money`
   */
  PaymentCharge: Money;
  /**
   * Status of this order. Only present on non-temporary orders
   */
  Status:
    | "Pending" // The order has not yet been seen by the seller
    | "Accepted" // The order has been seen by the seller and is pending shipment
    | "Shipped" // The order has been shipped but not yet delivered
    | "Delivered" // The order has been delivered
    | "Pickup Ready" // This means that the order has been packed by the seller and the buyer is ready and waiting for pickup
    | "Rejected" // The order was cancelled by the seller
    | "Cancelled" // The order was cancelled by the buyer
    | "Returned" // The order was returned to the seller
    | "NDR" // If the buyer doesn't accept the order, this is marked as "ndr". (Non-Delivery Report)
    | "RTO" //The order was returned to origin.
    | undefined;
  /**
   * Tracking Url for this order. Only present if the order has been shipped
   * and a tracking url is provided by the seller.
   */
  TrackingUrl?: string;
};
/**
 * Customer form fields
 */
export enum SurveyFields {
  name = "name",
  email = "email",
  phone = "phone",
}

export type CategoryCollectionListResponse = {
  /**
   * ID of the catergory collection.
   */
  Id: Uuid;
  /**
   * Seller-provided name of this category.
   *
   * Example: `"Winter Collections"`
   */
  Name: string;
  /**
   * Slug for the category. Derived from `Name` on creation and
   * and will change whenever `Name` get updated.
   *
   * Example: `"winter-collections"`
   */
  Slug: string;
  /**
   * Historical Slugs of the category collection.
   */
  HistoricalSlugs: string[];
};

export type CategoryCollectionDetailsResponse = CategoryCollectionListResponse & {
  /**
   * Ids of child categories
   */
  CategoryIDs: string[];
  /**
   * Historical Slugs of the category.
   */
  HistoricalSlugs: string[];
};

/**
 * A single category
 */
export type CategoryDb = {
  /**
   * Seller-provided name of this category.
   *
   * Example: `"Winter Collections"`
   */
  Name: string;
  /**
   * Current slug for the category. Derived from `Name` on creation and
   * can change based on updates to `Name`.
   *
   * Example: `"winter-collections"`
   */
  Slug: string;
  /**
   * Historical slugs of this category. When the category's `Slug` field changes,
   * we append the old slug here. Use this to redirect the old slug to the new
   * one.
   *
   * Note that the current `Slug` is also part of `HistoricalSlugs`. Also note
   * that all elements are deduplicated. Element order is non-deterministic and
   * should not be relied on to decide chronological order.
   *
   * Services must remove relevant historical slugs if they are used by a
   * different category.
   *
   * Example: [`"young-winters"`, `"winter-kids-collection"`]
   */
  HistoricalSlugs: string[];
  /**
   * Announcement bar to show on the seller's category page
   *
   * Example: See `SellerAnnouncement`
   */
  Announcement: SellerAnnouncement | null;
  /**
   * Banners to show on categoryfront.
   *
   * Example: See `SellerBanner`
   */
  Banners: SellerBanner[];
  /**
   * Number of products with this category. Not guaranteed to be up to date.
   * For critical situations, callers should calculate the exact number of
   * products manually.
   *
   * It is solely used to ignore categories with no products on storefront
   */
  NProducts: number;
  /**
   * A boolean check which tells whether to show the category on homepage or not.
   */
  IsVisibleOnHome: boolean;
  /**
   * A list of parent category IDs
   *
   * If this is an empty array or an array of categories then that category is a child
   *
   * If this is null, then that is a parent category
   */
  Parents: string[] | null;
};

export type CategoryListResponse = {
  /**
   * ID of the catergory.
   */
  Id: Uuid;
  /**
   * Banners for this category.
   * It is an array of `SellerBanner`.
   *
   * Example: See `SellerBanner`
   */
  Banners: SellerBanner[];
  /**
   * Seller-provided name of this category.
   *
   * Example: `"Winter Collections"`
   */
  Name: string;
  /**
   * Slug for the category. Derived from `Name` on creation and
   * and will change whenever `Name` get updated.
   *
   * Example: `"winter-collections"`
   */
  Slug: string;
  /**
   * Total number of products in this category.
   */
  NProducts: number;
  /**
   * A boolean check which tells whether to show the category on homepage or not.
   */
  IsVisibleOnHome: boolean;
  /**
   * A list of parent category IDs
   *
   * If this is an empty array or an array of categories then that category is a child
   *
   * If this is null, then that is a parent category
   */
  Parents: string[] | null;
};

export type CreateCategoryRequest = {
  /**
   * Banners for this category.
   * It is an array of `SellerBanner`.
   *
   * Example: See `SellerBanner`
   */
  Banners: SellerBanner[];
  /**
   * Seller-provided name of this category.
   *
   * Example: `"Winter Collections"`
   */
  Name: string;
  /**
   * Slug for the category. Derived from `Name` on creation and
   * and will change whenever `Name` get updated.
   *
   * Example: `"winter-collections"`
   */
  Slug: string;
  /**
   * Total number of products in this category.
   */
  NProducts: number;
  /**
   * Historical slugs for the category
   */
  HistoricalSlugs: string[];
  /**
   * Announcement for the category
   */
  Announcement: SellerAnnouncement | null;
  /**
   * A boolean check which tells whether to show the category on homepage or not.
   */
  IsVisibleOnHome: boolean;
  /**
   * A list of parent category IDs
   *
   * If this is an empty array or an array of categories then that category is a child
   *
   * If this is null, then that is a parent category
   */
  Parents: string[] | null;
};

export type CategoryDetailsResponse = CategoryListResponse & {
  /**
   * Array containing minimum details about each product in this category.
   */
  ProductIDs: string[];

  /**
   * Historical slugs for the category
   */
  HistoricalSlugs: string[];

  Products: {
    /**
     * ID of the product.
     * Equivalent to `Product.Id`
     */
    ProductId: Uuid;
    /**
     * Name of the product.
     */
    Name: string;
    /**
     * Slug to identify the product.
     */
    Slug: string;
    /**
     * An image of the product.
     */
    Media: MediaUrl | null;
  }[];
};

export type TypeaheadResponse = {
  Suggestions: {
    /** Product slug */
    /** Product ID */
    ID: string;
    /** Product slug */
    Slug: string;
    /** Product name */
    Name: string;
    /** How many are in stock? */
    Stock: number;
    /** Product price (currently rupees) */
    Price: number;
    /**
     * Comparison product price (currently rupees)
     * It is always greater than `Price`
     * If it is present, a discount badge will be shown on ProductCard and
     * CartGridItem
     */
    CmpPrice: number | null;
    /** Images of product */
    Images: ImageRes[];
    /** flag for product to check if it's digital */
    DigitalProduct?: boolean;

    RepresentativeVariantId: string;
    /** Basic list of variants */
    Variants: {
      /** Variant ID */
      ID: string;
      /** Product price (currently rupees) */
      Price: number;
      /**
       * Comparison product price (currently rupees)
       * It is always greater than `Price`
       * If it is present, a discount badge will be shown on ProductCard and
       * CartGridItem
       */
      CmpPrice: number | null;
      /** How many are in stock? */
      Stock: number;
      /** Variations of this product */
      Variations: ProductVariation[];
    }[];
    /** Custom requirements if there are any */
    CustomRequirements: string | null;
  }[];
};

/**
 * Someone who buys things from a `Seller`. It can also be anonymous
 */
export type Buyer = {
  /**
   * Whether the buyer is anonymous. Since all carts (signed in or not) require
   * a buyer to be attached to them, anonymous buyers fulfill this requirement.
   *
   * If true, only the `Buyer.Id` field will be present.
   */
  IsAnonymous: boolean;
  /**
   * Free-form buyer provided name.
   *
   * Example: `"daRsh m.n. arith"`
   */
  FullName: string | null;
  /**
   * Address information of buyer.
   *
   * Example: See `Address`
   */
  Address: Address | null;
  /**
   * Email address of buyer. Case sensitive
   *
   * Example: `123.Sarvesh@yahoo.co.in`
   */
  Email: string | null;
  /**
   * Phone number of buyer. E.164 format
   *
   * Example: `"+918142513301"`
   */
  PhoneNumber: string | null;
  /**
   * Tokens to send push notifications to the buyer.
   *
   * Example: See `PushToken`
   */
  Push?: FirestoreCollection<PushToken[]>;
};

/**
 * Someone who buys things from a `Seller`. It can also be anonymous
 */

export type Visitor = {
  /**
   * Name of the buyer(potential).
   */
  Name: string | null;
  /**
   * Email of the buyer(potential).
   */
  Email: string | null;
  /**
   * Phonenumber of the buyer(potential).
   */
  Phonenumber: string | null;
};

/**
 * Used to represent storefront analytics data, i.e, Orders, Revenue and Store Views etc
 */

export type AnalyticsData = {
  Orders: number;
  /** Average order value in rupees */
  Aov: number;
  /** Conversion rate in percent. Eg. 2 means 2% */
  ConvRate: number;
  TopSearches: {
    Query: string;
    Count: number;
  }[];
  BestSellers: {
    Id: string;
    Name: string;
    Image: MediaUrl;
    /** Total quantity sold in period */
    Count: number;
    Slug: string;
  }[];
  Revenue: number;
  Views: number;
  /** Sales pop up conversion rate in percent. Eg. 2 means 2% */
  SalesPopupConvRate: number;
  /** Abandoned cart notifications sent vs actual orders placed conversion rate in percent. Eg. 4 means 4% */
  AbandonedCartConvRate: number;
};

export type AbandonedCartList = Omit<OrderList, "Status" | "DeliveryMode" | "OrderId"> & {
  Id: Uuid;
  BuyerPhone: string | null;
  BuyerEmail: string | null;
  Media: MediaUrl | null;
};

/**
 * Details for the abandoned cart. It contains buyer details, product details such as name, images
 * and the price of the respective product variant.
 */
export type AbandonedCartDetails = {
  /**
   * Buyer details at the time of cart creation
   */
  BuyerDetails: {
    /** `Buyer["FullName"]` */
    FullName: string;
    /** `Buyer["Email"]` */
    Email: string;
    /** `Buyer["PhoneNumber"]` */
    PhoneNumber: string;
    /** `Buyer["Address"]` */
    Address: Address;
  };
  /**
   * Details of the cart items.
   */
  Items: {
    /**
     * Name of the product
     */
    ProductName: string;
    /**
     * Sku of the product
     */
    ProductId: Uuid;
    /**
     * Price of the product variant in rupees.
     */
    Price: number;
    /**
     * Quantity of the product item in the cart
     */
    Quantity: number;
    /**
     * An image of the product
     */
    Media: MediaUrl;
  }[];
  /**
   * Time in seconds.
   */
  Created: number;
  /**
   * Sum of all the quantities in the cart.
   */
  TotalItems: number;
  /**
   * Total value of the cart in rupees.
   */
  Total: number;
};

export type ParsedProductRecordFields = {
  "Product Name": string;
  "Unique Identifier": string;
  Description: string;
  Price: string;
  "StrikeOff Price": string;
  "Product Images": string;
  Stock: string;
  Size: string;
  Color: string;
};

export type ParsedUniqueProductFields = {
  Sku: string;
  ProductName: string;
  Description: string;
  Colors: string[];
  Stock: number;
  Sizes: string[];
  ProductImages: ImageRes[];
  ProductImagesToUpload: string[];
  Pricing: {
    variantPrice: number;
    variantSize: string | null;
    variantStrikeOffPrice: number;
  }[];
  errors: string[];
};

export type Pagination = {
  next: string | null;
  prev: string | null;
  goBack: boolean;
};

export type ProductListApiResponse = {
  products: ProductListResponse[];
} & Omit<Pagination, "goBack">;

export type AbandonedCartListApiResponse = {
  carts: AbandonedCartList[];
  canFetchMore: boolean;
} & Omit<Pagination, "goBack">;

export type OrderListApiResponse = {
  orders: OrderList[];
} & Omit<Pagination, "goBack">;

export type PaymentListApiResponse = {
  payments: Payment[];
} & Omit<Pagination, "goBack">;

export type InventoryConfigLimits = {
  /**
   * Total number of products that the seller has
   */
  ProductCount: number;
  /**
   * Total number of images that can be added in a product
   */
  ImageCount: number;
  /**
   * Maximum number of products that the seller can have
   */
  MaxProductCount: number;
};

export enum PlanTypes {
  BASIC = "__BASIC__",
  PREMIUM = "__PREMIUM__",
}
