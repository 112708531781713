import React, { useContext, useState, useEffect } from "react";
import { FiLoader } from "react-icons/fi";
import { AuthContext } from "../../../lib/services/auth";
import { SellerDetailsResponse, MediaUrl, SellerBanner } from "../../../lib/types";
import { uploadImage } from "../../../lib/utils";
import ImageTile from "./ImageTile";
import { NotificationContext } from "../../../lib/services/notification";

const StorePictures = () => {
  const { sellerDetails, updateSellerDetails, refreshAndReturnFirebaseToken, user } =
    useContext(AuthContext);
  const { addNotification } = useContext(NotificationContext);

  const [banners, setBanners] = useState<SellerBanner[]>(sellerDetails?.Banners ?? []);

  useEffect(() => {
    if (sellerDetails?.Banners) {
      setBanners(sellerDetails.Banners);
    }
  }, [sellerDetails]);

  if (sellerDetails === null) {
    return <FiLoader className="animate-spin"></FiLoader>;
  }

  /** Replace a banner image with another */
  const handleBannerReplace = async (idx: number, files: FileList) => {
    const img = files[0];
    if (!img || !user) {
      return;
    }
    const imageRes = await uploadImage(img, user.uid, true);
    if (imageRes !== null && sellerDetails) {
      const updatedSellerDetails: Partial<SellerDetailsResponse> = {
        Banners: sellerDetails!.Banners
          ? sellerDetails!.Banners.map((banner, i) =>
              i === idx
                ? {
                    Media: {
                      MediaUrl1: imageRes.MediaUrl1,
                      MediaUrl2: imageRes.MediaUrl2,
                      ObjectFit: "cover",
                    },
                    Caption: null,
                    Cta: null,
                  }
                : banner
            )
          : [],
      };
      const refreshedToken = await refreshAndReturnFirebaseToken();
      if (refreshedToken !== null) {
        await updateSellerDetails(refreshedToken, updatedSellerDetails);
        addNotification("success", "Successfully replaced the store banner!", "StorePictures");
      }
    } else {
      alert("Image upload failed");
    }
  };

  /** Upload a new banner image */
  const handleLogoUpload = async (files: FileList) => {
    const img = files[0];
    if (img != null && user != null) {
      const imageRes = await uploadImage(img, user?.uid, true);
      if (!imageRes) return;
      const logo: MediaUrl = {
        MediaUrl1: imageRes?.MediaUrl1,
        MediaUrl2: imageRes?.MediaUrl2,
      };

      const updatedSellerDetails: Partial<SellerDetailsResponse> = {
        Logo: logo,
      };
      const refreshedToken = await refreshAndReturnFirebaseToken();
      if (refreshedToken !== null) {
        await updateSellerDetails(refreshedToken, updatedSellerDetails);
        addNotification("success", "Successfully updated the store logo!", "StorePictures");
      }
    }
  };
  const handleRemoveLogo = async () => {
    const updatedSellerDetails: Partial<SellerDetailsResponse> = {
      Logo: null,
    };
    const refreshedToken = await refreshAndReturnFirebaseToken();
    if (refreshedToken !== null) {
      await updateSellerDetails(refreshedToken, updatedSellerDetails);
      addNotification("success", "Successfully removed the store logo!", "StorePictures");
    }
  };
  async function handleBannersUpload(files: FileList) {
    // TODO(rranjan14): Handle image upload. This code won't work

    if (!files || !user) {
      return;
    }

    const imageUploadResponses = await Promise.all(
      Array.from(files)
        .filter((file: Blob) => file != null)
        .map((img) => uploadImage(img, user.uid, true))
    );

    if (imageUploadResponses !== null && sellerDetails) {
      const newBanners: SellerBanner[] = [];
      imageUploadResponses.forEach((imageRes) => {
        if (!imageRes) return null;
        const banner: SellerBanner = {
          Media: {
            MediaUrl1: imageRes.MediaUrl1,
            MediaUrl2: imageRes.MediaUrl2,
            ObjectFit: "cover",
          },
          Caption: null,
          Cta: null,
        };
        newBanners.push(banner);
      });

      const updatedSellerDetails: Partial<SellerDetailsResponse> = {
        Banners: [...sellerDetails!.Banners, ...newBanners],
      };
      setBanners([...sellerDetails!.Banners, ...newBanners]);
      const refreshedToken = await refreshAndReturnFirebaseToken();
      if (refreshedToken !== null) {
        await updateSellerDetails(refreshedToken, updatedSellerDetails);
        addNotification(
          "success",
          `Successfully added ${newBanners.length} banner${newBanners.length > 1 ? "s" : ""}!`,
          "StorePictures"
        );
      }
    } else {
      alert("Image upload failed");
    }
  }

  const handleRemoveBanner = async (index: number) => {
    const updatedBannersList = sellerDetails.Banners.slice();
    updatedBannersList.splice(index, 1);
    const updatedSellerDetails: Partial<SellerDetailsResponse> = {
      Banners: updatedBannersList,
    };
    setBanners(updatedBannersList);
    const refreshedToken = await refreshAndReturnFirebaseToken();
    if (refreshedToken !== null) {
      await updateSellerDetails(refreshedToken, updatedSellerDetails);
      addNotification("success", "Successfully removed the store banner!", "StorePictures");
    }
  };
  return (
    <>
      <h3 className="text-3xl py-2 font-light leading-8 text-gray-900 sm:text-4xl sm:truncate">
        Store Logo
      </h3>
      <div className="grid grid-cols-2 gap-4 p-4 lg:grid-cols-3">
        <ImageTile
          uniqueId={"logo"}
          allowMultiple={false}
          isLogo={true}
          initialImageDataUri={sellerDetails.Logo ? sellerDetails.Logo.MediaUrl1 : null}
          onUpload={(img) => {
            handleLogoUpload(img);
          }}
          onDelete={() => {
            handleRemoveLogo();
          }}
        />
      </div>
      <h3 className="text-3xl py-2 font-light leading-8 text-gray-900 sm:text-4xl sm:truncate">
        Store Banners
      </h3>
      <div className="grid grid-cols-2 gap-4 p-4 lg:grid-cols-3">
        {banners.map((b, i) => {
          return (
            <ImageTile
              key={i}
              uniqueId={`banner-${i}`}
              allowMultiple={false}
              initialImageDataUri={b.Media.MediaUrl1}
              onUpload={(img) => {
                handleBannerReplace(i, img);
              }}
              onDelete={() => {
                handleRemoveBanner(i);
              }}
            />
          );
        })}

        {/* This tile lets the user upload a new image even if there are no banner images */}
        <ImageTile
          uniqueId={"new-banner"}
          initialImageDataUri={null}
          allowMultiple={true}
          onUpload={handleBannersUpload}
        />
      </div>
    </>
  );
};

export default StorePictures;
