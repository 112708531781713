import React, { useContext } from "react";
// import { FiEdit, FiTrash2 } from "react-icons/fi";
import { Visitor } from "../../../../../../lib/types";
import { AuthContext } from "../../../../../../lib/services/auth";
import { ImMail, ImWhatsapp } from "react-icons/im";

type Props = {
  visitors: Visitor[];
};

const VisitorsTable = React.memo(function VisitorsTable(props: Props) {
  const { sellerDetails } = useContext(AuthContext);

  const sendWhatsAppMsg = (name: string, number: string) => {
    const msg = `Hey ${name},

You have visited our store at ${sellerDetails?.BusinessName}!

Would you like to purchase something? Please click here: https://instasell.io/${sellerDetails?.Slug}`;
    const url = "whatsapp://send?text=" + msg + "&phone=" + number;
    window.open(url);
  };

  const sendMail = (name: string, email: string) => {
    const body = `Hey ${name},

You have visited our store at ${sellerDetails?.BusinessName}!

Would you like to purchase something? Please click here: https://instasell.io/${sellerDetails?.Slug}`;

    const url = "mailto://" + email + "?body=" + body + "&subject=" + sellerDetails?.BusinessName;
    window.open(url);
  };
  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-100">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Mobile
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Email
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {props.visitors.map((visitor, i) => (
                  <tr key={i} className="hover:bg-gray-50">
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {visitor.Name}
                    </td>
                    <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500">
                      {visitor.Phonenumber}
                    </td>
                    <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500">
                      {visitor.Email}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <div className="flex flex-row justify-center items-center">
                        {visitor.Phonenumber ? (
                          <ImWhatsapp
                            className="text-green-500 mr-1 text-lg aspect-square hover:scale-125"
                            onClick={() => {
                              sendWhatsAppMsg(visitor.Name!, visitor.Phonenumber!);
                            }}
                          />
                        ) : null}
                        {visitor.Email ? (
                          <ImMail
                            className="ml-1 text-lg aspect-square hover:scale-125 "
                            onClick={() => sendMail(visitor.Name!, visitor.Email!)}
                          />
                        ) : null}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
});

export default VisitorsTable;
