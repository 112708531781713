import React, { useContext, useState } from "react";
import { SubmitHandler } from "react-hook-form";
import { FiLoader } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

import { ApiContext } from "../../../../lib/services/api";
import { AuthContext } from "../../../../lib/services/auth";
import { NotificationContext } from "../../../../lib/services/notification";
import type { CouponDetails } from "../../../../lib/types";
import AddCouponForm, { CouponDetailsFields } from "./components/AddCouponForm";

const AddCoupon = () => {
  const navigate = useNavigate();
  const { sellerDetails, refreshAndReturnFirebaseToken } = useContext(AuthContext);
  const { createCoupon } = useContext(ApiContext);
  const { addNotification, removeNotificationByTag } = useContext(NotificationContext);

  const [couponDetails, setCouponDetails] = useState<CouponDetailsFields>({
    name: "",
    discountType: "amount",
    category: [],
    minimumOrderValue: 0,
    discountValue: null,
    maxDiscountValue: null,
    startDate: new Date(),
    endDate: new Date(new Date().getTime() + 86400 * 1000),
    oldCouponCode: "",
    oneCouponPerBuyer: false,
  });

  if (sellerDetails === null) {
    return <FiLoader className="animate-spin"></FiLoader>;
  }

  const onSubmit: SubmitHandler<CouponDetailsFields> = async (data) => {
    setCouponDetails(data);
    const coupon: Omit<CouponDetails, "Id"> = {
      Code: data.name,
      Active: true,
      DiscountType:
        data.discountType === "percentage"
          ? "percentage"
          : data.discountType === "amount"
          ? "amount"
          : "free_shipping",
      Category: data.category,
      DiscountValue: data.discountValue
        ? data.discountType === "percentage"
          ? data.discountValue / 100
          : data.discountValue * 100
        : null,
      EndDate: data.endDate.getTime() / 1000,
      MaxDiscount: data.maxDiscountValue ? data.maxDiscountValue * 100 : null,
      MinimumOrderValue: data.minimumOrderValue * 100,
      StartDate: data.startDate.getTime() / 1000,
      OneCouponPerBuyer: data.oneCouponPerBuyer,
    };

    removeNotificationByTag("Coupon");
    const refreshedToken = await refreshAndReturnFirebaseToken();
    if (refreshedToken) {
      const createdCoupon = await createCoupon(refreshedToken, coupon);

      addNotification("success", "Successfully added a new coupon!", "Coupon");
      if (createdCoupon.Data) {
        navigate(-1);
      }
    }
  };
  return (
    <div className="my-4 sm:px-6 lg:px-8">
      <div className="flex flex-col justify-between align-center content-center">
        <h2 className="text-3xl py-2 font-light leading-8 text-gray-900">Add Coupon</h2>
        <div>
          <AddCouponForm initialValue={couponDetails} next={onSubmit} key="add-coupon-form" />
        </div>
      </div>
    </div>
  );
};

export default AddCoupon;
