import React from "react";
import FlexibleImage from "../../../../../../components/FlexibleImage";
// import FlexibleImage from "../../../../../components/FlexibleImage";
import { ProductListResponse } from "../../../../../../lib/types";
// import { mediaUrl2ImageRes } from "../../../../../lib/utils";

type Props = {
  products: ProductListResponse[];
  selectedProducts: string[];
  setSelectedProducts: (selectedProducts: string[]) => void;
};

const ProductList = React.memo(function ProductGrid(props: Props) {
  return (
    <div className="flex flex-col w-full">
      <div className="-my-2 overflow-x-auto">
        <div className="py-2 align-middle inline-block w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col"></th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Product Name
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Price
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Stock
                  </th>
                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {props.products.map((product: ProductListResponse, index: number) => {
                  const isInCategory = props.selectedProducts.indexOf(product.Sku) !== -1;
                  const toggleSelectProduct = (e: React.ChangeEvent<HTMLInputElement>) => {
                    if (e.target.checked) {
                      if (props.selectedProducts.indexOf(product.Sku) === -1) {
                        props.setSelectedProducts([...props.selectedProducts, product.Sku]);
                      }
                    } else {
                      if (props.selectedProducts.indexOf(product.Sku) !== -1) {
                        props.setSelectedProducts(
                          props.selectedProducts.filter((sku) => sku !== product.Sku)
                        );
                      }
                    }
                  };
                  return (
                    <tr key={index}>
                      <td className="whitespace-nowrap text-right text-sm font-medium">
                        <label
                          htmlFor={"x-checkbox-" + product.Sku}
                          className="text-gray-700 px-6 py-4 w-full flex items-center"
                        >
                          <input
                            id={"x-checkbox-" + product.Sku}
                            type="checkbox"
                            checked={isInCategory}
                            onChange={toggleSelectProduct}
                            className="focus:ring-indigo-500 h-4 w-4 text-primary border-gray-300 rounded"
                          />
                        </label>
                      </td>
                      <td className="whitespace-nowrap">
                        <label
                          htmlFor={"x-checkbox-" + product.Sku}
                          className="text-gray-700 px-6 py-4 w-full flex items-center"
                        >
                          <div className="flex items-center">
                            <div className="flex-shrink-0 h-10 w-10">
                              <FlexibleImage
                                className="h-10 w-10 aspect-square"
                                alt={product.Name}
                                img={product.Image}
                                key={index}
                                loading="lazy"
                              />
                            </div>
                            <div className="ml-4">
                              <div className="text-sm font-medium text-gray-900">
                                {product.Name}
                              </div>
                            </div>
                          </div>
                        </label>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">{product.Price}</div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">{`${product.TotalStocks} in ${
                          product.VariantsCount
                        } ${product.VariantsCount! > 1 ? "variants" : "variant"}`}</div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
});

export default ProductList;
