import React, { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CreateCategoryRequest } from "../../../../../../lib/types";
import { resizedUrl, slugify } from "../../../../../../lib/utils";

type ModalProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  createCategory: (newCategory: CreateCategoryRequest) => Promise<void>;
};

const AddCategoryModal = (props: ModalProps) => {
  const [isCreatingCategory, setIsCreatingCategory] = useState<boolean>(false);
  const [category, setCategory] = useState<CreateCategoryRequest>({
    Name: "",
    NProducts: 0,
    Slug: "",
    Banners: [],
    HistoricalSlugs: [],
    Announcement: null,
    IsVisibleOnHome: true,
    Parents: [],
  });

  const { isOpen, setIsOpen, createCategory } = props;
  const closeModal = () => {
    setIsOpen(false);
  };
  const handleSave = async () => {
    const newCategory = Object.assign({}, category);

    if (newCategory.Name.length < 3) {
      alert("Please enter a category name that is greater than 3 characters");
      return;
    }
    newCategory.Slug = slugify(newCategory.Name.trim());
    newCategory.HistoricalSlugs = [newCategory.Slug];
    newCategory.Banners = newCategory.Banners.map((banner) => {
      if (!banner.Media.MediaUrl1.includes("_800x800.webp")) {
        banner.Media.MediaUrl1 = resizedUrl(banner.Media.MediaUrl1, ".webp");
      }
      if (!banner.Media.MediaUrl1.includes("_800x800.jpg")) {
        banner.Media.MediaUrl1 = resizedUrl(banner.Media.MediaUrl1, ".jpg");
      }

      return banner;
    });
    setIsCreatingCategory(true);
    await createCategory(newCategory);
    setIsCreatingCategory(false);
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                  New Category
                </Dialog.Title>
                <div className="mt-4">
                  <p className="text-sm text-gray-500">Category Name</p>
                  <label className="block">
                    <input
                      type="text"
                      value={category.Name}
                      onChange={(e) => setCategory({ ...category, Name: e.target.value })}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                      placeholder="Category Name"
                    />
                  </label>
                </div>

                <div className="w-full mt-4 flex items-center justify-between">
                  <button
                    type="button"
                    className="w-4/5 inline-flex justify-center px-4 py-2 text-sm font-medium text-red-500 bg-red-100 border border-transparent rounded-md hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                    onClick={closeModal}
                  >
                    Back
                  </button>{" "}
                  <button
                    type="button"
                    className="w-4/5 ml-4 inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    onClick={handleSave}
                  >
                    {isCreatingCategory ? "Saving" : "Save"}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default AddCategoryModal;
