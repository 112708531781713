import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

let firebaseConfig = {};
if (process.env.NODE_ENV === "production") {
  firebaseConfig = {
    apiKey: "AIzaSyAZ2uAUv5g92yptlOk3BSOsLG0WMgCukfU",
    authDomain: "instasell-7f53f.firebaseapp.com",
    databaseURL: "https://instasell-7f53f.firebaseio.com",
    projectId: "instasell-7f53f",
    storageBucket: "instasell-7f53f.appspot.com",
    messagingSenderId: "797002796562",
    appId: "1:797002796562:web:b9ed4e01073d6de9a312cb",
    measurementId: "G-MXSRFM6XYH",
  };
} else {
  firebaseConfig = {
    apiKey: "AIzaSyBRssg7ixZvcmChSmsQiPVO0e9xTIImPV0",
    authDomain: "instasell-prod.firebaseapp.com",
    databaseURL: "https://instasell-prod-default-rtdb.firebaseio.com",
    projectId: "instasell-prod",
    storageBucket: "instasell-prod.appspot.com",
    messagingSenderId: "141840535190",
    appId: "1:141840535190:web:9a5cb885f5fcb1c2e2fcb7",
    measurementId: "G-2GVYT3TVGH",
  };
}

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const storage = getStorage(app);

export { auth, storage };
