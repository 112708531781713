import React, { useEffect, useContext, useState } from "react";
// import { useNavigate } from "react-router-dom";

import { validateSlug } from "../../lib/utils";
// import AnnouncementBar from "./components/AnnouncementBar";
// import Footer from "../../components/Footer";
// import RevenueAnalytics from "./components/RevenueAnalytics";
// import VisitorAnalytics from "./components/VisitorsAnalytics";
// import CitiesAnalytics from "./components/CitiesAnalytics";
// import ProductAnalytics from "./components/ProductAnalytics";
import { AuthContext } from "../../lib/services/auth";
import StoreUrlModal from "./components/StoreUrlModal";
import { ApiContext } from "../../lib/services/api";
import { FiLoader } from "react-icons/fi";
import StorefrontStats from "./components/StorefrontStats";
import { AnalyticsData } from "../../lib/types";
import Loading from "../../pages/Loading";

const choices: {
  label: string;
  value: "today" | "week" | "month" | "yesterday" | "lifetime";
}[] = [
  { label: "Today", value: "today" },
  { label: "Yesterday", value: "yesterday" },
  { label: "Last 7 days", value: "week" },
  { label: "Last 30 days", value: "month" },
  { label: "Lifetime", value: "lifetime" },
];

function Home() {
  // const navigate = useNavigate();

  const {
    refreshAndReturnFirebaseToken,
    fetchSellerDetails,
    sellerDetails,
    updateSellerDetails,
    localeDetails,
  } = useContext(AuthContext);
  const { getAnalytics2 } = useContext(ApiContext);

  const [storeUrl, setStoreUrl] = useState<string>(sellerDetails?.Slug ?? "");
  const [analytics, setAnalytics] = useState<AnalyticsData | null>(null);
  const [error, setError] = useState<boolean>(false);
  const [selectedChoiceIndex, setSelectedChoiceIndex] = useState<number>(0);
  const [loading, setLoading] = useState(true);
  const startTime = new Date();
  startTime.setHours(0, 0, 0, 0);
  const [startTs, setStartTs] = useState(Number(startTime) / 1000);
  const [endTs, setEndTs] = useState(Number(new Date()) / 1000);

  const [open, setOpen] = useState(false);

  const hour = new Date().getHours();
  const greeting = (hour < 12 && "Morning") || (hour < 18 && "Afternoon") || "Evening";

  useEffect(() => {
    const getDetails = async () => {
      const refreshedToken = await refreshAndReturnFirebaseToken();
      if (refreshedToken !== null) {
        await fetchSellerDetails(refreshedToken);
      }
    };
    getDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getDetails = async () => {
      const refreshedToken = await refreshAndReturnFirebaseToken();
      setLoading(true);
      if (refreshedToken !== null) {
        const res = await getAnalytics2(refreshedToken, startTs, endTs);
        if (res.Data) {
          setAnalytics(res.Data);
        }
      }
      setLoading(false);
    };
    getDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedChoiceIndex]);

  if (sellerDetails === null) {
    return <FiLoader className="animate-spin"></FiLoader>;
  }

  const onSubmit = async () => {
    if (storeUrl.length < 3 || storeUrl.length > 50) {
      setError(true);
      return;
    }
    if (!validateSlug.test(storeUrl)) {
      setError(true);
      return;
    }
    const updatedSlug = {
      Slug: storeUrl,
    };

    //TODO(rranjan14): Add check for store slug

    const refreshedToken = await refreshAndReturnFirebaseToken();
    if (refreshedToken !== null) {
      await updateSellerDetails(refreshedToken, updatedSlug);
    }
    setOpen(false);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="my-4 sm:px-6 lg:px-8">
      <div className="flex flex-col">
        <h2 className="text-3xl font-medium leading-7 text-black my-4">
          Good {greeting}, {sellerDetails.SellerName}
        </h2>
        {/* <AnnouncementBar /> */}
        <div className="md:flex md:items-center md:justify-between my-4">
          <div className="flex-1 min-w-0">
            {/* <h6 className="text-base font-bold leading-7 text-gray-900">Overview</h6> */}
          </div>
          {/* <div className="mt-4 flex md:mt-0 md:ml-4">
            <button
              type="button"
              className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-opacity-95"
              onClick={() => navigate("/account/personal-details")}
            >
              Calendar
            </button>
          </div> */}
        </div>
        <StorefrontStats
          selectedChoiceIndex={selectedChoiceIndex}
          setSelectedChoiceIndex={setSelectedChoiceIndex}
          choices={choices}
          analytics={analytics}
          setStartTs={setStartTs}
          setEndTs={setEndTs}
          open={open}
          setOpen={setOpen}
          slug={sellerDetails.Slug}
          currencySymbol={localeDetails.currencySymbol}
        />
      </div>
      {/* <Footer /> */}
      <StoreUrlModal
        initialValue={storeUrl}
        next={onSubmit}
        key="store-url-modal"
        open={open}
        setOpen={setOpen}
        error={error}
        setStoreUrl={setStoreUrl}
      />
    </div>
  );
}

export default Home;
