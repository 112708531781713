import React, { useContext, useEffect } from "react";
import { Disclosure } from "@headlessui/react";
import { SearchIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom";

import { AuthContext } from "../../lib/services/auth";
import FlexibleImage from "../FlexibleImage";
import { mediaUrl2ImageRes } from "../../lib/utils";

export default function Example() {
  const { sellerDetails } = useContext(AuthContext);
  useEffect(() => {}, [sellerDetails]);

  return (
    sellerDetails && (
      <Disclosure as="nav" className="bg-white shadow">
        {() => (
          <>
            <div className="w-full px-2">
              <div className="flex justify-between w-full h-14 px-1">
                <div className="flex-1 flex items-center justify-start pr-2 lg:ml-6">
                  <div className="max-w-lg w-full lg:max-w-xs">
                    <label htmlFor="search" className="sr-only">
                      Search
                    </label>
                    <div className="relative">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                      </div>
                      <input
                        id="search"
                        name="search"
                        className="block w-full pl-10 pr-3 py-2 border border-transparent rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        placeholder="Search"
                        type="search"
                      />
                    </div>
                  </div>
                </div>
                <div className="flex px-2">
                  <Link to="/" className="flex-shrink-0 flex items-center">
                    {sellerDetails?.Logo && (
                      <FlexibleImage
                        img={mediaUrl2ImageRes(sellerDetails.Logo)}
                        alt="Logo"
                        className="h-7 aspect-square m-2 rounded-full"
                      />
                    )}
                    <h6 className="text-sm font-medium">{sellerDetails?.SellerName}</h6>
                  </Link>
                </div>
              </div>
            </div>
          </>
        )}
      </Disclosure>
    )
  );
}
