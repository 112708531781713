import React, { useState, useContext } from "react";
import { SubmitHandler } from "react-hook-form";
import { FiLoader } from "react-icons/fi";
import { AuthContext } from "../../../lib/services/auth";
import ShippingSettings, { ShippingSettingsFields } from "../components/ShippingSettings";
import ShippingPartner, { ShippingPartnerFields } from "../components/ShippingPartner";

const Shipping = () => {
  const { sellerDetails, refreshAndReturnFirebaseToken, updateSellerDetails } =
    useContext(AuthContext);
  const [isShippingChargesUpdated, setIsShippingChargesUpdated] = useState<boolean>(false);
  const [isUpdatingShippingCharges, setIsUpdatingShippingCharges] = useState<boolean>(false);
  // Can be updated
  const [shippingCharges, setShippingCharges] = useState<ShippingSettingsFields>({
    averageShippingDays: sellerDetails?.Shipping.AverageShippingDays
      ? sellerDetails?.Shipping.AverageShippingDays
      : 0,
  });
  const [internationalShipping, setInternationalShipping] = useState<boolean>(
    sellerDetails?.Shipping.InternationalShipping
      ? sellerDetails.Shipping.InternationalShipping
      : false
  );

  const [isShippingPartnerDataUpdated, setIsShippingPartnerDataUpdated] = useState<boolean>(false);
  const [isUpdatingShippingPartnerData, setIsUpdatingShippingPartnerData] =
    useState<boolean>(false);

  const [shippingPartnerData, setShippingPartnerData] = useState<ShippingPartnerFields>({
    email: sellerDetails?.ShiprocketCredentials?.Email ?? "",
    password: sellerDetails?.ShiprocketCredentials?.Password ?? "",
  });

  if (sellerDetails === null) {
    return <FiLoader className="animate-spin"></FiLoader>;
  }

  const submitShippingCharges: SubmitHandler<ShippingSettingsFields> = async (values) => {
    setShippingCharges(values);
    const updatedShippingSettings = { Shipping: sellerDetails.Shipping };
    updatedShippingSettings.Shipping.AverageShippingDays = values.averageShippingDays;
    updatedShippingSettings.Shipping.InternationalShipping = internationalShipping;
    const refreshedToken = await refreshAndReturnFirebaseToken();
    if (refreshedToken !== null) {
      await updateSellerDetails(refreshedToken, updatedShippingSettings);
      setIsUpdatingShippingCharges(false);
      setIsShippingChargesUpdated(true);
      setTimeout(() => {
        setIsShippingChargesUpdated(false);
      }, 3000);
    }
    setIsUpdatingShippingCharges(false);
  };

  const submitShippingPartner: SubmitHandler<ShippingPartnerFields> = async (values) => {
    setShippingPartnerData(values);
    // TODO(rranjan14): verify shiprocket credentials
    const updatedShiprocketCredentials = {
      ShiprocketCredentials: sellerDetails.ShiprocketCredentials,
    };
    if (updatedShiprocketCredentials.ShiprocketCredentials) {
      updatedShiprocketCredentials.ShiprocketCredentials.Email = values.email;
      updatedShiprocketCredentials.ShiprocketCredentials.Password = values.password;
    } else {
      updatedShiprocketCredentials.ShiprocketCredentials = {
        Email: values.email,
        Password: values.password,
        Token: "token",
        TokenCreatedAt: new Date().getTime(),
      };
    }
    const refreshedToken = await refreshAndReturnFirebaseToken();
    if (refreshedToken !== null) {
      setIsUpdatingShippingPartnerData(true);
      await updateSellerDetails(refreshedToken, updatedShiprocketCredentials);
      setIsUpdatingShippingPartnerData(false);
      setIsShippingPartnerDataUpdated(true);
      setTimeout(() => {
        setIsShippingPartnerDataUpdated(false);
      }, 3000);
    }
  };

  return (
    <div className="my-4 sm:px-6 lg:px-8">
      <div className="w-full flex flex-col items-center justify-between">
        <h6 className="text-xl font-medium my-2">Shipping Charges</h6>
        <ShippingSettings
          initialValue={shippingCharges}
          key="shipping-charges-form"
          next={submitShippingCharges}
          isUpdatingShippingSettings={isUpdatingShippingCharges}
          isShippingSettingsUpdated={isShippingChargesUpdated}
          internationalShipping={internationalShipping}
          setInternationalShipping={setInternationalShipping}
        />
        <h6 className="text-xl font-medium my-2 mt-4">Shipping Partner</h6>
        <ShippingPartner
          initialValue={shippingPartnerData}
          next={submitShippingPartner}
          key="shipping-partner-form"
          isShippingPartnerDataUpdated={isShippingPartnerDataUpdated}
          isUpdatingShippingPartnerData={isUpdatingShippingPartnerData}
        />
      </div>
    </div>
  );
};

export default Shipping;
