import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ApiContext } from "../../../../lib/services/api";
import { AuthContext } from "../../../../lib/services/auth";
import VisitorsTable from "./components/VisitorsTable";
import type { Visitor } from "../../../../lib/types";
import { FiLoader } from "react-icons/fi";

const ListVisitors = () => {
  const navigate = useNavigate();
  const { sellerDetails, refreshAndReturnFirebaseToken } = useContext(AuthContext);
  const { getAllVisitors } = useContext(ApiContext);
  const [visitors, setVisitors] = useState<Visitor[]>([]);

  useEffect(() => {
    const fetchCategories = async () => {
      const refreshedToken = await refreshAndReturnFirebaseToken();
      if (refreshedToken !== null) {
        const fetchedProducts = await getAllVisitors(refreshedToken);
        if (fetchedProducts.Err) {
          setVisitors([]);
        }
        if (fetchedProducts.Data) {
          setVisitors(fetchedProducts.Data);
        }
      }
    };
    fetchCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sellerDetails]);

  if (sellerDetails === null) {
    return <FiLoader className="animate-spin"></FiLoader>;
  }

  return (
    <div className="my-4 sm:px-6 lg:px-8">
      <div className="flex justify-between align-center content-center">
        <div className="mb-2">
          <h2 className="py-2 text-3xl font-light leading-8 text-gray-900 sm:text-4xl sm:truncate sm:px-6 lg:px-8">
            Visitors
          </h2>
        </div>
        <div className="flex justify-end px-8 mb-2 items-center">
          <button
            onClick={() => navigate("/store/customer-form")}
            type="button"
            className="inline-flex items-center px-4 py-2 text-base font-medium text-white bg-primary border border-transparent rounded-md shadow-sm hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Edit Visitor Pop-up
          </button>
        </div>
      </div>
      <VisitorsTable visitors={visitors} />
    </div>
  );
};

export default ListVisitors;
