import React from "react";

const AnnouncementBar = React.memo(function () {
  return (
    <div className="md:flex md:items-center md:justify-between my-4">
      <div className="flex-1 min-w-0">
        <h2 className="text-base font-bold leading-7 text-gray-900">
          Add your bank account/upi details to receive payments.
        </h2>
      </div>
      <div className="mt-4 flex md:mt-0 md:ml-4">
        <button
          type="button"
          className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-opacity-95 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Add
        </button>
      </div>
    </div>
  );
});

export default AnnouncementBar;
