import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Switch } from "@headlessui/react";
import FlexibleImage from "../../../../../components/FlexibleImage";
import { ProductListResponse } from "../../../../../lib/types";

type Props = {
  product: ProductListResponse;
  toggleProductState: (productId: string, isActive: boolean) => void;
  currencySymbol: string;
};

function ProductGridCard({ product, toggleProductState, currencySymbol = "₹" }: Props) {
  const [isActive, setIsActive] = useState(
    product.IsActive !== undefined ? product.IsActive : true
  );
  return (
    <li className="col-span-1 bg-white rounded-lg shadow-md border border-gray-200 border-solid divide-y divide-gray-200">
      <div className="w-full flex items-center justify-between p-6 space-x-6">
        <div className="flex-1 truncate">
          <div className="flex items-center space-x-3">
            <h3 className="text-gray-900 text-sm font-medium truncate">{product.Name}</h3>
            <span
              className={`flex-shrink-0 inline-block px-2 py-0.5 ${
                isActive ? "text-green-800" : "text-gray-800"
              } text-xs font-medium ${isActive ? "bg-green-100" : "bg-gray-300"} rounded-full`}
            >
              {isActive ? "Active" : "Inactive"}
            </span>
          </div>
          <p className="mt-1 text-gray-500 text-sm truncate">{`${product.TotalStocks} in ${
            product.VariantsCount
          } ${product.VariantsCount! > 1 ? "variants" : "variant"}`}</p>
          <p className="mt-2 text-gray-800 font-medium text-sm truncate">
            {currencySymbol}
            {product.Price}
          </p>
        </div>
        <FlexibleImage
          className="w-20 aspect-square"
          alt={product.Name}
          img={product.Image}
          loading="lazy"
        />
      </div>
      <div>
        <div className="-mt-px flex divide-x divide-gray-200">
          <div className="w-0 flex-1 flex">
            <Link
              to={`/products/${product.Slug}`}
              className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
            >
              <span className="ml-3">Edit Product</span>
            </Link>
          </div>
          <div className="w-0 flex-1 flex justify-end">
            <Switch.Group as="div" className="flex items-center my-2 mr-4">
              <Switch
                checked={true}
                onChange={() => {
                  toggleProductState(product.Sku, !isActive);
                  setIsActive(!isActive);
                }}
                className={`
            ${isActive ? "bg-indigo-600" : "bg-gray-400"}
            relative inline-flex flex-shrink-0 h-4 w-8 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
              >
                <span className="sr-only">Use setting</span>
                <span
                  aria-hidden="true"
                  className={`
              ${isActive ? "translate-x-4" : "translate-x-0"}
              pointer-events-none inline-block h-3 w-3 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`}
                />
              </Switch>
            </Switch.Group>
          </div>
        </div>
      </div>
    </li>
  );
}

export default ProductGridCard;
