import React, { useState, useContext } from "react";
import { AuthContext } from "../../../../../lib/services/auth";
import { Link } from "react-router-dom";
import { Switch } from "@headlessui/react";
import FlexibleImage from "../../../../../components/FlexibleImage";
import { ProductListResponse } from "../../../../../lib/types";

type Props = {
  product: ProductListResponse;
  toggleProductState: (productId: string, isActive: boolean) => void;
};

function ProductListRow({ product, toggleProductState }: Props) {
  const { localeDetails } = useContext(AuthContext);
  const [isActive, setIsActive] = useState(
    product.IsActive !== undefined ? product.IsActive : true
  );

  return (
    <tr>
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="flex items-center">
          <div className="flex-shrink-0 h-10 w-10">
            <FlexibleImage
              className="h-10 w-10 aspect-square"
              alt={product.Name}
              img={product.Image}
              loading="lazy"
            />
          </div>
          <div className="ml-4">
            <div className="text-sm font-medium text-gray-900">{product.Name}</div>
          </div>
        </div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="text-sm text-gray-900">
          {localeDetails.currencySymbol}
          {product.Price}
        </div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="text-sm text-gray-900">{`${product.TotalStocks} in ${
          product.VariantsCount
        } ${product.VariantsCount! > 1 ? "variants" : "variant"}`}</div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium flex items-center">
        <Switch.Group as="div" className="flex items-center my-2 mr-4">
          <Switch
            checked={true}
            onChange={() => {
              toggleProductState(product.Sku, !isActive);
              setIsActive(!isActive);
            }}
            className={`
                ${isActive ? "bg-indigo-600" : "bg-gray-400"}
                relative inline-flex flex-shrink-0 h-4 w-8 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
          >
            {/* <span className="sr-only">Use setting</span> */}
            <span
              aria-hidden="true"
              className={`
                  ${isActive ? "translate-x-4" : "translate-x-0"}
                  pointer-events-none inline-block h-3 w-3 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`}
            />
          </Switch>
        </Switch.Group>

        <Link to={`/products/${product.Slug}`} className="text-indigo-600 hover:text-indigo-900">
          Edit
        </Link>
      </td>
    </tr>
  );
}

export default ProductListRow;
