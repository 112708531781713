import React, { useEffect, useContext, useState } from "react";
import { SubmitHandler } from "react-hook-form";
import { FiLoader } from "react-icons/fi";
import { AuthContext } from "../../../lib/services/auth";
import { NotificationContext } from "../../../lib/services/notification";
import { SellerDetailsResponse } from "../../../lib/types";
import StorefrontTrackingDetailsForm, {
  StorefrontTrackingDetailsFields,
} from "./components/StorefrontTrackingDetailsForm";

const StorefrontTracking = () => {
  const { sellerDetails, updateSellerDetails, refreshAndReturnFirebaseToken } =
    useContext(AuthContext);

  const { addNotification, removeNotificationByTag } = useContext(NotificationContext);

  const [isUpdatingStorefrontTrackingDetails, setIsUpdatingStorefrontTrackingDetails] =
    useState<boolean>(false);
  const [storefrontTrackingDetails, setStorefrontTrackingDetails] =
    useState<StorefrontTrackingDetailsFields>({
      FbPixelId: sellerDetails?.WebsiteConfig.FbPixelId || null,
      GtmContainerId: sellerDetails?.WebsiteConfig.GtmContainerId || null,
    });

  useEffect(() => {}, [sellerDetails]);

  if (sellerDetails === null) {
    return <FiLoader className="animate-spin"></FiLoader>;
  }

  const submitStorefrontTrackingDetails: SubmitHandler<StorefrontTrackingDetailsFields> = async (
    _data
  ) => {
    setStorefrontTrackingDetails(_data);
    setIsUpdatingStorefrontTrackingDetails(false);
    removeNotificationByTag("StorefrontTracking");

    const updatedSellerDetails: Partial<SellerDetailsResponse> = {
      WebsiteConfig: sellerDetails.WebsiteConfig,
    };
    updatedSellerDetails.WebsiteConfig = {
      ...sellerDetails.WebsiteConfig,
      ..._data,
    };

    setIsUpdatingStorefrontTrackingDetails(true);
    const refreshedToken = await refreshAndReturnFirebaseToken();
    if (refreshedToken !== null) {
      await updateSellerDetails(refreshedToken, updatedSellerDetails);
      addNotification(
        "success",
        "Successfully updated the storefront tracking details!",
        "StorefrontTracking"
      );
    }
    setIsUpdatingStorefrontTrackingDetails(false);
  };

  return (
    <div className="my-4 sm:px-6 lg:px-8">
      <div className="w-full flex flex-col items-center">
        <div>
          <h6 className="text-xl font-medium leading-7 text-gray-900 my-4">Storefront Tracking</h6>
        </div>
        <div className="w-1/2 flex flex-col justify-center items-center">
          <StorefrontTrackingDetailsForm
            initialValue={storefrontTrackingDetails}
            next={submitStorefrontTrackingDetails}
            isUpdatingStorefrontTrackingDetails={isUpdatingStorefrontTrackingDetails}
            key="store-front-tracking-details-form"
          />
        </div>
      </div>
    </div>
  );
};

export default StorefrontTracking;
