import React, { useState, useContext, useEffect } from "react";
import { SubmitHandler, useForm, useWatch } from "react-hook-form";
import FormInput from "../../../components/FormInput";
import { withTestId } from "../../../lib/utils";
import { AuthContext } from "../../../lib/services/auth";
import { FiLoader } from "react-icons/fi";

export type PrepaidSettingsField = {
  cardCharge: number;
  prepaidShippingAbove: number | null;
  prepaidUpperLimit: number | null;
  prepaidLowerLimit: number | null;
};

const PrepaidSettingsForm = React.memo(function ShippingChargesForm() {
  const { sellerDetails, refreshAndReturnFirebaseToken, updateSellerDetails, fetchSellerDetails } =
    useContext(AuthContext);
  const [shippingCharges, setShippingCharges] = useState<PrepaidSettingsField>({
    cardCharge: sellerDetails?.Shipping.CardCharge ?? 0,
    prepaidShippingAbove: sellerDetails?.Shipping.PrepaidFreeShippingAbove ?? 0,
    prepaidLowerLimit: sellerDetails?.Shipping.PrepaidLowerLimit ?? 0,
    prepaidUpperLimit: sellerDetails?.Shipping.hasOwnProperty("PrepaidUpperLimit")
      ? sellerDetails?.Shipping.PrepaidUpperLimit ?? 0
      : sellerDetails?.Shipping.FreeShippingAbove ?? 0,
  });
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useForm<PrepaidSettingsField>({
    defaultValues: shippingCharges ?? undefined,
  });
  const [isShippingChargesUpdated, setIsShippingChargesUpdated] = useState<boolean>(false);
  const [isUpdatingShippingCharges, setIsUpdatingShippingCharges] = useState<boolean>(false);

  const [isPrepaid, setIsPrepaid] = useState<boolean>(
    sellerDetails?.Shipping.CardCharge
      ? sellerDetails?.Shipping.CardCharge * 100 !== -1
        ? true
        : false
      : false
  );

  const [isPrepaidFreeShipping, setIsPrepaidFreeShipping] = useState(
    sellerDetails?.Shipping
      ? (sellerDetails?.Shipping.PrepaidFreeShippingAbove ==
          sellerDetails?.Shipping.PrepaidUpperLimit &&
          sellerDetails?.Shipping.PrepaidUpperLimit !== null) ||
        (sellerDetails?.Shipping.FreeShippingAbove &&
          !sellerDetails?.Shipping.hasOwnProperty("PrepaidUpperLimit"))
        ? true
        : false
      : false
  );

  const PrepaidUpperLimit = useWatch({
    control,
    name: "prepaidUpperLimit",
  });

  useEffect(() => {
    if (isPrepaidFreeShipping) {
      setValue("prepaidShippingAbove", PrepaidUpperLimit);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PrepaidUpperLimit]);

  if (sellerDetails === null) {
    return <FiLoader className="animate-spin"></FiLoader>;
  }

  const submitShippingCharges: SubmitHandler<PrepaidSettingsField> = async (values) => {
    setShippingCharges(values);
    const updatedShippingCharges = { Shipping: sellerDetails.Shipping };
    updatedShippingCharges.Shipping.CardCharge = isPrepaid
      ? values.cardCharge == -1
        ? 0
        : +values.cardCharge * 100
      : -1;
    updatedShippingCharges.Shipping.PrepaidLowerLimit = values.prepaidLowerLimit
      ? values.prepaidLowerLimit * 100
      : null;
    updatedShippingCharges.Shipping.PrepaidUpperLimit = values.prepaidUpperLimit
      ? values.prepaidUpperLimit * 100
      : null;
    updatedShippingCharges.Shipping.PrepaidFreeShippingAbove = values.prepaidShippingAbove
      ? values.prepaidShippingAbove * 100
      : null;
    updatedShippingCharges.Shipping.CodCharge =
      updatedShippingCharges.Shipping.CodCharge == -1
        ? 0
        : updatedShippingCharges.Shipping.CodCharge * 100;
    updatedShippingCharges.Shipping.CodFreeShippingAbove = updatedShippingCharges.Shipping
      .CodFreeShippingAbove
      ? updatedShippingCharges.Shipping.CodFreeShippingAbove * 100
      : null;
    updatedShippingCharges.Shipping.CodLowerLimit = updatedShippingCharges.Shipping.CodLowerLimit
      ? updatedShippingCharges.Shipping.CodLowerLimit * 100
      : null;
    updatedShippingCharges.Shipping.CodUpperLimit = updatedShippingCharges.Shipping.CodUpperLimit
      ? updatedShippingCharges.Shipping.CodUpperLimit * 100
      : null;

    const refreshedToken = await refreshAndReturnFirebaseToken();
    if (refreshedToken !== null) {
      setIsUpdatingShippingCharges(true);
      await updateSellerDetails(refreshedToken, updatedShippingCharges);
      setIsUpdatingShippingCharges(false);
      setIsShippingChargesUpdated(true);
      await fetchSellerDetails(refreshedToken);
    }
  };
  return (
    <div className="my-4 sm:px-6 lg:px-8">
      <div className="w-full flex flex-col items-center justify-between">
        <h6 className="text-xl font-medium my-2">Prepaid Settings</h6>
        <form onSubmit={handleSubmit(submitShippingCharges)} className="w-1/2">
          <label
            htmlFor="allow-cod"
            className="mb-4 flex flex-row items-center justify-between w-full"
          >
            <p className="flex flex-col">
              <span className="font-medium text-sm">Allow Prepaid</span>
              <span className="text-xs text-gray-600">
                Enable prepaid option on all your orders
              </span>
            </p>
            <input
              type="checkbox"
              id="allow-prepaid"
              checked={isPrepaid}
              onChange={(e) => {
                if (!isPrepaid) {
                  setValue("cardCharge", 0);
                  setValue("prepaidLowerLimit", 0);
                  setValue("prepaidShippingAbove", 0);
                  setValue("prepaidUpperLimit", 0);
                }
                setIsPrepaid(e.target.checked);
              }}
              className="text-indigo-600 border-gray-300 rounded shadow-sm focus:border-indigo-300 focus:ring focus:ring-offset-0 focus:ring-indigo-200 focus:ring-opacity-50 "
            />
          </label>

          {isPrepaid ? (
            <>
              <FormInput
                {...withTestId("cardCharge-input")}
                id="cardCharge"
                placeholder="Card Charge"
                control={{
                  type: "number",
                  ...register("cardCharge", {
                    required: true,
                    valueAsNumber: true,
                    validate: (value) => value > 0,
                  }),
                }}
                errMsg="Please enter a valid amount"
                isError={errors.cardCharge != null}
              />
              <FormInput
                {...withTestId("prepaidLowerLimit-input")}
                id="prepaidLowerLimit"
                placeholder="Minimum Amount for Card Charges"
                control={{
                  type: "number",
                  ...register("prepaidLowerLimit"),
                }}
                errMsg="Please enter a valid amount"
                isError={errors.prepaidLowerLimit != null}
              />
              <FormInput
                {...withTestId("prepaidUpperLimit-input")}
                id="prepaidUpperLimit"
                placeholder="Maximum Amount for Card Charges"
                control={{
                  type: "number",
                  ...register("prepaidUpperLimit"),
                }}
                errMsg="Please enter a valid amount"
                isError={errors.prepaidUpperLimit != null}
              />
              <label
                htmlFor="allow-cod"
                className="mb-4 flex flex-row items-center justify-between w-full"
              >
                <p className="flex flex-col">
                  <span className="font-medium text-sm">
                    Allow orders even above max amount and give free shipping
                  </span>
                </p>
                <input
                  type="checkbox"
                  id="allow-cod"
                  checked={isPrepaidFreeShipping}
                  onChange={(e) => {
                    setIsPrepaidFreeShipping(e.target.checked);
                    if (!isPrepaidFreeShipping)
                      setValue("prepaidShippingAbove", getValues().prepaidUpperLimit);
                    else setValue("prepaidShippingAbove", null);
                  }}
                  className="text-indigo-600 border-gray-300 rounded shadow-sm focus:border-indigo-300 focus:ring focus:ring-offset-0 focus:ring-indigo-200 focus:ring-opacity-50 "
                />
              </label>
            </>
          ) : null}
          {isShippingChargesUpdated ? (
            <p className="px-4 py-2 my-4 text-green-500 bg-green-500 border border-green-500 border-solid rounded bg-opacity-5">
              Successfully updated prepaid settings!
            </p>
          ) : null}
          <button
            type="submit"
            disabled={Object.keys(errors).length > 0 || isUpdatingShippingCharges}
            className="w-full p-2 font-bold uppercase bg-indigo-700 rounded text-stone-200 disabled:bg-gray-400"
          >
            {isUpdatingShippingCharges ? "Saving..." : "Save Prepaid Settings"}
          </button>
        </form>
      </div>
    </div>
  );
});

export default PrepaidSettingsForm;
