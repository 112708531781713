import React, { ImgHTMLAttributes } from "react";

type FlexibleImageProps = {
  img: ImageRes;
  style?: ImgHTMLAttributes<HTMLImageElement>["style"];
  className?: ImgHTMLAttributes<HTMLImageElement>["className"];
  loading?: ImgHTMLAttributes<HTMLImageElement>["loading"];
  alt: string;
};

/**
 * A flexible image component that uses different image formats based on
 * browsers that support it. Note that while the alt tag is required, an empty
 * string will do.
 */
const FlexibleImage = React.memo(function FlexibleImage({
  img,
  style,
  className,
  loading,
  alt,
}: FlexibleImageProps) {
  return (
    <img
      style={style}
      className={className}
      loading={loading}
      srcSet={`${img.webpUrl ?? img.jpgUrl}, ${img.jpgUrl}`}
      alt={alt}
    />
  );
});

export default FlexibleImage;
