import React, { useState, useEffect, useContext, useRef } from "react";
import { Switch } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import { FiLoader } from "react-icons/fi";

import ProductGridView from "./components/ProductGridView";
import ProductListView from "./components/ProductListView";
import { TypeaheadResponse } from "../../../lib/types";
import { AuthContext } from "../../../lib/services/auth";
import { ApiResult } from "../../../lib/services/api";
import { baseUrl } from "../../../lib/utils";

function ProductsSearch() {
  const navigate = useNavigate();
  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
  }

  const { sellerDetails, refreshAndReturnFirebaseToken, user } = useContext(AuthContext);
  const [results, setResults] = useState<TypeaheadResponse>({
    Suggestions: [],
  });
  const [loading, setLoading] = useState(false);

  const [productSearchValue, setProductSearchValue] = useState<string>("");
  const [query, setQuery] = useState<string>("");
  const inputRef = useRef<HTMLInputElement>(null);
  const [gridViewEnabled, setGridViewEnabled] = useState(false);

  const fetchSearchResults = async () => {
    if (!query) return;

    setLoading(true);
    const refreshedToken = await refreshAndReturnFirebaseToken();
    if (refreshedToken !== null) {
      const response = await fetch(
        `${baseUrl}/v1/typeahead?storeId=${user?.uid}&query=${query}&dashboard=true`,

        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${refreshedToken}`,
          },
        }
      );
      const json = (await response.json()) as ApiResult<TypeaheadResponse>;
      if (json.Data) {
        setResults(json.Data);
      }
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchSearchResults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  function renderSwitch() {
    return (
      <Switch.Group as="div" className="flex items-center my-2">
        <Switch.Label as="span" className="mr-3">
          <span className="text-sm font-medium text-gray-900">Grid View</span>
        </Switch.Label>
        <Switch
          checked={gridViewEnabled}
          onChange={setGridViewEnabled}
          className={classNames(
            gridViewEnabled ? "bg-primary" : "bg-gray-200",
            "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          )}
        >
          <span className="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            className={classNames(
              gridViewEnabled ? "translate-x-5" : "translate-x-0",
              "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
            )}
          />
        </Switch>
        <Switch.Label as="span" className="ml-3">
          <span className="text-sm font-medium text-gray-900">List View</span>
        </Switch.Label>
      </Switch.Group>
    );
  }

  if (sellerDetails === null) {
    return <FiLoader className="animate-spin"></FiLoader>;
  }

  return (
    <div className="my-4 sm:px-6 lg:px-8">
      <div className="mb-4 flex justify-between align-center content-center">
        <div className="flex flex-col justify-between">
          <h2 className="text-3xl py-2 font-light leading-8 text-gray-900 sm:text-4xl sm:truncate">
            Products
          </h2>
        </div>
      </div>
      <div className="w-full flex flex-col items-between justify-start mb-4">
        <div className="flex items-center mb-4">
          <button
            className="py-1 px-4 mr-4 border border-solid border-red-500 text-red-500 rounded-md"
            onClick={() => navigate("/products")}
          >
            Cancel
          </button>
          <input
            type="text"
            value={productSearchValue}
            placeholder="Search"
            ref={inputRef}
            onKeyDown={(e: React.KeyboardEvent) =>
              e.key === "Enter" ? setQuery(productSearchValue) : null
            }
            onChange={(e) => setProductSearchValue(e.target.value)}
            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
          />
        </div>

        {renderSwitch()}
      </div>
      {query ? <p className="mb-4 ">Showing results for "{query}":</p> : null}
      {loading ? <FiLoader className="animate-spin my-4"></FiLoader> : null}
      {gridViewEnabled ? (
        <ProductListView products={results.Suggestions || []}></ProductListView>
      ) : (
        <ProductGridView products={results.Suggestions || []}></ProductGridView>
      )}
    </div>
  );
}

export default ProductsSearch;
