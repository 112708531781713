import React, { useContext, useState, useEffect } from "react";
import { SubmitHandler } from "react-hook-form";

import { AuthContext } from "../../../lib/services/auth";
import { withTestId } from "../../../lib/utils";
import { PersonalDetailsField } from "./components/Form";
import PersonalDetailsForm from "./components/Form";
import { FiLoader } from "react-icons/fi";
import { SellerDetailsResponse } from "../../../lib/types";
// import { ApiContext } from "../../lib/services/api";

const PersonalDetails = () => {
  const { sellerDetails, refreshAndReturnFirebaseToken, updateSellerDetails } =
    useContext(AuthContext);

  useEffect(() => {}, [sellerDetails]);

  const [personalDetailsForm, setPersonalDetailsForm] = useState<PersonalDetailsField>({
    name: sellerDetails?.SellerName ?? "",
    businessName: sellerDetails?.BusinessName ?? "",
    address: sellerDetails?.Address.Address1 ?? null,
    city: sellerDetails?.Address.City ?? null,
    email: sellerDetails?.Email ?? "",
    pincode: sellerDetails?.Address.Pincode ?? null,
    state: sellerDetails?.Address.State ?? null,
  });

  if (sellerDetails === null) {
    return <FiLoader className="animate-spin"></FiLoader>;
  }

  const onSubmit: SubmitHandler<PersonalDetailsField> = async (_personalDetailsFieldData) => {
    setPersonalDetailsForm(_personalDetailsFieldData);
    const updatedPersonalDetails: Partial<SellerDetailsResponse> = {
      SellerName: _personalDetailsFieldData.name,
      BusinessName: _personalDetailsFieldData.businessName,
      Email: _personalDetailsFieldData.email,
      Address: {
        ...sellerDetails.Address,
        Address1: _personalDetailsFieldData.address,
        City: _personalDetailsFieldData.city,
        Pincode: _personalDetailsFieldData.pincode,
        State: _personalDetailsFieldData.state,
      },
    };

    const refreshedToken = await refreshAndReturnFirebaseToken();
    if (refreshedToken !== null) {
      await updateSellerDetails(refreshedToken, updatedPersonalDetails);
    }
  };

  return (
    <div className="my-4 sm:px-6 lg:px-8">
      <div>
        <h1 className="text-3xl font-light leading-7 text-gray-900 my-2">Personal Details</h1>
      </div>
      <div className="flex flex-col justify-center items-center">
        <PersonalDetailsForm
          {...withTestId("personal-details-form")}
          key="personal-details-form"
          next={onSubmit}
          initialValue={personalDetailsForm}
        />
      </div>
    </div>
  );
};

export default PersonalDetails;
