import React, { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import FormInput from "../../../../components/FormInput";
import { withTestId } from "../../../../lib/utils";

export type CustomerFormFields = {
  nameEnabled: boolean;
  emailEnabled: boolean;
  phoneEnabled: boolean;
  afterDelaySecs: number;
  couponReward: string | null;
};

type CustomerFormProps = {
  initialValue: CustomerFormFields;
  isUpdatingCustomerFormDetails: boolean;
  /** Called when the user fills in the form */
  next: SubmitHandler<CustomerFormFields>;
} & TestingProps;

const CustomerFormDetails = React.memo(function CustomerFormDetails(props: CustomerFormProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CustomerFormFields>({
    defaultValues: props.initialValue ?? undefined,
  });

  const [isSelectedName, setSelectionName] = useState(props.initialValue.nameEnabled);
  const [isSelectedEmail, setSelectionEmail] = useState(props.initialValue.emailEnabled);
  const [isSelectedPhone, setSelectionPhone] = useState(props.initialValue.phoneEnabled);

  function toggleName() {
    setSelectionName(!isSelectedName);
  }

  function toggleEmail() {
    setSelectionEmail(!isSelectedEmail);
  }

  function togglePhone() {
    setSelectionPhone(!isSelectedPhone);
  }

  return (
    <form onSubmit={handleSubmit(props.next)} className="w-full">
      <div className="flex-1 min-w-0 mt-4">
        <h2 className="text-base font-bold leading-7 text-gray-900">
          Choose what you want to collect from your visitors:
        </h2>
      </div>
      <div className="flex justify-center">
        <div>
          <div className="form-check">
            <input
              className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
              type="checkbox"
              value=""
              id="name"
              checked={isSelectedName}
              {...register("nameEnabled")}
              onChange={toggleName}
            />
            <label className="form-check-label inline-block text-gray-800">Customer Name</label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
              type="checkbox"
              value=""
              id="phone"
              checked={isSelectedEmail}
              {...register("emailEnabled")}
              onChange={toggleEmail}
            />
            <label className="form-check-label inline-block text-gray-800">Customer Email</label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
              type="checkbox"
              value=""
              id="email"
              checked={isSelectedPhone}
              {...register("phoneEnabled")}
              onChange={togglePhone}
            />
            <label className="form-check-label inline-block text-gray-800">
              Customer Phone Number
            </label>
          </div>
        </div>
      </div>
      <div className="flex-1 min-w-0">
        <h2 className="text-base font-bold leading-7 text-gray-900 mt-4">
          Give discount to customers who fill the form
        </h2>
      </div>
      <FormInput
        {...withTestId("name-input")}
        id="name"
        placeholder="Discount Code"
        control={{ type: "text", ...register("couponReward") }}
        errMsg={"Please enter your coupon code"}
        isError={errors.couponReward != null}
      />
      <button
        type="submit"
        disabled={Object.keys(errors).length > 0 || props.isUpdatingCustomerFormDetails}
        className="uppercase bg-primary text-stone-200 font-bold p-2 w-full rounded disabled:bg-gray-400"
      >
        {props.isUpdatingCustomerFormDetails ? "Saving" : "Save"}
      </button>
    </form>
  );
});

export default CustomerFormDetails;
