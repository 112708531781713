import React, { useContext } from "react";
import { ChartBarIcon, HomeIcon } from "@heroicons/react/outline";

import { FiLogOut } from "react-icons/fi";
import { HiChevronDown, HiChevronUp, HiOutlineClipboardList } from "react-icons/hi";
import {
  MdOutlineInventory2,
  MdOutlineManageAccounts,
  MdOutlinePayments,
  MdOutlineLocalShipping,
} from "react-icons/md";
import { BiStore, BiCategory } from "react-icons/bi";
import { HiOutlineSpeakerphone } from "react-icons/hi";

import { Disclosure } from "@headlessui/react";

import NavBar from "../NavBar";
import { AuthContext } from "../../lib/services/auth";
import { Link, useLocation } from "react-router-dom";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}

const PrimaryMenu: React.FC<Props> = ({ children }) => {
  const location = useLocation();
  const { sellerDetails } = useContext(AuthContext);

  const navigation = [
    {
      name: "Dashboard",
      icon: HomeIcon,
      isActive: location.pathname === "/",
      href: "/",
    },
    {
      name: "Products",
      icon: MdOutlineInventory2,
      isActive: location.pathname === "/products",
      href: "/products",
    },
    {
      name: "Categories",
      icon: BiCategory,
      isActive: location.pathname.indexOf("/categories") === 0,
      children: [
        { name: "All Categories", href: "/categories", isActive: false },
        { name: "Super Categories", href: "/category-collections", isActive: false },
      ],
    },
    {
      name: "Orders",
      icon: HiOutlineClipboardList,
      isActive: location.pathname.indexOf("/orders") === 0,
      children: [
        {
          name: "Orders",
          href: "/orders/orders",
          isActive: location.pathname === "/orders/orders",
        },
        {
          name: "All Orders",
          href: "/orders/all-orders",
          isActive: location.pathname === "/orders/all-orders",
        },
        {
          name: "Abandoned Carts",
          href: "/orders/abandoned-carts",
          isActive: location.pathname === "/orders/abandoned-carts",
        },
      ],
    },
    {
      name: "Store Settings",
      icon: BiStore,
      isActive: location.pathname.indexOf("/store") === 0,
      children: [
        {
          name: "Store Pictures",
          href: "/store/store-pictures",
          isActive: location.pathname === "/store/store-pictures",
        },
        { name: "Theme", href: "/store/theme", isActive: location.pathname === "/store/theme" },
        {
          name: "About Us",
          href: "/store/about-us",
          isActive: location.pathname === "/store/about-us",
        },
        {
          name: "Coupons",
          href: "/store/coupons",
          isActive: location.pathname === "/store/coupons",
        },
        {
          name: "Customer Form",
          href: "/store/customer-form",
          isActive: location.pathname === "/store/customer-form",
        },
        {
          name: "Customise Checkout",
          href: "/store/customize-checkout",
          isActive: location.pathname === "/store/customize-checkout",
        },
        {
          name: "Returns & Refunds",
          href: "/store/returns-policy",
          isActive: location.pathname === "/store/returns-policy",
        },
        {
          name: "WhatsApp Support",
          href: "/store/whatsapp-support",
          isActive: location.pathname === "/store/whatsapp-support",
        },
        {
          name: "Announcement Bar",
          href: "/store/announcement-bar",
          isActive: location.pathname === "/store/announcement-bar",
        },
        {
          name: "Storefront Tracking",
          href: "/store/tracking",
          isActive: location.pathname === "/store/tracking",
        },
      ],
    },
    {
      name: "Account Settings",
      icon: MdOutlineManageAccounts,
      isActive: location.pathname.indexOf("/account") === 0,
      children: [
        {
          name: "Personal Details",
          href: "/account/personal-details",
          isActive: location.pathname === "/account/personal-details",
        },
        {
          name: "Bank Details",
          href: "/account/bank-details",
          isActive: location.pathname === "/account/bank-details",
        },
        {
          name: "GST Details",
          href: "/account/gst-details",
          isActive: location.pathname === "/account/gst-details",
        },
        ...(sellerDetails?.LocaleDetails.CountryCode != "IN"
          ? [
              {
                name: "Integrate Stripe",
                href: "/account/integrate-stripe",
                isActive: location.pathname === "/account/integrate-stripe",
              },
            ]
          : []),
      ],
    },
    {
      name: "Marketing",
      icon: HiOutlineSpeakerphone,
      isActive: location.pathname.indexOf("/marketing") === 0,
      children: [
        {
          name: "Social Media",
          href: "/marketing/social-media",
          isActive: location.pathname === "/marketing/social-media",
        },
        { name: "Instagram Story", href: "#", isActive: false },
      ],
    },
    {
      name: "Shipping",
      icon: MdOutlineLocalShipping,
      isActive: location.pathname === "/shipping",
      href: "/shipping",
      children: [
        {
          name: "Settings",
          href: "/shipping/shipping-settings",
          isActive: location.pathname === "/shipping/shipping-settings",
        },
        {
          name: "COD Settings",
          href: "/shipping/cod-settings",
          isActive: location.pathname === "/shipping/cod-settings",
        },
        {
          name: "Prepaid Settings",
          href: "/shipping/prepaid-settings",
          isActive: location.pathname === "/shipping/prepaid-settings",
        },
      ],
    },
    {
      name: "Customers & Payments",
      icon: MdOutlinePayments,
      isActive: false,
      children: [
        { name: "Payments", href: "/payments", isActive: false },
        { name: "Customers", href: "#", isActive: false },
        { name: "Visitors", href: "/store/visitors", isActive: false },
      ],
    },
    {
      name: "About Instasell",
      icon: ChartBarIcon,
      isActive: false,
      children: [
        { name: "Support", href: "#", isActive: false },
        { name: "Terms of Service", href: "#", isActive: false },
        { name: "FAQs", href: "#", isActive: false },
        { name: "Privacy Policy", href: "#", isActive: false },
      ],
    },
  ];

  const { logout } = useContext(AuthContext);

  return (
    <>
      <div className="w-screen h-screen flex">
        {/* Static sidebar for desktop */}
        <div className="min-w-64 w-1/5 lg:w-1/6 flex flex-col border-r border-gray-200 bg-gray-200 overflow-y-auto pt-7">
          <div className="flex items-center flex-shrink-0 px-4">
            <img className="h-8 w-auto" src="/imgbin/instasell-new-logo.svg" alt="Instasell" />
          </div>
          <div className="mt-5 flex-grow flex flex-col">
            <nav className="flex-1 px-4 space-y-1 bg-gray-200" aria-label="Sidebar">
              <a
                href={
                  sellerDetails?.Fqdn
                    ? `//${sellerDetails?.Fqdn}`
                    : `//www.instasell.io/${sellerDetails?.Slug}`
                }
                target="_blank"
                className="text-xs font-medium text-gray-600 lowercase"
              >
                {sellerDetails?.Fqdn
                  ? `${sellerDetails?.Fqdn}`
                  : `www.instasell.io/${sellerDetails?.Slug}`}
              </a>
              {navigation.map((item) =>
                !item.children ? (
                  <div key={item.name}>
                    <Link
                      to={item.href}
                      className={classNames(
                        item.isActive
                          ? "bg-white text-black hover:bg-white hover:bg-opacity-95"
                          : "text-black hover:bg-white hover:bg-opacity-20",
                        "group w-full flex items-center pl-2 py-2 text-sm font-medium rounded-md"
                      )}
                    >
                      <item.icon
                        className={classNames("text-black mr-3 flex-shrink-0 h-6 w-6")}
                        aria-hidden="true"
                      />
                      {item.name}
                    </Link>
                  </div>
                ) : (
                  <Disclosure
                    as="div"
                    key={item.name}
                    className="space-y-1"
                    defaultOpen={item.isActive}
                  >
                    {({ open }) => (
                      <>
                        <Disclosure.Button
                          className={classNames(
                            item.isActive
                              ? "bg-white text-black hover:bg-white hover:bg-opacity-95"
                              : "text-black hover:bg-white hover:bg-opacity-20",
                            "group w-full flex items-center pl-2 pr-1 py-2 text-left text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                          )}
                        >
                          <item.icon
                            className="mr-3 flex-shrink-0 h-6 w-6 text-black"
                            aria-hidden="true"
                          />
                          <span className="flex-1">{item.name}</span>
                          {open ? <HiChevronUp className="" /> : <HiChevronDown className="" />}
                        </Disclosure.Button>
                        <Disclosure.Panel className="space-y-1">
                          {item.children.map((subItem) => (
                            <Link
                              to={subItem.href}
                              key={subItem.name}
                              className={classNames(
                                subItem.isActive ? "text-black font-medium" : "",
                                "group w-full flex items-center text-left pl-11 pr-2 py-1 text-sm text-gray-500 rounded-md hover:text-gray-900"
                              )}
                            >
                              {subItem.name}
                            </Link>
                          ))}
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                )
              )}
            </nav>
          </div>
          <div className="flex-shrink-0 flex border-t border-gray-200 p-2">
            <div className="flex flex-col justify-center items-center">
              <button onClick={() => logout()}>
                <div className="flex flex-row ml-3 justify-center items-center">
                  <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">
                    <FiLogOut className="text-base mr-1 font-bold" />
                  </p>
                  <p className="text-base font-bold">Logout</p>
                </div>
              </button>
            </div>
          </div>
        </div>
        <div className="flex flex-col w-1/5 lg:w-5/6 min-w-0 flex-1">
          <div className="flex-1 relative z-0 flex">
            <main className="flex-1 relative z-0 focus:outline-none xl:order-last">
              <div className="absolute overflow-auto inset-0">
                <NavBar />
                {children}
              </div>
            </main>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrimaryMenu;
