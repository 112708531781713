import React from "react";
import { Link } from "react-router-dom";
import FlexibleImage from "../../../../../components/FlexibleImage";
import { TypeaheadResponse } from "../../../../../lib/types";

function ProductListView({ products }: { products: TypeaheadResponse["Suggestions"] }) {
  function renderProduct(_product: TypeaheadResponse["Suggestions"][0], index: number) {
    return (
      <tr key={index}>
        <td className="px-6 py-4 whitespace-nowrap">
          <div className="flex items-center">
            <div className="flex-shrink-0 h-10 w-10">
              <FlexibleImage
                className="h-10 w-10 aspect-square"
                alt={_product.Name}
                img={_product.Images[0]}
                key={index}
                loading="lazy"
              />
            </div>
            <div className="ml-4">
              <div className="text-sm font-medium text-gray-900">{_product.Name}</div>
            </div>
          </div>
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
          <div className="text-sm text-gray-900">{_product.Price}</div>
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
          <div className="text-sm text-gray-900">{`${_product.Stock} in ${
            _product.Variants.length
          } ${_product.Variants.length! > 1 ? "variants" : "variant"}`}</div>
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
          <Link to={`/products/${_product.Slug}`} className="text-primary hover:text-indigo-900">
            Edit
          </Link>
        </td>
      </tr>
    );
  }

  return (
    <div className="flex flex-col w-full">
      <div className="-my-2 overflow-x-auto">
        <div className="py-2 align-middle inline-block w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Product Name
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Price
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Stock
                  </th>
                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {products.map((product: TypeaheadResponse["Suggestions"][0], index: number) =>
                  renderProduct(product, index)
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductListView;
