import React, { useContext, useState, useEffect } from "react";
import { ApiContext } from "../../lib/services/api";
import { AuthContext } from "../../lib/services/auth";
import type { AbandonedCartList } from "../../lib/types";
import { useNavigate } from "react-router-dom";
import PagingControls from "../Orders/Orders/components/PagingControls";
import usePagination from "../../hooks/usePagination";

function AbandonedCheckouts() {
  const navigate = useNavigate();
  const { sellerDetails, refreshAndReturnFirebaseToken, localeDetails } = useContext(AuthContext);
  const { fetchCarts } = useContext(ApiContext);
  const [abandonedCartList, setAbandonedCartList] = useState<AbandonedCartList[]>([]);
  const [canFetchMore, setCanFetchMore] = useState(false);

  const {
    canGoBack,
    goBack,
    nextCursor,
    nextPage,
    prevCursor,
    prevPage,
    setGoBack,
    setNextCursor,
    setPrevCursor,
    page,
  } = usePagination();

  useEffect(() => {
    const fetchAbandonedCarts = async () => {
      const refreshedToken = await refreshAndReturnFirebaseToken();
      if (refreshedToken !== null) {
        const fetchedAbandonedCarts = await fetchCarts(
          refreshedToken,
          goBack ? 1 : 0,
          goBack ? null : nextCursor,
          goBack ? prevCursor : null
        );
        if (fetchedAbandonedCarts.Err) {
          setAbandonedCartList([]);
        }
        if (fetchedAbandonedCarts.Data) {
          setAbandonedCartList(
            fetchedAbandonedCarts.Data.carts.sort((a, b) => b.Created - a.Created)
          );
          setCanFetchMore(fetchedAbandonedCarts.Data.canFetchMore);
          setNextCursor(fetchedAbandonedCarts.Data.next);
          setPrevCursor(fetchedAbandonedCarts.Data.prev);
        }
      }
    };
    fetchAbandonedCarts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sellerDetails, goBack, page]);

  const handlePageChange = (backPress: boolean) => {
    if (backPress) {
      prevPage();
    } else {
      nextPage();
    }
    setGoBack(backPress);
  };

  function renderCart(_cart: AbandonedCartList) {
    return (
      <tr key={_cart.Id} onClick={() => navigate(`/orders/abandoned-carts/${_cart.Id}`)}>
        <td className="px-6 py-4 whitespace-nowrap">
          <div className="text-sm font-medium text-gray-900">
            <p>{_cart.BuyerEmail}</p>
            <p>{_cart.BuyerPhone}</p>
          </div>
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
          <div className="text-sm text-gray-900">{_cart.BuyerName}</div>
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
          <div className="text-sm text-gray-900">
            {new Date(_cart.Created * 1000).toDateString()}
          </div>
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
          <div className="text-sm text-gray-900">
            {_cart.TotalItems} item{_cart.TotalItems !== 1 ? "s" : ""}{" "}
          </div>
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
          <div className="text-sm text-gray-900">
            {localeDetails.currencySymbol}
            {_cart.Total}
          </div>
        </td>
      </tr>
    );
  }

  return (
    <div className="my-4 sm:px-6 lg:px-8">
      <div>
        <h2 className="text-3xl py-2 font-light leading-8 text-gray-900 sm:text-4xl sm:truncate">
          Abandoned Carts
        </h2>
      </div>
      <div className="flex flex-col space-y-4">
        <div className="flex space-x-4">
          <input
            className="border-2 border-gray-300 bg-white w-full h-10 px-5 pr-16 rounded-lg text-sm focus:indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            type="search"
            name="search"
            placeholder="Search abandoned checkouts"
          ></input>
          <button
            type="button"
            className="items-center w-32 px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-primary hover:bg-opacity-95 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Sort
          </button>
        </div>
        <div className="flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Customer ID
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Customer Name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Date
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        # Of Items
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Amount
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {abandonedCartList.map((cart: AbandonedCartList) => renderCart(cart))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full my-2 mt-6 flex flex-row justify-center items-center">
          <PagingControls
            nextPage={canFetchMore ? () => handlePageChange(false) : null}
            previousPage={prevCursor == null || !canGoBack ? null : () => handlePageChange(true)}
          />
        </div>
      </div>
    </div>
  );
}

export default AbandonedCheckouts;
