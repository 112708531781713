import React from "react";
import FlexibleImage from "../../../../../../components/FlexibleImage";
// import FlexibleImage from "../../../../../components/FlexibleImage";
import { ProductListResponse } from "../../../../../../lib/types";
// import { mediaUrl2ImageRes } from "../../../../../lib/utils";

type Props = {
  products: ProductListResponse[];
  selectedProducts: string[];
  setSelectedProducts: (selectedProducts: string[]) => void;
  currencySymbol: string;
};

const ProductGrid: React.FC<Props> = React.memo(
  ({ products, selectedProducts, setSelectedProducts, currencySymbol = "₹" }) => {
    return (
      <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
        {products.map((product: ProductListResponse, index: number) => {
          const isInCategory = selectedProducts.indexOf(product.Sku) !== -1;
          const toggleSelectProduct = (e: React.ChangeEvent<HTMLInputElement>) => {
            if (e.target.checked) {
              if (selectedProducts.indexOf(product.Sku) === -1) {
                setSelectedProducts([...selectedProducts, product.Sku]);
              }
            } else {
              if (selectedProducts.indexOf(product.Sku) !== -1) {
                setSelectedProducts(selectedProducts.filter((sku) => sku !== product.Sku));
              }
            }
          };
          return (
            <li
              key={index}
              className="col-span-1 bg-white rounded-lg border border-gray-100 border-solid flex w-full "
            >
              <label
                htmlFor={"x-checkbox-" + product.Sku}
                className="font-medium text-gray-700 px-2 py-2 w-full flex items-center"
              >
                <input
                  id={"x-checkbox-" + product.Sku}
                  type="checkbox"
                  checked={isInCategory}
                  onChange={toggleSelectProduct}
                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                />
                <FlexibleImage
                  className="w-20 aspect-square ml-4"
                  alt={product.Name}
                  img={product.Image}
                  key={index}
                  loading="lazy"
                />
                <div className="ml-4 flex-1 truncate">
                  <div className="flex items-center space-x-3">
                    <h3 className="text-gray-900 text-sm font-medium truncate">{product.Name}</h3>
                  </div>
                  <p className="mt-1 text-gray-500 text-sm truncate">{`${product.TotalStocks} in ${
                    product.VariantsCount
                  } ${product.VariantsCount! > 1 ? "variants" : "variant"}`}</p>
                  <p className="mt-2 text-gray-800 font-semibold text-sm truncate">
                    {currencySymbol}
                    {product.Price}
                  </p>
                </div>
              </label>
            </li>
          );
        })}
      </ul>
    );
  }
);

export default ProductGrid;
