import React, { useState, useContext, useEffect } from "react";
import { SubmitHandler, useForm, useWatch } from "react-hook-form";
import FormInput from "../../../components/FormInput";
import { withTestId } from "../../../lib/utils";
import { AuthContext } from "../../../lib/services/auth";
import { FiLoader } from "react-icons/fi";

export type CodSettingsField = {
  codCharge: number;
  codFreeShippingAbove: number | null;
  codUpperLimit: number | null;
  codLowerLimit: number | null;
};

const CodSettingsForm = React.memo(function ShippingChargesForm() {
  const { sellerDetails, refreshAndReturnFirebaseToken, updateSellerDetails, fetchSellerDetails } =
    useContext(AuthContext);
  const [shippingCharges, setShippingCharges] = useState<CodSettingsField>({
    codCharge: sellerDetails?.Shipping.CodCharge ?? 0,
    codFreeShippingAbove: sellerDetails?.Shipping.CodFreeShippingAbove ?? 0,
    codLowerLimit: sellerDetails?.Shipping.CodLowerLimit ?? 0,
    codUpperLimit: sellerDetails?.Shipping.hasOwnProperty("CodUpperLimit")
      ? sellerDetails?.Shipping.CodUpperLimit ?? 0
      : sellerDetails?.Shipping.FreeShippingAbove ?? 0,
  });
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useForm<CodSettingsField>({
    defaultValues: shippingCharges ?? undefined,
  });
  const [isShippingChargesUpdated, setIsShippingChargesUpdated] = useState<boolean>(false);
  const [isUpdatingShippingCharges, setIsUpdatingShippingCharges] = useState<boolean>(false);

  const [isCOD, setIsCOD] = useState<boolean>(
    sellerDetails?.Shipping.CodCharge
      ? sellerDetails?.Shipping.CodCharge * 100 !== -1
        ? true
        : false
      : false
  );

  const [isCODFreeShipping, setIsCODFreeShipping] = useState(
    sellerDetails?.Shipping
      ? (sellerDetails?.Shipping.CodFreeShippingAbove == sellerDetails?.Shipping.CodUpperLimit &&
          sellerDetails?.Shipping.CodUpperLimit !== null) ||
        (sellerDetails?.Shipping.FreeShippingAbove &&
          !sellerDetails?.Shipping.hasOwnProperty("CodUpperLimit"))
        ? true
        : false
      : false
  );

  const codUpperLimit = useWatch({
    control,
    name: "codUpperLimit",
  });
  useEffect(() => {
    if (isCODFreeShipping) {
      setValue("codFreeShippingAbove", codUpperLimit);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codUpperLimit]);

  if (sellerDetails === null) {
    return <FiLoader className="animate-spin"></FiLoader>;
  }

  const submitShippingCharges: SubmitHandler<CodSettingsField> = async (values) => {
    setShippingCharges(values);
    const updatedShippingCharges = { Shipping: sellerDetails.Shipping };
    updatedShippingCharges.Shipping.CodCharge = isCOD
      ? values.codCharge == -1
        ? 0
        : +values.codCharge * 100
      : -1;
    updatedShippingCharges.Shipping.CodLowerLimit = values.codLowerLimit
      ? values.codLowerLimit * 100
      : null;
    updatedShippingCharges.Shipping.CodUpperLimit = values.codUpperLimit
      ? values.codUpperLimit * 100
      : null;
    updatedShippingCharges.Shipping.CodFreeShippingAbove = values.codFreeShippingAbove
      ? values.codFreeShippingAbove * 100
      : null;
    updatedShippingCharges.Shipping.CardCharge =
      updatedShippingCharges.Shipping.CardCharge == -1
        ? 0
        : updatedShippingCharges.Shipping.CardCharge * 100;
    updatedShippingCharges.Shipping.PrepaidFreeShippingAbove = updatedShippingCharges.Shipping
      .PrepaidFreeShippingAbove
      ? updatedShippingCharges.Shipping.PrepaidFreeShippingAbove * 100
      : null;
    updatedShippingCharges.Shipping.PrepaidLowerLimit = updatedShippingCharges.Shipping
      .PrepaidLowerLimit
      ? updatedShippingCharges.Shipping.PrepaidLowerLimit * 100
      : null;
    updatedShippingCharges.Shipping.PrepaidUpperLimit = updatedShippingCharges.Shipping
      .PrepaidUpperLimit
      ? updatedShippingCharges.Shipping.PrepaidUpperLimit * 100
      : null;

    const refreshedToken = await refreshAndReturnFirebaseToken();
    if (refreshedToken !== null) {
      setIsUpdatingShippingCharges(true);
      await updateSellerDetails(refreshedToken, updatedShippingCharges);
      setIsUpdatingShippingCharges(false);
      setIsShippingChargesUpdated(true);
      await fetchSellerDetails(refreshedToken);
    }
  };
  return (
    <div className="my-4 sm:px-6 lg:px-8">
      <div className="w-full flex flex-col items-center justify-between">
        <h6 className="text-xl font-medium my-2">COD Settings</h6>
        <form onSubmit={handleSubmit(submitShippingCharges)} className="w-1/2">
          <label
            htmlFor="allow-cod"
            className="mb-4 flex flex-row items-center justify-between w-full"
          >
            <p className="flex flex-col">
              <span className="font-medium text-sm">Allow COD</span>
              <span className="text-xs text-gray-600">Enable COD option on all your orders</span>
            </p>
            <input
              type="checkbox"
              id="allow-cod"
              checked={isCOD}
              onChange={(e) => {
                if (!isCOD) {
                  setValue("codCharge", 0);
                  setValue("codFreeShippingAbove", 0);
                  setValue("codLowerLimit", 0);
                  setValue("codUpperLimit", 0);
                }
                setIsCOD(e.target.checked);
              }}
              className="text-indigo-600 border-gray-300 rounded shadow-sm focus:border-indigo-300 focus:ring focus:ring-offset-0 focus:ring-indigo-200 focus:ring-opacity-50 "
            />
          </label>

          {isCOD ? (
            <>
              <FormInput
                {...withTestId("codCharge-input")}
                id="codCharge"
                placeholder="COD Charge"
                control={{
                  type: "number",
                  ...register("codCharge", {
                    required: true,
                    valueAsNumber: true,
                    validate: (value) => value > 0,
                  }),
                }}
                errMsg="Please enter a valid amount"
                isError={errors.codCharge != null}
              />
              <FormInput
                {...withTestId("codLowerLimit-input")}
                id="codLowerLimit"
                placeholder="Minimum Amount for COD Charges"
                control={{
                  type: "number",
                  ...register("codLowerLimit"),
                }}
                errMsg="Please enter a valid amount"
                isError={errors.codLowerLimit != null}
              />
              <FormInput
                {...withTestId("codUpperLimit-input")}
                id="codUpperLimit"
                placeholder="Maximum Amount for COD Charges"
                control={{
                  type: "number",
                  ...register("codUpperLimit"),
                }}
                errMsg="Please enter a valid amount"
                isError={errors.codUpperLimit != null}
              />
              <label
                htmlFor="allow-cod"
                className="mb-4 flex flex-row items-center justify-between w-full"
              >
                <p className="flex flex-col">
                  <span className="font-medium text-sm">
                    Allow orders even above max amount and give free shipping
                  </span>
                </p>
                <input
                  type="checkbox"
                  id="allow-cod"
                  checked={isCODFreeShipping}
                  onChange={(e) => {
                    setIsCODFreeShipping(e.target.checked);
                    if (!isCODFreeShipping)
                      setValue("codFreeShippingAbove", getValues().codUpperLimit);
                    else setValue("codFreeShippingAbove", null);
                  }}
                  className="text-indigo-600 border-gray-300 rounded shadow-sm focus:border-indigo-300 focus:ring focus:ring-offset-0 focus:ring-indigo-200 focus:ring-opacity-50 "
                />
              </label>
            </>
          ) : null}
          {isShippingChargesUpdated ? (
            <p className="px-4 py-2 my-4 text-green-500 bg-green-500 border border-green-500 border-solid rounded bg-opacity-5">
              Successfully updated COD settings!
            </p>
          ) : null}
          <button
            type="submit"
            disabled={Object.keys(errors).length > 0 || isUpdatingShippingCharges}
            className="w-full p-2 font-bold uppercase bg-indigo-700 rounded text-stone-200 disabled:bg-gray-400"
          >
            {isUpdatingShippingCharges ? "Saving..." : "Save COD Settings"}
          </button>
        </form>
      </div>
    </div>
  );
});

export default CodSettingsForm;
