import React, { useContext, useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { AuthContext } from "../lib/services/auth";
import { withTestId } from "../lib/utils";

import Auth from "../pages/Auth";
import Home from "../pages/Home";
import Loading from "../pages/Loading";
import Onboarding from "../pages/Onboarding";
import OrderDetails from "../pages/Orders/OrderDetails";
import Payments from "../pages/Customers-n-Payments/Payments";
import Products from "../pages/Products/Products";
import EditProduct from "../pages/Products/EditProduct";
import Orders from "../pages/Orders/Orders";
import AbandonedCheckouts from "../pages/AbandonedCheckouts";
import AddProduct from "../pages/Products/AddProduct";
import PersonalDetails from "../pages/AccountSettings/PersonalDetails";
import BankDetails from "../pages/AccountSettings/BankDetails";
import SocialMedia from "../pages/Marketing/SocialMedia";
import GSTDetails from "../pages/AccountSettings/GSTDetails";
import PaymentGatewayIntegration from "../pages/AccountSettings/PaymentGatewayIntegration";
import AboutUs from "../pages/AboutUs";
import Shipping from "../pages/Shipping/shipping-settings";
import Theme from "../pages/StoreSettings/Theme";
import StorePictures from "../pages/StoreSettings/StorePictures";
import Coupons from "../pages/StoreSettings/Coupons/Coupons";
import EditCoupon from "../pages/StoreSettings/Coupons/EditCoupon";
import AddCoupon from "../pages/StoreSettings/Coupons/AddCoupon";
import AnnouncementBar from "../pages/StoreSettings/AnnouncementBar";
import CustomizeCheckout from "../pages/StoreSettings/CustomizeCheckout";
import WhatsAppSupport from "../pages/StoreSettings/WhatsAppSupport";
import ReturnsPolicy from "../pages/StoreSettings/ReturnsPolicy";
import CustomerForm from "../pages/CustomerForm";
import Layout from "../Layout";
import Categories from "../pages/Categories/AllCategories/Categories";
import AddCategory from "../pages/Categories/AllCategories/AddCategory";
import EditCategory from "../pages/Categories/AllCategories/EditCategory";
import CategoryDetails from "../pages/Categories/AllCategories/CategoryDetails";
import ListVisitors from "../pages/Customers-n-Payments/Visitors/ListVisitors";
import AllOrders from "../pages/Orders/AllOrders";
import AbandonedCartDetails from "../pages/AbandonedCheckouts/AbandonedCartDetails/index";
import BulkUploadPage from "../pages/Products/BulkUpload";
import CodSettingsForm from "../pages/Shipping/cod-settings";
import PrepaidSettingsForm from "../pages/Shipping/prepaid-settings";
import CategoryCollections from "../pages/Categories/CategoryCollections/CategoryCollections";
import CategoryCollectionDetails from "../pages/Categories/CategoryCollections/CategoryCollectionDetails";
import PurchasePremium from "../components/PurchasePremium";
import ProductsSearch from "../pages/Products/ProductSearch";
import StorefrontTracking from "../pages/StoreSettings/StorefrontTracking";
import { PlanTypes } from "../lib/types";

function Pages() {
  const { isLoading, isLoggedIn, user, sellerDetails } = useContext(AuthContext);
  useEffect(() => {}, [user, isLoading, isLoggedIn]);

  return isLoading ? (
    <Loading />
  ) : isLoggedIn && user ? (
    sellerDetails ? (
      sellerDetails.IsPremium && sellerDetails.PlanType == PlanTypes.PREMIUM ? (
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/products" element={<Products />} />
            <Route path="/products/search" element={<ProductsSearch />} />
            <Route path="/products/bulk-upload" element={<BulkUploadPage />} />
            <Route path="/products/:productSlug" element={<EditProduct />} />
            <Route path="/products/add-product" element={<AddProduct />} />
            <Route path="/orders/orders" element={<Orders />} />
            <Route path="/orders/all-orders" element={<AllOrders />} />
            <Route path="/orders/orders/:orderId" element={<OrderDetails />} />
            <Route path="/payments" element={<Payments />} />
            <Route path="/orders/abandoned-carts" element={<AbandonedCheckouts />} />
            <Route path="/orders/abandoned-carts/:cartId" element={<AbandonedCartDetails />} />
            <Route path="/account/personal-details" element={<PersonalDetails />} />
            <Route path="/account/bank-details" element={<BankDetails />} />
            <Route path="/account/integrate-stripe" element={<PaymentGatewayIntegration />} />
            <Route path="/account/gst-details" element={<GSTDetails />} />
            <Route path="/marketing/social-media" element={<SocialMedia />} />
            <Route path="/store/about-us" element={<AboutUs />} />
            <Route path="/shipping/shipping-settings" element={<Shipping />} />
            <Route path="/shipping/cod-settings" element={<CodSettingsForm />} />
            <Route path="/shipping/prepaid-settings" element={<PrepaidSettingsForm />} />
            <Route path="/store/theme" element={<Theme />} />
            <Route path="/store/store-pictures" element={<StorePictures />} />
            <Route path="/store/announcement-bar" element={<AnnouncementBar />} />
            <Route path="/store/customize-checkout" element={<CustomizeCheckout />} />
            <Route path="/store/whatsapp-support" element={<WhatsAppSupport />} />
            <Route path="/store/returns-policy" element={<ReturnsPolicy />} />
            <Route path="/store/coupons" element={<Coupons />} />
            <Route path="/store/coupons/new" element={<AddCoupon />} />
            <Route path="/store/coupons/:couponId/edit" element={<EditCoupon />} />
            <Route path="/store/customer-form" element={<CustomerForm />} />
            <Route path="/store/visitors" element={<ListVisitors />} />
            <Route path="/store/tracking" element={<StorefrontTracking />} />
            <Route path="/categories" element={<Categories />} />
            <Route path="/categories/:categorySlug" element={<CategoryDetails />} />
            <Route path="/categories/:categorySlug/edit" element={<EditCategory />} />
            <Route path="/categories/add-category" element={<AddCategory />} />
            <Route path="/category-collections" element={<CategoryCollections />} />
            <Route
              path="/category-collections/:categoryCollectionSlug"
              element={<CategoryCollectionDetails />}
            />
          </Routes>
        </Layout>
      ) : (
        <PurchasePremium />
      )
    ) : null
  ) : (
    <Routes>
      <Route path="/" element={<Auth />} />
      <Route path="/getting-started" element={<Onboarding {...withTestId("onboarding-page")} />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}

export default Pages;
