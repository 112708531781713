import React, { useContext, useState, useEffect } from "react";
import countryCodes from "../../../lib/constant/countryCodes";
import { AuthContext } from "../../../lib/services/auth";
import { FiLoader } from "react-icons/fi";
import { NotificationContext } from "../../../lib/services/notification";
import { Switch } from "@headlessui/react";
import { classNames, withTestId } from "../../../lib/utils";
import FormInput from "../../../components/FormInput";

const WhatsAppSupport = () => {
  const { sellerDetails, updateSellerDetails, refreshAndReturnFirebaseToken } =
    useContext(AuthContext);
  const { addNotification } = useContext(NotificationContext);

  const [isUpdatingWhatsAppSupportDetails, setIsUpdatingWhatsAppSupportDetails] = useState(false);

  useEffect(() => {}, [sellerDetails]);

  const [whatsappNumber, setWhatsappNumber] = useState<string>(
    sellerDetails?.WhatsappNumber
      ? sellerDetails?.WhatsappNumber.substring(sellerDetails?.WhatsappNumber.length - 10)
      : ""
  );
  const [showWhatsappButton, setShowWhatsappButton] = useState(
    sellerDetails?.WebsiteConfig.ShowWhatsAppBadge
      ? sellerDetails?.WebsiteConfig.ShowWhatsAppBadge
      : false
  );

  if (sellerDetails === null) {
    return <FiLoader className="animate-spin"></FiLoader>;
  }

  const countryCode = sellerDetails?.PhoneNumber.substring(
    0,
    sellerDetails?.PhoneNumber.length - 10
  );

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const websiteConfig = Object.assign({}, sellerDetails.WebsiteConfig);
    websiteConfig.ShowWhatsAppBadge = showWhatsappButton;
    const updatedSellerDetails = {
      WhatsappNumber: whatsappNumber,
      WebsiteConfig: websiteConfig,
    };

    setIsUpdatingWhatsAppSupportDetails(true);

    const refreshedToken = await refreshAndReturnFirebaseToken();
    if (refreshedToken !== null) {
      await updateSellerDetails(refreshedToken, updatedSellerDetails);
      addNotification(
        "success",
        "Successfully updated the whatsapp support details!",
        "StoreSettings"
      );
    }
    setIsUpdatingWhatsAppSupportDetails(false);
  };

  return (
    <div className="my-4 sm:px-6 lg:px-8">
      <div>
        <h1 className="text-3xl font-light leading-7 text-gray-900 my-2">WhatsApp Support</h1>
      </div>
      <form onSubmit={onSubmit} className="w-1/2 mx-auto">
        <div className="flex items-center justify-between mt-4">
          <div>
            <h3 className="font-medium">WhatsApp Button</h3>
            <p className="text-xs">
              Enabling this will let your customers contact you on WhatsApp directly from your
              website.
            </p>
          </div>
          <Switch.Group as="div" className="flex items-center justify-start">
            <Switch.Label as="span" className="text-sm font-medium text-gray-900 px-2" passive>
              No
            </Switch.Label>
            <Switch
              checked={showWhatsappButton}
              onChange={setShowWhatsappButton}
              className={classNames(
                showWhatsappButton ? "bg-primary" : "bg-gray-200",
                "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              )}
            >
              <span
                aria-hidden="true"
                className={classNames(
                  showWhatsappButton ? "translate-x-5" : "translate-x-0",
                  "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                )}
              />
            </Switch>
            <Switch.Label as="span" className="text-sm font-medium text-gray-900 px-2" passive>
              Yes
            </Switch.Label>
          </Switch.Group>
        </div>
        <section className={`my-4 `}>
          <FormInput
            {...withTestId("whatsAppNumber-input")}
            id="whatsAppNumber"
            placeholder="WhatsApp Number"
            label="WhatsApp Number"
            control={{
              value: countryCode + whatsappNumber,
              type: "text",
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                const text = e.target.value;
                setWhatsappNumber(
                  text
                    .replace(countryCode, "")
                    .replace(countryCode.slice(0, countryCode.length - 1), "") || ""
                );
              },
            }}
            errMsg="Please enter a valid WhatsApp Number"
            isError={
              !countryCodes[sellerDetails.LocaleDetails.CountryCode || "IN"].phoneLength.includes(
                whatsappNumber.length
              )
            }
          />
        </section>
        <button
          type="submit"
          disabled={isUpdatingWhatsAppSupportDetails}
          className="uppercase bg-primary text-stone-200 font-bold p-2 w-full rounded disabled:bg-gray-400"
        >
          {isUpdatingWhatsAppSupportDetails ? "Saving" : "Save"}
        </button>
      </form>
    </div>
  );
};

export default WhatsAppSupport;
