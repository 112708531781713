import React from "react";
import { SubmitHandler, useForm, useWatch } from "react-hook-form";
import FormInput from "../../../../../components/FormInput";
import ImageInput from "../../../../../components/ImageInput";
import { User } from "../../../../../lib/types";
import { uploadImage, withTestId } from "../../../../../lib/utils";

export type GSTDetailsFields = {
  name: string;
  gstNumber: string;
  pan: string;
  gstImage: string;
  panImage: string;
};

type GSTDetailsProps = {
  initialValue: GSTDetailsFields;
  /** Called when the user fills in the form */
  user: User;
  next: SubmitHandler<GSTDetailsFields>;
} & TestingProps;

const SocialMediaLinksForm = React.memo((props: GSTDetailsProps) => {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<GSTDetailsFields>({
    defaultValues: props.initialValue ?? undefined,
  });

  const images = useWatch({
    name: ["gstImage", "panImage"],
    control: control,
  });

  return (
    <form onSubmit={handleSubmit(props.next)} className="w-1/2 flex flex-col justify-between">
      <FormInput
        {...withTestId("name-input")}
        id="name"
        placeholder="Name as printed on GST Certificate"
        control={{
          type: "text",
          ...register("name", {
            required: true,
            minLength: 2,
          }),
        }}
        errMsg={"Please enter a valid name"}
        isError={errors.name != null}
      />
      <FormInput
        {...withTestId("gstNumber-input")}
        id="gstNumber"
        placeholder="GST Number / GST ID"
        control={{
          type: "text",
          ...register("gstNumber", {
            required: true,
            maxLength: 15,
          }),
        }}
        errMsg="Please enter a valid GST Number / GST ID"
        isError={errors.gstNumber != null}
      />
      {images[0] && (
        <img src={getValues("gstImage")} className="w-full aspect-video object-cover" />
      )}
      <ImageInput
        control={{
          onChange: async (_event: React.ChangeEvent<HTMLInputElement>) => {
            if (_event.target.files?.length && props.user) {
              const imageRes = await uploadImage(_event.target.files[0], props.user.uid, true);
              if (imageRes) {
                setValue("gstImage", imageRes.MediaUrl1);
              } else {
                alert("Image upload failed");
              }
            }
          },
        }}
        id="gstImage"
        key="gstImage"
        isError={errors.gstImage != null}
        placeholder="GST Photo"
        errMsg="Please upload a photo of you GST certificate"
        ref={register("gstImage")}
      />
      <FormInput
        {...withTestId("pan-input")}
        id="pan"
        placeholder="PAN Number"
        control={{ type: "text", ...register("pan", { required: true, maxLength: 10 }) }}
        errMsg="Please enter a valid PAN Number"
        isError={errors.pan != null}
      />
      {images[1] && (
        <img src={getValues("panImage")} className="w-full aspect-video object-cover" />
      )}
      <ImageInput
        control={{
          onChange: async (_event: React.ChangeEvent<HTMLInputElement>) => {
            if (_event.target.files?.length && props.user) {
              const imageRes = await uploadImage(_event.target.files[0], props.user.uid, false);
              if (imageRes) {
                setValue("panImage", imageRes.MediaUrl1);
              } else {
                alert("Image upload failed");
              }
            }
          },
        }}
        id="panImage"
        key="panImage"
        isError={errors.gstImage != null}
        placeholder="PAN Card Photo"
        errMsg="Please upload a photo of you PAN card"
        ref={register("panImage")}
      />
      <button
        type="submit"
        disabled={Object.keys(errors).length > 0}
        className="uppercase bg-primary text-stone-200 font-bold p-2 w-full rounded disabled:bg-gray-400"
      >
        Save
      </button>
    </form>
  );
});

export default SocialMediaLinksForm;
