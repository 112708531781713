import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import FormInput from "../../../../../components/FormInput";
import { withTestId } from "../../../../../lib/utils";

export type UPIDetailsFields = {
  name: string;
  upi: string;
  address: string;
  email: string;
  pan: string | null;
};

type PersonalDetailsProps = {
  initialValue: UPIDetailsFields;
  /** Called when the user fills in the form */
  next: SubmitHandler<UPIDetailsFields>;
} & TestingProps;

const PersonalDetailsForm = React.memo(function PersonalDetailsForm(props: PersonalDetailsProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<UPIDetailsFields>({
    defaultValues: props.initialValue ?? undefined,
  });

  return (
    <form onSubmit={handleSubmit(props.next)} className="w-full">
      <FormInput
        {...withTestId("name-input")}
        id="name"
        placeholder="Account Holder Name"
        control={{ type: "text", ...register("name", { required: true, minLength: 2 }) }}
        errMsg={"Please enter the name of account holder."}
        isError={errors.name != null}
      />
      <FormInput
        {...withTestId("email-input")}
        id="email"
        placeholder="Email Id"
        control={{
          type: "email",
          ...register("email", {
            required: true,
            pattern:
              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          }),
        }}
        errMsg="Please enter a valid email"
        isError={errors.email != null}
      />
      <FormInput
        {...withTestId("upi-input")}
        id="upi"
        placeholder="UPI ID"
        control={{
          type: "text",
          ...register("upi", { required: true, pattern: /^[\w.-]+@[\w.-]+$/ }),
        }}
        errMsg="Please enter a valid upi id"
        isError={errors.upi != null}
      />
      <FormInput
        {...withTestId("address-input")}
        id="address"
        placeholder="Address"
        control={{ type: "text", ...register("address", { minLength: 5 }) }}
        errMsg="Please enter your address"
        isError={errors.address != null}
      />
      <FormInput
        {...withTestId("pan-input")}
        id="pan"
        placeholder="PAN Card Number (optional)"
        control={{ type: "text", ...register("pan", { minLength: 10, maxLength: 10 }) }}
        errMsg="Please enter a valid pan number"
        isError={errors.pan != null}
      />
      <button
        type="submit"
        disabled={Object.keys(errors).length > 0}
        className="uppercase bg-primary text-stone-200 font-bold p-2 w-full rounded disabled:bg-gray-400"
      >
        Save
      </button>
    </form>
  );
});

export default PersonalDetailsForm;
