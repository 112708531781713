import React from "react";
import { Order } from "../../../../../lib/types";

type Props = {
  customerDetails: Order["BuyerDetails"];
};

const CustomerDetails = React.memo(function (props: Props) {
  return (
    <div className="w-full flex flex-col my-4">
      <div className="w-full my-2">
        <div className="relative w-full">
          <div className="absolute inset-0 flex items-center" aria-hidden="true">
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center">
            <h6 className="px-2 bg-white text-gray-500 font-light text-lg">Customer Details</h6>
          </div>
        </div>
      </div>
      <div className="w-5/6 flex flex-row justify-between self-center">
        <div className="w-1/2 flex flex-col justify-center items-start h-full">
          <h6 className="text-base font-medium my-2 text-left">Contact</h6>
          <span className="font-light text-left">Name: {props.customerDetails.FullName}</span>
          <span className="font-light text-left">Email: {props.customerDetails.Email}</span>
          <span className="font-light text-left">Phone: {props.customerDetails.PhoneNumber}</span>
        </div>
        <div className="w-1/2 flex flex-col justify-center items-end h-full">
          <h6 className="text-base font-medium my-2">Address</h6>
          <span className="text-right font-light">
            {props.customerDetails.Address.Address1 ?? ""},{" "}
            {props.customerDetails.Address.City ?? ""}, {props.customerDetails.Address.State ?? ""},{" "}
            {props.customerDetails.Address.Pincode ?? ""}
          </span>
          {/* <span className="text-right">City: {props.customerDetails.Address.City ?? ""}</span>
          <span className="text-right">State: {props.customerDetails.Address.State ?? ""}</span>
          <span className="text-right">Pincode: {props.customerDetails.Address.Pincode ?? ""}</span> */}
        </div>
      </div>
    </div>
  );
});

export default CustomerDetails;
