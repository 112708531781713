import React, { useState } from "react";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/outline";

const VariantsContent = ({
  colorVariants,
  removeColorVariantOption,
  addColorOption,
  sizeVariants,
  removeSizeVariantOption,
  addSizeOption,
}: {
  colorVariants: string[];
  removeColorVariantOption: (_option: string) => void;
  addColorOption: (_val: string) => void;
  sizeVariants: string[];
  removeSizeVariantOption: (_option: string) => void;
  addSizeOption: (_val: string) => void;
  generateVariantsData: () => void;
}) => {
  const [tempColorVariant, setTempColorVariant] = useState("");
  const [tempSizeVariant, setTempSizeVariant] = useState("");

  function changeTempColorVariant(_event: React.ChangeEvent<HTMLInputElement>) {
    setTempColorVariant(_event.target.value);
  }

  function changeTempSizeVariant(_event: React.ChangeEvent<HTMLInputElement>) {
    setTempSizeVariant(_event.target.value);
  }

  function sendAddColorOption() {
    if (tempColorVariant != "") {
      addColorOption(tempColorVariant);
      setTempColorVariant("");
    }
  }

  function sendAddSizeOption() {
    if (tempSizeVariant != "") {
      addSizeOption(tempSizeVariant);
      setTempSizeVariant("");
    }
  }

  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <dl className="space-y-6 divide-y divide-gray-200">
      <Disclosure as="div" className="pt-2">
        {({ open }) => (
          <>
            <dt className="text-lg">
              <Disclosure.Button className="text-left w-full flex justify-between items-start bg-gray-200 p-3">
                <span className="font-semibold text-gray-900">Edit Variants</span>
                <span className="ml-6 h-7 flex items-center">
                  <ChevronDownIcon
                    className={classNames(open ? "-rotate-180" : "rotate-0", "h-6 w-6 transform")}
                    aria-hidden="true"
                  />
                </span>
              </Disclosure.Button>
            </dt>
            <Disclosure.Panel as="dd" className="">
              <div className="border-solid border-2 border-t-0 pb-4 mx-auto px-4 sm:px-6 lg:px-8">
                <div className="mx-auto">
                  <div className="p-3">
                    <h4 className="text-md leading-6 font-bold text-gray-900">Colors</h4>
                    {colorVariants.map((_c: string) => (
                      <span
                        key={_c}
                        className="inline-flex rounded-full items-center py-0.5 pl-2.5 pr-1 text-sm font-medium bg-indigo-100 text-primary mr-3 mt-2"
                      >
                        {_c}
                        <button
                          type="button"
                          key={_c}
                          onClick={() => removeColorVariantOption(_c)}
                          className="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500 focus:outline-none focus:bg-indigo-500 focus:text-white"
                        >
                          <span className="sr-only">Remove option</span>
                          <svg
                            className="h-2 w-2"
                            stroke="currentColor"
                            fill="none"
                            viewBox="0 0 8 8"
                          >
                            <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                          </svg>
                        </button>
                      </span>
                    ))}
                    <div className="p-3 pt-0">
                      <div className="flex pt-5 w-full">
                        <div className="mt-1 sm:mt-0 flex items-center w-full">
                          <input
                            type="text"
                            name="first-name"
                            id="first-name"
                            autoComplete="given-name"
                            className="block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                            value={tempColorVariant}
                            onChange={changeTempColorVariant}
                            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                              if (e.key === "Enter" || e.key === ",") {
                                e.preventDefault();
                                sendAddColorOption();
                              }
                            }}
                          />
                          <button
                            type="button"
                            onClick={sendAddColorOption}
                            className="ml-3 inline-flex items-center px-2.5 py-1.5 border border-transparent text-sm font-bold rounded text-primary bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          >
                            Add
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="p-3">
                    <h4 className="text-md leading-6 font-bold text-gray-900">Sizes</h4>
                    {sizeVariants.map((_c: string) => (
                      <span
                        key={_c}
                        className="inline-flex rounded-full items-center py-0.5 pl-2.5 pr-1 text-sm font-medium bg-indigo-100 text-primary mr-3 mt-2"
                      >
                        {_c}
                        <button
                          type="button"
                          onClick={() => removeSizeVariantOption(_c)}
                          className="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500 focus:outline-none focus:bg-indigo-500 focus:text-white"
                        >
                          <span className="sr-only">Remove option</span>
                          <svg
                            className="h-2 w-2"
                            stroke="currentColor"
                            fill="none"
                            viewBox="0 0 8 8"
                          >
                            <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                          </svg>
                        </button>
                      </span>
                    ))}
                    <div className="p-3 pt-0">
                      <div className="flex pt-5 w-full">
                        <div className="mt-1 sm:mt-0 flex items-center w-full">
                          <input
                            type="text"
                            name="first-name"
                            id="first-name"
                            autoComplete="given-name"
                            className="block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                            value={tempSizeVariant}
                            onChange={changeTempSizeVariant}
                            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                              if (e.key === "Enter" || e.key === ",") {
                                e.preventDefault();
                                sendAddSizeOption();
                              }
                            }}
                          />
                          <button
                            type="button"
                            onClick={sendAddSizeOption}
                            className="ml-3 inline-flex items-center px-2.5 py-1.5 border border-transparent text-sm font-bold rounded text-primary bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          >
                            Add
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </dl>
  );
};

export default VariantsContent;
