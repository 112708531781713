import React from "react";

import { CategoryCollectionListResponse } from "../../../../../../lib/types";
import CategoryTableRow from "../CategoryCollectionTableRow";

type Props = {
  deleteCategoryCollection: (slug: string) => void;
  categoryCollections: CategoryCollectionListResponse[];
};

const CategoryCollectionTable = (props: Props) => {
  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-100">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Category
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider text-right"
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {props.categoryCollections.map((category) => (
                  <CategoryTableRow
                    categoryCollection={category}
                    deleteCategoryCollection={props.deleteCategoryCollection}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryCollectionTable;
