import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { resizedUrl, slugify } from "../../../../lib/utils";
import { AuthContext } from "../../../../lib/services/auth";
import { ApiContext } from "../../../../lib/services/api";
import type { CreateCategoryRequest } from "../../../../lib/types";

// import ProductList from "./components/ProductList";
import AddCategoryBanners from "./components/AddCategoryBanners";

function AddCategory() {
  const apiCtx = useContext(ApiContext);
  const { sellerDetails, user, refreshAndReturnFirebaseToken } = useContext(AuthContext);
  const { createCategory } = useContext(ApiContext);
  const navigate = useNavigate();

  const [category, setCategory] = useState<CreateCategoryRequest>({
    Name: "",
    NProducts: 0,
    Slug: "",
    Banners: [],
    HistoricalSlugs: [],
    Announcement: null,
    IsVisibleOnHome: true,
    Parents: [],
  });

  useEffect(() => {
    (async () => {
      // Don't refetch

      const refreshedToken = await refreshAndReturnFirebaseToken(false);

      if (!refreshedToken) {
        alert("Please sign in");
        location.reload();
      }
    })();
  }, [apiCtx, refreshAndReturnFirebaseToken]);

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const newCategory = Object.assign({}, category);

    if (newCategory.Name.length < 3) {
      alert("Please enter a category name that is greater than 3 characters");
      return;
    }
    newCategory.Slug = slugify(newCategory.Name.trim());
    newCategory.HistoricalSlugs = [newCategory.Slug];

    const refreshedToken = await refreshAndReturnFirebaseToken();
    if (refreshedToken != null) {
      newCategory.Banners = newCategory.Banners.map((banner) => {
        if (!banner.Media.MediaUrl1.includes("_800x800.webp")) {
          banner.Media.MediaUrl1 = resizedUrl(banner.Media.MediaUrl1, ".webp");
        }
        if (!banner.Media.MediaUrl1.includes("_800x800.jpg")) {
          banner.Media.MediaUrl1 = resizedUrl(banner.Media.MediaUrl1, ".jpg");
        }

        return banner;
      });

      const result = await createCategory(refreshedToken, newCategory);
      if (result.Err) {
        alert(result.Err.message);
      } else {
        navigate(-1);
      }
    }
  };

  if (sellerDetails == null || user == null) {
    return <div>Please Login</div>;
  }

  return (
    <div className="my-4 sm:px-6 lg:px-8">
      <div>
        <h2 className="text-3xl py-2 font-light leading-8 text-gray-900 sm:text-4xl sm:truncate">
          Add Category
        </h2>
      </div>
      <form>
        <div className="grid grid-cols-3 mt-10 sm:mt-0">
          <div className="col-span-1">
            <AddCategoryBanners
              banners={category.Banners}
              user={user!}
              onBannerChange={(newBanners) =>
                setCategory((old) => ({ ...old, Banners: newBanners }))
              }
            />
          </div>
          <div className="col-span-2">
            <div className="shadow sm:rounded-md">
              <div className="px-4 py-5 bg-white sm:p-6">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6">
                    <label
                      htmlFor="product-name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Category Name
                    </label>
                    <input
                      type="text"
                      name="Name"
                      id="Name"
                      autoComplete="Name"
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      value={category.Name}
                      onChange={(e) => setCategory((old) => ({ ...old, Name: e.target.value }))}
                    />
                  </div>
                </div>
              </div>
              <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                <button
                  onClick={handleSubmit}
                  className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-opacity-95 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default AddCategory;
