import React, { useContext, useEffect, useState } from "react";
import { jsPDF } from "jspdf";

import AcceptOrderComponent from "./components/AcceptOrderComponent";
import ProductList from "./components/ProductList";
import OrderValue from "./components/OrderValue";
import CustomerDetails from "./components/CustomerDetails";
import { AuthContext } from "../../../lib/services/auth";
import { ApiContext } from "../../../lib/services/api";
import { useParams } from "react-router-dom";
import { Order, OrderStage } from "../../../lib/types";
import { FiLoader } from "react-icons/fi";
import { getDate } from "../../../lib/utils";
import { DownloadIcon } from "@heroicons/react/outline";

export default function OrderDetails() {
  const { orderId } = useParams();
  const { sellerDetails, refreshAndReturnFirebaseToken, localeDetails } = useContext(AuthContext);
  const { getOrder, updateOrderStatus } = useContext(ApiContext);

  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState<Order>({} as Order);
  const [itemTotal, setItemTotal] = useState<number>(0);

  useEffect(() => {
    const fetchOrder = async () => {
      if (orderId) {
        const refreshedToken = await refreshAndReturnFirebaseToken();
        if (refreshedToken) {
          const fetchedOrder = await getOrder(refreshedToken, orderId);
          if (fetchedOrder.Data) {
            setOrder(fetchedOrder.Data);
            setItemTotal(fetchedOrder.Data.Items.reduce((a, b) => a + b.Price * b.Quantity, 0));
          }
          setLoading(false);
        }
      }
    };
    fetchOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sellerDetails]);

  const updateOrderStatusWithUrl = async (updatedDetails: {
    orderStatus: OrderStage;
    trackingUrl?: string;
  }) => {
    const refreshedToken = await refreshAndReturnFirebaseToken();
    if (refreshedToken) {
      if (updatedDetails.orderStatus === "shipped") {
        await updateOrderStatus(refreshedToken, {
          orderId: order.HumanId!,
          orderStatus: updatedDetails.orderStatus,
          trackingUrl: updatedDetails.trackingUrl,
        })
          .then((result) => {
            if (result.Data) {
              setOrder({ ...order, Status: result.Data.Status });
            } else {
              alert("Order could not be updated. Please try again.");
            }
          })
          .catch(() => alert("Order could not be updated. Please try again."));
      } else {
        await updateOrderStatus(refreshedToken, {
          orderId: order.HumanId!,
          orderStatus: updatedDetails.orderStatus,
        })
          .then((result) => {
            if (result.Data) {
              setOrder({ ...order, Status: result.Data.Status });
            } else {
              alert("Order could not be updated. Please try again.");
            }
          })
          .catch(() => alert("Order could not be updated. Please try again."));
      }
    }
  };

  if (sellerDetails === null || order === null) {
    return <FiLoader className="animate-spin"></FiLoader>;
  }

  const generatePDF = () => {
    const doc = new jsPDF();
    let totalAmount = 0;
    let discountPrice = 0;
    for (let i = 0; i < order.Items.length; i++) {
      totalAmount = totalAmount + order.Items[i].Price * order.Items[i].Quantity;
    }
    const couponName =
      order.hasOwnProperty("Coupon") && order.Coupon?.Code ? order.Coupon?.Code : "None";
    discountPrice = order.ShippingCharge + totalAmount - order.Total;
    discountPrice = +discountPrice.toFixed(2);
    const d = "";
    let t = "";
    let gstNo = "";
    for (let i = 0; i < order.Items.length; i++) {
      t =
        t +
        `<tr>
        <td colspan="4">
            <table>
                <td style="width: 12%;">
                    ${i + 1}
                </td>
                <td style="text-align:left;width: 40%;">
                    ${order.Items[i].Name}
                </td>
                <td style="width: 25%;text-align: center;">
                    ${order.Items[i].Quantity}
                </td>
                <td style="text-align:right;width: 23%;">
                    ${localeDetails.currencySymbol}${order.Items[i].Price * order.Items[i].Quantity}
                </td>

            </table>

        </td>
    </tr>`;
    }
    const shippingCharge =
      order.ShippingCharge !== 0
        ? `${localeDetails.currencySymbol}${order.ShippingCharge}`
        : "Free";
    const paymentMethod = order.DeliveryMode === "cod" ? "COD" : "Prepaid";
    if (sellerDetails.GstDetails && sellerDetails.GstDetails.Id) {
      gstNo = "<br> " + "<strong>" + "GSTIN No. " + "</strong>" + sellerDetails.GstDetails?.Id;
    }
    const html = `
        <html>
            <head>
                <meta charset="utf-8">
                <link rel="preconnect" href="https://fonts.googleapis.com">
                <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
                <link href="https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700;800&display=swap"
                  rel="stylesheet">
                <title>Invoice</title>
                <style>
                    html {
                      font-size: 16px;
                    }
                    .invoice-box {
                        max-width: 960px;
                        margin: 0;
                        padding: 30px;
                        border: 1px solid #eee;
                        box-shadow: 0 0 10px rgba(0, 0, 0, .15);
                        font-size: 16px;
                        line-height: 24px;
                        font-family: "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
                        color: #555;
                    }

                    .invoice-box table {
                        width: 100%;
                        line-height: inherit;
                        text-align: left;
                    }

                    .invoice-box table td {
                        padding: 5px;
                        vertical-align: top;
                    }

                    .invoice-box table tr td:nth-child(2) {
                        text-align: right;
                    }

                    .invoice-box table tr.top table td {
                        padding-bottom: 20px;
                    }

                    .invoice-box table tr.top table td.title {
                        font-size: 45px;
                        line-height: 45px;
                        color: #333;
                    }

                    .invoice-box table tr.information table td {
                        padding-bottom: 40px;
                    }

                    .invoice-box table tr.heading td {
                        background: #eee;
                        border-bottom: 1px solid #ddd;
                        font-weight: bold;
                    }

                    .invoice-box table tr.details td {
                        padding-bottom: 20px;
                    }

                    .invoice-box table tr.item td {
                        border-bottom: 1px solid #eee;
                    }

                    .invoice-box table tr.item.last td {
                        border-bottom: none;
                    }

                    .invoice-box table tr.total td:nth-child(2) {
                        border-top: 2px solid #eee;
                        font-weight: bold;
                    }

                    @media only screen and (max-width: 600px) {
                        .invoice-box table tr.top table td {
                            width: 100%;
                            display: block;
                            text-align: center;
                        }

                        .invoice-box table tr.information table td {
                            width: 100%;
                            display: block;
                            text-align: center;
                        }
                    }

                    .rtl {
                        direction: rtl;
                        font-family: Tahoma, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
                    }

                    .rtl table {
                        text-align: right;
                    }

                    .rtl table tr td:nth-child(2) {
                        text-align: left;
                    }
                </style>
            </head>

            <body>
                <div class="invoice-box">
                    <table cellpadding="0" cellspacing="0">
                        <tr class="top">
                            <td colspan="2">
                                <table>
                                    <tr>
                                        <td class="title">
                                            Invoice
                                        </td>
                                        <td>
                                            Order Id # ${order.HumanId} <br>
                                            Order Date: ${getDate(order.Created)} <br>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                        <tr class="information">
                            <td colspan="2">
                                <table>
                                    <tr>
                                        <td> <b>Sold By: </b><br>
                                            ${
                                              sellerDetails?.BusinessName
                                                ? sellerDetails.BusinessName
                                                : ""
                                            } <br>
                                            ${sellerDetails?.SellerName ? "C/o" : ""}
                                            ${
                                              sellerDetails?.SellerName
                                                ? sellerDetails.SellerName
                                                : ""
                                            }<br>
                                            ${
                                              sellerDetails?.Address.Address1
                                                ? sellerDetails.Address.Address1 + ","
                                                : ""
                                            }
                                            ${
                                              sellerDetails?.Address.Address2
                                                ? sellerDetails.Address.Address2 + ","
                                                : ""
                                            }
                                            ${
                                              sellerDetails?.Address.City
                                                ? sellerDetails.Address.City + ","
                                                : ""
                                            }
                                            ${
                                              sellerDetails?.Address.State
                                                ? sellerDetails.Address.State
                                                : ""
                                            } <br>
                                            <strong>${
                                              sellerDetails?.Address.Pincode ? "Pincode:" : ""
                                            } </strong>
                                            ${
                                              sellerDetails?.Address.Pincode
                                                ? sellerDetails.Address.Pincode
                                                : ""
                                            }<br>
                                            <strong>Phone Number: ${
                                              sellerDetails?.PhoneNumber
                                            }</strong>
                                            ${gstNo}
                                        </td>
                                        <td>
                                            <b>Shipping Address:</b> <br> ${
                                              order.BuyerDetails.FullName
                                            } <br>
                                            ${order.BuyerDetails.Address.Address1} <br>
                                            ${order.BuyerDetails.Address.City} ,
                                            ${order.BuyerDetails.Address.State} <br>
                                            ${
                                              order.BuyerDetails.Address.Pincode
                                            } <br> <strong>Ph No. </strong>
                                            ${
                                              order.BuyerDetails.PhoneNumber
                                            } <br> <strong>Email ID:</strong>
                                            ${order.BuyerDetails.Email}
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>


                        <tr style="background: #eee;
                        font-weight: bold;">
                            <td colspan="4">
                                <table>
                                    <td style="width: 12%;"><b>S.No.</b></td>
                                    <td style="text-align:left;width: 40%;"><b>Product Name</b></td>
                                    <td style="width:25% ;text-align: center;"><b>Quantity</b></td>
                                    <td style="text-align:right;width:23% ;"><b>Price </b></td>
                                </table>
                            </td>
                        </tr>
                        
                        ${t}
                    ${d}

                    <tr class="heading">
                        <td>
                            Payment Method
                        </td>
                        <td>
                            ${paymentMethod}
                        </td>
                    </tr>
                    <tr class="heading">
                        <td>
                            Shipping Charge
                        </td>
                        <td>
                            ${shippingCharge}
                        </td>
                    </tr>
                    <tr class="heading">
                        <td>
                            Coupon
                        </td>
                        <td>
                            ${couponName}
                        </td>
                    </tr>
                    <tr class="heading">
                        <td>
                            Discount
                        </td>
                        <td>
                            ${discountPrice}
                        </td>
                    </tr>
                
                    <tr class="total">
                        <td>Total</td>
                        <td>Total: ${localeDetails.currencySymbol}${order.Total} </td>
                    </tr>
                    </table>
                </div>
            </body>
        </html>
`;

    doc.setLanguage("en-US");
    doc.setFont("Manrope", "normal");
    doc.html(html, {
      callback: function (generatedDoc) {
        generatedDoc.save(`Invoice Order #${order.HumanId}`);
      },
      margin: 20,
      width: 200,
      x: 0,
      y: 0,
      windowWidth: 1100,
    });

    // It needs to be rendered in dom first to generate proper alignment
    const div = document.createElement("div");
    div.innerHTML = html;
    // div.classList.add("sr-only");
    document.body.append(div);
    window.setTimeout(() => {
      // div.remove();
    }, 2000);
    // document.body.innerHTML = html;
  };

  return (
    <div className="my-4 sm:px-6 lg:px-44">
      {!loading && (
        <>
          <AcceptOrderComponent
            orderId={order.HumanId}
            orderStatus={order.Status}
            updateOrderStatusWithUrl={updateOrderStatusWithUrl}
            orderCreatedAt={order.Created}
          />
          <button
            className="flex items-center py-1 px-2 text-blue-500 border-blue-500 border boder-solid rounded-md focus:ring-2 outline-none focus:ring-opacity-50 focus:ring-blue-500"
            onClick={generatePDF}
          >
            <DownloadIcon className="mr-1 h-4 w-4" />
            Download Invoice
          </button>
          <ProductList orderItems={order.Items} currencySymbol={localeDetails.currencySymbol} />
          <OrderValue
            couponDetails={order.Coupon}
            customRequirements={null}
            itemTotal={itemTotal}
            paymentCharge={order.PaymentCharge}
            shippingCharge={order.ShippingCharge}
            total={order.Total}
            currencySymbol={localeDetails.currencySymbol}
          />
          <CustomerDetails customerDetails={order.BuyerDetails} />
        </>
      )}
    </div>
  );
}
