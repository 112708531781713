import React, { useState } from "react";
import AddTrackingLinkModal from "./AddTrackingLinkModal";
import { Order, OrderStage } from "../../../../../lib/types";
import { getDate } from "../../../../../lib/utils";

type Props = {
  orderId: string;
  orderStatus: string | undefined;
  orderCreatedAt: number;
  updateOrderStatusWithUrl: (updatedDetails: {
    orderStatus: OrderStage;
    trackingUrl?: string;
  }) => void;
};

enum OrderStatusType {
  "pending" = "Pending",
  "accepted" = "Accepted",
  "shipped" = "Shipped",
  "delivered" = "Delivered",
  "pickup_ready" = "Pickup Ready",
  "rejected" = "Rejected",
  "cancelled" = "Cancelled",
  "returned" = "Returned",
  "ndr" = "NDR",
  "rto" = "RTO",
}
enum OrderStatusTypeReverse {
  "Pending" = "pending",
  "Accepted" = "accepted",
  "Shipped" = "shipped",
  "Delivered" = "delivered",
  "Pickup Ready" = "pickup_ready",
  "Rejected" = "rejected",
  "Cancelled" = "cancelled",
  "Returned" = "returned",
  "NDR" = "ndr",
  "RTO" = "rto",
}

const options: string[] = ["pending", "shipped", "delivered", "cancelled"];

export default function OrderStatus(props: Props) {
  const [orderStatus, setOrderStatus] = useState(
    props.orderStatus != null
      ? OrderStatusTypeReverse[props.orderStatus as Exclude<Order["Status"], undefined>]
      : "pending"
  );

  const [isOpen, setIsOpen] = useState(false);

  const handleOrderStatusChange = (value: OrderStage) => {
    if (value === "shipped") {
      setIsOpen(true);
      return;
    }
    props.updateOrderStatusWithUrl({ orderStatus: value });
    setOrderStatus(value);
  };
  const updateTrackingLink = (trackinglink: string) => {
    props.updateOrderStatusWithUrl({ orderStatus: "shipped", trackingUrl: trackinglink });
    setIsOpen(false);
    setOrderStatus("Shipped");
  };

  return (
    <div className="my-2 mb-8 md:flex md:items-center md:justify-between">
      <div className="flex-1 min-w-0">
        <h2 className="text-3xl py-2 font-light leading-7 text-gray-900 sm:text-3xl sm:truncate">
          Order #{props.orderId}
        </h2>
        <p>Placed on: {getDate(props.orderCreatedAt)}</p>
      </div>
      <div className="flex  flex-col mt-4 md:mt-0 md:ml-4">
        <label>Update Order Status</label>
        <select
          value={orderStatus}
          onChange={(e) => handleOrderStatusChange(e.target.value as OrderStage)}
          className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        >
          {options.map((option) => (
            <option value={option}>{OrderStatusType[option as OrderStage]}</option>
          ))}
        </select>
      </div>
      <AddTrackingLinkModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        updateTrackingLink={updateTrackingLink}
      />
    </div>
  );
}
