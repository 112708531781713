import { useEffect } from "react";
import {
  DetailedProductVariant,
  MediaUrl,
  ParsedProductRecordFields,
  ParsedUniqueProductFields,
} from "./types";

import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { parse } from "csv-parse/browser/esm";
import { storage } from "./firebase";
import { v4 as uuidv4 } from "uuid";
import { customAlphabet } from "nanoid";

/**
 * Add a class to `document.body`. Used for styling the document using Tailwind
 */
export function useBodyClass(className: string | string[]) {
  useEffect(() => {
    if (document) {
      if (Array.isArray(className)) {
        className.map((c) => document.body.classList.add(c));
        return () => className.map((c) => document.body.classList.remove(c));
      }
      document.body.classList.add(className);
      return () => document.body.classList.remove(className);
    }
    return undefined;
  }, [className]);
}
export const cloudFunctionsUrl =
  process.env.NODE_ENV == "development"
    ? "https://asia-south1-instasell-prod.cloudfunctions.net"
    : "https://asia-south1-instasell-7f53f.cloudfunctions.net";

/**
 * Returns a react prop called data-testid with the given testId
 * ONLY when NODE_ENV is "test", otherwise it returns an empty object
 */
export function withTestId(testId: string) {
  if (process.env.NODE_ENV === "test") {
    return {
      "data-testid": testId,
    };
  }
  return {};
}

export const baseUrl =
  process.env.NODE_ENV == "development"
    ? "http://localhost:8080"
    : "https://main-api-x5vwqptybq-et.a.run.app";

export function processText(text: string) {
  return text.replace(/[^-_ a-zA-Z0-9]/gi, "").trim();
}

export function mediaUrl2ImageRes(
  m: MediaUrl,
  objectFitFallback: ImageRes["objectFit"] = "cover"
): ImageRes {
  return {
    webpUrl: m.MediaUrl1,
    jpgUrl: m.MediaUrl2,
    objectFit: m.ObjectFit ?? objectFitFallback,
  };
}

export const resizedUrl = (fileName: string, changedExtension: string, dimensions = "800x800") => {
  const extIndex = fileName.lastIndexOf(".");
  let ext = fileName.substring(extIndex);
  const params = ext.split("?")[1];
  ext = `${changedExtension}?${params}`;
  return `${fileName.substring(0, extIndex)}_${dimensions}${ext}`;
};

export async function uploadImage(
  file: object,
  userId: string,
  compressed = true
): Promise<MediaUrl | null> {
  const id = uuidv4();
  const storageRef = ref(storage, `${userId}/pic_${id}.jpg`);

  const uploadedContent = await uploadBytes(storageRef, file as Blob, {
    contentType: "image/jpeg",
    cacheControl: "public, max-age=31536000",
  })
    .then(async (snapshot) => {
      const url = await getDownloadURL(snapshot.ref);
      if (url) {
        if (compressed) {
          return {
            MediaUrl1: resizedUrl(url, ".webp"),
            MediaUrl2: resizedUrl(url, ".jpg"),
            ObjectFit: "cover",
          } as MediaUrl;
        }
        return {
          MediaUrl1: url,
          MediaUrl2: url,
          ObjectFit: "cover",
        } as MediaUrl;
      }
      return null;
    })
    .catch((err) => {
      alert(`Error uploading image ${err}`);
      return null;
    });
  return uploadedContent;
}

export const newVariantId = customAlphabet(
  "1234567890qwertyuiopasdfghjklzxcvbnmQWERTYUIOPASDFGHJKLZXCVBNM",
  6
);

export const validateSlug = new RegExp("^(?![d]+$)[a-z0-9]+(?:-[a-z0-9]+)*$");
export const urlRegex = /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/;

export function slugify(text: string) {
  return text
    .toLocaleLowerCase()
    .replace(/[^a-z0-9 -]/g, "")
    .replace(/[^a-z0-9]/g, "-");
}

export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}
export const getDate = (timestamp: number) => {
  const monthsArr = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  const date = new Date(timestamp * 1000);
  const year = date.getFullYear();
  const month = monthsArr[date.getMonth()];
  const day = date.getDate();
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = "0" + date.getMinutes();

  const fulldate = day + " " + month + " " + year + " " + hours + ":" + minutes.substr(-2);
  return fulldate;
};

export const extractUrlsFromString = (str: string) => {
  const regexp =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?!&//=]*)/gi;

  if (typeof str !== "string") {
    throw new TypeError(`The str argument should be a string, got ${typeof str}`);
  }
  if (str) {
    const urls = str.match(regexp);
    if (urls) {
      return urls.map((url) => (url[url.length - 1] === ")" ? url.slice(0, url.length - 1) : url));
    } else {
      return [];
    }
  } else {
    return [];
  }
};

export const parseRecordsFromCSV = (fileContents: string): Promise<ParsedUniqueProductFields[]> => {
  return new Promise((resolve) => {
    parse(
      fileContents,
      { columns: true, skip_records_with_error: true },
      (err, parsedRecords: ParsedProductRecordFields[]) => {
        if (err) return;
        const uniqueRecords = parsedRecords.reduce(
          (acc: Record<string, ParsedProductRecordFields[]>, record) => {
            if (!acc[record["Unique Identifier"]]) {
              acc[record["Unique Identifier"]] = [];
            }
            acc[record["Unique Identifier"]].push(record);
            return acc;
          },
          {}
        );
        const uniqueProductsList: ParsedUniqueProductFields[] = Object.keys(uniqueRecords).map(
          (uniqueIdentifier) => {
            const allRecordsWithSameIdentifier = uniqueRecords[uniqueIdentifier];
            const sizes = Array.from(
              new Set(
                allRecordsWithSameIdentifier
                  .map((record) => record.Size)
                  .filter((value) => Boolean(value))
              )
            );
            const pricing = sizes.map((size) => ({
              variantSize: size,
              variantPrice:
                Number(
                  allRecordsWithSameIdentifier
                    .filter((record) => record.Size === size)
                    .find((record) => Number(record.Price) > 0)?.Price
                ) || 0,
              variantStrikeOffPrice:
                Number(
                  allRecordsWithSameIdentifier
                    .filter((record) => record.Size === size)
                    .find((record) => Number(record["StrikeOff Price"]) > 0)?.["StrikeOff Price"]
                ) || 0,
            }));
            return {
              Sku: uuidv4(),
              ProductName: allRecordsWithSameIdentifier[0]["Product Name"],
              Description: allRecordsWithSameIdentifier[0].Description,
              Stock: Number(allRecordsWithSameIdentifier[0]?.Stock) || 0,
              Pricing:
                pricing.length > 0
                  ? pricing
                  : [
                      {
                        variantSize: null,
                        variantPrice:
                          Number(
                            allRecordsWithSameIdentifier.find((record) => Number(record.Price) > 0)
                              ?.Price
                          ) || 0,
                        variantStrikeOffPrice:
                          Number(
                            allRecordsWithSameIdentifier.find(
                              (record) => Number(record["StrikeOff Price"]) > 0
                            )?.["StrikeOff Price"]
                          ) || 0,
                      },
                    ],
              ProductImagesToUpload: allRecordsWithSameIdentifier
                .map((record) => extractUrlsFromString(record["Product Images"]))
                .filter((urls) => urls?.length)
                .flat(),
              ProductImages: [],
              Colors: Array.from(
                new Set(
                  allRecordsWithSameIdentifier
                    .map((record) => record.Color)
                    .filter((value) => Boolean(value))
                )
              ),
              Sizes: sizes,
              errors: [],
            };
          }
        );
        resolve(uniqueProductsList);
      }
    );
  });
};

export const createVariants = (
  colorsArray: string[],
  sizeArray: string[],
  priceArray: {
    name: string;
    price: number;
    comparePrice: number | null;
  }[],
  id: string,
  baseStock: number,
  basePrice: number,
  baseComparePrice: number | null
): DetailedProductVariant[] => {
  const combos: DetailedProductVariant[] = [];
  if (colorsArray.length >= 2 && sizeArray.length >= 2) {
    for (let i = 0; i < colorsArray.length; i++) {
      for (let j = 0; j < sizeArray.length; j++) {
        const index = priceArray.findIndex((obj) => obj.name === sizeArray[j]);
        if (index !== -1) {
          combos.push({
            Sku: id,
            ID: newVariantId(),
            CmpPrice: priceArray[index].comparePrice,
            Images: [],
            Price: priceArray[index].price,
            Stock: baseStock,
            Variations: [
              {
                Name: "Size",
                Value: sizeArray[j],
              },
              {
                Name: "Color",
                Value: colorsArray[i],
              },
            ],
          });
        }
      }
    }
  } else if (colorsArray.length >= 2 && sizeArray.length < 2) {
    for (let i = 0; i < colorsArray.length; i++) {
      combos.push({
        Sku: id,
        ID: newVariantId(),
        CmpPrice: baseComparePrice,
        Images: [],
        Price: sizeArray.length ? priceArray[0].price : basePrice,
        Stock: baseStock,
        Variations: [
          {
            Name: "Color",
            Value: colorsArray[i],
          },
        ],
      });
    }
  } else if (colorsArray.length < 2 && sizeArray.length >= 2) {
    for (let i = 0; i < sizeArray.length; i++) {
      const index = priceArray.findIndex((obj) => obj.name === sizeArray[i]);
      combos.push({
        Sku: id,
        ID: newVariantId(),
        CmpPrice: priceArray[index].comparePrice,
        Images: [],
        Price: priceArray[index].price,
        Stock: baseStock,
        Variations: [
          {
            Name: "Size",
            Value: sizeArray[i],
          },
        ],
      });
    }
  } else {
    combos.push({
      Sku: id,
      ID: newVariantId(),
      CmpPrice: baseComparePrice,
      Images: [],
      Price: basePrice,
      Stock: baseStock,
      Variations: [],
    });
  }
  return combos;
};

export const generateBeneficiaryId = (businessName: string) => {
  const beneficiaryId = (
    businessName + Math.floor(1000000000 + Math.random() * 9000000000).toString()
  )
    .replace(/[^a-zA-Z0-9_]/gi, "")
    .slice(0, 49);
  return beneficiaryId;
};
