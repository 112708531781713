import React, { MouseEvent } from "react";

import { PlusIcon } from "@heroicons/react/solid";
import { TrashIcon } from "@heroicons/react/solid";
import FlexibleImage from "../../../../../components/FlexibleImage";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import type { DraggableProvided, DroppableProvided, DropResult } from "react-beautiful-dnd";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

type EditProductImagesProps = {
  images: ImageRes[];
  deleteImage: (index: number) => void;
  reorderImages: (x: number, y: number) => void;
  handleImageUploadClick: (event: MouseEvent<HTMLButtonElement>) => void;
  hiddenImageFileInput: React.RefObject<HTMLInputElement>;
  handleImageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const EditProductImages = React.memo(
  ({
    images,
    deleteImage,
    reorderImages,
    hiddenImageFileInput,
    handleImageUploadClick,
    handleImageChange,
  }: EditProductImagesProps) => {
    const handleOnDragEnd = (result: DropResult) => {
      if (!result?.destination) return;

      reorderImages(result.destination.index, result.source.index);
    };

    return (
      <div className="p-4">
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="product-images">
            {(droppableProvided: DroppableProvided) => (
              <div
                className="grid grid-cols-3 gap-3"
                {...droppableProvided.droppableProps}
                ref={droppableProvided.innerRef}
              >
                {images.map((_img: ImageRes, index: number) => {
                  return (
                    <Draggable draggableId={_img.jpgUrl} key={_img.jpgUrl} index={index}>
                      {(draggableProvided: DraggableProvided) => (
                        <div
                          className={
                            index === 0
                              ? "relative flex aspect-square rounded-lg bg-gray-100 overflow-hidden"
                              : "relative flex aspect-square rounded-lg bg-gray-100 overflow-hidden"
                          }
                          {...draggableProvided.dragHandleProps}
                          {...draggableProvided.draggableProps}
                          ref={draggableProvided.innerRef}
                        >
                          <FlexibleImage
                            alt="Product Image"
                            img={images[index]}
                            className={
                              index === 0
                                ? "w-full aspect-square object-center object-cover rounded-lg"
                                : "w-full h-full object-center object-cover"
                            }
                            loading="lazy"
                          />
                          <div
                            onClick={() => deleteImage(index)}
                            className="backdrop-blur-sm absolute rounded-lg cursor-pointer"
                          >
                            <TrashIcon
                              className="text-white sm:h-5 sm:w-5 m-1"
                              aria-hidden="true"
                            />
                          </div>
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {droppableProvided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <div className={images.length === 0 ? "grid grid-cols-3 w-full" : ""}>
          <div className=" mt-4 flex rounded-lg bg-gray-100 overflow-hidden">
            <label
              htmlFor="imageupload"
              className={classNames(
                images.length === 0 ? "aspect-square" : "",
                "flex justify-center items-center	 relative w-full border-2 border-gray-300 border-dashed rounded-lg text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              )}
            >
              <button type="button" onClick={handleImageUploadClick}>
                <PlusIcon className="sm:h-10 sm:w-10" aria-hidden="true" />
              </button>
            </label>
            <input
              ref={hiddenImageFileInput}
              onChange={handleImageChange}
              className="hidden"
              type="file"
              id="imgupload"
              name="imageupload"
              multiple={true}
              accept="image"
              style={{ display: "none" }}
            />
          </div>
        </div>
      </div>
    );
  }
);

export default EditProductImages;
