import React, { useContext, useState, useEffect } from "react";
import { RadioGroup } from "@headlessui/react";

import { AuthContext } from "../../../lib/services/auth";
import { generateBeneficiaryId, withTestId } from "../../../lib/utils";
import AccountDetailsForm, { AccountDetailsField } from "./components/AccountDetailsForm";
import UPIDetailsForm from "./components/UPIDetailsForm";
import { UPIDetailsFields } from "./components/UPIDetailsForm";
import { SubmitHandler } from "react-hook-form";
import { FiLoader } from "react-icons/fi";
import { SellerDetailsResponse } from "../../../lib/types";
import { ApiContext } from "../../../lib/services/api";
import { NotificationContext } from "../../../lib/services/notification";

const BankDetails = () => {
  const { sellerDetails, updateSellerDetails, refreshAndReturnFirebaseToken } =
    useContext(AuthContext);
  const { addNotification, removeNotificationByTag } = useContext(NotificationContext);
  const { addBeneficiary } = useContext(ApiContext);
  const [paymentMethod, setPaymentMethod] = useState<string>("bank");

  useEffect(() => {
    if (sellerDetails?.BankDetails.Method === "bankDetails") {
      setPaymentMethod("bank");
    } else {
      setPaymentMethod("upi");
    }
  }, [sellerDetails]);

  const [upiDetailsForm, setUpiDetailsForm] = useState<UPIDetailsFields>({
    address: "",
    email: sellerDetails?.BankDetails?.Email ?? "",
    name: sellerDetails?.BankDetails?.AccountHolder ?? "",
    pan: sellerDetails?.BankDetails?.Pan ?? null,
    upi: sellerDetails?.BankDetails?.UpiId ?? "",
  });

  const [bankDetailsForm, setBankDetailsForm] = useState<AccountDetailsField>({
    accountNumber: sellerDetails?.BankDetails?.AccountNumber ?? "",
    address: "",
    confirmAccountNumber: sellerDetails?.BankDetails?.AccountNumber ?? "",
    email: sellerDetails?.BankDetails?.Email ?? "",
    ifsc: sellerDetails?.BankDetails?.IfscCode ?? "",
    name: sellerDetails?.BankDetails?.AccountHolder ?? "",
    pan: sellerDetails?.BankDetails?.Pan ?? null,
  });

  if (sellerDetails === null) {
    return <FiLoader className="animate-spin"></FiLoader>;
  }

  const getBeneficiaryId = async (payload: any, method: "upi" | "bankDetails") => {
    const d = await addBeneficiary(payload);
    if (d.status === "ok") {
      let dataToSave = {};
      if (method === "upi") {
        dataToSave = {
          UpiId: payload.vpa,
          AccountHolder: "Dummy",
          IfscCode: null,
          Email: payload.email,
          BeneficiaryId: d.data,
          Method: method,
          AccountNumber: null,
          PanImgUrl: null,
        };
      } else if (method === "bankDetails") {
        dataToSave = {
          AccountNumber: payload.bankAccount,
          AccountHolder: payload.name,
          IfscCode: payload.ifsc,
          Email: payload.email,
          BeneficiaryId: d.data,
          Method: method,
          UpiId: null,
          PanImgUrl: null,
        };
      }
      return dataToSave;
    } else {
      return null;
    }
  };

  const onSubmitUpiHandler: SubmitHandler<UPIDetailsFields> = async (_data) => {
    setUpiDetailsForm(_data);
    removeNotificationByTag("Bank Details");
    const beneficiaryId = generateBeneficiaryId(sellerDetails.BusinessName);
    const payload = {
      beneId: beneficiaryId,
      name: "Dummy",
      email: _data.email,
      phone: sellerDetails.PhoneNumber.replace("++", "+"),
      vpa: _data.upi,
      address1: _data.address,
    };

    const dataToSave = await getBeneficiaryId(payload, "upi");
    if (dataToSave) {
      const updatedBankDetails: Partial<SellerDetailsResponse> = {
        BankDetails: {
          ...sellerDetails.BankDetails,
          ...dataToSave,
          Pan: _data.pan,
          Method: "upi",
        },
      };
      const refreshedToken = await refreshAndReturnFirebaseToken();
      if (refreshedToken !== null) {
        addNotification("success", "Successfully added upi details!", "Bank Details");
        await updateSellerDetails(refreshedToken, updatedBankDetails);
      }
    } else {
      addNotification("error", "Could not add upi details. Try again", "Bank Details");
    }
  };

  const onSubmitAccountDetails: SubmitHandler<AccountDetailsField> = async (_data) => {
    if (!sellerDetails.CanUpdatebankDetails) {
      addNotification(
        "error",
        "Cannot update bank details more than 3 times.",
        "seller-details",
        null,
        3
      );
      return;
    }
    setBankDetailsForm(_data);
    removeNotificationByTag("Bank Details");
    const beneficiaryId = generateBeneficiaryId(sellerDetails.BusinessName);

    const payload = {
      beneId: beneficiaryId,
      name: _data.name,
      email: _data.email,
      phone: sellerDetails.PhoneNumber.replace("++", "+"),
      bankAccount: _data.accountNumber,
      ifsc: _data.ifsc,
      address1: _data.address,
    };
    const dataToSave = await getBeneficiaryId(payload, "bankDetails");
    if (dataToSave) {
      const updatedBankDetails: Partial<SellerDetailsResponse> = {
        BankDetails: {
          ...sellerDetails.BankDetails,
          ...dataToSave,
          Pan: _data.pan,
          Method: "bankDetails",
        },
      };

      const refreshedToken = await refreshAndReturnFirebaseToken();
      if (refreshedToken !== null) {
        await updateSellerDetails(refreshedToken, updatedBankDetails);
        addNotification("success", "Successfully added bank details!", "Bank Details");
      }
    } else {
      addNotification("error", "Could not add bank details. Try again", "Bank Details");
    }
  };

  return (
    <div className="my-4 sm:px-6 lg:px-8">
      <div>
        <h1 className="text-3xl font-light leading-7 text-gray-900 my-2">Settlement Method</h1>
      </div>
      <div>
        <span className="text-sm font-primary text-gray-900 my-2">
          *You can update your settlement details upto 3 times.
        </span>
      </div>
      <div className="flex flex-col justify-center items-center">
        <div className="w-1/2">
          <RadioGroup
            value={paymentMethod}
            onChange={setPaymentMethod}
            as="div"
            className="flex items-center justify-start gap-2 mb-4"
          >
            {[
              {
                value: "bank",
                title: "Bank Account",
              },
              {
                value: "upi",
                title: "UPI",
              },
            ].map(({ value, title }) => (
              <RadioGroup.Option value={value}>
                {({ checked }) => (
                  <button
                    className={`py-1.5 px-3 border border-solid rounded ${
                      checked ? "bg-primary text-white" : "bg-white text-black text-border-gray-200"
                    }`}
                  >
                    {title}
                  </button>
                )}
              </RadioGroup.Option>
            ))}
          </RadioGroup>
          {paymentMethod === "bank" ? (
            <AccountDetailsForm
              {...withTestId("account-details-form")}
              initialValue={bankDetailsForm}
              next={onSubmitAccountDetails}
              key="account-details-form"
            />
          ) : (
            <UPIDetailsForm
              {...withTestId("personal-details-form")}
              key="upi-details-form"
              next={onSubmitUpiHandler}
              initialValue={upiDetailsForm}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default BankDetails;
