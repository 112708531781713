import React, { useContext, useEffect, useState } from "react";
import { SubmitHandler } from "react-hook-form";
import { FiLoader } from "react-icons/fi";

import { AuthContext } from "../../../lib/services/auth";
import GSTForm, { GSTDetailsFields } from "./components/GSTForm";

const GSTDetails = () => {
  const { sellerDetails, user } = useContext(AuthContext);

  const [gstDetails] = useState<GSTDetailsFields>({
    gstImage: "",
    gstNumber: "",
    name: "",
    pan: "",
    panImage: "",
  });

  useEffect(() => {}, [sellerDetails]);

  if (sellerDetails === null || user === null) {
    return <FiLoader className="animate-spin"></FiLoader>;
  }

  const onSubmit: SubmitHandler<GSTDetailsFields> = () => {};

  return (
    <div className="my-4 sm:px-6 lg:px-8">
      <div>
        <h1 className="text-3xl font-light leading-7 text-gray-900 my-2">GST Details</h1>
      </div>
      <div className="w-full flex flex-col justify-start items-center">
        <GSTForm initialValue={gstDetails} next={onSubmit} key="gst-details" user={user} />
      </div>
    </div>
  );
};

export default GSTDetails;
