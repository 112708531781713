import React, { useContext, useState, useEffect } from "react";

import { AuthContext } from "../../../lib/services/auth";
import { FiLoader } from "react-icons/fi";
import { NotificationContext } from "../../../lib/services/notification";

const CustomizeCheckout = () => {
  const { sellerDetails, updateSellerDetails, refreshAndReturnFirebaseToken } =
    useContext(AuthContext);
  const { addNotification } = useContext(NotificationContext);

  const [isUpdatingCustomizeCheckoutDetails, setIsUpdatingCustomizeCheckoutDetails] =
    useState(false);

  const [customField, setCustomField] = useState(sellerDetails?.WebsiteConfig.CustomField ?? "");
  useEffect(() => {}, [sellerDetails]);

  if (sellerDetails === null) {
    return <FiLoader className="animate-spin"></FiLoader>;
  }

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const sellerWebConfig = Object.assign({}, sellerDetails?.WebsiteConfig);

    sellerWebConfig.CustomField = customField;
    const updatedSellerDetails = { WebsiteConfig: sellerWebConfig };

    setIsUpdatingCustomizeCheckoutDetails(true);

    const refreshedToken = await refreshAndReturnFirebaseToken();
    if (refreshedToken !== null) {
      await updateSellerDetails(refreshedToken, updatedSellerDetails);
      addNotification(
        "success",
        "Successfully updated the customize checkout details!",
        "StoreSettings"
      );
    }
    setIsUpdatingCustomizeCheckoutDetails(false);
  };

  return (
    <div className="my-4 sm:px-6 lg:px-8">
      <div>
        <h1 className="text-3xl font-light leading-7 text-gray-900 my-2">Customize Checkout</h1>
      </div>
      <form onSubmit={onSubmit} className="w-1/2 mx-auto">
        <p>
          This is an extra field your customers will have to fill out. Responses can be read in
          their order details page.
        </p>
        <section className={`my-4 `}>
          <label
            htmlFor={"announcement-bar"}
            className={`block text-xs mb-1 font-medium text-left text-neutral-600`}
          >
            Custom field
          </label>
          {customField.length < 2 ? (
            <p className="block text-red-500 text-xs mb-1 font-normal text-left">
              Please enter a valid custom field
            </p>
          ) : null}
          <textarea
            id={"announcement-bar"}
            placeholder="Announcement goes here!"
            className={`mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${
              customField.length > 2 ? "ring-red-500" : "ring-gray-300"
            }`}
            value={customField}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setCustomField(e.target.value)}
          />
        </section>
        <button
          type="submit"
          disabled={isUpdatingCustomizeCheckoutDetails}
          className="uppercase bg-primary text-stone-200 font-bold p-2 w-full rounded disabled:bg-gray-400"
        >
          {isUpdatingCustomizeCheckoutDetails ? "Saving" : "Save"}
        </button>
      </form>
    </div>
  );
};

export default CustomizeCheckout;
