import React, { useContext, useState } from "react";
import ProductsTable from "./components/ProductsTable";
import UploadFileSelect from "./components/UploadFileSelect";
import {
  mediaUrl2ImageRes,
  parseRecordsFromCSV,
  uploadImage,
  createVariants,
} from "../../../lib/utils";
import { ParsedUniqueProductFields, ProductDetailsResponse } from "../../../lib/types";
import { ChevronLeftIcon } from "@heroicons/react/solid";
import { AuthContext } from "../../../lib/services/auth";
import { ApiContext } from "../../../lib/services/api";
import { NotificationContext } from "../../../lib/services/notification";
import BulkUploadInstructions from "./components/BulkUploadInstructions";

type ImageToUpload = {
  imageUrl: string;
  productId: string;
};

const BulkUploadPage = () => {
  const { user, refreshAndReturnFirebaseToken } = useContext(AuthContext);
  const { bulkProductUpload } = useContext(ApiContext);
  const { addNotification } = useContext(NotificationContext);

  const [uniqueProducts, setUniqueProducts] = useState<ParsedUniqueProductFields[]>([]);
  const [imagesToUploadCount, setImagesToUploadCount] = useState<number>(0);
  const [imagesUploadedCount, setImagesUploadedCount] = useState<number>(0);
  const [isUploadingRecords, setIsUploadingRecords] = useState<boolean>(false);
  const [recordsHavingError, setRecordsHavingError] = useState<number>(0);
  const [isFileUploaded, setIsFileUploaded] = useState<boolean>(false);

  const resetPage = () => {
    setUniqueProducts([]);
    setImagesToUploadCount(0);
    setImagesUploadedCount(0);
    setIsUploadingRecords(false);
    setRecordsHavingError(0);
    setIsFileUploaded(false);
  };
  const handleImagesUpload = async (imagesToBeUpload: ImageToUpload[]) => {
    if (!user?.uid) return;
    for (let i = 0; i < Math.ceil(imagesToBeUpload.length / 10); i++) {
      const batch = imagesToBeUpload.slice(i * 10, (i + 1) * 10);
      await Promise.all(
        batch.map(async (imageToUpload: ImageToUpload) => {
          try {
            const file = await fetch(imageToUpload.imageUrl).then((response) => response.blob());
            const imageRes = await uploadImage(file, user.uid, false);
            if (imageRes !== null) {
              setImagesUploadedCount((count) => count + 1);
              setUniqueProducts((products) => {
                const productImage: ImageRes = mediaUrl2ImageRes({
                  MediaUrl1: imageRes.MediaUrl1,
                  MediaUrl2: imageRes.MediaUrl2,
                });
                return products.map((product) =>
                  product.Sku === imageToUpload.productId
                    ? { ...product, ProductImages: product.ProductImages.concat(productImage) }
                    : product
                );
              });
            } else {
              setImagesToUploadCount((count) => count - 1);
            }
          } catch (error) {
            setImagesToUploadCount((count) => count - 1);
          }
        })
      );
    }
  };

  const validateRecords = () => {
    let recordsHavingErrorCount = 0;
    setUniqueProducts(
      uniqueProducts.map((product) => {
        const errors: string[] = [];
        if (product.ProductImages.length === 0) {
          errors.push("Product must have at least one image");
        }
        if (product.Description.length < 3) {
          errors.push("Please enter a valid product description");
        }
        if (product.ProductName.length < 3) {
          errors.push("Please enter a valid product name");
        }
        if (errors.length > 0) {
          recordsHavingErrorCount++;
        }
        return { ...product, errors };
      })
    );
    setRecordsHavingError(recordsHavingErrorCount);
    return recordsHavingErrorCount;
  };
  const handleSubmit = async () => {
    setIsUploadingRecords(false);
    if (validateRecords() === 0) {
      //send the records to server
      const productsToAdded: Omit<ProductDetailsResponse, "Slug" | "Rating">[] = uniqueProducts.map(
        (uniqueProduct) => {
          return {
            Sku: uniqueProduct.Sku,
            Images: uniqueProduct.ProductImages,
            Name: uniqueProduct.ProductName,
            Description: uniqueProduct.Description,
            SeoDescription: "",
            Categories: [],
            DigitalProductUrl: undefined,
            Variants: createVariants(
              uniqueProduct.Colors,
              uniqueProduct.Sizes,
              uniqueProduct.Pricing.map((pricing) => ({
                name: pricing.variantSize || "",
                price: pricing.variantPrice,
                comparePrice: pricing.variantStrikeOffPrice,
              })),
              uniqueProduct.Sku,
              uniqueProduct.Stock,
              uniqueProduct.Pricing[0].variantPrice,
              uniqueProduct.Pricing[0].variantStrikeOffPrice
            ),
          };
        }
      );
      const refreshedToken = await refreshAndReturnFirebaseToken();
      if (refreshedToken !== null) {
        setIsUploadingRecords(true);
        const res = await bulkProductUpload(refreshedToken, productsToAdded);
        addNotification("success", res.Data?.message || "Products added Successfully", "Product");
        resetPage();
      }
    }
  };

  const handleUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.[0]) {
      return;
    }
    const file = e.target.files[0];

    if (file.type !== "text/csv") return;
    setIsFileUploaded(true);
    const fileContents = await file.text();
    const uniqueProductsList = await parseRecordsFromCSV(fileContents);
    if (uniqueProductsList) {
      setUniqueProducts(uniqueProductsList);
      const productImagesToUpload = uniqueProductsList.reduce((acc: ImageToUpload[], product) => {
        product.ProductImagesToUpload.forEach((image) => {
          if (image) {
            acc.push({
              imageUrl: image,
              productId: product.Sku,
            } as ImageToUpload);
          }
        });
        return acc;
      }, []);
      setImagesToUploadCount(productImagesToUpload.length);
      handleImagesUpload(productImagesToUpload);
    }
  };
  const updateProduct = (sku: string, updatedProduct: ParsedUniqueProductFields) => {
    if (!sku) return;
    setUniqueProducts((initialProducts) =>
      initialProducts.map((product) => (product.Sku === sku ? updatedProduct : product))
    );
  };

  const deleteProduct = (sku: string) => {
    if (!sku) return;
    setUniqueProducts((initialProducts) =>
      initialProducts.filter((product) => product.Sku !== sku)
    );
  };
  return (
    <div
      className={
        isFileUploaded
          ? "bg-white fixed h-screen left-0 overflow-scroll py-6 px-10 top-0 w-screen"
          : "my-4 sm:px-6 lg:px-8"
      }
    >
      <div className="mb-4 flex justify-between align-center content-center">
        <div className="flex items-center justify-between">
          {isFileUploaded ? (
            <button
              onClick={resetPage}
              className="flex items-center font-bold mr-4 border border-red-500 py-1 px-2 text-red-500 rounded"
            >
              <ChevronLeftIcon className="text-red-500 w-5 h-5" /> Cancel
            </button>
          ) : null}
          <h2 className="text-3xl py-2 font-light leading-8 text-gray-900 sm:text-4xl sm:truncate">
            Bulk Upload
          </h2>
        </div>
      </div>

      <div></div>
      {!isFileUploaded ? (
        <>
          <UploadFileSelect handleUpload={handleUpload} />
          <BulkUploadInstructions />
        </>
      ) : (
        <>
          <div className="flex items-start justify-between mb-4">
            <div>
              <p>
                {imagesToUploadCount > imagesUploadedCount ? "Uploading Files" : "Uploaded Files"}:{" "}
                <span className="font-bold">{imagesUploadedCount}</span>/
                <span className="font-bold">{imagesToUploadCount}</span>
              </p>
              <p>
                <span className="font-bold">{uniqueProducts.length}</span> products found!
              </p>
              {recordsHavingError ? (
                <p className="text-red-500">
                  Please fix the errors in {recordsHavingError} products to continue uploading!
                </p>
              ) : null}
            </div>

            <button
              disabled={isUploadingRecords}
              className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-opacity-95 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={handleSubmit}
            >
              {isUploadingRecords ? "Uploading.." : "Upload"}
            </button>
          </div>
          <ProductsTable
            uniqueProducts={uniqueProducts}
            updateProduct={updateProduct}
            deleteProduct={deleteProduct}
          />
        </>
      )}
    </div>
  );
};

export default BulkUploadPage;
