import React from "react";
import type { HTMLAttributes } from "react";

/**
 * A single, full width input in form
 */
const FormInput = function FormInput(
  props: {
    /** ID for input */
    id: string;
    placeholder: string;
    label?: string;
    icon?: JSX.Element;
    /** React-Hook-Form control props */
    control: HTMLAttributes<HTMLInputElement> | object;
    /** Error message */
    errMsg?: string;
    /** Whether to show error message */
    isError: boolean;
    className?: string;
  } & TestingProps
) {
  return (
    <section data-testid={props["data-testid"]} className={`mb-4 ${props.icon ? "relative" : ""}`}>
      <label
        htmlFor={props.id}
        className={`block text-xs mb-1 font-medium text-left text-neutral-600`}
      >
        {props.label || props.placeholder}
      </label>
      {props.isError ? (
        <p className="block text-red-500 text-xs mb-1 font-normal text-left">{props.errMsg}</p>
      ) : null}
      {props.icon}
      <input
        id={props.id}
        placeholder={props.placeholder}
        className={`mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${
          props.isError ? "ring-red-500" : "ring-gray-300"
        } ${props.icon ? "pl-9" : ""} ${props.className ? props.className : ""}`}
        {...props.control}
      />
    </section>
  );
};

export default FormInput;
