// Contains functions to interact with API
import { createContext } from "react";
import {
  AbandonedCartDetails,
  AbandonedCartListApiResponse,
  AnalyticsData,
  CategoryCollectionListResponse,
  CategoryDb,
  CategoryDetailsResponse,
  CategoryListResponse,
  CouponDetails,
  CreateCategoryRequest,
  Order,
  OrderListApiResponse,
  OrderStage,
  PaymentListApiResponse,
  ProductDetailsResponse,
  ProductListApiResponse,
  SellerDetailsResponse,
  TypeaheadResponse,
  Visitor,
} from "../types";
import { baseUrl, cloudFunctionsUrl } from "../utils";

/**
 * IApiClient is a mockable interface that provides access to `fetch`
 */
export interface IApiTransport {
  /**
   * makeRequest has the same signature as window.fetch
   */
  makeRequest: (input: RequestInfo, init?: RequestInit) => Promise<Response>;
}

/**
 * API response code
 */
export enum ApiCode {
  OK = 200,
  NOT_FOUND = 404,
  NO_INTERNET = -4,
  COULD_NOT_AUTHENTICATE = 32,
}

export type ApiResult<T = unknown> = {
  Err?: { message: string; code: ApiCode };
  Data?: T;
};

/**
 * Class to interact with the API
 */
export interface IApiService {
  /**
   * Get information about a storefront
   *
   * FIXME(muscache, rranjan14): This method currently returns mock information.
   * Tweak the APIs to match this spec
   */
  fetchStoreDetails(slug: string): Promise<ApiResult<SellerDetailsResponse>>;
  /**
   *
   *
   */
  updateSellerDetails(
    token: string,
    detailsToUpdate: Partial<SellerDetailsResponse>
  ): Promise<ApiResult<SellerDetailsResponse>>;

  /**
   * Get detailed information about a single product
   *
   * FIXME(muscache, rranjan14): This method currently returns mock information.
   * Tweak the APIs to match this spec
   */
  fetchProductDetails(
    storeSlug: string,
    productSlug: string
  ): Promise<ApiResult<ProductDetailsResponse>>;

  /**
   * Fetch all the products in the store.
   */
  fetchProducts(
    token: string,
    goBack: 0 | 1,
    next: string | null,
    prev: string | null,
    limit?: number
  ): Promise<ApiResult<ProductListApiResponse>>;

  /**
   * Add a product to the store.
   */

  createProduct(
    token: string,
    product: Omit<ProductDetailsResponse, "Rating" | "Slug">
  ): Promise<ApiResult<ProductDetailsResponse>>;
  /**
   * Add products to the store.
   */
  bulkProductUpload(
    token: string,
    products: Omit<ProductDetailsResponse, "Rating" | "Slug">[]
  ): Promise<ApiResult<{ success: boolean; message: string }>>;
  /**
   * Edit a product's details.
   */
  editProduct(
    token: string,
    productId: string,
    productData: ProductDetailsResponse
  ): Promise<ApiResult<ProductDetailsResponse>>;
  /**
   * Toggle a product's state.
   */
  toggleProduct(token: string, productId: string, isActive: boolean): Promise<ApiResult<string>>;

  /**
   * Delete a Product.
   */
  deleteProduct(token: string, productSlug: string): Promise<ApiResult<ProductDetailsResponse>>;

  /**
   * Fetch abandoned cart details.
   */
  fetchCarts(
    token: string,
    goBack: 0 | 1,
    next: string | null,
    prev: string | null
  ): Promise<ApiResult<AbandonedCartListApiResponse>>;

  /**
   * Fetch abandoned cart list.
   */
  fetchCart(token: string, cartId: string): Promise<ApiResult<AbandonedCartDetails>>;

  /**
   * Gets all the payments details of the seller.
   */
  getAllPayments(token: string): Promise<ApiResult<PaymentListApiResponse>>;

  /**
   * Create a new coupon in the store.
   */
  createCoupon(
    token: string,
    details: Omit<CouponDetails, "Id">
  ): Promise<ApiResult<CouponDetails>>;

  /**
   * Fetch a single coupon.
   */
  fetchCoupon(token: string, couponId: string): Promise<ApiResult<CouponDetails>>;

  /**
   * Fetch all the coupons available in the store.
   */
  fetchAllCoupons(token: string): Promise<ApiResult<CouponDetails[]>>;
  /**
   * Edit coupon details.
   */
  editCoupon(
    token: string,
    couponId: string,
    couponDetails: CouponDetails
  ): Promise<ApiResult<CouponDetails>>;
  /**
   * Delete coupon details.
   */
  deleteCoupon(token: string, couponId: string): Promise<ApiResult<CouponDetails>>;
  /**
   * Get all the orders for the seller
   */
  getAllOrders(
    token: string,
    goBack: 0 | 1,
    next: string | null,
    prev: string | null,
    orderStatus: "pending" | "shipped" | "delivered" | "rejected" | "all",
    limit: number
  ): Promise<ApiResult<OrderListApiResponse>>;

  /**
   * Search suggestions for products on a store
   */
  typeahead(token: string, storeId: string, query: string): Promise<ApiResult<TypeaheadResponse>>;

  /**
   * Get a single order for the seller
   */
  getOrder(token: string, orderId: string): Promise<ApiResult<Order>>;

  updateOrderStatus(
    token: string,
    {
      orderId,
      trackingUrl,
      orderStatus,
    }: { orderId: string; trackingUrl?: string; orderStatus: OrderStage }
  ): Promise<ApiResult<Order>>;

  /**
   * Get all category collections in the store
   */
  getAllCategoryCollections(token: string): Promise<ApiResult<CategoryCollectionListResponse[]>>;

  /**
   * Delete a category in the store
   */
  deleteCategoryCollection(
    token: string,
    categororyCollectionSlug: string
  ): Promise<ApiResult<CategoryDb>>;

  /**
   * Add or remove parents from categories depending on the selection done
   */
  toggleCollectionsOfCategory(
    token: string,
    selectedCategories: string[],
    removedCategories: string[],
    categoryCollectionSlug: string
  ): Promise<ApiResult<CategoryDetailsResponse>>;

  /**
   * Get all the categories present in the store.
   */
  getAllCategories(token: string): Promise<ApiResult<CategoryListResponse[]>>;
  /**
   * get details of a category by slug
   */
  getCategory(
    token: string,
    categorySlug: string,
    offset: number
  ): Promise<ApiResult<CategoryDetailsResponse>>;

  /**
   * Create a new category.
   */
  createCategory(
    token: string,
    details: CreateCategoryRequest
  ): Promise<ApiResult<CategoryDetailsResponse>>;

  /**
   * toggle a category.
   */
  toggleCategory(
    token: string,
    categprySlug: string,
    isVisibleOnHome: boolean
  ): Promise<ApiResult<string>>;

  /**
   * Remove items from categories.
   */
  removeItemsFromCategory(
    token: string,
    categorySlug: string,
    productIds: string[]
  ): Promise<ApiResult<CategoryDetailsResponse>>;

  toggleProductsOfCategory(
    token: string,
    selectedProductIDs: string[],
    removedProductIDs: string[],
    categorySlug: string
  ): Promise<ApiResult<CategoryDetailsResponse>>;
  /**
   * Delete a category in the store
   */
  deleteCategory(token: string, categorySlug: string): Promise<ApiResult<CategoryDetailsResponse>>;
  /**
   * Edit a category in the store
   */
  editCategory(
    token: string,
    categorySlug: string,
    category: Omit<CategoryDetailsResponse, "ProductIDs">
  ): Promise<ApiResult<CategoryDetailsResponse>>;

  getAllVisitors(token: string): Promise<ApiResult<Visitor[]>>;

  getAnalytics(
    token: string,
    timespan: "today" | "yesterday" | "month" | "week" | "lifetime"
  ): Promise<ApiResult<AnalyticsData>>;

  /**
   * More powerful analytics compared to getAnanlytics
   */
  getAnalytics2(token: string, startTs: number, endTs: number): Promise<ApiResult<AnalyticsData>>;

  /**
   * Register beneficiary on cashfree
   */
  addBeneficiary(
    payload: any
  ): Promise<{ data: string | null; error?: string; status: string; message?: string }>;
}

export const ApiContext = createContext<IApiService>(null as never);

export class ApiService implements IApiService {
  async fetchStoreDetails(token: string): Promise<ApiResult<SellerDetailsResponse>> {
    const r = await fetch(`${baseUrl}/v1/dash/get_store`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/json",
      },
    });
    const json = await r.json();
    return json as ApiResult<SellerDetailsResponse>;
  }

  async typeahead(
    token: string,
    storeId: string,
    query: string
  ): Promise<ApiResult<TypeaheadResponse>> {
    const r = await fetch(`${baseUrl}/v1/typeahead?storeId=${storeId}&query=${query}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const json = await r.json();
    return json as ApiResult<TypeaheadResponse>;
  }

  async updateSellerDetails(
    token: string,
    detailsToUpdate: SellerDetailsResponse
  ): Promise<ApiResult<SellerDetailsResponse>> {
    const r = await fetch(`${baseUrl}/v1/dash/edit_store`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(detailsToUpdate),
    });
    const json = await r.json();
    return json as ApiResult<SellerDetailsResponse>;
  }

  async fetchProducts(
    token: string,
    goBack: 0 | 1,
    next: string | null,
    prev: string | null,
    limit?: number
  ): Promise<ApiResult<ProductListApiResponse>> {
    const r = await fetch(
      `${baseUrl}/v1.1/dash/get_products?device=web&next=${next}&prev=${prev}&limit=${
        limit || 24
      }&back=${goBack}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const json = await r.json();
    return json as ApiResult<ProductListApiResponse>;
  }

  async fetchProductDetails(
    token: string,
    productSlug: string
  ): Promise<ApiResult<ProductDetailsResponse>> {
    const r = await fetch(`${baseUrl}/v1/dash/get_product/${productSlug}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const json = await r.json();
    return json as ApiResult<ProductDetailsResponse>;
  }

  async createProduct(
    token: string,
    product: Omit<ProductDetailsResponse, "Rating" | "Slug">
  ): Promise<ApiResult<ProductDetailsResponse>> {
    const r = await fetch(`${baseUrl}/v1/dash/add_product`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(product),
    });
    const json = await r.json();
    return json as ApiResult<ProductDetailsResponse>;
  }

  async bulkProductUpload(
    token: string,
    products: Omit<ProductDetailsResponse, "Rating" | "Slug">[]
  ): Promise<ApiResult<{ success: boolean; message: string }>> {
    const r = await fetch(`${baseUrl}/v1/dash/add_product_bulk_upload`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(products),
    });
    const json = await r.json();
    return json as ApiResult<{ success: boolean; message: string }>;
  }

  async editProduct(
    token: string,
    productId: string,
    productData: ProductDetailsResponse
  ): Promise<ApiResult<ProductDetailsResponse>> {
    const r = await fetch(`${baseUrl}/v1/dash/edit_product/${productId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(productData),
    });
    const json = await r.json();
    return json as ApiResult<ProductDetailsResponse>;
  }

  async toggleProduct(
    token: string,
    productId: string,
    isActive: boolean
  ): Promise<ApiResult<string>> {
    const r = await fetch(`${baseUrl}/v1/dash/toggle_product/${productId}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ IsActive: isActive }),
    });
    const json = await r.json();
    return json as ApiResult<string>;
  }

  async deleteProduct(
    token: string,
    productSlug: string
  ): Promise<ApiResult<ProductDetailsResponse>> {
    const r = await fetch(`${baseUrl}/v1/dash/delete_product/${productSlug}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const json = await r.json();
    return json as ApiResult<ProductDetailsResponse>;
  }

  async fetchCarts(
    token: string,
    goBack: 0 | 1,
    next: string | null,
    prev: string | null
  ): Promise<ApiResult<AbandonedCartListApiResponse>> {
    const r = await fetch(
      `${baseUrl}/v1.1/dash/get_carts?next=${next}&prev=${prev}&back=${goBack}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const json = await r.json();
    return json as ApiResult<AbandonedCartListApiResponse>;
  }

  async fetchCart(token: string, cartId: string): Promise<ApiResult<AbandonedCartDetails>> {
    const r = await fetch(`${baseUrl}/v1/dash/get_cart/${cartId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const json = await r.json();
    return json as ApiResult<AbandonedCartDetails>;
  }

  async getAllPayments(token: string): Promise<ApiResult<PaymentListApiResponse>> {
    const r = await fetch(`${baseUrl}/v1.1/dash/get_payments`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const json = await r.json();
    return json as ApiResult<PaymentListApiResponse>;
  }

  async createCoupon(
    token: string,
    details: Omit<CouponDetails, "Id">
  ): Promise<ApiResult<CouponDetails>> {
    const r = await fetch(`${baseUrl}/v1/dash/create_coupon`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(details),
    });
    const json = await r.json();
    return json as ApiResult<CouponDetails>;
  }

  async fetchCoupon(token: string, couponId: string): Promise<ApiResult<CouponDetails>> {
    const r = await fetch(`${baseUrl}/v1/dash/get_coupon/${couponId}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    const json = await r.json();
    return json as ApiResult<CouponDetails>;
  }

  async fetchAllCoupons(token: string): Promise<ApiResult<CouponDetails[]>> {
    const r = await fetch(`${baseUrl}/v1/dash/get_coupons`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    const json = await r.json();
    return json as ApiResult<CouponDetails[]>;
  }

  async editCoupon(
    token: string,
    couponId: string,
    couponData: CouponDetails
  ): Promise<ApiResult<CouponDetails>> {
    const r = await fetch(`${baseUrl}/v1/dash/edit_coupon/${couponId}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(couponData),
    });
    const json = await r.json();
    return json as ApiResult<CouponDetails>;
  }

  async deleteCoupon(token: string, couponId: string): Promise<ApiResult<CouponDetails>> {
    const r = await fetch(`${baseUrl}/v1/dash/delete_coupon/${couponId}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    const json = await r.json();
    return json as ApiResult<CouponDetails>;
  }

  async getAllOrders(
    token: string,
    goBack: 0 | 1,
    next: string | null,
    prev: string | null,
    orderStatus: "pending" | "shipped" | "delivered" | "rejected" | "all",
    limit: number
  ): Promise<ApiResult<OrderListApiResponse>> {
    const r = await fetch(
      `${baseUrl}/v1.1/dash/get_orders?next=${next}&prev=${prev}&back=${goBack}&orderStatus=${orderStatus}&limit=${limit}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    const json = await r.json();
    return json as ApiResult<OrderListApiResponse>;
  }

  async getOrder(token: string, orderId: string): Promise<ApiResult<Order>> {
    const r = await fetch(`${baseUrl}/v1/dash/get_order/${orderId}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    const json = await r.json();
    return json as ApiResult<Order>;
  }

  async updateOrderStatus(
    token: string,
    {
      orderId,
      trackingUrl,
      orderStatus,
    }: { orderId: string; trackingUrl?: string; orderStatus: OrderStage }
  ): Promise<ApiResult<Order>> {
    const r = await fetch(`${baseUrl}/v1/dash/update_order/${orderId}`, {
      method: "PATCH",
      body: JSON.stringify({
        trackingUrl: trackingUrl,
        orderStatus: orderStatus,
      }),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    const json = await r.json();
    return json as ApiResult<Order>;
  }

  async getAllCategoryCollections(
    token: string
  ): Promise<ApiResult<CategoryCollectionListResponse[]>> {
    const r = await fetch(`${baseUrl}/v1/dash/get_category_collections`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    const json = await r.json();
    return json as ApiResult<CategoryCollectionListResponse[]>;
  }

  async deleteCategoryCollection(
    token: string,
    categororyCollectionSlug: string
  ): Promise<ApiResult<CategoryDb>> {
    const r = await fetch(
      `${baseUrl}/v1/dash/delete_category_collection/${categororyCollectionSlug}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const json = await r.json();
    return json as ApiResult<CategoryDb>;
  }

  async toggleCollectionsOfCategory(
    token: string,
    selectedCategories: string[],
    removedCategories: string[],
    categoryCollectionSlug: string
  ): Promise<ApiResult<CategoryDetailsResponse>> {
    const r = await fetch(`${baseUrl}/v1/dash/toggle_collections_of_category`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        selectedCategories: selectedCategories,
        removedCategories: removedCategories,
        categoryCollectionSlug,
      }),
    });
    const json = await r.json();
    return json as ApiResult<CategoryDetailsResponse>;
  }

  async getAllCategories(token: string): Promise<ApiResult<CategoryListResponse[]>> {
    const r = await fetch(`${baseUrl}/v1/dash/get_categories`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    const json = await r.json();
    return json as ApiResult<CategoryListResponse[]>;
  }

  async createCategory(
    token: string,
    details: CreateCategoryRequest
  ): Promise<ApiResult<CategoryDetailsResponse>> {
    const r = await fetch(`${baseUrl}/v1/dash/create_category`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(details),
    });
    const json = await r.json();
    return json as ApiResult<CategoryDetailsResponse>;
  }

  async toggleCategory(
    token: string,
    categoryId: string,
    isVisibleOnHome: boolean
  ): Promise<ApiResult<string>> {
    const r = await fetch(`${baseUrl}/v1/dash/toggle_category/${categoryId}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ IsVisibleOnHome: isVisibleOnHome }),
    });
    const json = await r.json();
    return json as ApiResult<string>;
  }

  async getCategory(
    token: string,
    categorySlug: string,
    offset: number
  ): Promise<ApiResult<CategoryDetailsResponse>> {
    const r = await fetch(`${baseUrl}/v1/dash/get_category/${categorySlug}?offset=${offset}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    const json = await r.json();
    return json as ApiResult<CategoryDetailsResponse>;
  }

  async toggleProductsOfCategory(
    token: string,
    selectedProductIDs: string[],
    removedProductIDs: string[],
    categorySlug: string
  ): Promise<ApiResult<CategoryDetailsResponse>> {
    const r = await fetch(`${baseUrl}/v1/dash/toggle_products_of_category`, {
      body: JSON.stringify({
        selectedProducts: selectedProductIDs,
        removedProducts: removedProductIDs,
        categorySlug,
      }),
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    const json = await r.json();
    return json as ApiResult<CategoryDetailsResponse>;
  }

  async removeItemsFromCategory(
    token: string,
    categorySlug: string,
    productIds: string[]
  ): Promise<ApiResult<CategoryDetailsResponse>> {
    const r = await fetch(`${baseUrl}/v1/dash/remove_items_from_category/${categorySlug}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ productIds }),
    });
    const json = await r.json();
    return json as ApiResult<CategoryDetailsResponse>;
  }

  async editCategory(
    token: string,
    categorySlug: string,
    category: Omit<CategoryDetailsResponse, "ProductIDs">
  ): Promise<ApiResult<CategoryDetailsResponse>> {
    const r = await fetch(`${baseUrl}/v1/dash/edit_category/${categorySlug}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(category),
    });

    const json = await r.json();
    return json as ApiResult<CategoryDetailsResponse>;
  }

  async deleteCategory(
    token: string,
    categorySlug: string
  ): Promise<ApiResult<CategoryDetailsResponse>> {
    const r = await fetch(`${baseUrl}/v1/dash/delete_category/${categorySlug}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const json = await r.json();
    return json as ApiResult<CategoryDetailsResponse>;
  }

  async getAllVisitors(token: string): Promise<ApiResult<Visitor[]>> {
    const r = await fetch(`${baseUrl}/v1/dash/get_visitors`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const json = await r.json();
    return json as ApiResult<Visitor[]>;
  }

  async getAnalytics(
    token: string,
    timespan: "today" | "yesterday" | "month" | "week" | "lifetime"
  ): Promise<ApiResult<AnalyticsData>> {
    const r = await fetch(`${baseUrl}/v1.1/dash/get_analytics?timespan=${timespan}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const json = await r.json();
    return json as ApiResult<AnalyticsData>;
  }

  async getAnalytics2(
    token: string,
    startTs: number,
    endTs: number
  ): Promise<ApiResult<AnalyticsData>> {
    const r = await fetch(`${baseUrl}/v1/dash/get_analytics2?startTs=${startTs}&endTs=${endTs}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const json = await r.json();
    return json as ApiResult<AnalyticsData>;
  }

  async addBeneficiary(payload: any) {
    const result = await fetch(`${cloudFunctionsUrl}/webApi/api/v2/util/addBeneficiaryOnCashFree`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(payload),
    });
    const d = await result.json();
    if (d.hasOwnProperty("status") && d.status === "error") {
      return { data: null, status: d.status, error: d.message as string };
    } else {
      return { data: d.beneficiaryId as string, status: "ok", message: d.message as string };
    }
  }
}

export const standardApiClient = new ApiService();
