import React, { useState, useContext, useEffect } from "react";
import { FiLoader } from "react-icons/fi";
import { useParams, useNavigate } from "react-router-dom";
import { Switch } from "@headlessui/react";
// import FormInput from "../../../components/FormInput";
import { ApiContext } from "../../../../lib/services/api";
import { AuthContext } from "../../../../lib/services/auth";
import { CategoryDetailsResponse, CategoryListResponse } from "../../../../lib/types";
import CategoryGrid from "./components/CategoryGrid";
import { classNames, slugify } from "../../../../lib/utils";
import CategoryList from "./components/CategoryList";
import { NotificationContext } from "../../../../lib/services/notification";
import EditCategoryCollectionModal from "./components/EditCategoryCollectionModal";

const CategoryCollectionDetails = () => {
  const { categoryCollectionSlug } = useParams();
  const navigate = useNavigate();

  const { sellerDetails, refreshAndReturnFirebaseToken } = useContext(AuthContext);
  const { getCategory, editCategory, getAllCategories, toggleCollectionsOfCategory } =
    useContext(ApiContext);
  const { addNotification, removeNotificationByTag } = useContext(NotificationContext);

  const [isOpen, setIsOpen] = useState(false);
  const [categoryCollection, setCategoryCollection] = useState<CategoryDetailsResponse | null>(
    null
  );
  const [categoryUpdatedDetails, setCategoryUpdatedDetails] =
    useState<CategoryDetailsResponse | null>(null);
  const [isUpdatingCategory, setIsUpdatingCategory] = useState<boolean>(false);
  const [savingCategories, setSavingCategories] = useState(false);
  const [gridViewEnabled, setGridViewEnabled] = useState(true);

  const [categories, setCategories] = useState<CategoryListResponse[]>([]);
  const [categorySearchValue, setCategorySearchValue] = useState<string>("");
  const [originalCategories, setOriginalCategories] = useState<string[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);

  useEffect(() => {
    const getCategories = async () => {
      const refreshedToken = await refreshAndReturnFirebaseToken();
      if (refreshedToken !== null) {
        const fetchedCategories = await getAllCategories(refreshedToken);
        if (fetchedCategories.Err) {
          setCategories([]);
        }
        if (fetchedCategories.Data) {
          setCategories(fetchedCategories.Data);
        }
      }
    };
    getCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sellerDetails]);

  function renderSwitch() {
    return (
      <Switch.Group as="div" className="flex items-center my-2">
        <Switch.Label as="span" className="mr-3">
          <span className="text-sm font-medium text-gray-900">Grid View</span>
        </Switch.Label>
        <Switch
          checked={gridViewEnabled}
          onChange={setGridViewEnabled}
          className={classNames(
            !gridViewEnabled ? "bg-indigo-600" : "bg-gray-200",
            "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          )}
        >
          <span className="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            className={classNames(
              !gridViewEnabled ? "translate-x-5" : "translate-x-0",
              "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
            )}
          />
        </Switch>
        <Switch.Label as="span" className="ml-3">
          <span className="text-sm font-medium text-gray-900">List View</span>
        </Switch.Label>
      </Switch.Group>
    );
  }
  useEffect(() => {
    const fetchCategory = async () => {
      if (categoryCollectionSlug) {
        const refreshedToken = await refreshAndReturnFirebaseToken();
        if (refreshedToken !== null) {
          const fetchedCategory = await getCategory(refreshedToken, categoryCollectionSlug, 0);
          if (fetchedCategory.Err) {
            setCategoryCollection({} as CategoryDetailsResponse);
          }
          if (fetchedCategory.Data) {
            setCategoryCollection(fetchedCategory.Data);
            const initialSelectedCategories = categories
              .filter((cat) => cat.Parents?.includes(fetchedCategory!.Data!.Id))
              .map((cat) => cat.Id);
            setOriginalCategories([...initialSelectedCategories]);
            setSelectedCategories([...initialSelectedCategories]);
          }
        }
      }
    };
    fetchCategory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sellerDetails, categoryCollectionSlug, categories]);

  const saveCategories = async () => {
    // setLoading(true);
    setSavingCategories(true);
    removeNotificationByTag("CategoryCollection");
    const removedCategories = originalCategories.filter(
      (catId) => !selectedCategories.includes(catId)
    );
    const refreshedToken = await refreshAndReturnFirebaseToken();
    if (refreshedToken !== null && categoryCollectionSlug != null) {
      await toggleCollectionsOfCategory(
        refreshedToken,
        selectedCategories ?? [],
        removedCategories ?? [],
        categoryCollectionSlug
      );
      removeNotificationByTag("CategoryCollection");
      addNotification("success", "Successfully updated the categories list!", "CategoryCollection");
    }
    setSavingCategories(false);
    // setLoading(false);
  };
  const handleSave = async () => {
    const newCategory = Object.assign({}, categoryUpdatedDetails);

    if (newCategory.Name.length < 3) {
      alert("Please enter a category name that is greater than 3 characters");
      return;
    }
    if (categoryCollectionSlug == null || categoryCollection == null) {
      return;
    }
    const slug = slugify(newCategory.Name.trim());
    newCategory.Slug = slug;

    newCategory.HistoricalSlugs = newCategory.HistoricalSlugs.length
      ? [...newCategory.HistoricalSlugs, slug].filter((value, index, self) => {
          return self.indexOf(value) === index;
        })
      : [slug];

    const refreshedToken = await refreshAndReturnFirebaseToken();
    if (refreshedToken !== null) {
      setIsUpdatingCategory(true);
      const res = await editCategory(refreshedToken, categoryCollectionSlug, newCategory);
      if (res.Data) {
        setCategoryCollection({ ...categoryCollection, Name: res.Data.Name, Slug: res.Data.Slug });
        navigate(`/category-collections/${res.Data.Slug}`);
        addNotification(
          "success",
          "Successfully updated the Category Collection!",
          "CategoryCollection"
        );
      }
      setIsOpen(false);

      setIsUpdatingCategory(false);
    }
  };

  if (sellerDetails === null || categoryCollection === null) {
    return <FiLoader className="animate-spin"></FiLoader>;
  }

  return (
    <div className="my-4 sm:px-6 lg:px-8">
      <div>
        <h2 className="flex items-center text-3xl py-2 font-light leading-8 text-gray-900 sm:text-4xl sm:truncate">
          {categoryCollection.Name}
          <button
            className="ml-2 text-blue-600 text-base"
            onClick={() => {
              setCategoryUpdatedDetails(categoryCollection);
              setIsOpen(true);
            }}
          >
            Edit
          </button>
        </h2>
      </div>
      <h3 className="text-xl font-light mb-4">Categories</h3>
      <EditCategoryCollectionModal
        category={categoryUpdatedDetails}
        setCategory={setCategoryUpdatedDetails}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        handleSave={handleSave}
        isUpdatingCategory={isUpdatingCategory}
        setIsUpdatingCategory={setIsUpdatingCategory}
      />

      <div className="flex items-between justify-between mb-4">
        {renderSwitch()}
        <input
          type="text"
          value={categorySearchValue}
          placeholder="Search"
          onChange={(e) => setCategorySearchValue(e.target.value)}
          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full max-w-sm shadow-sm sm:text-sm border-gray-300 rounded-md"
        />
      </div>
      {gridViewEnabled ? (
        <CategoryGrid
          categories={(categories || []).filter((cat) =>
            categorySearchValue.trim() !== ""
              ? cat.Name.toLowerCase().includes(categorySearchValue.toLowerCase())
              : true
          )}
          selectedCategories={selectedCategories}
          setSelectedCategories={setSelectedCategories}
        />
      ) : (
        <CategoryList
          categories={(categories || []).filter((cat) =>
            categorySearchValue.trim() !== ""
              ? cat.Name.toLowerCase().includes(categorySearchValue.toLowerCase())
              : true
          )}
          selectedCategories={selectedCategories}
          setSelectedCategories={setSelectedCategories}
        />
      )}
      <div className="py-6 flex items-center justify-end">
        <button
          className="inline-flex items-center px-4 py-2 text-base font-medium text-white bg-indigo-800 border border-transparent rounded-md shadow-sm hover:bg-green-00 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700"
          onClick={saveCategories}
        >
          {savingCategories ? "Saving..." : "Save"}
        </button>
      </div>
    </div>
  );
};

export default CategoryCollectionDetails;
