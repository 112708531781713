import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import countries from "../../../lib/constant/countryCodes";

type ModalProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  setCountryKey: (newValue: string) => void;
  setCountryCode: (newValue: string) => void;
};

const CountryModal = (props: ModalProps) => {
  const { isOpen, setIsOpen, setCountryKey, setCountryCode } = props;
  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className="w-full max-w-md p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl"
                style={{ maxHeight: "500px", overflowY: "auto" }}
              >
                <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                  Select your country
                </Dialog.Title>
                <div className="mt-4">
                  {Object.entries(countries).map((country) => (
                    <div className="border-b border-gray-220 pb-2 mt-2 grid grid-flow-col">
                      <div
                        key={country[0]}
                        onClick={() => {
                          setCountryCode(country[1].callingCode[0]);
                          setCountryKey(country[0]);
                          setIsOpen(false);
                        }}
                      >
                        <div className="flex gap-2">
                          <img
                            src={country[1].flag}
                            style={{ width: "25px", height: "20px", marginTop: "4px" }}
                          />
                          <p>{"+" + country[1].callingCode[0]}</p>
                        </div>
                      </div>
                      <p style={{ marginLeft: "auto" }}>{country[1].name.common}</p>
                    </div>
                  ))}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default CountryModal;
