import React from "react";

const BulkUploadInstructions = () => {
  return (
    <div className="my-4">
      <h3 className="font-bold text-2xl text-center mb-6">Instructions</h3>
      <ol className="list-decimal pl-8 marker:font-bold grid gap-y-2">
        <li>You'll need an airtable account for this process.</li>
        <li>
          <a
            className="text-indigo-600 hover:text-indigo-500"
            href="https://airtable.com/invite/l?inviteId=inviGzPSijmxhNYd0&inviteToken=b2595decf30dae121ad1eddf1a14cc02696c7804ab23a17387eb159114d92f92&utm_medium=email&utm_source=product_team&utm_content=transactional-alerts"
            target="_blank"
          >
            Open this Airtable
          </a>
        </li>
        <li>
          You can duplicate this airtable base by clicking on the dropdown menu arrow next to the
          title of the base, then clicking on the three dot menu on the right side of this modal to
          open up the base menu. From there you can click the "Duplicate base" option
          <img
            className="w-full max-w-full mt-4 rounded-md"
            src="/airtable-duplicate-base.jpg"
          ></img>
        </li>
        <li>You can add multiple products in this table. Make sure to not add empty rows.</li>
        <li>
          For product with multiple variants, add multiple rows with same `Unique Identifier` value.
        </li>
        <li>You can directly upload your product photos to your airtable base.</li>
        <li>Once done you can download the csv and upload it here.</li>
        <li>
          To download the csv file, click on the view menu, clicking on the name of the view, then
          selecting the "Download CSV" menu option.
        </li>
        <li>
          You can edit product after uploading. You might have to wait some time for images to get
          uploaded from airtable before clicking upload button.
        </li>
      </ol>
    </div>
  );
};

export default BulkUploadInstructions;
