import React from "react";
import { Link } from "react-router-dom";
import { AnalyticsData } from "../../../../lib/types";
import StoreFrontDetails from "../StorefrontDetails";

type StorefrontStatsProps = {
  selectedChoiceIndex: number;
  setSelectedChoiceIndex: (val: number) => void;
  analytics: AnalyticsData | null;
  choices: {
    label: string;
    value: "today" | "week" | "month" | "yesterday" | "lifetime";
  }[];
  setStartTs: (val: number) => void;
  setEndTs: (val: number) => void;
  open: boolean;
  setOpen: (open: boolean) => void;
  slug: string;
  currencySymbol: string;
};

const StorefrontStats = (props: StorefrontStatsProps) => {
  const analytics = [
    {
      name: "Revenue",
      data:
        props.currencySymbol +
        (props.analytics
          ? Number(props.analytics?.Revenue.toFixed(0)).toLocaleString("en-US")
          : "0"),
      className: "col-span-2",
    },
    {
      name: "Orders",
      data: props.analytics ? props.analytics.Orders.toLocaleString("en-US") : 0,
      className: "col-span-1",
    },
    {
      name: "Avg. Order Value",
      data:
        props.currencySymbol +
        (props.analytics && props.analytics?.Aov
          ? Number(props.analytics.Aov.toFixed(0)).toLocaleString("en-US")
          : "0"),
      className: "col-span-1",
    },
    {
      name: "Conversion Rate",
      data:
        (props.analytics && props.analytics.ConvRate ? props.analytics.ConvRate.toFixed(2) : "0") +
        "%",
      className: "col-span-2",
    },
    {
      name: "Views",
      data: props.analytics ? props.analytics.Views.toLocaleString("en-US") : 0,
      className: "col-span-2",
    },
    {
      name: "Sales Popup Conversion Rate",
      data:
        (props.analytics && props.analytics.SalesPopupConvRate
          ? props.analytics.SalesPopupConvRate.toFixed(2)
          : "0") + "%",
      className: "col-span-2",
    },
    {
      name: "Abandoned Cart to Orders Conversion Rate",
      data:
        (props.analytics && props.analytics.AbandonedCartConvRate
          ? props.analytics.AbandonedCartConvRate.toFixed(2)
          : "0") + "%",
      className: "col-span-2",
    },
  ];

  return (
    <div>
      <div className="flex items-center justify-end mb-6">
        <select
          className="block mt-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          value={String(props.selectedChoiceIndex)}
          onChange={(e) => {
            const value = e.target.value;
            if (value === "0") {
              const startTime = new Date();
              startTime.setHours(0, 0, 0, 0);
              const endTime = new Date();
              props.setStartTs(Number(startTime) / 1000);
              props.setEndTs(Number(endTime) / 1000);
            } else if (value === "1") {
              const endTime = new Date();
              endTime.setHours(0, 0, 0, 0);
              const startTime = Number(endTime) - 24 * 60 * 60 * 1000;
              props.setStartTs(Number(startTime) / 1000);
              props.setEndTs(Number(endTime) / 1000);
            } else if (value === "2") {
              const startTime = Number(new Date()) - 7 * 24 * 60 * 60 * 1000;
              const endTime = new Date();
              props.setStartTs(Number(startTime) / 1000);
              props.setEndTs(Number(endTime) / 1000);
            } else if (value == "3") {
              const startTime = Number(new Date()) - 30 * 24 * 60 * 60 * 1000;
              const endTime = new Date();
              props.setStartTs(Number(startTime) / 1000);
              props.setEndTs(Number(endTime) / 1000);
            } else {
              const startTime = 1422729000000;
              const endTime = new Date();
              props.setStartTs(Number(startTime) / 1000);
              props.setEndTs(Number(endTime) / 1000);
            }
            props.setSelectedChoiceIndex(Number(e.target.value));
          }}
        >
          {props.choices.map((choice, i) => (
            <option value={i}>{choice.label}</option>
          ))}
        </select>
      </div>
      <div className="grid grid-cols-4 grid-rows-3 gap-4 w-full mb-4">
        <div className="py-1 px-4 border border-gray-250 border-solid rounded-md row-span-2 col-span-2">
          <StoreFrontDetails slug={props.slug} open={props.open} setOpen={props.setOpen} />
        </div>
        {analytics.map((item) => {
          return (
            <div
              className={`py-5 px-4 border border-solid border-gray-250 rounded-md ${item.className}`}
            >
              <h3 className="font-medium text-xs text-black">{item.name}</h3>
              <p className="text-3xl font-bold tracking-tighter">{item.data}</p>
            </div>
          );
        })}
      </div>
      <div className="grid grid-cols-2 gap-4 w-full mb-10">
        <div className="py-1 px-2">
          <p className="font-bold text-base tracking-tighter mb-2">Best Selling Products</p>
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Product Name
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    No. of Orders
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Link
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {props.analytics &&
                  props.analytics.BestSellers &&
                  props.analytics?.BestSellers.slice(0, 5).map((bs) => (
                    <tr>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900 text-left">{bs.Name}</div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900 text-center">{`${bs.Count}`}</div>
                      </td>
                      <td className="px-4 py-2 whitespace-nowrap text-sm font-medium flex justify-end">
                        <Link
                          to={`/products/${bs.Slug}`}
                          className="text-gray-900 hover:text-indigo-600 text-sm rounded-md pr-2 pl-2 p-1 border border-solid border-gray-300"
                        >
                          View
                        </Link>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="py-1 px-2">
          <p className="font-bold text-base tracking-tighter mb-2">Top Search Terms</p>
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Query
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    No of Searches
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {props.analytics &&
                  props.analytics.TopSearches &&
                  props.analytics?.TopSearches.map((ts) => (
                    <tr>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900 text-left">{ts.Query}</div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900 text-right">{`${ts.Count}`}</div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StorefrontStats;
