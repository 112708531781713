import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { FiLoader, FiPlus } from "react-icons/fi";
import { ApiContext } from "../../../../lib/services/api";
import { AuthContext } from "../../../../lib/services/auth";
import type { CategoryCollectionListResponse, CreateCategoryRequest } from "../../../../lib/types";
import CategoryCollectionTable from "./components/CategoryCollectionTable";
import AddCategoryCollectionModal from "./components/AddCategoryCollectionModal";
import { NotificationContext } from "../../../../lib/services/notification";
import DeleteCategoryCollectionConfirmation from "./components/DeleteCategoryCollectionConfirmation";
import { Switch } from "@headlessui/react";

const CategoryCollections = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [categoryToDeleteSlug, setCategoryToDeleteSlug] = useState<string | null>(null);
  const { sellerDetails, refreshAndReturnFirebaseToken, updateSellerDetails } =
    useContext(AuthContext);
  const [categoryCollectionState, setCategoryCollectionState] = useState<boolean>(
    sellerDetails?.WebsiteConfig.CategoryCollections ?? false
  );
  const { getAllCategoryCollections, createCategory, deleteCategoryCollection } =
    useContext(ApiContext);
  const [categoryCollections, setCategoryCollections] = useState<CategoryCollectionListResponse[]>(
    []
  );
  const { addNotification, removeNotificationByTag } = useContext(NotificationContext);

  useEffect(() => {
    const fetchCategories = async () => {
      const refreshedToken = await refreshAndReturnFirebaseToken();
      if (refreshedToken !== null) {
        const fetchedProducts = await getAllCategoryCollections(refreshedToken);
        if (fetchedProducts.Err) {
          setCategoryCollections([]);
        }
        if (fetchedProducts.Data) {
          setCategoryCollections(fetchedProducts.Data);
        }
      }
    };
    fetchCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sellerDetails, deleteCategoryCollection]);

  if (sellerDetails === null) {
    return <FiLoader className="animate-spin"></FiLoader>;
  }

  const createNewCategoryCollection = async (newCategory: CreateCategoryRequest) => {
    const refreshedToken = await refreshAndReturnFirebaseToken();
    if (refreshedToken !== null) {
      const result = await createCategory(refreshedToken, newCategory);
      if (result.Err) {
        alert(result.Err.message);
      } else {
        removeNotificationByTag("Category");
        addNotification("success", "Successfully added a new category!", "Category");
        navigate(`/category-collections/${result.Data?.Slug}`);
      }
    }
  };

  const removeCategoryCollection = async (slug: string | null) => {
    if (!slug) return;
    const refreshedToken = await refreshAndReturnFirebaseToken();
    if (refreshedToken !== null) {
      const deletedCategoryCollection = await deleteCategoryCollection(refreshedToken, slug);
      if (deletedCategoryCollection.Data) {
        setCategoryCollections(categoryCollections.filter((category) => slug !== category.Slug));
        removeNotificationByTag("Category");
        addNotification("success", "Successfully deleted a category!", "Category");
      }
    }
  };

  const handleDeleteCategoryCollection = (slug: string) => {
    setCategoryToDeleteSlug(slug);
  };

  const toggleCategoryCollectionFeature = async (categoryCollectionsState: boolean) => {
    if (sellerDetails) {
      const updateCategoryCollection = { WebsiteConfig: sellerDetails.WebsiteConfig };
      updateCategoryCollection.WebsiteConfig.CategoryCollections = categoryCollectionsState;
      const refreshedToken = await refreshAndReturnFirebaseToken();
      if (refreshedToken) {
        await updateSellerDetails(refreshedToken, updateCategoryCollection);
      }
    }
  };

  return (
    <div className="my-4 sm:px-6 lg:px-8">
      <div className="mb-4 flex justify-between align-center content-center">
        <div className="flex flex-col justify-center">
          <h2 className="py-2 text-3xl font-light leading-8 text-gray-900 sm:text-4xl sm:truncate sm:px-6 lg:px-8">
            Super Categories
          </h2>
          <div className="sm:px-6 lg:px-8 my-2 flex flex-row items-center">
            <div className="text-sm">{`Switch ${
              categoryCollectionState ? "Off" : "On"
            } Super Categories`}</div>
            <Switch.Group as="div" className="flex items-center mx-4">
              <Switch
                checked={true}
                onChange={() => {
                  setCategoryCollectionState((prev) => {
                    toggleCategoryCollectionFeature(!prev);
                    return !prev;
                  });
                }}
                className={` ${categoryCollectionState ? "bg-indigo-600" : "bg-gray-400"}
                relative inline-flex flex-shrink-0 h-4 w-8 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
              >
                <span className="sr-only">Use setting</span>
                <span
                  aria-hidden="true"
                  className={`${categoryCollectionState ? "translate-x-4" : "translate-x-0"}
                  pointer-events-none inline-block h-3 w-3 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`}
                />
              </Switch>
            </Switch.Group>
          </div>
        </div>
        <div className="flex justify-end px-8 mb-2 items-center">
          <button
            onClick={() => setIsOpen(true)}
            type="button"
            disabled={categoryCollections.length >= 5}
            className={`inline-flex items-center px-4 py-2 text-base font-medium text-white 
               ${
                 categoryCollections.length < 5
                   ? "bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500"
                   : "bg-gray-600 hover:bg-gray-700 focus:ring-gray-500"
               }
             border border-transparent rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2`}
          >
            {categoryCollections.length < 5 ? (
              <>
                <FiPlus />
                Add New
              </>
            ) : (
              "Only 5 Super Categories Allowed"
            )}
          </button>
          <AddCategoryCollectionModal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            createCategoryCollection={createNewCategoryCollection}
          />
        </div>
      </div>
      <CategoryCollectionTable
        categoryCollections={categoryCollections}
        deleteCategoryCollection={handleDeleteCategoryCollection}
      />
      <DeleteCategoryCollectionConfirmation
        categoryToDeleteSlug={categoryToDeleteSlug}
        setCategoryToDeleteSlug={setCategoryToDeleteSlug}
        onConfirmation={() => {
          setCategoryToDeleteSlug(null);
          removeCategoryCollection(categoryToDeleteSlug);
        }}
      />
    </div>
  );
};

export default CategoryCollections;
