import React, { useContext, useEffect, useState } from "react";
import { BiSearch } from "react-icons/bi";
import { FiLoader } from "react-icons/fi";

import { ApiContext, ApiResult } from "../../lib/services/api";
import { AuthContext } from "../../lib/services/auth";
import type { TypeaheadResponse } from "../../lib/types";
import FlexibleImage from "../FlexibleImage";

export type DesktopSearchProps = {
  storeId: string;
} & TestingProps;

const DesktopSearch = React.memo<DesktopSearchProps>(function DesktopSearch(props) {
  const apiCtx = useContext(ApiContext);
  const { refreshAndReturnFirebaseToken } = useContext(AuthContext);

  const [focus, setFocus] = useState(false);
  const [suggestions, setSuggestions] = useState<ApiResult<TypeaheadResponse> | null>();
  const [query, setQuery] = useState("");
  const [typeaheadTimeout, setTypeaheadTimeout] = useState<unknown | null>(null);

  useEffect(() => {
    if (query != "") {
      if (typeaheadTimeout != null) {
        clearTimeout(typeaheadTimeout as never);
      }
      setTypeaheadTimeout(
        setTimeout(async () => {
          const refreshedToken = await refreshAndReturnFirebaseToken();
          setSuggestions(await apiCtx.typeahead(refreshedToken!, props.storeId, query));
          setTypeaheadTimeout(null);
        }, 500)
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return (
    <div data-testid={props["data-testid"]} className="relative">
      <input
        type="text"
        role="searchbox"
        onFocus={() => setFocus(true)}
        onBlur={() => setTimeout(() => setFocus(false), 500)}
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        placeholder={`Search Products`}
        className="px-4 bg-gray-100 w-full h-10 rounded-md outline-0 ring-2 ring-gray-200 focus:ring-blue-400"
      />
      <button
        className="absolute h-full text-gray-500 top-0 right-0 flex items-center p-2"
        role="search"
      >
        {typeaheadTimeout == null ? (
          <BiSearch size={20} />
        ) : (
          <FiLoader size={20} className="animate-spin" />
        )}
      </button>

      {suggestions?.Data && focus && (
        <div className="absolute top-12 max-h-96 overflow-y-auto z-50 bg-white shadow-md overflow-hidden rounded border border-gray-200 w-full">
          {suggestions.Data.Suggestions.map((s, i) => (
            <div
              className={`flex items-center ${
                i != suggestions.Data!.Suggestions.length - 1 ? "border-b" : ""
              } border-gray-100 hover:bg-blue-100`}
            >
              <div className="w-12 h-12 mr-2 relative">
                <FlexibleImage img={{ ...s.Images[0], objectFit: "cover" }} alt="" />
              </div>
              <p className="flex-1 text-sm whitespace-nowrap overflow-hidden text-ellipsis">
                {s.Name}
              </p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
});

export default DesktopSearch;
