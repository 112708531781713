import React from "react";
import { Order } from "../../../../../lib/types";

type Props = {
  total: number;
  itemTotal: number;
  shippingCharge: number;
  paymentCharge: number;
  couponDetails: Order["Coupon"];
  customRequirements: string | null;
  currencySymbol: string;
};

const OrderValue: React.FC<Props> = React.memo(
  ({
    couponDetails,
    currencySymbol = "₹",
    customRequirements,
    itemTotal,
    shippingCharge,
    total,
  }) => {
    return (
      <div className="w-full flex flex-col my-4">
        {customRequirements && (
          <>
            <div className="w-full my-2">
              <div className="relative w-full">
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center">
                  <h6 className="px-2 bg-white text-gray-500">Custom Requirements</h6>
                </div>
              </div>
            </div>
            <div>
              <span>{customRequirements}</span>
            </div>
          </>
        )}
        <div className="w-full my-2">
          <div className="relative w-full">
            <div className="absolute inset-0 flex items-center" aria-hidden="true">
              <div className="w-full border-t border-gray-300" />
            </div>
            <div className="relative flex justify-center">
              <h6 className="px-2 bg-white text-gray-500 font-light text-lg">Order Summary</h6>
            </div>
          </div>
        </div>
        <div className="w-5/6 flex flex-col items-center justify-center self-center">
          <div className="w-full flex flex-row justify-evenly my-1">
            <span className="w-1/2 text-left font-light">Subtotal</span>
            <span className="w-1/2 text-right font-light">
              {currencySymbol}
              {itemTotal}
            </span>
          </div>
          <div className="w-full flex flex-row justify-evenly my-1">
            <span className="w-1/2 text-left font-light">Shipping Fee</span>
            <span className="w-1/2 text-right font-light">
              {currencySymbol}
              {shippingCharge}
            </span>
          </div>
          {couponDetails && (
            <div className="w-full flex flex-row justify-evenly my-1">
              <span className="w-1/2 text-left font-light">Discount ({couponDetails.Code})</span>
              <span className="w-1/2 text-right font-light">
                - {currencySymbol}
                {couponDetails.DiscountedAmount / 100}
              </span>
            </div>
          )}
          <div className="w-full flex flex-row justify-evenly my-1">
            <span className="w-1/2 text-left font-light text-primary">Total</span>
            <span className="w-1/2 text-right font-light text-primary">
              {currencySymbol}
              {total}
            </span>
          </div>
        </div>
      </div>
    );
  }
);

export default OrderValue;
