import React, { useState, useContext, useRef, useEffect } from "react";
import countries from "../../lib/constant/countryCodes";
import { AuthContext } from "../../lib/services/auth";
import { useBodyClass, withTestId } from "../../lib/utils";
import CountryModal from "./CountryModal";
import currencySymbols from "../../lib/constant/currencySymbols";

function Auth() {
  const { getOtp, verifyOtp } = useContext(AuthContext);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const [timer, setTimer] = useState(0);
  const timerIntervalRef = useRef<number | undefined>();
  const [countryKey, setCountryKey] = useState("IN");
  const [countryCode, setCountryCode] = useState("91");
  const [OTPRequested, setOTPRequested] = useState(false);
  const [isOTPResent, setIsOTPResent] = useState(false);
  const [isRequestingOTP, setIsRequestingOTP] = useState(false);
  const [isVerifyingOTP, setIsVerifyingOTP] = useState(false);
  const [open, setOpen] = useState(false);
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);

  useEffect(() => {
    if (
      !isPhoneNumberValid &&
      phoneNumber &&
      countries[countryKey].phoneLength.includes(phoneNumber.length)
    ) {
      setIsPhoneNumberValid(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneNumber, countryKey]);

  const startTimer = () => {
    setTimer(30);
    timerIntervalRef.current = window.setInterval(() => {
      setTimer((value) => {
        if (value <= 0) {
          window.clearInterval(timerIntervalRef.current);
          return 0;
        }
        return Math.max(value - 1, 0);
      });
    }, 1000);
  };

  /**Here we are generating the verifier id and otp for authenticating user */
  const generateOtp = async () => {
    setError("");
    setIsRequestingOTP(true);
    const res = await getOtp(`${phoneNumber}`, `+${countryCode}`);
    setIsRequestingOTP(false);
    if (!res?.Err) {
      setOTPRequested(true);
      startTimer();
      return;
    }
    setError(res.Err?.message || "Something went wrong!");
  };

  /**Here we are verifying the otp */
  const verify = async () => {
    setIsOTPResent(false);
    setError("");
    setIsVerifyingOTP(true);
    const res = await verifyOtp(`${phoneNumber}`, countryCode, otp, {
      countryCode: countryKey,
      currencyCode: countries[countryKey].currency[0],
      callingCode: countries[countryKey].callingCode[0],
      currencySymbol:
        currencySymbols[countries[countryKey].currency[0]] ?? countries[countryKey].currency[0],
      phoneLength: countries[countryKey].phoneLength,
    });
    setIsVerifyingOTP(false);
    if (!res?.Err) {
      return;
    }
    setError(res.Err?.message || "Something went wrong!");
  };

  const resendOtp = async () => {
    if (timer <= 0) {
      await generateOtp();
      setIsOTPResent(true);
    }
  };

  useBodyClass(["bg-gray-50"]);

  const phoneNumberValid = () => {
    return phoneNumber && countries[countryKey].phoneLength.includes(phoneNumber.length);
  };

  return (
    <main className="sm:px-6 lg:px-8">
      <div
        {...withTestId("auth-div")}
        className="flex flex-col justify-center min-h-screen py-12 sm:px-6 lg:px-8"
      >
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            {...withTestId("auth-div-logo")}
            className="w-auto h-16 mx-auto"
            src="/imgbin/instasell-new-logo.svg"
            alt="Instasell"
          />
          <h2
            {...withTestId("auth-div-heading")}
            className="mt-6 text-3xl py-2 font-light text-center text-gray-900"
          >
            Sign in to your account
          </h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="px-4 py-8 bg-white rounded-lg shadow sm:px-10">
            <form
              {...withTestId("auth-form")}
              className="space-y-6"
              onSubmit={(e) => {
                e.preventDefault();
                if (phoneNumberValid()) {
                  setIsPhoneNumberValid(true);
                  if (OTPRequested) {
                    verify();
                  } else {
                    if (!isRequestingOTP) {
                      generateOtp();
                    }
                  }
                } else setIsPhoneNumberValid(false);
              }}
            >
              {OTPRequested ? null : (
                <div>
                  <label
                    {...withTestId("phone-label")}
                    htmlFor="phonenumber"
                    className="block text-sm font-bold text-gray-700"
                  >
                    Phone Number
                  </label>
                  <div className="mt-1 flex gap-4">
                    <div
                      className="flex gap-2 border border-gray-300 rounded-md p-1 w-28"
                      onClick={() => setOpen(true)}
                    >
                      <img
                        src={countries[countryKey].flag}
                        style={{ width: "25px", height: "20px", marginTop: "4px" }}
                      />
                      <p style={{ marginTop: "2px" }}>{"+" + countryCode}</p>
                    </div>
                    <input
                      {...withTestId("phone-input")}
                      id="phonenumber"
                      name="phonenumber"
                      type="tel"
                      autoComplete="phonenumber"
                      required
                      className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                  </div>
                  {!isPhoneNumberValid ? (
                    <p className="px-4 py-2 my-4 text-red-500 bg-red-500 border border-red-500 border-solid rounded bg-opacity-5">
                      Please enter a valid number
                    </p>
                  ) : null}
                </div>
              )}
              {OTPRequested ? (
                <div>
                  <p className="mb-4 text-xs text-gray-500">
                    We have sent the verification code to <br />
                    <span className="font-bold">{countryCode + phoneNumber} </span>
                    <button
                      type="button"
                      className="text-secondary"
                      onClick={() => {
                        setOTPRequested(false);
                      }}
                    >
                      Change Phone Number?
                    </button>
                  </p>

                  <label
                    {...withTestId("otp-label")}
                    htmlFor="otp"
                    className="block text-sm font-bold text-gray-700"
                  >
                    OTP
                  </label>
                  <div className="mt-1">
                    <input
                      id="otp"
                      name="otp"
                      type="text"
                      required
                      className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                    />
                  </div>
                  <p className="font-bold text-xs text-center mt-4">
                    00:{timer.toString().padStart(2, "0")}
                  </p>
                </div>
              ) : null}
              {isOTPResent ? (
                <p className="px-4 py-2 my-4 text-green-500 bg-green-500 border border-green-500 border-solid rounded bg-opacity-5">
                  OTP has been successfully resent!
                </p>
              ) : null}
              {error ? (
                <p className="px-4 py-2 my-4 text-red-500 bg-red-500 border border-red-500 border-solid rounded bg-opacity-5">
                  {error}
                </p>
              ) : null}
              {OTPRequested ? (
                <div className="my-8 grid grid-cols-2 gap-x-4">
                  <button
                    id="login-btn"
                    type="button"
                    disabled={timer !== 0}
                    onClick={() => resendOtp()}
                    className={`w-full font-bold flex justify-center py-2 px-4 border rounded-md shadow-sm text-sm ${
                      isRequestingOTP
                        ? "bg-white text-primary border-primary"
                        : "bg-white text-primary border-primary"
                    } disabled:text-opacity-75 disabled:border-opacity-75 disabled:cursor-not-allowed focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                  >
                    Resend
                  </button>

                  <button
                    id="login-btn"
                    type="submit"
                    className={`w-full font-bold flex justify-center py-2 px-4 border rounded-md shadow-sm text-sm ${
                      isVerifyingOTP
                        ? "bg-white text-primary border-primary"
                        : "text-white bg-primary hover:bg-opacity-95 border-transparent"
                    } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                  >
                    {isVerifyingOTP ? "Verifying.." : "Sign In"}
                  </button>
                </div>
              ) : (
                <div className="my-8">
                  <button
                    id="login-btn"
                    type="submit"
                    className={`w-full font-bold flex justify-center py-2 px-4 border rounded-md shadow-sm text-sm ${
                      isRequestingOTP
                        ? "bg-white text-primary border-primary"
                        : "text-white bg-primary hover:bg-opacity-95 border-transparent"
                    } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                  >
                    {isRequestingOTP ? "Sending OTP..." : "Get OTP"}
                  </button>
                </div>
              )}
            </form>
            <CountryModal
              isOpen={open}
              setIsOpen={setOpen}
              setCountryKey={setCountryKey}
              setCountryCode={setCountryCode}
            />
          </div>
        </div>
      </div>
    </main>
  );
}

export default Auth;
