import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Switch } from "@headlessui/react";

import ProductGridView from "./components/ProductGridView";
import ProductListView from "./components/ProductListView";
import PagingControls from "../../../components/PagingControls";
import { ProductListResponse } from "../../../lib/types";
import { AuthContext } from "../../../lib/services/auth";
import { ApiContext } from "../../../lib/services/api";
import { FiLoader } from "react-icons/fi";
import usePagination from "../../../hooks/usePagination";
import { NotificationContext } from "../../../lib/services/notification";

const RESULT_PER_PAGE = 24;

function Products() {
  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
  }

  const navigate = useNavigate();

  const {
    canGoBack,
    goBack,
    nextCursor,
    nextPage,
    prevCursor,
    prevPage,
    setGoBack,
    setNextCursor,
    setPrevCursor,
    page,
  } = usePagination();

  const { sellerDetails, refreshAndReturnFirebaseToken, localeDetails } = useContext(AuthContext);
  const { fetchProducts, toggleProduct } = useContext(ApiContext);
  const { addNotification } = useContext(NotificationContext);

  const [products, setProducts] = useState<ProductListResponse[]>([]);
  const [productSearchValue, setProductSearchValue] = useState<string>("");
  const [gridViewEnabled, setGridViewEnabled] = useState(false);

  useEffect(() => {
    const getProducts = async () => {
      const refreshedToken = await refreshAndReturnFirebaseToken();
      if (refreshedToken !== null) {
        const fetchedProducts = await fetchProducts(
          refreshedToken,
          goBack ? 1 : 0,
          goBack ? null : nextCursor,
          goBack ? prevCursor : null,
          RESULT_PER_PAGE
        );
        if (fetchedProducts.Err) {
          setProducts([]);
        }
        if (fetchedProducts.Data) {
          setProducts(fetchedProducts.Data.products);
          setNextCursor(fetchedProducts.Data.next);
          setPrevCursor(fetchedProducts.Data.prev);
        }
      }
    };
    getProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sellerDetails, page, goBack]);

  const handlePageChange = (backPress: boolean) => {
    if (backPress) {
      prevPage();
    } else {
      nextPage();
    }
    setGoBack(backPress);
  };

  function renderSwitch() {
    return (
      <Switch.Group as="div" className="flex items-center my-2">
        <Switch.Label as="span" className="mr-3">
          <span className="text-sm font-medium text-gray-900">Grid View</span>
        </Switch.Label>
        <Switch
          checked={gridViewEnabled}
          onChange={setGridViewEnabled}
          className={classNames(
            gridViewEnabled ? "bg-primary" : "bg-gray-200",
            "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          )}
        >
          <span className="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            className={classNames(
              gridViewEnabled ? "translate-x-5" : "translate-x-0",
              "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
            )}
          />
        </Switch>
        <Switch.Label as="span" className="ml-3">
          <span className="text-sm font-medium text-gray-900">List View</span>
        </Switch.Label>
      </Switch.Group>
    );
  }

  const toggleProductState = async (productId: string, isActive: boolean) => {
    const refreshedToken = await refreshAndReturnFirebaseToken();
    if (refreshedToken !== null) {
      const result = await toggleProduct(refreshedToken, productId, isActive);
      if (result.Err) {
        alert(result.Err.message);
      }
    }
  };

  if (sellerDetails === null) {
    return <FiLoader className="animate-spin"></FiLoader>;
  }

  return (
    <div className="my-4 sm:px-6 lg:px-8">
      <div className="mb-4 flex justify-between align-center content-center">
        <div className="flex flex-col justify-between">
          <h2 className="text-3xl py-2 font-light leading-8 text-gray-900 sm:text-4xl sm:truncate">
            Products
          </h2>
        </div>
        <div className="flex flex-row justify-center items-center">
          <button
            onClick={() => navigate("bulk-upload")}
            type="button"
            className="inline-flex mr-2 items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-primary hover:bg-opacity-95 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Bulk Upload
          </button>
          <button
            onClick={() => {
              if (
                sellerDetails.InventoryConfig.ProductCount >=
                sellerDetails.InventoryConfig.MaxProductCount
              ) {
                addNotification(
                  "error",
                  "Maximum product limit reached. Delete old products to add new.",
                  "add-product",
                  null,
                  3
                );
                return;
              }
              navigate("add-product");
            }}
            type="button"
            className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-primary hover:bg-opacity-95 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Add Product
          </button>
        </div>
      </div>
      <div className="w-full flex items-between justify-between mb-4">
        {renderSwitch()}
        <input
          type="text"
          value={productSearchValue}
          placeholder="Search"
          onClick={() => navigate("/products/search")}
          onFocus={() => navigate("/products/search")}
          onChange={(e) => setProductSearchValue(e.target.value)}
          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full max-w-sm shadow-sm sm:text-sm border-gray-300 rounded-md"
        />
      </div>{" "}
      {gridViewEnabled ? (
        <ProductListView
          products={(products || []).filter((product) =>
            productSearchValue.trim() !== ""
              ? product.Name.toLowerCase().includes(productSearchValue.toLowerCase())
              : true
          )}
          toggleProductState={toggleProductState}
        ></ProductListView>
      ) : (
        <ProductGridView
          toggleProductState={toggleProductState}
          products={(products || []).filter((product) =>
            productSearchValue.trim() !== ""
              ? product.Name.toLowerCase().includes(productSearchValue.toLowerCase())
              : true
          )}
          currencySymbol={localeDetails.currencySymbol}
        />
      )}
      <div className="w-full my-2 mt-6 flex flex-row justify-center items-center">
        <PagingControls
          nextPage={products.length == RESULT_PER_PAGE ? () => handlePageChange(false) : null}
          previousPage={prevCursor == null || !canGoBack ? null : () => handlePageChange(true)}
        />
      </div>
    </div>
  );
}

export default Products;
