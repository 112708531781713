import React, { useState } from "react";
import { PencilIcon, TrashIcon } from "@heroicons/react/outline";
import { ParsedUniqueProductFields } from "../../../../../lib/types";
import EditProductModal from "../EditProductModal";

type ProductsTableProps = {
  uniqueProducts: ParsedUniqueProductFields[];
  updateProduct: (sku: string, updatedProduct: ParsedUniqueProductFields) => void;
  deleteProduct: (sku: string) => void;
};

const ProductsTable = (props: ProductsTableProps) => {
  const { uniqueProducts, updateProduct, deleteProduct } = props;
  const [isEditingProduct, setIsEditingProduct] = useState(false);
  const [editingProduct, setEditingProductDetails] = useState<ParsedUniqueProductFields | null>(
    null
  );

  return (
    <div className="shadow overflow-auto border-b border-gray-200 sm:rounded-lg">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-100">
          <tr>
            <th
              scope="col"
              className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Product Name
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Description
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Price
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Images
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Stock
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Colors
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Sizes
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider"
            ></th>
          </tr>
        </thead>
        <tbody>
          {uniqueProducts.map((uniqueProduct) => (
            <React.Fragment key={uniqueProduct.Sku}>
              <tr className={uniqueProduct.errors.length > 0 ? "" : "border-b border-gray-200"}>
                <td className="py-2 px-4 text-center">{uniqueProduct.ProductName}</td>
                <td className="py-2 px-4 text-center">
                  <div
                    className="text-black-40 text-sm max-w-sm break-words"
                    role="contentinfo"
                    dangerouslySetInnerHTML={{
                      __html: uniqueProduct.Description.slice(0, 100) + "...",
                    }}
                  ></div>
                </td>
                <td className="py-2 px-4 text-center">
                  {uniqueProduct.Sizes.length > 0 ? (
                    uniqueProduct.Pricing.map((variant) => (
                      <p key={variant.variantSize}>
                        {variant.variantSize}:{" "}
                        <s className="text-xs">₹{variant.variantStrikeOffPrice}</s>₹
                        {variant.variantPrice}
                      </p>
                    ))
                  ) : (
                    <p>
                      <s className="text-xs">₹{uniqueProduct.Pricing[0].variantStrikeOffPrice}</s>₹
                      {uniqueProduct.Pricing[0].variantPrice}
                    </p>
                  )}
                </td>
                <td className="py-2 px-4 text-center">{uniqueProduct.ProductImages.length}</td>
                <td className="py-2 px-4 text-center">{uniqueProduct.Stock}</td>
                <td className="py-2 px-4 text-center">
                  <ul className="text-left pl-4">
                    {uniqueProduct.Colors.map((color) => (
                      <li key={color} className="list-disc">
                        {color}
                      </li>
                    ))}
                  </ul>
                </td>
                <td className="py-2 px-4 text-center">
                  <ul className="text-left pl-4">
                    {uniqueProduct.Sizes.map((size) => (
                      <li key={size} className="list-disc">
                        {size}
                      </li>
                    ))}
                  </ul>
                </td>
                <td className="py-2 px-4 text-center">
                  <button
                    className="cursor-pointer"
                    onClick={() => {
                      setIsEditingProduct(true);
                      setEditingProductDetails(uniqueProduct);
                    }}
                  >
                    <PencilIcon className="h-5 w-5 text-black hover:text-gray-700"></PencilIcon>
                  </button>
                  <button
                    className="cursor-pointer ml-2"
                    onClick={() => {
                      deleteProduct(uniqueProduct.Sku);
                    }}
                  >
                    <TrashIcon className="h-5 w-5 text-black hover:text-gray-700"></TrashIcon>
                  </button>
                </td>
              </tr>

              {uniqueProduct.errors.length > 0 ? (
                <tr className="border-b border-gray-200">
                  <td colSpan={8} className="px-8">
                    <ul className="text-red-500">
                      {uniqueProduct.errors.map((error, index) => (
                        <li key={index} className="list-disc">
                          {error}
                        </li>
                      ))}
                    </ul>
                  </td>
                </tr>
              ) : null}
            </React.Fragment>
          ))}
        </tbody>
      </table>
      {editingProduct ? (
        <EditProductModal
          isOpen={isEditingProduct}
          closeModal={() => {
            setIsEditingProduct(true);
            setEditingProductDetails(null);
          }}
          updateProduct={updateProduct}
          editingProduct={editingProduct}
        />
      ) : null}
    </div>
  );
};

export default ProductsTable;
