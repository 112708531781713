import React from "react";
import { AbandonedCartDetails } from "../../../../../lib/types";
import ProductItem from "./ProductItem";

type Props = {
  orderItems: AbandonedCartDetails["Items"];
  currencySymbol: string;
};

const ProductList = React.memo(({ orderItems, currencySymbol = "₹" }: Props) => {
  return (
    <>
      <div className="w-full my-2">
        <div className="relative w-full">
          <div className="absolute inset-0 flex items-center" aria-hidden="true">
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center">
            <h6 className="px-2 bg-white text-gray-500 font-light text-lg">Cart Items</h6>
          </div>
        </div>
      </div>
      <div className="w-full flex flex-col">
        {orderItems.map((orderItem) => (
          <ProductItem
            key={orderItem.ProductId}
            orderItem={orderItem}
            currencySymbol={currencySymbol}
          />
        ))}
      </div>
    </>
  );
});

export default ProductList;
