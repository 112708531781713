import React from "react";
import { GrClose } from "react-icons/gr";
import FlexibleImage from "../../../../../../components/FlexibleImage";

import { AbandonedCartDetails } from "../../../../../../lib/types";
import { mediaUrl2ImageRes } from "../../../../../../lib/utils";

export type Props = {
  orderItem: AbandonedCartDetails["Items"][0];
  currencySymbol: string;
};

const ProductItem: React.FC<Props> = React.memo(({ orderItem, currencySymbol = "₹" }) => {
  return (
    <tr
      key={orderItem.ProductId}
      className="w-5/6 flex flex-row items-center justify-evenly self-center"
    >
      <td className="py-4 w-1/3 items-center flex flex-col justify-center">
        <div className="flex items-center">
          <div className="flex-shrink-0 h-20 w-20">
            <FlexibleImage
              alt={orderItem.ProductName}
              img={mediaUrl2ImageRes(orderItem.Media)}
              className="w-20 aspect-square rounded-md"
              loading="lazy"
              key={orderItem.ProductId}
            />
          </div>
          <div className="ml-4">
            <div className="text-sm font-light text-gray-900">{orderItem.ProductName}</div>
          </div>
        </div>
      </td>
      <td className="py-4 whitespace-nowrap w-1/3 items-center flex flex-row justify-center">
        <div className="text-gray-900 inline-flex items-center">
          <span className="font-light">{orderItem.Quantity}</span>
          <GrClose className="mx-1 h-2 w-2" /> <span className="font-light">{orderItem.Price}</span>
        </div>
      </td>
      <td className="py-4 whitespace-nowrap w-1/3 flex flex-row justify-end">
        <span className="text-gray-900 font-light">
          {currencySymbol}
          {orderItem.Price * orderItem.Quantity}
        </span>
      </td>
    </tr>
  );
});

export default ProductItem;
