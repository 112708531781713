import React from "react";
import { CouponDetails } from "../../../../../../lib/types";
import CouponCard from "../CouponCard";

type CouponGridProps = {
  coupons: CouponDetails[];
  editCoupon: (coupon: CouponDetails, index: number) => void;
  currencySymbol: string;
  deleteCoupon: (couponId: string) => void;
};

const CouponGrid = React.memo(function CouponGrid({
  coupons,
  editCoupon,
  currencySymbol = "₹",
  deleteCoupon,
}: CouponGridProps) {
  return (
    <div className="w-full grid grid-cols-2 lg:grid-cols-3 gap-2">
      {coupons.map((coupon, index: number) => (
        <CouponCard
          coupon={coupon}
          index={index}
          editCoupon={editCoupon}
          key={coupon.Id}
          currencySymbol={currencySymbol}
          deleteCoupon={deleteCoupon}
        />
      ))}
    </div>
  );
});

export default CouponGrid;
