/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useRef, useContext, MouseEvent, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import CategoryList from "./components/CategoryList";
import VariantsContent from "./components/VariantsContent";
import {
  mediaUrl2ImageRes,
  newVariantId,
  processText,
  resizedUrl,
  uploadImage,
} from "../../../lib/utils";
import { AuthContext } from "../../../lib/services/auth";
import {
  Category,
  ProductDetailsResponse,
  ProductVariation,
  CategoryListResponse,
} from "../../../lib/types";
import AddProductImages from "./components/AddProductImages";
import VariantsTable from "./components/VariantsTable";
import { ApiContext } from "../../../lib/services/api";
import { useNavigate } from "react-router-dom";
import { FiLoader } from "react-icons/fi";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { NotificationContext } from "../../../lib/services/notification";
import { DetailedProductVariant } from "../../../lib/types";
import { Switch } from "@headlessui/react";
import DigitalProduct from "./components/DigitalProduct";
import WarningModal from "./components/DigitalProduct/WarningModal";
import { urlRegex } from "../../../lib/utils";

function AddProduct() {
  const { sellerDetails, user, refreshAndReturnFirebaseToken, localeDetails } =
    useContext(AuthContext);
  const { createProduct, getAllCategories } = useContext(ApiContext);
  const { addNotification, removeNotificationByTag } = useContext(NotificationContext);
  const navigate = useNavigate();
  const [isDigitalProduct, setIsDigitalProduct] = useState(false);
  const [digitalProductURL, setDigitalProductURL] = useState<string>("");
  const [product, setProduct] = useState<Omit<ProductDetailsResponse, "Slug" | "Rating">>({
    Sku: uuidv4(),
    Name: "",
    Categories: [],
    Description: "",
    Images: [],
    SeoDescription: "",
    Variants: [],
    ...(isDigitalProduct && { DigitalProductUrl: digitalProductURL }),
  } as unknown as Omit<ProductDetailsResponse, "Slug" | "Rating">);

  const showErrorNotification = (title: string) => addNotification("error", title, "Product");

  const [, setVariantsData] = useState([]) as any;
  const [categories, setCategories] = useState<CategoryListResponse[]>([]);
  const [open, setOpen] = useState(false);
  const [{ colorVariants, sizeVariants }, setVariants] = useState<{
    colorVariants: string[];
    sizeVariants: string[];
  }>({
    colorVariants: [],
    sizeVariants: [],
  });
  const setColorVariants = (newColorVariants: string[]) =>
    setVariants((variants) => ({ ...variants, colorVariants: newColorVariants }));

  const setSizeVariants = (newSizeVariants: string[]) =>
    setVariants((variants) => ({ ...variants, sizeVariants: newSizeVariants }));

  const hiddenImageFileInput = useRef<HTMLInputElement>(null);

  const variantsTableContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const fetchCategories = async () => {
      const refreshedToken = await refreshAndReturnFirebaseToken();
      if (refreshedToken !== null) {
        const fetchedProducts = await getAllCategories(refreshedToken);
        if (fetchedProducts.Err) {
          setCategories([]);
        }
        if (fetchedProducts.Data) {
          setCategories(fetchedProducts.Data);
        }
      }
    };
    fetchCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sellerDetails]);

  const handleImageUploadClick = (_event: MouseEvent<HTMLButtonElement>) => {
    if (hiddenImageFileInput.current) {
      hiddenImageFileInput.current.click();
    }
  };

  const handleImageChange = async (_event: React.ChangeEvent<HTMLInputElement>) => {
    if (product.Images.length >= 5) {
      addNotification("error", "You can only add a maximum of 5 images", "add-product", null, 3);
      return;
    }
    if (hiddenImageFileInput.current) {
      const files = _event.target.files;
      if (files?.length && user) {
        const imageUploadResponses = await Promise.all(
          Array.from(files)
            .filter((file: Blob) => file != null)
            .map((img) => uploadImage(img, user.uid, false))
        );
        const newProductImages: ProductDetailsResponse["Images"] = [];
        imageUploadResponses.forEach((imageRes) => {
          if (!imageRes) return null;
          const productImage: ImageRes = mediaUrl2ImageRes({
            MediaUrl1: imageRes.MediaUrl1,
            MediaUrl2: imageRes.MediaUrl2,
          });
          newProductImages.push(productImage);
        });

        const newProduct = {
          ...product,
          Images: [...product.Images, ...newProductImages],
        };
        setProduct(newProduct);
      }
    }
  };

  function generateVariantsData() {
    if (colorVariants.length <= 1 && sizeVariants.length <= 1) {
      setVariantsData([]);
    } else {
      let combos = [{}];
      let newcombos = [];
      if (colorVariants.length > 1) {
        newcombos = [];
        for (let i = 0; i < colorVariants.length; i++) {
          for (let j = 0; j < combos.length; j++) {
            newcombos.push({ ...combos[j], color: colorVariants[i] });
          }
        }
        combos = newcombos;
      }
      if (sizeVariants.length > 1) {
        newcombos = [];
        for (let i = 0; i < sizeVariants.length; i++) {
          for (let j = 0; j < combos.length; j++) {
            newcombos.push({ ...combos[j], size: sizeVariants[i] });
          }
        }
        combos = newcombos;
      }

      const temp = [] as any;
      for (let i = 0; i < combos.length; i++) {
        const newCombo: any = combos[i];
        const data: DetailedProductVariant = {
          Variations: [] as ProductVariation[],
          Price: product?.Variants[0]?.Price || 0,
          CmpPrice: product?.Variants[0]?.CmpPrice || 0,
          Stock: product?.Variants[0]?.Stock || 0,
          Images: [],
          ID: uuidv4(),
          Sku: product.Sku,
        };
        if (newCombo.color) {
          data.Variations.push({
            Name: "Color",
            Value: newCombo.color as string,
          });
        }
        if (newCombo.size) {
          data.Variations.push({
            Name: "Size",
            Value: newCombo.size as string,
          });
          const existingProductVariantWithSameSize = product?.Variants?.find((variant) =>
            variant.Variations.some(
              (variation) => variation.Name === "Size" && variation.Value === newCombo.size
            )
          );
          data.Price = existingProductVariantWithSameSize?.Price || data?.Price;
          data.CmpPrice = existingProductVariantWithSameSize?.CmpPrice || data?.CmpPrice;
        }
        temp.push(data);
        if (newCombo.size && newCombo.color) {
          const existingProductVariantWithSameSizeAndColor = product?.Variants?.find(
            (variant) =>
              variant.Variations.some(
                (variation) => variation.Name === "Size" && variation.Value === newCombo.size
              ) &&
              variant.Variations.some(
                (variation) => variation.Name === "Color" && variation.Value === newCombo.color
              )
          );
          data.Stock = existingProductVariantWithSameSizeAndColor?.Stock || data.Stock;
          data.Sku = existingProductVariantWithSameSizeAndColor?.Sku || data.Sku;
          data.ID = existingProductVariantWithSameSizeAndColor?.ID || data.ID;
        }
      }
      const newProduct = product;
      if (newProduct) {
        newProduct.Variants = temp;
      }
      setProduct(newProduct);
      setVariantsData(temp);
      variantsTableContainerRef.current?.scrollIntoView();
    }
  }

  useEffect(() => {
    if (product) {
      generateVariantsData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sizeVariants, colorVariants]);

  function deleteImage(_index: number) {
    const newProduct = Object.assign({}, product);
    newProduct.Images = newProduct.Images.filter(
      (_image: ImageRes, index: number) => index !== _index
    );
    setProduct(newProduct);
  }

  const reorderImages = (x: number, y: number) => {
    if (x < 0 || x >= product.Images.length) {
      return;
    }
    if (y < 0 || y >= product.Images.length) {
      return;
    }
    setProduct({
      ...product,
      Images: product.Images.map((val, i, arr) => {
        if (x === i) {
          return arr[y];
        }
        if (y === i) {
          return arr[x];
        }
        return val;
      }),
    });
  };

  function addSizeOption(_val: string) {
    if (!sizeVariants.includes(_val)) setSizeVariants([...sizeVariants, _val]);
  }

  function addColorOption(_val: string) {
    if (!colorVariants.includes(_val)) setColorVariants([...colorVariants, _val]);
  }

  function removeSizeVariantOption(_option: string) {
    const tempList = sizeVariants.filter((_c: string) => _c !== _option);
    setSizeVariants(tempList);
  }

  function addCategory(_category: Category): void {
    const newProduct = { ...product };
    if (newProduct !== null) {
      if (newProduct.Categories.findIndex((category) => category.ID === _category.ID) !== -1) {
        const newCategories = newProduct.Categories.filter((c: Category) => c.ID !== _category.ID);
        newProduct.Categories = newCategories;
        setProduct(newProduct);
      } else {
        newProduct.Categories.push(_category);
        setProduct(newProduct);
      }
    }
  }

  function removeColorVariantOption(_option: string) {
    const tempList = colorVariants.filter((_c: string) => _c !== _option);
    setColorVariants(tempList);
  }

  if (sellerDetails === null) {
    return <FiLoader className="animate-spin"></FiLoader>;
  }

  const addFirstVariant = (CmpPrice: number | null, Stock: number, Price: number) => {
    const newProduct = Object.assign({}, product);
    const newProdId = uuidv4();

    if (newProduct) {
      newProduct.Variants = [
        {
          Variations: [],
          Price: Price,
          CmpPrice: CmpPrice,
          Stock: Stock,
          Images: [],
          ID: "",
          Sku: newProdId,
        },
      ];
    }
    setProduct(newProduct);
  };

  const updateAllVariantsPriceBySize = (
    size: string,
    detailsToUpdate: { Price?: number; CmpPrice?: number; Stock?: number }
  ) => {
    const newProduct = { ...product };
    newProduct.Variants = product.Variants.map((variant) =>
      variant.Variations.some((variation) => variation.Name === "Size" && variation.Value === size)
        ? { ...variant, ...detailsToUpdate }
        : variant
    );
    setProduct(newProduct);
  };

  const handleChanges =
    (fieldName: string) =>
    (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
      const newProduct = { ...product };
      if (fieldName === "Name") {
        newProduct.Name = e.target.value;
        setProduct(newProduct);
      }
      if (fieldName === "Description") {
        newProduct.Description = e.target.value;
        setProduct(newProduct);
      }
      if (fieldName === "Price") {
        const processedValue = processText(
          e.target.value.replace(localeDetails.currencySymbol, "")
        );
        let value = +processedValue;
        if (isNaN(value)) {
          value = 0;
          return;
        }

        if (!product.Variants.length) {
          addFirstVariant(null, 0, value);
        } else {
          newProduct.Variants = newProduct.Variants.map((variant) => ({
            ...variant,
            Price: value,
          }));
          setProduct(newProduct);
        }
      }
      if (fieldName === "CmpPrice") {
        const processedValue = processText(
          e.target.value.replace(localeDetails.currencySymbol, "")
        );
        let value = +processedValue;
        if (isNaN(value)) {
          value = 0;
          return;
        }

        if (!product.Variants.length) {
          addFirstVariant(value, 0, 0);
        } else {
          newProduct.Variants = newProduct.Variants.map((variant) => ({
            ...variant,
            CmpPrice: value === 0 ? null : value,
          }));
          setProduct(newProduct);
        }
      }
      if (fieldName === "Stock") {
        let val: number = +e.target.value;
        if (isNaN(+e.target.value)) {
          val = 0;
        }
        if (!product.Variants.length) {
          addFirstVariant(null, val, 0);
        } else {
          newProduct.Variants = newProduct.Variants.map((variant) => ({ ...variant, Stock: val }));
          setProduct(newProduct);
        }
      }
    };

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    removeNotificationByTag("Product");
    const newProduct = Object.assign({}, product);

    if (isDigitalProduct) {
      newProduct.DigitalProductUrl = digitalProductURL;
    }

    if (newProduct.Name.length < 3) {
      showErrorNotification("Please enter a valid product name");
      return;
    }
    if (newProduct.Images.length === 0) {
      showErrorNotification("Please upload atleast 1 image");
      return;
    }
    if (newProduct.Variants[0].Price === 0) {
      showErrorNotification("Please enter a valid price");
      return;
    }

    if (
      newProduct.Variants[0] &&
      newProduct.Variants[0].Price != null &&
      newProduct.Variants[0].CmpPrice != null &&
      newProduct.Variants[0].CmpPrice != 0 &&
      newProduct.Variants[0].Price >= newProduct.Variants[0].CmpPrice
    ) {
      showErrorNotification("Price should be lower than strike-off price.");
      return;
    }

    if (newProduct.Variants[0].Stock < 0 && !isDigitalProduct) {
      showErrorNotification("Please enter a valid stock");
      return;
    }

    if (isDigitalProduct && digitalProductURL.length < 3 && !urlRegex.test(digitalProductURL)) {
      showErrorNotification("Please enter a valid digital product URL");
      return;
    }

    newProduct.Variants = newProduct.Variants.map((variant) => {
      return {
        ...variant,
        ID: newVariantId(),
      };
    });

    const refreshedToken = await refreshAndReturnFirebaseToken();
    if (refreshedToken !== null) {
      const newImages = newProduct.Images.map((image: ImageRes) => {
        const img = Object.assign({}, image);
        if (img.webpUrl) {
          if (!img.webpUrl?.includes("_800x800.webp")) {
            img.webpUrl = resizedUrl(img.webpUrl, ".webp");
          }
        }
        if (img.jpgUrl) {
          if (!img.jpgUrl?.includes("_800x800.jpg")) {
            img.jpgUrl = resizedUrl(img.jpgUrl, ".jpg");
          }
        }
        return img;
      });

      const addedProduct = await createProduct(refreshedToken, {
        ...newProduct,
        Images: newImages,
      });
      if (addedProduct.Err) {
        setProduct({} as ProductDetailsResponse);
      }
      if (addedProduct.Data) {
        addNotification("success", "Successfully added the product!", "Product");

        navigate(-1);
      }
    }
  };

  const hasSizeVariants: boolean =
    Array.from(
      new Set(
        (product?.Variants || [])
          .map(
            (variant) =>
              variant.Variations.find((variation) => variation.Name === "Size")?.Value || ""
          )
          .filter((val) => val)
      )
    ).length > 0;

  const lowestPricedVariant = product?.Variants.reduce(
    (acc, variant) => (variant.Price < acc.Price ? variant : acc),
    product?.Variants[0]
  );

  return (
    <div className="my-4 sm:px-6 lg:px-8">
      <div className="mb-2">
        <h2 className="text-3xl py-2 font-light leading-8 text-gray-900 sm:text-4xl sm:truncate">
          Add Product
        </h2>
      </div>
      <form>
        <div className="grid grid-cols-3 mt-10 sm:mt-0">
          <div className="col-span-1">
            <AddProductImages
              images={product.Images}
              reorderImages={reorderImages}
              deleteImage={deleteImage}
              handleImageChange={handleImageChange}
              handleImageUploadClick={handleImageUploadClick}
              hiddenImageFileInput={hiddenImageFileInput}
            />
          </div>
          <div className="col-span-2">
            <div className="shadow overflow-hidden sm:rounded-md">
              <div className="px-4 py-5 bg-white sm:p-6">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6">
                    <label htmlFor="product-name" className="block text-sm font-bold text-gray-700">
                      Product Name
                    </label>
                    <input
                      type="text"
                      name="Name"
                      id="Name"
                      autoComplete="Name"
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      value={product.Name}
                      onChange={handleChanges("Name")}
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label htmlFor="Price" className="block text-sm font-bold text-gray-700">
                      Price
                    </label>
                    <input
                      type="text"
                      name="Price"
                      id="Price"
                      autoComplete="Price"
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      value={
                        localeDetails.currencySymbol +
                        (hasSizeVariants
                          ? lowestPricedVariant?.Price || "0"
                          : product.Variants[0]?.Price || "0")
                      }
                      onChange={handleChanges("Price")}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3 flex gap-4">
                    <div className="block text-sm font-bold mt-8 text-gray-700">
                      Digital Product
                    </div>
                    <Switch.Group as="div" className="flex items-center mt-6">
                      <Switch
                        checked={isDigitalProduct}
                        onChange={() => {
                          if (isDigitalProduct) setIsDigitalProduct(false);
                          else setOpen(true);
                        }}
                        className={` ${isDigitalProduct ? "bg-indigo-600" : "bg-gray-400"}
                relative inline-flex flex-shrink-0 h-4 w-8 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                      >
                        <span
                          aria-hidden="true"
                          className={`${isDigitalProduct ? "translate-x-4" : "translate-x-0"}
                  pointer-events-none inline-block h-3 w-3 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`}
                        />
                      </Switch>
                    </Switch.Group>
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <label htmlFor="CmpPrice" className="block text-sm font-bold text-gray-700">
                      Strike-off Price
                    </label>
                    <input
                      type="text"
                      name="CmpPrice"
                      id="CmpPrice"
                      autoComplete="CmpPrice"
                      disabled={hasSizeVariants}
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md disabled:bg-gray-200 disabled:cursor-not-allowed"
                      value={
                        localeDetails.currencySymbol +
                        (hasSizeVariants
                          ? lowestPricedVariant?.CmpPrice || "0"
                          : product.Variants[0]?.CmpPrice || "0")
                      }
                      onChange={handleChanges("CmpPrice")}
                    />
                  </div>

                  {!isDigitalProduct && (
                    <div className="col-span-3">
                      <label htmlFor="Stock" className="block text-sm font-bold text-gray-700">
                        Stock
                      </label>
                      <input
                        type="text"
                        name="Stock"
                        id="Stock"
                        autoComplete="Stock"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        value={product.Variants.length > 0 ? product.Variants[0].Stock : "0"}
                        onChange={handleChanges("Stock")}
                      />
                    </div>
                  )}
                  <div className="col-span-6">
                    <label htmlFor="Description" className="block text-sm font-bold text-gray-700">
                      Product Description
                    </label>
                    <div className="mt-1">
                      <ReactQuill
                        className="border border-gray-300 shadow-sm rounded-md"
                        value={product.Description}
                        onChange={(value) =>
                          setProduct((currentProduct) => ({
                            ...currentProduct,
                            Description: value,
                          }))
                        }
                      />
                    </div>
                  </div>

                  {isDigitalProduct && (
                    <div className="col-span-6">
                      <DigitalProduct
                        digitalProductURL={digitalProductURL}
                        setDigitalProductURL={setDigitalProductURL}
                      />
                    </div>
                  )}
                  <WarningModal
                    open={open}
                    setOpen={setOpen}
                    setIsDigitalProduct={setIsDigitalProduct}
                  />
                  {!isDigitalProduct && (
                    <div className="col-span-6">
                      <VariantsContent
                        colorVariants={colorVariants}
                        removeColorVariantOption={removeColorVariantOption}
                        addColorOption={addColorOption}
                        sizeVariants={sizeVariants}
                        removeSizeVariantOption={removeSizeVariantOption}
                        addSizeOption={addSizeOption}
                        generateVariantsData={generateVariantsData}
                      />
                    </div>
                  )}
                  {hasSizeVariants && !isDigitalProduct ? (
                    <div className="col-span-6">
                      <VariantsTable
                        key={"variants-table"}
                        variantsTableContainerRef={variantsTableContainerRef}
                        updateAllVariantsPriceBySize={updateAllVariantsPriceBySize}
                        sizeVariants={sizeVariants}
                        variantsData={product.Variants}
                        currencySymbol={localeDetails.currencySymbol}
                      />
                    </div>
                  ) : null}

                  <div className="col-span-6">
                    <CategoryList
                      categoryList={categories}
                      categoriesSelected={product.Categories}
                      addCategory={addCategory}
                    />
                  </div>
                </div>
              </div>
              <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                <button
                  onClick={handleSubmit}
                  className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-opacity-95 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default AddProduct;
