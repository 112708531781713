import React, { useRef } from "react";
import { PlusIcon, TrashIcon } from "@heroicons/react/solid";

import FlexibleImage from "../../../../../../components/FlexibleImage";
import { mediaUrl2ImageRes, uploadImage } from "../../../../../../lib/utils";
import type { CreateCategoryRequest, User } from "../../../../../../lib/types";

type AddCategoryBannersProps = {
  user: Readonly<User>;
  banners: CreateCategoryRequest["Banners"];
  onBannerChange: (newBanners: CreateCategoryRequest["Banners"]) => void;
};

const AddCategoryBanners = React.memo(
  ({ user, banners, onBannerChange }: AddCategoryBannersProps) => {
    const hiddenImageFileInput = useRef<HTMLInputElement>(null);

    function deleteImage(index: number) {
      const newBanners = Object.assign({}, banners);
      newBanners.splice(index, 1);
      onBannerChange(newBanners);
    }

    const handleImageUploadClick = () => {
      if (hiddenImageFileInput.current) {
        hiddenImageFileInput.current.click();
      }
    };

    const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
      if (hiddenImageFileInput.current) {
        if (e.target.files?.length) {
          const mediaUrl = await uploadImage(e.target.files[0], user.uid, true);
          if (mediaUrl != null) {
            onBannerChange([
              ...banners,
              {
                Caption: null,
                Cta: null,
                Media: mediaUrl,
              },
            ]);
          } else {
            alert("Image upload failed");
          }
        }
      }
    };

    return (
      <div className="grid grid-cols-3 gap-3 p-4">
        {banners.map((banner, i) => {
          if (i === 0) {
            return (
              <div className="relative col-span-3 flex aspect-square rounded-lg bg-gray-100 overflow-hidden">
                <FlexibleImage
                  alt="Product Image"
                  img={mediaUrl2ImageRes(banner.Media)}
                  className="w-full aspect-square object-center object-cover rounded-lg"
                  loading="lazy"
                />
                <div
                  onClick={() => deleteImage(0)}
                  className="backdrop-blur-sm absolute rounded-lg cursor-pointer"
                >
                  <TrashIcon className="text-white sm:h-5 sm:w-5 m-2" aria-hidden="true" />
                </div>
              </div>
            );
          } else {
            return (
              <div className="relative flex aspect-square rounded-lg bg-gray-100 overflow-hidden">
                <FlexibleImage
                  alt="Product Image"
                  img={mediaUrl2ImageRes(banner.Media)}
                  className="w-full h-full object-center object-cover"
                  loading="lazy"
                />
                <div
                  onClick={() => deleteImage(i)}
                  className="backdrop-blur-sm absolute rounded-lg cursor-pointer"
                >
                  <TrashIcon className="text-white sm:h-5 sm:w-5 m-1" aria-hidden="true" />
                </div>
              </div>
            );
          }
        })}
        <div className="flex aspect-square rounded-lg bg-gray-100 overflow-hidden">
          <label
            htmlFor="imageupload"
            className="flex justify-center items-center	 relative w-full border-2 border-gray-300 border-dashed rounded-lg text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <button type="button" onClick={handleImageUploadClick}>
              <PlusIcon className="sm:h-10 sm:w-10" aria-hidden="true" />
            </button>
          </label>
          <input
            ref={hiddenImageFileInput}
            onChange={handleImageChange}
            className="hidden"
            type="file"
            id="imgupload"
            name="imageupload"
            accept="image"
            style={{ display: "none" }}
          />
        </div>
      </div>
    );
  }
);

export default AddCategoryBanners;
