/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CategoryDetailsResponse } from "../../../../../../lib/types";
import FlexibleImage from "../../../../../../components/FlexibleImage";
import { mediaUrl2ImageRes } from "../../../../../../lib/utils";

type Props = {
  categoryName: string;
  open: boolean;
  setOpen: (val: boolean) => void;
  products: CategoryDetailsResponse["Products"];
  removeProductsFromCategory: (productIds: string[]) => void;
};

export default function RemoveProductsModal(props: Props) {
  const cancelButtonRef = useRef(null);
  const [selectedProduct, setSelectedProduct] = useState<string[]>([]);

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={() => props.setOpen(false)}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="sm:flex sm:items-start">
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                    Remove Products from {props.categoryName}
                  </Dialog.Title>
                  <Dialog.Description className="mt-1 text-xs">
                    Click items to remove
                  </Dialog.Description>
                  <div className="my-2 h-full max-h-72">
                    {props.products.map((product, index) => {
                      if (selectedProduct.indexOf(product.ProductId) !== -1) {
                        return null;
                      }
                      return (
                        <div
                          key={index}
                          className="my-1 p-1 inline-flex justify-start items-center cursor-pointer"
                          onClick={() => {
                            setSelectedProduct([...selectedProduct, product.ProductId]);
                          }}
                        >
                          {product.Media && (
                            <FlexibleImage
                              className="h-12 aspect-square rounded-full mx-2"
                              alt={product.Name}
                              img={mediaUrl2ImageRes(product.Media)}
                              key={index}
                              loading="lazy"
                            />
                          )}
                          <span>{product.Name}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => {
                    props.removeProductsFromCategory(selectedProduct);
                    props.setOpen(false);
                  }}
                >
                  Remove
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={() => {
                    setSelectedProduct([]);
                    props.setOpen(false);
                  }}
                  ref={cancelButtonRef}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
