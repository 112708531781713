export const colors = [
  "#433d9f",
  "#7a7cff",
  "#1e88e5",
  "#00838f",
  "#009688",
  "#4e342e",
  "#263238",
  "#3b633e",
  "#00d600",
  "#9003d6",
  "#5ea2d6",
  "#991e1f",
  "#a51b90",
  "#525423",
  "#000000",
];
