import React from "react";
import { CategoryListResponse } from "../../../../../../lib/types";

type Props = {
  categories: CategoryListResponse[];
  selectedCategories: string[];
  setSelectedCategories: (selectedCategories: string[]) => void;
};

const CategoryList = React.memo(function CategoryList(props: Props) {
  return (
    <div className="flex flex-col w-full">
      <div className="-my-2 overflow-x-auto">
        <div className="py-2 align-middle inline-block w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="text-left px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Select
                  </th>
                  <th
                    scope="col"
                    className=" text-center px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Category Name
                  </th>
                  <th
                    scope="col"
                    className="text-center px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    No of Products
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {props.categories.map((category: CategoryListResponse, index: number) => {
                  const isInCategory = props.selectedCategories.indexOf(category.Id) !== -1;
                  const toggleSelectCategory = (e: React.ChangeEvent<HTMLInputElement>) => {
                    if (e.target.checked) {
                      if (props.selectedCategories.indexOf(category.Id) === -1) {
                        props.setSelectedCategories([...props.selectedCategories, category.Id]);
                      }
                    } else {
                      if (props.selectedCategories.indexOf(category.Id) !== -1) {
                        props.setSelectedCategories(
                          props.selectedCategories.filter((id) => id !== category.Id)
                        );
                      }
                    }
                  };
                  return (
                    <tr key={index}>
                      <td className="whitespace-nowrap text-right text-sm font-medium">
                        <label
                          htmlFor={"x-checkbox-" + category.Id}
                          className="text-gray-700 px-6 py-4 w-full flex items-center"
                        >
                          <input
                            id={"x-checkbox-" + category.Id}
                            type="checkbox"
                            checked={isInCategory}
                            onChange={toggleSelectCategory}
                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                          />
                        </label>
                      </td>
                      <td className="whitespace-nowrap text-center">
                        <div className="text-sm font-medium text-gray-900 text-center">
                          {category.Name}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-center">
                        <div className="text-sm text-gray-900">{category.NProducts}</div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
});

export default CategoryList;
