import React, { useContext, useState, useEffect } from "react";
import { SubmitHandler } from "react-hook-form";
import { Switch } from "@headlessui/react";

import { AuthContext } from "../../lib/services/auth";
import { SurveyFields } from "../../lib/types";
import CustomerFormDetails, { CustomerFormFields } from "./components/CustomerFormDetails";
import { FiLoader } from "react-icons/fi";
import { NotificationContext } from "../../lib/services/notification";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const CustomerForm = () => {
  const { sellerDetails, updateSellerDetails, refreshAndReturnFirebaseToken } =
    useContext(AuthContext);

  const [isUpdatingCustomerFormDetails, setIsUpdatingCustomerFormDetails] = useState(false);
  const { addNotification } = useContext(NotificationContext);

  useEffect(() => {}, [sellerDetails]);

  const [customerFormFields, setCustomerFormFields] = useState<CustomerFormFields>({
    nameEnabled:
      sellerDetails?.WebsiteConfig?.SurveyForm?.Fields?.indexOf(SurveyFields.name) != -1
        ? true
        : false,
    emailEnabled:
      sellerDetails?.WebsiteConfig?.SurveyForm?.Fields?.indexOf(SurveyFields.email) != -1
        ? true
        : false,
    phoneEnabled:
      sellerDetails?.WebsiteConfig?.SurveyForm?.Fields.indexOf(SurveyFields.phone) != -1
        ? true
        : false,
    afterDelaySecs: 5,
    couponReward: sellerDetails?.WebsiteConfig?.SurveyForm?.CouponReward ?? null,
  });

  const [enabled, setEnabled] = useState(
    sellerDetails?.WebsiteConfig?.SurveyForm &&
      sellerDetails?.WebsiteConfig?.SurveyForm?.AfterDelaySecs != -1
      ? true
      : false
  );

  if (sellerDetails === null) {
    return <FiLoader className="animate-spin"></FiLoader>;
  }

  const onSubmit: SubmitHandler<CustomerFormFields> = async (_customerFormFieldData) => {
    setCustomerFormFields(_customerFormFieldData);
    const updatedSellerDetails = { WebsiteConfig: sellerDetails.WebsiteConfig };
    updatedSellerDetails.WebsiteConfig.SurveyForm = {
      Fields: [
        ...(!!_customerFormFieldData.nameEnabled ? [SurveyFields.name] : []),
        ...(!!_customerFormFieldData.emailEnabled ? [SurveyFields.email] : []),
        ...(!!_customerFormFieldData.phoneEnabled ? [SurveyFields.phone] : []),
      ],
      AfterDelaySecs: _customerFormFieldData.afterDelaySecs,
      CouponReward: _customerFormFieldData.couponReward,
    };
    setIsUpdatingCustomerFormDetails(true);
    const refreshedToken = await refreshAndReturnFirebaseToken();
    if (refreshedToken !== null) {
      await updateSellerDetails(refreshedToken, updatedSellerDetails);
      addNotification("success", "Successfully updated the customer form!", "StoreSettings");
    }
    setIsUpdatingCustomerFormDetails(false);
  };

  return (
    <div className="my-4 sm:px-6 lg:px-8">
      <div>
        <h1 className="text-3xl font-light leading-7 text-gray-900 my-2">Customer Form</h1>
      </div>
      <div className="w-1/2 mx-auto">
        <div className="flex-1 min-w-0 items-center">
          <h2 className="text-base font-bold leading-7 text-gray-900">
            This form will gather customer information from your website when a customer comes to
            your website for the first time.
          </h2>
        </div>
        <div className="flex items-center justify-between mt-4">
          <h3>Show Customer Form</h3>
          <Switch.Group as="div" className="flex items-center justify-start">
            <Switch.Label as="span" className="text-sm font-medium text-gray-900 px-2" passive>
              No
            </Switch.Label>
            <Switch
              checked={enabled}
              onChange={setEnabled}
              className={classNames(
                enabled ? "bg-primary" : "bg-gray-200",
                "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              )}
            >
              <span
                aria-hidden="true"
                className={classNames(
                  enabled ? "translate-x-5" : "translate-x-0",
                  "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                )}
              />
            </Switch>
            <Switch.Label as="span" className="text-sm font-medium text-gray-900 px-2" passive>
              Yes
            </Switch.Label>
          </Switch.Group>
        </div>
        {enabled ? (
          <div className="flex flex-col justify-center items-center">
            <CustomerFormDetails
              key="customer-form-details"
              next={onSubmit}
              isUpdatingCustomerFormDetails={isUpdatingCustomerFormDetails}
              initialValue={customerFormFields}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default CustomerForm;
