import React from "react";
import { Link } from "react-router-dom";
import FlexibleImage from "../../../../../../components/FlexibleImage";
import { CategoryDetailsResponse } from "../../../../../../lib/types";
import { mediaUrl2ImageRes } from "../../../../../../lib/utils";

type Props = {
  products: CategoryDetailsResponse["Products"];
};

const ProductGrid = React.memo(function ProductGrid(props: Props) {
  return (
    <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
      {props.products.map((product: CategoryDetailsResponse["Products"][0], index: number) => (
        <li
          key={index}
          className="col-span-1 bg-white rounded-lg shadow-md divide-y divide-gray-200"
        >
          <Link to={`/products/${product.Slug}`}>
            <div className="w-full flex flex-row flex-wrap items-center justify-between p-6 space-x-6">
              <div className="flex-1">
                <div className="flex items-center space-x-3">
                  <h3 className="text-gray-900 text-sm font-medium break-normal">{product.Name}</h3>
                </div>
              </div>
              <div>
                {product.Media && (
                  <FlexibleImage
                    className="w-20 aspect-square"
                    alt={product.Name}
                    img={mediaUrl2ImageRes(product.Media)}
                    key={index}
                    loading="lazy"
                  />
                )}
              </div>
            </div>
          </Link>
        </li>
      ))}
    </ul>
  );
});

export default ProductGrid;
