import React, { useContext, useState } from "react";
import { BlockPicker } from "react-color";

import { colors } from "../../../lib/constants";
import { AuthContext } from "../../../lib/services/auth";
import { FiLoader } from "react-icons/fi";
import { NotificationContext } from "../../../lib/services/notification";

const Theme = () => {
  const { sellerDetails, updateSellerDetails, refreshAndReturnFirebaseToken } =
    useContext(AuthContext);
  const { addNotification } = useContext(NotificationContext);

  const [primaryColor, setPrimaryColor] = useState(
    sellerDetails?.WebsiteConfig.PrimaryColor
      ? "#" + sellerDetails?.WebsiteConfig.PrimaryColor
      : colors[0]
  );
  const [announcementBarColor, setAnnouncementBarColor] = useState(
    "#" + sellerDetails?.Announcement?.BgColor ?? colors[0]
  );

  if (sellerDetails === null) {
    return <FiLoader className="animate-spin"></FiLoader>;
  }

  const saveTheme = async () => {
    const updatedThemeData = { WebsiteConfig: sellerDetails.WebsiteConfig };

    updatedThemeData.WebsiteConfig.PrimaryColor = primaryColor.replace("#", "");

    const refreshedToken = await refreshAndReturnFirebaseToken();
    if (refreshedToken !== null) {
      await updateSellerDetails(refreshedToken, updatedThemeData);
      addNotification("success", "Successfully updated the store theme!", "StoreSettings");
    }
  };

  return (
    <div className="my-4 sm:px-6 lg:px-8">
      <div className="w-full flex flex-col items-center justify-center">
        <div className="grid grid-cols-2 gap-4 w-full p-4">
          <div className="w-full flex flex-col items-center">
            <h6 className="text-xl font-bold mb-4">Primary Color - Buttons</h6>
            <div>
              <BlockPicker
                color={primaryColor}
                colors={colors}
                onChange={(color) => setPrimaryColor(color.hex)}
              />
            </div>
          </div>
          <div className="w-full flex flex-col items-center">
            <h6 className="text-xl font-bold mb-4">Announcement Bar Color</h6>
            <div>
              <BlockPicker
                color={announcementBarColor}
                colors={colors}
                onChange={(color) => setAnnouncementBarColor(color.hex)}
              />
            </div>
          </div>
        </div>
        <div className="w-1/5 mt-12">
          <button
            className="uppercase bg-primary text-stone-200 font-bold w-full p-2 rounded disabled:bg-gray-400"
            onClick={saveTheme}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default Theme;
