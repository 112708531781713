import React from "react";
import { FiArrowDownCircle, FiArrowUpCircle } from "react-icons/fi";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import type { DraggableProvided, DroppableProvided, DropResult } from "react-beautiful-dnd";

import { CategoryListResponse } from "../../../../../../lib/types";

type Props = {
  categories: CategoryListResponse[];
  setCategoriesOrder: (categoriesOrder: CategoryListResponse[]) => void;
};

const DnDCategoryTable = React.memo(function DnDCategoryTable(props: Props) {
  const { categories, setCategoriesOrder } = props;

  const replacePositionInReorderedState = (x: number, y: number) => {
    if (x < 0 || x >= categories.length) {
      return;
    }
    if (y < 0 || y >= categories.length) {
      return;
    }
    setCategoriesOrder(
      categories.map((val, i, arr) => {
        if (x === i) {
          return arr[y];
        }
        if (y === i) {
          return arr[x];
        }
        return val;
      })
    );
  };
  const handleOnDragEnd = (result: DropResult) => {
    if (!result?.destination) return;
    replacePositionInReorderedState(result.destination.index, result.source.index);
  };

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="categories">
        {(droppableProvided: DroppableProvided) => (
          <ul
            className="border border-gray-200 border-solid rounded-md overflow-hidden px-4 py-4"
            {...droppableProvided.droppableProps}
            ref={droppableProvided.innerRef}
          >
            {props.categories.map((category, i) => (
              <Draggable draggableId={category.Id} key={category.Id} index={i}>
                {(draggableProvided: DraggableProvided) => (
                  <li
                    key={category.Id}
                    className="hover:bg-gray-50 mb-2 bg-gray-100 flex justify-between items-center"
                    {...draggableProvided.dragHandleProps}
                    {...draggableProvided.draggableProps}
                    ref={draggableProvided.innerRef}
                  >
                    <p className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {category.Name}
                    </p>

                    <div className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <div className="flex flex-row justify-end items-center ">
                        <FiArrowUpCircle
                          onClick={() => replacePositionInReorderedState(i, i - 1)}
                          className="text-gray-500 mr-1 text-lg aspect-square hover:scale-125 hover:text-primary"
                        />
                        <FiArrowDownCircle
                          onClick={() => replacePositionInReorderedState(i, i + 1)}
                          className="text-gray-500 ml-1 text-lg aspect-square hover:scale-125 hover:text-primary"
                        />
                      </div>
                    </div>
                  </li>
                )}
              </Draggable>
            ))}
            {droppableProvided.placeholder}
          </ul>
        )}
      </Droppable>
    </DragDropContext>
  );
});

export default DnDCategoryTable;
