import React, { useState } from "react";
import { CategoryListResponse } from "../../../../lib/types";

const CategoryListModal = (props: {
  selectedItems: string[];
  setSelectedItems: (x: string[]) => void;
  data: CategoryListResponse[];
  showCategoryModal: boolean;
  setShowCategoryModal: (x: boolean) => void;
  setValue: (category: string[]) => void;
}) => {
  const [query, setQuery] = useState<string>("");

  const filterSearched = (text: string) => {
    if (props.data.length > 0) {
      const toReturn = props.data.filter((product) => {
        return product.Name.toLowerCase().includes(text.trim().toLowerCase());
      });
      return toReturn;
    } else {
      return props.data;
    }
  };

  const onItemPressed = (item: CategoryListResponse) => {
    const oldSelectedItems = props.selectedItems;
    const itemState = oldSelectedItems.includes(item.Id);
    if (!itemState) {
      oldSelectedItems.push(item.Id);
    } else {
      oldSelectedItems.splice(oldSelectedItems.indexOf(item.Id), 1);
    }
    props.setSelectedItems([...oldSelectedItems]);
    props.setValue(props.selectedItems);
  };

  return (
    <>
      <section className="mb-4">
        <label
          htmlFor="category"
          className={`block text-xs mb-1 font-medium text-left text-neutral-600`}
        >
          Applicable Categories
        </label>
        <input
          onClick={() => props.setShowCategoryModal(true)}
          id="category"
          className={`ring-1 ring-gray-300 w-full mb-1 px-4 py-2 rounded-sm`}
          value={
            props.selectedItems.length == 0
              ? "Select Categories"
              : `Selected ${props.selectedItems.length} ${
                  props.selectedItems.length > 1 ? "Categories" : "Category"
                }`
          }
          readOnly
        />
      </section>
      {props.showCategoryModal && (
        <div
          className="relative z-10"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="fixed inset-0 overflow-y-auto">
            <div
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              onClick={() => {
                props.setShowCategoryModal(false);
              }}
            ></div>
            <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <div className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-left sm:mt-0 sm:text-left w-full">
                      <div className="flex justify-center">
                        <input
                          type="search"
                          className="form-control relative flex-1 min-w-0 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded-tl rounded-bl transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-indigo-700 focus:outline-none"
                          placeholder="Search"
                          aria-label="Search"
                          aria-describedby="button-addon2"
                          value={query}
                          onChange={(e) => setQuery(e.target.value)}
                        />
                        <button
                          className="btn inline-block px-6 py-2.5 bg-indigo-700 text-white font-medium text-xs leading-tight uppercase rounded-tr rounded-br shadow-md hover:bg-indigo-800 hover:shadow-lg focus:bg-indigo-700  focus:shadow-lg focus:outline-none focus:ring-0 active:bg-indigo-800 active:shadow-lg transition duration-150 ease-in-out flex items-center"
                          type="button"
                          id="button-addon2"
                        >
                          <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="search"
                            className="w-4"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                          >
                            <path
                              fill="currentColor"
                              d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                            ></path>
                          </svg>
                        </button>
                      </div>
                      <div className="mt-4 h-60 overflow-scroll">
                        {filterSearched(query).map((item) => {
                          return (
                            <div className="form-check my-2 mx-1" key={item.Id}>
                              <input
                                className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-indigo-700 checked:border-indigo-700 focus:ring-indigo-500 text-indigo-600 transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                type="checkbox"
                                value="1"
                                id="flexCheckDefault"
                                onChange={() => onItemPressed(item)}
                                checked={props.selectedItems.includes(item.Id)}
                              />
                              <label
                                className="form-check-label inline-block text-gray-800"
                                htmlFor="flexCheckDefault"
                              >
                                {item.Name}
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    onClick={() => {
                      props.setShowCategoryModal(false);
                    }}
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-700 text-base font-medium text-white hover:bg-indigo-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CategoryListModal;
