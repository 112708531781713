import React from "react";
// import FlexibleImage from "../../../../../components/FlexibleImage";
import { CategoryListResponse } from "../../../../../../lib/types";
// import { mediaUrl2ImageRes } from "../../../../../lib/utils";

type Props = {
  categories: CategoryListResponse[];
  selectedCategories: string[];
  setSelectedCategories: (selectedCategory: string[]) => void;
};

const CategoryGrid = React.memo(function CategoryGrid(props: Props) {
  return (
    <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
      {props.categories.map((category: CategoryListResponse, index: number) => {
        const isInCategory = props.selectedCategories.indexOf(category.Id) !== -1;
        const toggleSelectCategory = (e: React.ChangeEvent<HTMLInputElement>) => {
          if (e.target.checked) {
            if (props.selectedCategories.indexOf(category.Id) === -1) {
              props.setSelectedCategories([...props.selectedCategories, category.Id]);
            }
          } else {
            if (props.selectedCategories.indexOf(category.Id) !== -1) {
              props.setSelectedCategories(
                props.selectedCategories.filter((id) => id !== category.Id)
              );
            }
          }
        };
        return (
          <li
            key={index}
            className="col-span-1 bg-white rounded-lg border border-gray-100 border-solid flex w-full "
          >
            <label
              htmlFor={"x-checkbox-" + category.Id}
              className="font-medium text-gray-700 px-2 py-2 w-full flex items-center"
            >
              <input
                id={"x-checkbox-" + category.Id}
                type="checkbox"
                checked={isInCategory}
                onChange={toggleSelectCategory}
                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
              />
              <div className="ml-4 flex-1 truncate">
                <div className="flex items-center space-x-3">
                  <h3 className="text-gray-900 text-sm font-medium truncate">{category.Name}</h3>
                </div>
              </div>
            </label>
          </li>
        );
      })}
    </ul>
  );
});

export default CategoryGrid;
