import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import { AuthContext } from "../../../../../lib/services/auth";
import { ParsedUniqueProductFields } from "../../../../../lib/types";
import { mediaUrl2ImageRes, processText, uploadImage } from "../../../../../lib/utils";
import AddProductImages from "../AddProductImages";
import VariantsContent from "../VariantsContent";

type EditProductModalProps = {
  isOpen: boolean;
  closeModal: () => void;
  updateProduct: (sku: string, updatedProduct: ParsedUniqueProductFields) => void;
  editingProduct: ParsedUniqueProductFields;
};

const EditProductModal = (props: EditProductModalProps) => {
  const { user } = useContext(AuthContext);
  const { isOpen, closeModal, editingProduct, updateProduct } = props;

  const [product, setProduct] = useState(editingProduct);
  const hiddenImageFileInput = useRef<HTMLInputElement>(null);

  const handleChanges =
    (fieldName: string) =>
    (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
      const newProduct = { ...product };
      if (fieldName === "Name") {
        newProduct.ProductName = e.target.value;
        setProduct(newProduct);
      }
      if (fieldName === "Description") {
        newProduct.Description = e.target.value;
        setProduct(newProduct);
      }
      // if (fieldName === "Price") {
      //   const processedValue = processText(e.target.value.replace("₹", ""));
      //   let value = +processedValue;
      //   if (isNaN(value)) {
      //     value = 0;
      //     return;
      //   }
      //   setProduct(newProduct);
      // }
      if (fieldName === "CmpPrice") {
        const processedValue = processText(e.target.value.replace("₹", ""));
        let value = +processedValue;
        if (isNaN(value)) {
          value = 0;
        }
        newProduct.Pricing[0].variantStrikeOffPrice = value;
        setProduct(newProduct);
      }
      if (fieldName === "Stock") {
        let value: number = +e.target.value;
        if (isNaN(+e.target.value)) {
          value = 0;
        }
        newProduct.Stock = value;
        setProduct(newProduct);
      }
    };

  const removeColorVariantOption = (colorToRemove: string) => {
    setProduct((currentProduct) => ({
      ...currentProduct,
      Colors: currentProduct.Colors.filter((color) => color != colorToRemove),
    }));
  };
  const addColorOption = (color: string) => {
    setProduct((currentProduct) => ({
      ...currentProduct,
      Colors: currentProduct.Colors.concat(color),
    }));
  };
  const removeSizeVariantOption = (sizeToRemove: string) => {
    setProduct((currentProduct) => ({
      ...currentProduct,
      Sizes: currentProduct.Sizes.filter((size) => size != sizeToRemove),
    }));
  };
  const addSizeOption = (size: string) => {
    setProduct((currentProduct) => ({
      ...currentProduct,
      Sizes: currentProduct.Sizes.concat(size),
    }));
  };

  function deleteImage(_index: number) {
    const newProduct = Object.assign({}, product);
    newProduct.ProductImages = newProduct.ProductImages.filter(
      (_image: ImageRes, index: number) => index !== _index
    );
    setProduct(newProduct);
  }

  const reorderImages = (x: number, y: number) => {
    if (x < 0 || x >= product.ProductImages.length) {
      return;
    }
    if (y < 0 || y >= product.ProductImages.length) {
      return;
    }
    setProduct({
      ...product,
      ProductImages: product.ProductImages.map((val, i, arr) => {
        if (x === i) {
          return arr[y];
        }
        if (y === i) {
          return arr[x];
        }
        return val;
      }),
    });
  };
  const handleImageUploadClick = () => {
    if (hiddenImageFileInput.current) {
      hiddenImageFileInput.current.click();
    }
  };

  const handleImageChange = async (_event: React.ChangeEvent<HTMLInputElement>) => {
    if (hiddenImageFileInput.current) {
      const files = _event.target.files;
      if (files?.length && user) {
        const imageUploadResponses = await Promise.all(
          Array.from(files)
            .filter((file: Blob) => file != null)
            .map((img) => uploadImage(img, user.uid, true))
        );
        const newProductImages: ImageRes[] = [];
        imageUploadResponses.forEach((imageRes) => {
          if (!imageRes) return null;
          const productImage: ImageRes = mediaUrl2ImageRes({
            MediaUrl1: imageRes.MediaUrl1,
            MediaUrl2: imageRes.MediaUrl2,
          });
          newProductImages.push(productImage);
        });

        const newProduct = {
          ...product,
          ProductImages: [...product.ProductImages, ...newProductImages],
        };
        setProduct(newProduct);
      }
    }
  };

  const saveChanges = () => {
    updateProduct(product.Sku, product);
    closeModal();
  };
  useEffect(() => {
    setProduct(editingProduct);
  }, [editingProduct]);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-3xl transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-xl font-bold leading-6 text-gray-900 py-4 px-6 bg-gray-200"
                >
                  Edit Product
                </Dialog.Title>
                <div className="grid grid-cols-6 gap-4 p-6">
                  <div className="col-span-6">
                    <p className="block text-sm font-bold text-gray-700">Product Images</p>
                    <AddProductImages
                      images={product.ProductImages}
                      reorderImages={reorderImages}
                      deleteImage={deleteImage}
                      handleImageChange={handleImageChange}
                      handleImageUploadClick={handleImageUploadClick}
                      hiddenImageFileInput={hiddenImageFileInput}
                    />
                  </div>
                  <div className="col-span-6">
                    <label htmlFor="product-name" className="block text-sm font-bold text-gray-700">
                      Product Name
                    </label>
                    <input
                      type="text"
                      name="Name"
                      id="Name"
                      autoComplete="Name"
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      value={product.ProductName}
                      onChange={handleChanges("Name")}
                    />
                  </div>
                  <div className="col-span-6">
                    <label htmlFor="Description" className="block text-sm font-bold text-gray-700">
                      Product Description
                    </label>
                    <div className="mt-1">
                      <ReactQuill
                        className="border border-gray-300 shadow-sm rounded-md"
                        value={product.Description}
                        onChange={(value) =>
                          setProduct((currentProduct) => ({
                            ...currentProduct,
                            Description: value,
                          }))
                        }
                      />
                    </div>
                  </div>
                  <div className="col-span-6">
                    <label
                      htmlFor="product-stock"
                      className="block text-sm font-bold text-gray-700"
                    >
                      Stock
                    </label>
                    <input
                      type="text"
                      name="Stock"
                      id="Stock"
                      autoComplete="Stock"
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      value={product.Stock}
                      onChange={handleChanges("Stock")}
                    />
                  </div>
                  <div className="col-span-6">
                    <VariantsContent
                      colorVariants={product.Colors}
                      removeColorVariantOption={removeColorVariantOption}
                      addColorOption={addColorOption}
                      sizeVariants={product.Sizes}
                      removeSizeVariantOption={removeSizeVariantOption}
                      addSizeOption={addSizeOption}
                    />
                  </div>
                </div>

                <div className="p-6 flex items-center justify-end">
                  <button
                    type="button"
                    className="inline-flex justify-center rounded-md border border-transparent bg-primary px-4 py-2 text-sm font-medium text-white hover:bg-opacity-95 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    onClick={saveChanges}
                  >
                    Save
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default EditProductModal;
