import React, { useState } from "react";
import { SubmitHandler } from "react-hook-form";

import { withTestId } from "../../lib/utils";
import OnboardingForm, { OnboardingFormFields } from "./components/OnboardingForm";

const Onboarding = (props: TestingProps) => {
  const [name, setName] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [storeUrl, setStoreUrl] = useState("");

  const handleSubmit: SubmitHandler<OnboardingFormFields> = (_onboardingFormData) => {
    setName(_onboardingFormData.name);
    setBusinessName(_onboardingFormData.businessName);
    setStoreUrl(_onboardingFormData.storeUrl);
    // TODO: call api to set data and onboarding complete
  };

  return (
    <main className="h-screen flex flex-col justify-center items-center py-12 sm:px-6 lg:px-8">
      <div className="h-auto w-auto drop-shadow-md bg-white px-16 py-8 rounded-lg">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            {...withTestId("auth-div-logo")}
            className="mx-auto h-24 w-auto"
            src="/imgbin/logo.png"
            alt="Instasell"
          />
          <h2
            {...withTestId("auth-div-heading")}
            className="mt-6 text-center text-2xl font-extrabold text-gray-900"
          >
            Enter your business details
          </h2>
        </div>
        <div data-testid={props["data-testid"]} className="my-5">
          <OnboardingForm
            businessName={businessName}
            name={name}
            storeUrl={storeUrl}
            {...withTestId("onboarding-form")}
            next={handleSubmit}
          />
        </div>
      </div>
    </main>
  );
};

export default Onboarding;
