import React from "react";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";

export type PagingControlsProps<PageFunc = (() => void) | null> = {
  nextPage: PageFunc;
  previousPage: PageFunc;
};

const PagingControls = React.memo<PagingControlsProps>(function PagingControls(props) {
  const btnStyles =
    "flex items-center rounded-md border-2 px-4 py-2" +
    " text-gray-700 bg-white hover:bg-gray-100 disabled:bg-gray-200";

  // Hide button when both buttons are disabled
  if (props.nextPage == null && props.previousPage == null) return null;

  return (
    <div className="flex justify-center">
      <button
        disabled={props.previousPage == null}
        onClick={props.previousPage ?? undefined}
        className={`${btnStyles} rounded-r-none border-r`}
      >
        <FiArrowLeft className="mr-1" /> Back
      </button>

      <button
        disabled={props.nextPage == null}
        onClick={props.nextPage ?? undefined}
        className={`${btnStyles} rounded-l-none border-l`}
      >
        Next <FiArrowRight className="ml-1" />
      </button>
    </div>
  );
});

export default PagingControls;
