import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import { withTestId, useBodyClass } from "../../../../lib/utils";
import FormInput from "../../../../components/FormInput";

type OnboardingFormProps = {
  name: string;
  businessName: string;
  storeUrl: string;
  /** Called when the user fills in the form */
  next: SubmitHandler<OnboardingFormFields>;
} & TestingProps;

export type OnboardingFormFields = {
  name: string;
  businessName: string;
  storeUrl: string;
};

/**
 * Onboarding form
 */
const OnboardingForm = React.memo(function OnboardingForm(props: OnboardingFormProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<OnboardingFormFields>({
    defaultValues: {
      name: props.name,
      businessName: props.businessName,
      storeUrl: props.storeUrl,
    },
  });

  useBodyClass(["bg-gray-50"]);

  return (
    <form data-testid={props["data-testid"]} onSubmit={handleSubmit(props.next)}>
      <FormInput
        {...withTestId("name-input")}
        id="full-name-ref"
        placeholder="Full Name"
        control={register("name", { required: true })}
        errMsg={"Please enter a name"}
        isError={errors.name != null}
      />

      <FormInput
        {...withTestId("business-name-input")}
        id="business-name-ref"
        placeholder="Business Name"
        control={register("businessName", { required: true })}
        errMsg={"Please enter an Business Name"}
        isError={errors.businessName != null}
      />

      <FormInput
        {...withTestId("storeurl-input")}
        id="storeurl-ref"
        placeholder="Store Url"
        control={register("storeUrl", { required: true })}
        errMsg={"Please enter a valid store url"}
        isError={errors.storeUrl != null}
      />

      <button
        type="submit"
        className="uppercase bg-black text-stone-200 font-bold p-2 w-full rounded"
      >
        Create Store
      </button>
    </form>
  );
});

export default OnboardingForm;
