import React from "react";

const RevenueSummary = React.memo(function () {
  return (
    <div className="grid-col-2 gap-4 flex flex-row justify-around w-full my-4 sm:px-6 lg:px-8">
      <div className="w-1/3 h-48 flex flex-grow flex-col justify-evenly items-center bg-gray-200">
        <h6>Total Revenue</h6>
        <span>$20,000</span>
      </div>
      <div className="w-1/3 h-48 flex flex-grow flex-col justify-evenly items-center bg-gray-200">
        <h6>Total Revenue</h6>
        <span>$20,000</span>
      </div>
    </div>
  );
});

export default RevenueSummary;
