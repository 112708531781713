import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { FiLoader, FiPlus, FiX } from "react-icons/fi";
import { HiOutlineSelector } from "react-icons/hi";
import { ApiContext } from "../../../../lib/services/api";
import { AuthContext } from "../../../../lib/services/auth";
import type {
  CategoryListResponse,
  CreateCategoryRequest,
  SellerDetailsResponse,
} from "../../../../lib/types";
import CategoriesTable from "./components/CategoriesTable";
import DnDCategoryTable from "./components/ReorderCategoriesTable/index";
import AddCategoryModal from "./components/AddCategoryModal";
import { NotificationContext } from "../../../../lib/services/notification";
import DeleteCategoryConfirmation from "./components/DeleteCategoryConfirmation";

const Categories = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const { sellerDetails, refreshAndReturnFirebaseToken } = useContext(AuthContext);
  const { getAllCategories, createCategory, deleteCategory, updateSellerDetails, toggleCategory } =
    useContext(ApiContext);
  const [categories, setCategories] = useState<CategoryListResponse[]>([]);
  const [categoryToDeleteSlug, setCategoryToDeleteSlug] = useState<string | null>(null);
  const [categoriesOrder, setCategoriesOrder] = useState<CategoryListResponse[]>([]);
  const [isReorderedEnabled, setIsReorderedEnabled] = useState(false);
  const { addNotification, removeNotificationByTag } = useContext(NotificationContext);

  useEffect(() => {
    const fetchCategories = async () => {
      const refreshedToken = await refreshAndReturnFirebaseToken();
      if (refreshedToken !== null) {
        const fetchedProducts = await getAllCategories(refreshedToken);
        if (fetchedProducts.Err) {
          setCategories([]);
        }
        if (fetchedProducts.Data) {
          setCategories(fetchedProducts.Data);
        }
      }
    };
    fetchCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sellerDetails, deleteCategory]);

  if (sellerDetails === null) {
    return <FiLoader className="animate-spin"></FiLoader>;
  }

  const createNewCategory = async (newCategory: CreateCategoryRequest) => {
    const refreshedToken = await refreshAndReturnFirebaseToken();
    if (refreshedToken !== null) {
      const result = await createCategory(refreshedToken, newCategory);
      if (result.Err) {
        alert(result.Err.message);
      } else {
        removeNotificationByTag("Category");
        addNotification("success", "Successfully added a new category!", "Category");
        navigate(`/categories/${result.Data?.Slug}`);
      }
    }
  };

  const toggleCategoryState = async (categoryId: string, isVisibleOnHome: boolean) => {
    const refreshedToken = await refreshAndReturnFirebaseToken();
    if (refreshedToken !== null) {
      const result = await toggleCategory(refreshedToken, categoryId, isVisibleOnHome);
      if (result.Err) {
        alert(result.Err.message);
      }
    }
  };

  const removeCategory = async (slug: string | null) => {
    if (!slug) return;
    const refreshedToken = await refreshAndReturnFirebaseToken();
    if (refreshedToken !== null) {
      const deletedCategory = await deleteCategory(refreshedToken, slug);
      if (deletedCategory.Data) {
        setCategories(categories.filter((category) => slug !== category.Slug));
        removeNotificationByTag("Category");
        addNotification("success", "Successfully deleted a category!", "Category");
      }
    }
  };

  const handleDeleteCategory = (slug: string) => {
    setCategoryToDeleteSlug(slug);
  };

  const reorderCategories = async () => {
    const refreshedToken = await refreshAndReturnFirebaseToken();
    if (refreshedToken == null) {
      return;
    }

    const mutationBodyArg = {
      CategoryOrder: categoriesOrder.map((category) => category.Id),
    } as Partial<SellerDetailsResponse>;
    await updateSellerDetails(refreshedToken, mutationBodyArg).then((payload) => {
      if (payload.Data) {
        // navigation.goBack();
      } else {
        alert("Error:" + payload.Err?.message);
      }
    });
  };

  return (
    <div className="my-4 sm:px-6 lg:px-8">
      <div className="mb-4 flex justify-between align-center content-center">
        <div>
          <h2 className="py-2 text-3xl font-light leading-8 text-gray-900 sm:text-4xl sm:truncate">
            Categories
          </h2>
        </div>
        <div className="flex justify-end mb-2 items-center">
          {isReorderedEnabled ? (
            <button
              onClick={() => {
                setIsReorderedEnabled(false);
              }}
              type="button"
              className="inline-flex items-center px-4 py-2 mr-4 text-base font-medium text-red-500 bg-white border border-red-500 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              <FiX />
              Cancel Reordering
            </button>
          ) : (
            <button
              onClick={() => {
                setCategoriesOrder(categories);
                setIsReorderedEnabled(true);
              }}
              type="button"
              className="inline-flex items-center px-4 py-2 mr-4 text-base font-medium text-white bg-primary border border-transparent rounded-md shadow-sm hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <HiOutlineSelector />
              Reorder Categories
            </button>
          )}
          <button
            onClick={() => setIsOpen(true)}
            type="button"
            className="inline-flex items-center px-4 py-2 text-base font-medium text-white bg-primary border border-transparent rounded-md shadow-sm hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <FiPlus />
            Add Category
          </button>
          <AddCategoryModal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            createCategory={createNewCategory}
          />
        </div>
      </div>
      <DeleteCategoryConfirmation
        categoryToDeleteSlug={categoryToDeleteSlug}
        setCategoryToDeleteSlug={setCategoryToDeleteSlug}
        onConfirmation={() => {
          setCategoryToDeleteSlug(null);
          removeCategory(categoryToDeleteSlug);
        }}
      />
      {isReorderedEnabled ? (
        <DnDCategoryTable categories={categoriesOrder} setCategoriesOrder={setCategoriesOrder} />
      ) : (
        <CategoriesTable
          categories={categories}
          deleteCategory={handleDeleteCategory}
          toggleCategoryState={toggleCategoryState}
        />
      )}

      {isReorderedEnabled ? (
        <div className="flex justify-end px-8 py-4">
          <button
            className="inline-flex items-center px-4 py-2 text-base font-medium text-white bg-green-500 border border-transparent rounded-md shadow-sm hover:bg-green-00 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
            onClick={() => reorderCategories()}
          >
            Save
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default Categories;
