import React, { useState, useEffect } from "react";
import Pages from "./routes";
import { ApiContext, standardApiClient } from "./lib/services/api";
import { AuthContextProvider } from "./lib/services/auth";
import { NotificationContextProvider } from "./lib/services/notification";

function App() {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return width >= 768 || process.env.NODE_ENV == "development" ? (
    <ApiContext.Provider value={standardApiClient}>
      <AuthContextProvider>
        <NotificationContextProvider>
          <Pages />
        </NotificationContextProvider>
      </AuthContextProvider>
    </ApiContext.Provider>
  ) : (
    <div className="w-screen h-screen flex flex-col justify-center items-center">
      <span className="font-medium text-lg text-center">
        To get started with our web version, come back on desktop or download the app.
      </span>
    </div>
  );
}

export default App;
