import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../lib/services/auth";
import { baseUrl } from "../../../lib/utils";
import Loading from "../../Loading";
const PaymentGatewayIntegration = () => {
  const [loading, setLoading] = useState(false);
  const [webViewUrl, setWebViewUrl] = useState("");
  const { sellerDetails, refreshAndReturnFirebaseToken } = useContext(AuthContext);
  const StripeAccountDetails = sellerDetails?.StripeAccountDetails;
  const localeDetails = sellerDetails?.LocaleDetails;

  useEffect(() => {
    if (webViewUrl) window.open(webViewUrl, "_self");
  }, [webViewUrl]);

  const stripeOnboarding = async () => {
    setLoading(true);
    const refreshedToken = await refreshAndReturnFirebaseToken();
    if (StripeAccountDetails?.AccountId) {
      const onboardingResult = await fetch(`${baseUrl}/v1/dash/link_stripe_account`, {
        method: "POST",
        body: JSON.stringify({
          accountId: StripeAccountDetails.AccountId,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${refreshedToken}`,
        },
      }).then(async (res) => {
        const result = await res.json();
        return result;
      });
      if (onboardingResult.Data) {
        setLoading(false);
        setWebViewUrl(onboardingResult.Data.url);
      }
    } else {
      const createAccount = await fetch(`${baseUrl}/v1/dash/create_stripe_account`, {
        method: "POST",
        body: JSON.stringify({
          phoneNumber: sellerDetails?.PhoneNumber,
          country: localeDetails?.CountryCode,
          currency: localeDetails?.CurrencyCode,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${refreshedToken}`,
        },
      }).then(async (res) => {
        const result = await res.json();
        return result;
      });
      if (createAccount.Data) {
        const onboardingResult = await fetch(`${baseUrl}/v1/dash/link_stripe_account`, {
          method: "POST",
          body: JSON.stringify({ accountId: createAccount.Data.id }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${refreshedToken}`,
          },
        }).then(async (res) => {
          const result = await res.json();
          return result;
        });
        if (onboardingResult.Data) {
          setLoading(false);
          setWebViewUrl(onboardingResult.Data.url);
        }
      }
    }
  };
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className="my-4 sm:px-6 lg:px-8">
          <div>
            <h1 className="text-3xl font-light leading-7 text-gray-900 my-2">
              Integrate with Stripe
            </h1>
          </div>
          <div className="flex flex-col justify-center items-center">
            <div className="w-1/2" style={{ marginTop: "5%" }}>
              {StripeAccountDetails?.AccountId && StripeAccountDetails?.Onboarded ? (
                <div>
                  <p>Your payment gateway has been linked! :D{"\n\n"}</p>
                  <br />
                  <br />
                  <p>Stripe Account Id: {StripeAccountDetails.AccountId}</p>
                  {StripeAccountDetails.Email && (
                    <p>Email Registered: {StripeAccountDetails.Email}</p>
                  )}
                </div>
              ) : (
                <div>
                  {StripeAccountDetails?.AccountId === null ? (
                    <>
                      <h3>
                        Integrate your Stripe account with us to receive payments.
                        {"\n\n"}
                        To receive international payments, make sure you do not choose your account
                        to be an Individual account.
                      </h3>
                      <button
                        className="bg-primary text-stone-200 font-bold p-2 w-full rounded disabled:bg-gray-400 mt-8"
                        onClick={stripeOnboarding}
                      >
                        Integrate your Stripe Account
                      </button>
                    </>
                  ) : (
                    <>
                      <p>
                        Your onboarding with Stripe is still incomplete. {"\n\n"}
                        Please complete your onboarding to start accepting orders.
                      </p>
                      <button
                        className="bg-primary text-stone-200 font-bold p-2 w-full rounded disabled:bg-gray-400 mt-8"
                        onClick={stripeOnboarding}
                      >
                        Integrate your Stripe Account
                      </button>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PaymentGatewayIntegration;
