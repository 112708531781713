import React, { useEffect, useState, useContext } from "react";
import DatePicker from "react-datepicker";
import { Switch } from "@headlessui/react";

import FormInput from "../../../../../components/FormInput";
import { processText } from "../../../../../lib/utils";
import { ApiContext } from "../../../../../lib/services/api";
import { AuthContext } from "../../../../../lib/services/auth";
import { CategoryListResponse } from "../../../../../lib/types";
import CategoryListModal from "../../components/CategoryListModal";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export type CouponDetailsFields = {
  name: string;
  discountType: "percentage" | "amount" | "free_shipping";
  category: string[];
  minimumOrderValue: number;
  discountValue: number | null;
  maxDiscountValue: number | null;
  startDate: Date;
  endDate: Date;
  oldCouponCode: string;
  oneCouponPerBuyer: boolean;
};

type OptionsFlags<Type> = {
  [Property in keyof Type]: boolean;
};

type CouponDetailsFieldsError = OptionsFlags<CouponDetailsFields>;

type CouponDetailsProps = {
  initialValue: CouponDetailsFields;
  /** Called when the user fills in the form */
  next: (formData: CouponDetailsFields) => void;
} & TestingProps;

const AddCouponForm = React.memo(function AddCouponForm(props: CouponDetailsProps) {
  const [formData, setFormData] = useState<CouponDetailsFields>(
    props.initialValue || {
      name: "",
      discountType: "amount",
      minimumOrderValue: 0,
      discountValue: 0,
      maxDiscountValue: null,
      startDate: new Date(),
      endDate: new Date(new Date().getTime() + 86400 * 1000),
    }
  );
  const [errors, setErrors] = useState<Partial<CouponDetailsFieldsError>>({});

  const validateForm = () => {
    if (formData === props.initialValue) return;
    const currentErrors: Partial<CouponDetailsFieldsError> = {};
    if (formData.name.length < 5) {
      currentErrors.name = true;
    }
    if (
      formData.discountType === "percentage" &&
      (!formData.discountValue || formData.discountValue <= 0 || formData.discountValue > 100)
    ) {
      currentErrors.discountValue = true;
    }
    if (formData.minimumOrderValue < 0) {
      currentErrors.minimumOrderValue = true;
    }
    if (formData.endDate.getTime() <= formData.startDate.getTime()) {
      currentErrors.endDate = true;
    }

    setErrors({ ...currentErrors });
  };

  useEffect(() => {
    validateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  const { sellerDetails, refreshAndReturnFirebaseToken, localeDetails } = useContext(AuthContext);
  const { getAllCategories } = useContext(ApiContext);
  const [categories, setCategories] = useState<CategoryListResponse[]>([]);
  const [showCategoryModal, setShowCategoryModal] = useState<boolean>(false);
  const [selectedCategories, setSelectedCategories] = useState<string[]>(
    props.initialValue?.category && Array.isArray(props.initialValue?.category)
      ? props.initialValue.category
      : []
  );
  const [allProducts, setAllProducts] = useState<boolean>(
    props.initialValue?.category && props.initialValue.category.length > 0 ? false : true
  );
  const [oneCouponPerBuyer, setOneCouponPerBuyer] = useState<boolean>(
    props.initialValue?.oneCouponPerBuyer ? props.initialValue.oneCouponPerBuyer : false
  );

  useEffect(() => {
    const fetchCategories = async () => {
      const refreshedToken = await refreshAndReturnFirebaseToken();
      if (refreshedToken !== null) {
        const fetchedProducts = await getAllCategories(refreshedToken);
        if (fetchedProducts.Err) {
          setCategories([]);
        }
        if (fetchedProducts.Data) {
          setCategories(fetchedProducts.Data);
        }
      }
    };
    fetchCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sellerDetails]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        props.next(formData);
      }}
    >
      <div className="w-full p-4 flex flex-col justify-center items-center">
        <div className="w-2/3 p-4 shadow rounded-md mb-8">
          <div className="mb-6">
            <span className="font-medium text-lg">Create Coupon</span>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <FormInput
                id="name"
                isError={errors.name != null}
                placeholder="Coupon Name"
                control={{
                  type: "text",
                  value: formData.name,
                  onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                    setFormData({ ...formData, name: e.target.value }),
                }}
                errMsg="Please enter a valid name"
              />
            </div>
            <div>
              <div>
                <label
                  htmlFor="discountType"
                  className="block text-xs mb-1 font-medium text-left text-neutral-600"
                >
                  Coupon Type
                </label>
                <select
                  id="discountType"
                  value={formData.discountType}
                  className="block w-full py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                    setFormData({
                      ...formData,
                      discountType: e.target.value as CouponDetailsFields["discountType"],
                    })
                  }
                >
                  <option value="amount">Flat Discount</option>
                  <option value="percentage">Percentage</option>
                  <option value="free_shipping">Free Shipping</option>
                </select>
              </div>
            </div>
            <div>
              <label
                htmlFor="category"
                className={`block text-xs mb-1 font-medium text-left text-neutral-600`}
              >
                Apply To
              </label>
              <div className="flex justify-center">
                <div className="form-check form-check-inline mr-2 mt-2">
                  <input
                    className="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-indigo-700 checked:border-indigo-700 focus:ring-indigo-500 text-indigo-600 transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    value="option1"
                    checked={allProducts}
                    onChange={() => {
                      setAllProducts(true);
                      setFormData({ ...formData, category: [] });
                    }}
                  />
                  <label
                    className="form-check-label inline-block text-gray-800"
                    htmlFor="inlineCheckbox1"
                  >
                    All Products
                  </label>
                </div>
                <div className="form-check form-check-inline ml-2 mt-2">
                  <input
                    className="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-indigo-700 checked:border-indigo-700 focus:ring-indigo-500 text-indigo-600 transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    value="option1"
                    checked={!allProducts}
                    onChange={() => {
                      setAllProducts(false);
                      setFormData({ ...formData, category: selectedCategories });
                    }}
                  />
                  <label
                    className="form-check-label inline-block text-gray-800"
                    htmlFor="inlineCheckbox2"
                  >
                    Specific Categories
                  </label>
                </div>
              </div>
            </div>

            {!allProducts && (
              <div>
                <CategoryListModal
                  showCategoryModal={showCategoryModal}
                  setShowCategoryModal={setShowCategoryModal}
                  data={categories}
                  selectedItems={selectedCategories}
                  setSelectedItems={setSelectedCategories}
                  setValue={(category) => {
                    setFormData({ ...formData, category });
                  }}
                />
              </div>
            )}
          </div>
        </div>
        <div className="w-2/3 p-4 shadow rounded-md mb-8">
          <div className="mb-6">
            <span className="font-medium text-lg">Coupon Details</span>
          </div>
          <div className="w-full grid grid-cols-2 gap-4">
            <div>
              <FormInput
                id="discountValue"
                isError={errors.discountValue != null}
                placeholder={
                  formData.discountType === "percentage" ? "Discount Percent" : "Discount Value"
                }
                control={{
                  type: "text",
                  value:
                    (formData.discountType === "amount" ? localeDetails.currencySymbol : "") +
                    (formData.discountValue || 0),
                  onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                    const processedValue = processText(
                      e.target.value.replace(localeDetails.currencySymbol, "")
                    );
                    let value = +processedValue;
                    if (isNaN(value)) {
                      value = 0;
                    }

                    setFormData({ ...formData, discountValue: value });
                  },
                }}
                errMsg={
                  formData.discountType === "amount"
                    ? "Please enter a valid value"
                    : formData.discountType === "percentage"
                    ? "Please enter a valid percentage(1-100)"
                    : "Please enter 0 in case of free shipping"
                }
              />
            </div>
            <div>
              <FormInput
                id="minimumOrderValue"
                isError={errors.minimumOrderValue != null}
                placeholder="Minimum Order Value"
                control={{
                  type: "text",
                  value: localeDetails.currencySymbol + (formData.minimumOrderValue || 0),
                  onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                    const processedValue = processText(
                      e.target.value.replace(localeDetails.currencySymbol, "")
                    );
                    let value = +processedValue;
                    if (isNaN(value)) {
                      value = 0;
                    }

                    setFormData({ ...formData, minimumOrderValue: value });
                  },
                }}
                errMsg="Please enter a minimum order value"
              />
            </div>
          </div>
        </div>
        <div className="w-2/3 p-4 shadow rounded-md mb-8">
          <div className="mb-6">
            <span className="font-medium text-lg">Coupon Validity</span>
          </div>
          <div className="w-full grid grid-cols-2 gap-4">
            <div>
              <div>
                <label
                  htmlFor="startDate"
                  className={`block text-xs mb-1 font-medium text-left ${
                    errors.startDate ? "text-red-500" : "text-neutral-600"
                  }`}
                >
                  {errors.startDate ? "Please enter a valid date" : "Start Date"}
                </label>
                <DatePicker
                  id="startDate"
                  placeholderText="Select start date"
                  onChange={(date: Date) =>
                    setFormData({
                      ...formData,
                      startDate: date,
                    })
                  }
                  selected={formData.startDate}
                  className={`mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${
                    errors.startDate ? "ring-red-500" : "ring-gray-300"
                  }`}
                />
              </div>
            </div>
            <div>
              <div>
                <label
                  htmlFor="endDate"
                  className={`flex flex-col text-xs mb-1 font-medium text-left text-neutral-600`}
                >
                  End Date
                  {errors.endDate ? (
                    <span className="text-red-500">Please enter a valid date</span>
                  ) : null}
                </label>
                <DatePicker
                  id="endDate"
                  placeholderText="Select end date"
                  onChange={(date: Date) =>
                    setFormData({
                      ...formData,
                      endDate: date,
                    })
                  }
                  selected={formData.endDate}
                  className={`mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${
                    errors.endDate ? "ring-red-500" : "ring-gray-300"
                  }`}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="w-2/3 p-4 shadow rounded-md mb-8">
          <div className="mb-6">
            <span className="font-medium text-lg">Usage Limits</span>
          </div>
          <div>
            <Switch.Group as="div" className="flex items-center justify-between mb-4">
              <span className="flex-grow flex flex-col">
                <Switch.Label as="span" className="text-xs font-medium text-neutral-600" passive>
                  Limit To One Use Per Customer
                </Switch.Label>
              </span>
              <Switch
                checked={oneCouponPerBuyer}
                onChange={() =>
                  setOneCouponPerBuyer((prev) => {
                    setFormData({ ...formData, oneCouponPerBuyer: !prev });
                    return !prev;
                  })
                }
                className={classNames(
                  oneCouponPerBuyer ? "bg-indigo-600" : "bg-gray-400",
                  "relative inline-flex flex-shrink-0 h-5 w-10 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    oneCouponPerBuyer ? "translate-x-5" : "translate-x-0",
                    "pointer-events-none inline-block h-4 w-4 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                  )}
                />
              </Switch>
            </Switch.Group>
          </div>
        </div>

        <div className="w-2/3">
          <button
            type="submit"
            disabled={Object.keys(errors).length > 0}
            className="uppercase bg-primary text-stone-200 font-bold p-2 w-full rounded disabled:bg-gray-400"
          >
            Create
          </button>
        </div>
      </div>
    </form>
  );
});

export default AddCouponForm;
