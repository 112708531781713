import React, { useContext, useState, useEffect } from "react";
import { SubmitHandler } from "react-hook-form";
import { FiLoader } from "react-icons/fi";
import { useNavigate, useParams } from "react-router-dom";

import { ApiContext } from "../../../../lib/services/api";
import { AuthContext } from "../../../../lib/services/auth";
import { NotificationContext } from "../../../../lib/services/notification";
import type { CouponDetails } from "../../../../lib/types";
import EditCouponForm, { CouponDetailsFields } from "./components/EditCoupon";

const EditCoupon = () => {
  const navigate = useNavigate();
  const { couponId } = useParams();
  const { sellerDetails, refreshAndReturnFirebaseToken } = useContext(AuthContext);
  const { editCoupon, fetchCoupon } = useContext(ApiContext);
  const { addNotification, removeNotificationByTag } = useContext(NotificationContext);

  const [couponDetails, setCouponDetails] = useState<CouponDetailsFields | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCouponDetails = async () => {
      if (couponId) {
        const refreshedToken = await refreshAndReturnFirebaseToken();
        if (refreshedToken) {
          const data = await fetchCoupon(refreshedToken, couponId);
          if (data.Data) {
            setCouponDetails({
              discountType: data.Data.DiscountType,
              category: data.Data.Category || [],
              discountValue: data.Data.DiscountValue ?? 0,
              endDate: new Date(data.Data.EndDate),
              minimumOrderValue: data.Data.MinimumOrderValue,
              maxDiscountValue: data.Data.MaxDiscount ?? 0,
              name: data.Data.Code,
              startDate: new Date(data.Data.StartDate),
              oldCouponCode: data.Data.Code,
              oneCouponPerBuyer: data.Data.OneCouponPerBuyer,
            });
            setLoading(false);
          }
        }
      }
    };
    fetchCouponDetails();
  }, [couponId]); //eslint-disable-line

  if (sellerDetails === null) {
    return <FiLoader className="animate-spin"></FiLoader>;
  }

  const onSubmit: SubmitHandler<CouponDetailsFields> = async (data) => {
    setCouponDetails(data);
    const coupon: CouponDetails & { OldCouponCode: string } = {
      Id: couponId!,
      Code: data.name,
      Active: true,
      DiscountType:
        data.discountType === "percentage"
          ? "percentage"
          : data.discountType === "amount"
          ? "amount"
          : "free_shipping",
      Category: data.category,
      DiscountValue: data.discountValue
        ? data.discountType === "percentage"
          ? data.discountValue / 100
          : data.discountValue * 100
        : null,
      EndDate: data.endDate.getTime() / 1000,
      MaxDiscount: data.maxDiscountValue ? data.maxDiscountValue * 100 : null,
      MinimumOrderValue: data.minimumOrderValue * 100,
      StartDate: data.startDate.getTime() / 1000,
      OldCouponCode: data.oldCouponCode,
      OneCouponPerBuyer: data.oneCouponPerBuyer,
    };

    removeNotificationByTag("Coupon");
    const refreshedToken = await refreshAndReturnFirebaseToken();
    if (refreshedToken) {
      const updatedCoupon = await editCoupon(refreshedToken, couponId!, coupon);
      addNotification("success", "Successfully updated the coupon!", "Coupon");
      if (updatedCoupon.Data) {
        navigate(-1);
      }
    }
  };

  return (
    <div className="my-4 sm:px-6 lg:px-8">
      {!loading && couponDetails ? (
        <div className="flex flex-col justify-between align-center content-center">
          <h2 className="text-3xl py-2 font-light leading-8 text-gray-900">Edit Coupon</h2>
          <div>
            <EditCouponForm initialValue={couponDetails} next={onSubmit} key="add-coupon-form" />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default EditCoupon;
