import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FiEdit, FiTrash2 } from "react-icons/fi";
import { Switch } from "@headlessui/react";

import { CategoryListResponse } from "../../../../../../lib/types";

type Props = {
  deleteCategory: (slug: string) => void;
  toggleCategoryState: (categorySlug: string, isVisibleOnHome: boolean) => void;
  category: CategoryListResponse;
};

const CategoryTableRow = ({ category, deleteCategory, toggleCategoryState }: Props) => {
  const [isVisibleOnHome, setIsVisibleOnHome] = useState(
    category.IsVisibleOnHome !== undefined ? category.IsVisibleOnHome : true
  );
  const navigate = useNavigate();
  return (
    <tr
      key={category.Id}
      className="hover:bg-gray-50 cursor-pointer"
      onClick={() => {
        navigate(`/categories/${category.Slug}`);
      }}
    >
      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
        <Link to={`/categories/${category.Slug}`}>{category.Name}</Link>
      </td>
      <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500">
        {category.NProducts}
      </td>
      <td
        onClick={(e) => e.stopPropagation()}
        className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 cursor-default"
      >
        <div className="flex flex-row justify-center items-center">
          <Switch.Group as="div" className="flex items-center my-2 mr-4">
            <Switch
              checked={true}
              onChange={() => {
                toggleCategoryState(category.Id, !isVisibleOnHome);
                setIsVisibleOnHome(!isVisibleOnHome);
              }}
              className={` ${isVisibleOnHome ? "bg-indigo-600" : "bg-gray-400"}
                relative inline-flex flex-shrink-0 h-4 w-8 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
            >
              <span className="sr-only">Use setting</span>
              <span
                aria-hidden="true"
                className={`${isVisibleOnHome ? "translate-x-4" : "translate-x-0"}
                  pointer-events-none inline-block h-3 w-3 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`}
              />
            </Switch>
          </Switch.Group>

          <FiEdit
            className="text-gray-500 mr-1 text-lg aspect-square hover:scale-125 hover:text-indigo-600"
            onClick={() => navigate(`${category.Slug}`)}
          />
          <FiTrash2
            className="text-gray-500 ml-1 text-lg aspect-square hover:scale-125 hover:text-red-500"
            onClick={(e) => {
              e.stopPropagation();
              deleteCategory(category.Slug);
            }}
          />
        </div>
      </td>
    </tr>
  );
};
export default CategoryTableRow;
