import React from "react";
import { useNavigate } from "react-router-dom";

import { OrderList } from "../../../../../lib/types";

type OrderItem = {
  order: OrderList;
  currencySymbol: string;
};

const OrderListItem: React.FC<OrderItem> = React.memo(({ order, currencySymbol = "₹" }) => {
  const navigate = useNavigate();
  return (
    <tr key={order.OrderId} onClick={() => navigate(`/orders/orders/${order.OrderId}`)}>
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="text-sm font-medium text-gray-900">#{order.OrderId}</div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="text-sm text-gray-900">{order.BuyerName}</div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="text-sm text-gray-900">
          {new Date(order.Created * 1000).toLocaleDateString("en-US")}
        </div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        {order.TotalItems > 1 ? (
          <div className="text-sm text-gray-900">{order.TotalItems} items</div>
        ) : (
          <div className="text-sm text-gray-900">{order.TotalItems} item</div>
        )}
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="text-sm text-gray-900">{order.Status}</div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="text-sm text-gray-900">
          {currencySymbol}
          {order.Total}
        </div>
      </td>
    </tr>
  );
});

export default OrderListItem;
