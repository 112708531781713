import React from "react";
import { Switch } from "@headlessui/react";
import { FiEdit, FiTrash } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

import { CouponDetails } from "../../../../../../lib/types";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

type CouponProps = {
  coupon: CouponDetails;
  index: number;
  editCoupon: (coupon: CouponDetails, index: number) => void;
  currencySymbol: string;
  deleteCoupon: (couponId: string) => void;
};

const CouponCard: React.FC<CouponProps> = ({
  coupon,
  index,
  editCoupon,
  currencySymbol = "₹",
  deleteCoupon,
}) => {
  const navigate = useNavigate();

  const updateData = () => {
    const updatedCoupon = Object.assign({}, coupon);
    updatedCoupon.Active = !updatedCoupon.Active;
    editCoupon(updatedCoupon, index);
  };

  return (
    <div className="w-full flex flex-col items-center p-4 shadow-md rounded-md">
      <div className="w-full py-1 flex flex-row justify-between items-center">
        <div className="inline-flex items-center">
          <img
            src="https://img.icons8.com/color/48/000000/discount--v1.png"
            className="h-8 w-8 mr-2"
          />
          <h6 className="font-bold text-lg">{coupon.Code}</h6>
        </div>
        <div>
          <Switch
            checked={coupon.Active}
            onChange={updateData}
            className={classNames(
              coupon.Active ? "bg-indigo-600" : "bg-gray-200",
              "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            )}
          >
            <span
              aria-hidden="true"
              className={classNames(
                coupon.Active ? "translate-x-5" : "translate-x-0",
                "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
              )}
            />
          </Switch>
        </div>
      </div>
      <div className="w-full inline-flex my-2">
        Minimum Order Value : {currencySymbol}
        {coupon.MinimumOrderValue}
      </div>
      <div className="w-full flex flex-row justify-between">
        <div className="w-2/5 flex flex-col justify-center items-center">
          <div>Valid From</div>
          <div>{new Date(coupon.StartDate).toLocaleDateString()}</div>
        </div>
        <div className="w-2/5 flex flex-col justify-center items-center">
          <div>Valid Till</div>
          <div>{new Date(coupon.EndDate).toLocaleDateString()}</div>
        </div>
      </div>
      <div className="w-full mt-4 pt-4 flex flex-row justify-between items-center border-t-2 border-dashed border-t-gray-300">
        <div className="w-2/5">
          <button
            className="bg-indigo-700 text-stone-200 font-bold p-2 w-full rounded disabled:bg-gray-400 inline-flex justify-center items-center"
            onClick={() => navigate(`/store/coupons/${coupon.Id}/edit`)}
          >
            <span>Edit</span>
            <FiEdit className="h-4 w-4 mx-2" />
          </button>
        </div>
        <div className="w-2/5">
          <button
            onClick={() => deleteCoupon(coupon.Id)}
            className="bg-red-700 text-white hover:bg-opacity-95 font-bold p-2 w-full rounded disabled:bg-gray-400 inline-flex justify-center items-center"
          >
            <span>Delete</span>
            <FiTrash className="h-4 w-4 mx-2" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default CouponCard;
