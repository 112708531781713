import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../../lib/services/auth";
import type { OrderList } from "../../../lib/types";
import { ApiContext } from "../../../lib/services/api";
import OrderListItem from "./components/OrderListItem";
import PagingControls from "../../../components/PagingControls";
import usePagination from "../../../hooks/usePagination";

const RESULT_PER_PAGE = 24;

const orderStatusList: {
  title: string;
  value: "pending" | "shipped" | "delivered" | "rejected";
}[] = [
  { title: "Pending", value: "pending" },
  { title: "Shipped", value: "shipped" },
  { title: "Delivered", value: "delivered" },
  { title: "Rejected", value: "rejected" },
];

function Orders() {
  const { sellerDetails, refreshAndReturnFirebaseToken, localeDetails } = useContext(AuthContext);
  const { getAllOrders } = useContext(ApiContext);

  const [loading, setLoading] = useState(true);
  const [orderStatus, setOrderStatus] = useState<"pending" | "shipped" | "delivered" | "rejected">(
    "pending"
  );
  const [orders, setOrders] = useState<OrderList[]>([]);

  const {
    canGoBack,
    goBack,
    nextCursor,
    nextPage,
    prevCursor,
    prevPage,
    setGoBack,
    setNextCursor,
    setPrevCursor,
    page,
    setPage,
  } = usePagination();

  const fetchOrders = async () => {
    const refreshedToken = await refreshAndReturnFirebaseToken();
    if (refreshedToken) {
      const fetchedOrders = await getAllOrders(
        refreshedToken,
        goBack ? 1 : 0,
        goBack ? null : nextCursor,
        goBack ? prevCursor : null,
        orderStatus,
        RESULT_PER_PAGE
      );
      if (fetchedOrders.Data) {
        setOrders(fetchedOrders.Data.orders);
        setNextCursor(fetchedOrders.Data.next);
        setPrevCursor(fetchedOrders.Data.prev);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sellerDetails, page, orderStatus, goBack]);

  const handlePageChange = (backPress: boolean) => {
    if (backPress) {
      prevPage();
    } else {
      nextPage();
    }
    setGoBack(backPress);
  };

  const changeStatus = (status: "pending" | "shipped" | "delivered" | "rejected") => {
    setOrderStatus(status);
    setPage(1);
  };

  return (
    <div className="my-4 sm:px-6 lg:px-8">
      {!loading && (
        <>
          <div className="mb-2">
            <h2 className="text-3xl py-2 font-light leading-8 text-gray-900 sm:text-4xl sm:truncate">
              Orders
            </h2>
          </div>
          <div className="flex flex-col space-y-4">
            <div className="flex flex-row justify-start items-center">
              {orderStatusList.map((status) => (
                <button
                  type="button"
                  className={
                    orderStatus === status.value
                      ? "items-center mx-1 w-32 px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-primary hover:bg-opacity-95 ring-2 ring-offset-2 ring-indigo-500"
                      : `items-center mx-1 w-32 px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-primary hover:bg-opacity-95`
                  }
                  onClick={() => changeStatus(status.value)}
                >
                  {status.title}
                </button>
              ))}
            </div>
            {/* <div className="flex space-x-4">
              <input
                className="border-2 border-gray-300 bg-white w-full h-10 px-5 pr-16 rounded-lg text-sm focus:indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                type="search"
                name="search"
                placeholder="Search orders"
              ></input>
            </div> */}
            <div className="flex flex-col">
              <div className="-my-2 overflow-x-auto">
                <div className="py-2 align-middle inline-block min-w-full">
                  <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Order ID
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Customer Name
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Date
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            # Of Items
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Payment Mode
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Amount
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {orders.map((order) => (
                          <OrderListItem
                            order={order}
                            currencySymbol={localeDetails.currencySymbol}
                          />
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <PagingControls
              nextPage={orders.length == RESULT_PER_PAGE ? () => handlePageChange(false) : null}
              previousPage={prevCursor == null || !canGoBack ? null : () => handlePageChange(true)}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default Orders;
