import React from "react";
import { Link } from "react-router-dom";
import FlexibleImage from "../../../../../components/FlexibleImage";
import { TypeaheadResponse } from "../../../../../lib/types";

function ProductGridView({ products }: { products: TypeaheadResponse["Suggestions"] }) {
  return (
    <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
      {products.map((product: TypeaheadResponse["Suggestions"][0], index: number) => (
        <li
          key={index}
          className="col-span-1 bg-white rounded-lg shadow-md border border-gray-200 border-solid divide-y divide-gray-200"
        >
          <div className="w-full flex items-center justify-between p-6 space-x-6">
            <div className="flex-1 truncate">
              <div className="flex items-center space-x-3">
                <h3 className="text-gray-900 text-sm font-medium truncate">{product.Name}</h3>
                <span className="flex-shrink-0 inline-block px-2 py-0.5 text-green-800 text-xs font-medium bg-green-100 rounded-full">
                  Active
                </span>
              </div>
              <p className="mt-1 text-gray-500 text-sm truncate">{`${product.Stock} in ${
                product.Variants.length
              } ${product.Variants.length! > 1 ? "variants" : "variant"}`}</p>
              <p className="mt-2 text-gray-800 font-medium text-sm truncate">₹{product.Price}</p>
            </div>
            <FlexibleImage
              className="w-20 aspect-square"
              alt={product.Name}
              img={product.Images[0]}
              key={index}
              loading="lazy"
            />
          </div>
          <div>
            <div className="-mt-px flex divide-x divide-gray-200">
              <div className="w-0 flex-1 flex">
                <Link
                  to={`/products/${product.Slug}`}
                  className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
                >
                  <span className="ml-3">Edit Product</span>
                </Link>
              </div>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
}

export default ProductGridView;
