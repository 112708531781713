import React, { useEffect } from "react";

import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/outline";
import { DetailedProductVariant } from "../../../../../lib/types";
import { classNames, processText } from "../../../../../lib/utils";

type VariantsTableProps = {
  variantsData: DetailedProductVariant[] | null;
  sizeVariants: string[] | null;
  updateAllVariantsPriceBySize: (
    size: string,
    detailsToUpdate: { Price?: number; CmpPrice?: number; Stock?: number }
  ) => void;
  currencySymbol: string;
  variantsTableContainerRef: React.Ref<HTMLDivElement>;
};

const VariantsTable = ({
  variantsData,
  sizeVariants,
  updateAllVariantsPriceBySize,
  currencySymbol = "₹",
  variantsTableContainerRef,
}: VariantsTableProps) => {
  useEffect(() => {}, [variantsData]);

  return (
    <div className="py-2 sm:py-2">
      <div ref={variantsTableContainerRef} className="divide-y-2 divide-gray-200">
        <span className="text-lg text-gray-900">Add Variants Pricing</span>
        <dl className="mt-3 space-y-2 divide-y divide-gray-200">
          {sizeVariants &&
            sizeVariants.map((size: string, _index: number) => {
              const price =
                variantsData?.find((variant) =>
                  variant.Variations.some(
                    (variation) => variation.Name === "Size" && variation.Value === size
                  )
                )?.Price || 0;
              const comparePrice =
                variantsData?.find((variant) =>
                  variant.Variations.some(
                    (variation) => variation.Name === "Size" && variation.Value === size
                  )
                )?.CmpPrice || 0;
              const stock =
                variantsData?.find((variant) =>
                  variant.Variations.some(
                    (variation) => variation.Name === "Size" && variation.Value === size
                  )
                )?.Stock || 0;
              return (
                <Disclosure as="div" key={_index} className="pt-3" defaultOpen={_index === 0}>
                  {({ open }) => (
                    <>
                      <dt className="text-lg">
                        <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                          <span className="text-sm text-gray-900">{size}</span>
                          <span className="ml-6 h-7 flex items-center">
                            <ChevronDownIcon
                              className={classNames(
                                open ? "-rotate-180" : "rotate-0",
                                "h-6 w-6 transform"
                              )}
                              aria-hidden="true"
                            />
                          </span>
                        </Disclosure.Button>
                      </dt>
                      <Disclosure.Panel as="dd" className="mt-2">
                        <div className="grid grid-cols-6 gap-6">
                          <div className="col-span-2">
                            <label
                              htmlFor="first-name"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Price
                            </label>
                            <input
                              type="text"
                              name="price"
                              id="price"
                              value={currencySymbol + (price ?? "0")}
                              onChange={(e) => {
                                if (
                                  !isNaN(+processText(e.target.value.replace(currencySymbol, "")))
                                ) {
                                  updateAllVariantsPriceBySize(size, {
                                    Price: +processText(e.target.value.replace(currencySymbol, "")),
                                  });
                                }
                              }}
                              className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>
                          <div className="col-span-2">
                            <label
                              htmlFor="first-name"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Strike-off Price
                            </label>
                            <input
                              type="text"
                              name="price"
                              id="price"
                              value={currencySymbol + (comparePrice ?? "0")}
                              onChange={(e) => {
                                if (
                                  !isNaN(+processText(e.target.value.replace(currencySymbol, "")))
                                ) {
                                  updateAllVariantsPriceBySize(size, {
                                    CmpPrice: +processText(
                                      e.target.value.replace(currencySymbol, "")
                                    ),
                                  });
                                }
                              }}
                              className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>
                          <div className="col-span-2">
                            <label
                              htmlFor="stock"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Stock
                            </label>
                            <input
                              type="number"
                              name="stock"
                              id="stock"
                              value={stock}
                              onChange={(e) => {
                                if (!isNaN(+processText(e.target.value))) {
                                  updateAllVariantsPriceBySize(size, {
                                    Stock: +processText(e.target.value),
                                  });
                                }
                              }}
                              className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>
                          <div className="col-span-3">
                            {price && comparePrice && price > comparePrice ? (
                              <p className="text-xs text-red-500">
                                Price can't be more than strike-off price!
                              </p>
                            ) : price === comparePrice && comparePrice !== 0 ? (
                              <p className="text-xs text-red-500">
                                Price can't be equal to strike-off price!
                              </p>
                            ) : null}
                          </div>
                        </div>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              );
            })}
        </dl>
      </div>
    </div>
  );
};

export default VariantsTable;
