import React, { useContext } from "react";
import { FiLogOut } from "react-icons/fi";
import { AuthContext } from "../../lib/services/auth";

const PurchasePremium = () => {
  const { logout } = useContext(AuthContext);

  return (
    <div className="w-screen h-screen flex flex-col justify-center items-center">
      <span className="font-medium text-lg text-center text-red-500">
        Purchase Premium to use desktop app!
      </span>
      <button
        className="flex items-center py-2 px-4 border border-solid border-primary text-primary rounded-md mt-4 hover:text-opacity-80 hover:border-opacity-80"
        onClick={() => logout()}
      >
        <span className="text-sm font-medium">
          <FiLogOut className="text-base mr-1 font-bold" />
        </span>
        <span className="text-base font-bold">Logout</span>
      </button>
    </div>
  );
};

export default PurchasePremium;
