import React, { useState, useContext, useEffect } from "react";
import { FiLoader } from "react-icons/fi";
import { useParams } from "react-router-dom";
import FormInput from "../../../../components/FormInput";
import PagingControls from "../../../../components/PagingControls";
import { ApiContext } from "../../../../lib/services/api";
import { AuthContext } from "../../../../lib/services/auth";
import { CategoryDetailsResponse } from "../../../../lib/types";
import AddProductsModal from "./components/AddProductsModal";
import ProductGrid from "./components/ProductGrid";
import RemoveProductsModal from "./components/RemoveProductsModal";

const EditCategory = () => {
  const { categorySlug } = useParams();

  const { sellerDetails, refreshAndReturnFirebaseToken } = useContext(AuthContext);
  const { getCategory, removeItemsFromCategory } = useContext(ApiContext);

  const [category, setCategory] = useState<CategoryDetailsResponse | null>(null);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [openRemoveProductsModal, setOpenRemoveProductsModal] = useState(false);
  const [openAddProductsModal, setOpenAddProductsModal] = useState(false);

  useEffect(() => {
    const fetchCategory = async () => {
      if (categorySlug) {
        const refreshedToken = await refreshAndReturnFirebaseToken();
        if (refreshedToken !== null) {
          const fetchedCategory = await getCategory(
            refreshedToken,
            categorySlug,
            (pageNumber - 1) * 20
          );
          if (fetchedCategory.Err) {
            setCategory({} as CategoryDetailsResponse);
          }
          if (fetchedCategory.Data) {
            setCategory(fetchedCategory.Data);
          }
        }
      }
    };
    fetchCategory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sellerDetails, pageNumber, categorySlug]);

  if (sellerDetails === null || category === null) {
    return <FiLoader className="animate-spin"></FiLoader>;
  }

  const handlePageChange = (page: number) => {
    setPageNumber(page);
  };

  const removeProductsFromCategory = async (productIds: string[]) => {
    if (categorySlug) {
      const refreshedToken = await refreshAndReturnFirebaseToken();
      if (refreshedToken !== null) {
        const fetchedCategory = await removeItemsFromCategory(
          refreshedToken,
          categorySlug,
          productIds
        );
        if (fetchedCategory.Err) {
          setCategory({} as CategoryDetailsResponse);
        }
        if (fetchedCategory.Data) {
          setCategory(fetchedCategory.Data);
        }
      }
    }
  };

  return (
    <div className="my-4 sm:px-6 lg:px-8">
      <div className="mb-8">
        <h2 className="text-3xl py-2 font-light leading-8 text-gray-900 sm:text-4xl sm:truncate">
          Edit Category
        </h2>
      </div>
      <div className="w-full my-4 p-2">
        <div className="mb-4">
          <span className="">Category Information</span>
        </div>
        <div className="grid grid-cols-2">
          <div className="w-4/5">
            <FormInput
              id="categoryName"
              isError={false}
              errMsg="Please enter a valud category name"
              placeholder="Category Name"
              control={{ onChange: () => {}, defaultValue: category.Name }}
            />
          </div>
          <div className="grid grid-cols-2 items-center">
            <div className="flex flex-row justify-center items-center">
              <button
                type="button"
                onClick={() => setOpenAddProductsModal(true)}
                className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded shadow-sm text-white bg-primary hover:bg-opacity-95 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Add Products
              </button>
            </div>
            <div className="flex flex-row justify-center items-center">
              <button
                type="button"
                onClick={() => setOpenRemoveProductsModal(true)}
                className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded shadow-sm text-white bg-primary hover:bg-opacity-95 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Remove Products
              </button>
            </div>
          </div>
        </div>
      </div>
      <ProductGrid products={category.Products || []} />
      <PagingControls
        nextPage={
          (category?.Products || []).length == 20 ? () => handlePageChange(pageNumber + 1) : null
        }
        previousPage={pageNumber == 1 ? null : () => handlePageChange(pageNumber - 1)}
      />
      <RemoveProductsModal
        categoryName={category.Name}
        open={openRemoveProductsModal}
        setOpen={setOpenRemoveProductsModal}
        products={category.Products || []}
        removeProductsFromCategory={removeProductsFromCategory}
      />
      <AddProductsModal
        slug={sellerDetails.Slug}
        categoryName={category.Name}
        open={openAddProductsModal}
        setOpen={setOpenAddProductsModal}
        addProductsToCategory={removeProductsFromCategory}
      />
    </div>
  );
};

export default EditCategory;
