import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import FormInput from "../../../../components/FormInput";
import { withTestId } from "../../../../lib/utils";

export type ShippingPartnerFields = {
  email: string;
  password: string;
};

type ShippingPartnerProps = {
  initialValue: ShippingPartnerFields;
  /** Called when the user fills in the form */
  next: SubmitHandler<ShippingPartnerFields>;
  isShippingPartnerDataUpdated: boolean;
  isUpdatingShippingPartnerData: boolean;
} & TestingProps;

const ShippingPartnerForm = React.memo(function ShippingPartnerForm(props: ShippingPartnerProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ShippingPartnerFields>({
    defaultValues: props.initialValue ?? undefined,
  });

  return (
    <form onSubmit={handleSubmit(props.next)} className="w-1/2">
      <FormInput
        {...withTestId("email-input")}
        id="email"
        placeholder="Shiprocket API Email"
        control={{
          type: "email",
          ...register("email", {
            required: true,
            pattern:
              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          }),
        }}
        errMsg={"Please enter a valid email"}
        isError={errors.email != null}
      />
      <FormInput
        {...withTestId("password-input")}
        id="password"
        placeholder="Shiprocket API Password"
        control={{
          type: "password",
          ...register("password", {
            required: true,
          }),
        }}
        errMsg="Please enter a password that contain at least 6 characters, 1 uppercase, 1 lowercase and 1 number"
        isError={errors.password != null}
      />
      {props.isShippingPartnerDataUpdated ? (
        <p className="px-4 py-2 my-4 text-green-500 bg-green-500 border border-green-500 border-solid rounded bg-opacity-5">
          Successfully updated shipping Parnter Data!
        </p>
      ) : null}
      <button
        type="submit"
        disabled={Object.keys(errors).length > 0 || props.isUpdatingShippingPartnerData}
        className="uppercase bg-primary text-stone-200 font-bold p-2 w-full rounded disabled:bg-gray-400"
      >
        {props.isUpdatingShippingPartnerData ? "Saving..." : "Integrate Shiprocket"}
      </button>
    </form>
  );
});

export default ShippingPartnerForm;
