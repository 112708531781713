import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../../../lib/services/auth";
import { NotificationContext } from "../../../../../../lib/services/notification";
import { SellerBanner } from "../../../../../../lib/types";
import { uploadImage } from "../../../../../../lib/utils";
import ImageTile from "./ImageTile";

export type CategoryBannersProps = {
  banners: SellerBanner[];
  saveBanners: (banners: SellerBanner[]) => Promise<void>;
};

const CategoryBanners = (props: CategoryBannersProps) => {
  const { user } = useContext(AuthContext);
  const { addNotification } = useContext(NotificationContext);
  const [banners, setBanners] = useState(props.banners ?? []);

  useEffect(() => {
    if (props.banners) {
      setBanners(props.banners);
    }
  }, [props.banners]);

  const handleBannersUpload = async (files: FileList) => {
    if (!files || !user) {
      return;
    }

    const imageUploadResponses = await Promise.all(
      Array.from(files)
        .filter((file: Blob) => file != null)
        .map((img) => uploadImage(img, user.uid, true))
    );

    if (imageUploadResponses !== null && banners) {
      const newBanners: SellerBanner[] = [];
      imageUploadResponses.forEach((imageRes) => {
        if (!imageRes) return null;
        const banner: SellerBanner = {
          Media: {
            MediaUrl1: imageRes.MediaUrl1,
            MediaUrl2: imageRes.MediaUrl2,
            ObjectFit: "cover",
          },
          Caption: null,
          Cta: null,
        };
        newBanners.push(banner);
      });
      setBanners([...banners, ...newBanners]);
      await props.saveBanners([...banners, ...newBanners]);
      addNotification(
        "success",
        `Successfully added ${newBanners.length} banner${newBanners.length > 1 ? "s" : ""}!`,
        "Categories"
      );
    }
  };

  const handleBannerReplace = async (idx: number, files: FileList) => {
    const img = files[0];
    if (!img || !user) {
      return;
    }
    const imageRes = await uploadImage(img, user.uid, true);
    if (imageRes !== null && banners) {
      const updatedBanners = banners
        ? banners.map((banner: SellerBanner, i) => {
            if (i !== idx) return banner;
            const newBanner: SellerBanner = {
              Media: {
                MediaUrl1: imageRes.MediaUrl1,
                MediaUrl2: imageRes.MediaUrl2,
                ObjectFit: "cover",
              },
              Caption: null,
              Cta: null,
            };
            return newBanner;
          })
        : [];
      setBanners(updatedBanners);
      await props.saveBanners(updatedBanners);
      addNotification("success", "Successfully replaced the category banner!", "Categories");
    } else {
      alert("Image upload failed");
    }
  };

  const handleRemoveBanner = async (index: number) => {
    const updatedBannersList = banners.slice();
    updatedBannersList.splice(index, 1);
    setBanners(updatedBannersList);
    await props.saveBanners(updatedBannersList);
    addNotification("success", "Successfully removed the category banner!", "Categories");
  };

  return (
    <>
      <h3 className="text-2xl py-2 font-light leading-8 text-gray-900 sm:truncate">
        Category Banners
      </h3>
      <div className="grid grid-cols-2 gap-4 py-4 lg:grid-cols-3">
        {banners.map((b, i) => {
          return (
            <ImageTile
              key={i}
              uniqueId={`banner-${i}`}
              allowMultiple={false}
              initialImageDataUri={b.Media.MediaUrl1}
              onUpload={(img) => {
                handleBannerReplace(i, img);
              }}
              onDelete={() => {
                handleRemoveBanner(i);
              }}
            />
          );
        })}

        <ImageTile
          uniqueId={"new-banner"}
          initialImageDataUri={null}
          allowMultiple={true}
          onUpload={handleBannersUpload}
        />
      </div>
    </>
  );
};

export default CategoryBanners;
