import React from "react";
import { ProductListResponse } from "../../../../../lib/types";
import ProductListRow from "../ProductListRow";

type Props = {
  products: ProductListResponse[];
  toggleProductState: (productId: string, isActive: boolean) => void;
};

function ProductListView({ products, toggleProductState }: Props) {
  return (
    <div className="flex flex-col w-full">
      <div className="-my-2 overflow-x-auto">
        <div className="py-2 align-middle inline-block w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Product Name
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Price
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Stock
                  </th>
                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {products.map((product: ProductListResponse, index: number) => (
                  <ProductListRow
                    product={product}
                    key={index}
                    toggleProductState={toggleProductState}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductListView;
